import React,{useEffect, useState} from 'react'
import { toast } from 'react-hot-toast'
import { ButtonFilledAutoWidth } from '../../../components/button'
import { TextInput } from '../../../components/input'
import { useNavigate, useLocation } from 'react-router-dom'
import GoBack from '../../../components/back/GoBack'
import { CreateFeatureOptionService, UpdateFeatureOptionService, UploadFeatureOptionService } from '../../../services/ITServices/FeatureOption/FeatureOptionServices'
import ItMenu from '../ItMenu'
import Uploader from '../../../components/Uploader'

function FeatureOptionCE() {
 
  const [data,setdata] = useState({name:'',image:'',});  
  const [error,seterror] = useState({name:'',image:''});
  const [type,settype] = useState("");
  
  const {pathname}  = useLocation()
  const path = pathname.split('/')[pathname.split('/').length - 2]

  const {state} = useLocation();
  const navigate = useNavigate();

  useEffect(()=>{
    if(path == 'brand'){
      settype("Brand")
    }
    if(path == 'processor'){
      settype("Processor")
    }
     if(path == 'purchase_type'){
       settype("Purchase Type")
     }
     if(path == 'device_type'){
       settype("Device Type")
     }
     if(path == 'device_stage'){
       settype("Device Stage")
     }
     if(path == 'device_status'){
      settype("Device Status")
    }
   },[path])

  useEffect(()=>{
    if(state?._id !== null && state?._id !== undefined){
      setdata({...data,...state})
    }
  },[state])

  function handlechange(e){
    setdata({...data,[e.target.name] : e.target.value})
    seterror({...error,[e.target.name]:''})
  }

  async function submitform(){
    if(!data.name){
        seterror({...error,name:'Name field is required *'})
    }else{
        if(state?._id === undefined || state?._id === null){
        let send_data = {...data}
        send_data['type'] = type
        const response = await CreateFeatureOptionService(send_data)
        if (response.status === 201) {
          setdata({...data,name:''})
          seterror({...error,name:''})
          toast.success(`${type} Created Successfully`)
        }   
        if(response.status === 422){
          seterror({...error,name:response?.data?.errors?.name})
        } 
      }else{
        let send_data = {...data}
        send_data["_id"] = state?._id
        const response = await UpdateFeatureOptionService(send_data,state?._id)
        if (response.status === 200) {
          setdata({...data,name:''})
          seterror({...error,name:''})
          navigate(-1)
          toast.success(`${type} Updated Successfully`)
        }   
        if(response.status === 422){
          seterror({...error,name:response?.data?.errors?.name})
        } 
      }
  }
  }

  
  async function uploadfilefunc(v,name){
    const fd = new FormData()
    fd.append('file',v); 
    const response = await UploadFeatureOptionService(fd)
    if(response?.status === 200){
    setdata({...data,[name]:response?.data?.data})
    }
  }

  return (
    <div className='flex'>


      <ItMenu />
   
    <div className='sm:w-full lg:w-72 px-4 pt-5' >


        <GoBack /> 

        <h6 className='font-[700]'>{(state?._id !== null && state?._id !== undefined) ? 'Edit' : 'Add'} {type}</h6>
        <h6 className='text-[10px] bg-slate-100 p-2 font-[500] leading-snug' >Use the below form to create or edit the <b> {type}</b> for your comapny employees.</h6>
     

        <TextInput 
            label={'Name'}  
            variant="standard"
            name="name"
            type="text"
            mandatory={true}
            error={error.name}
            value={data.name}
            handlechange={handlechange}
            placeholder="Enter your name"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>

          {['Processor','Brand']?.includes(type) && 
          <>
          <h6 className='text-[11px] font-[600] mb-1 mt-2' >Image</h6>    
          <Uploader image={data?.image}  setimagefunc={(e)=>{uploadfilefunc(e,'image');seterror({...error,image:''})}}  removeimageuploadfunc = {()=>{setdata({...data,image:''});seterror({...error,image:''})}}/>    
          </>}

        <div className='mt-5'>
        <ButtonFilledAutoWidth btnName={(state?._id !== null && state?._id !== undefined) ? `Update ${type}` : `Add ${type}`}  onClick={submitform} />  
        </div>


    </div>
    </div>
  )
}

export default FeatureOptionCE