import { IconButton, Tooltip, setRef } from '@mui/material'
import React, { useEffect, useState } from 'react'
import DashboardMenu from './DashboardMenu'
import { GetAllAssignedLeadsBasedStageService } from '../../services/AssignLeadServices'
import { useSelector } from 'react-redux'
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { DatePicker } from 'antd';
import { ButtonOutlinedAutoWidth } from '../../components/button'
import { GetDepartmentService } from '../../services/DepartmentServices'
import { Select } from 'antd'
import { useNavigate } from 'react-router-dom'
import { AiOutlineEdit } from 'react-icons/ai';

function MdLeadsData() {
  

  const [step,setstep] = useState(1)
  const [page,setpage] = useState(1)
  const [selected_department,setselected_department] = useState(null)
  const [selected_user,setselected_user] = useState(null)

  const navigate = useNavigate()

  const [datas,setdatas] = useState({})
  const [departments,setdepartments] = useState([])
  const [users,setusers] = useState([])

  const user = useSelector(state=>state.Auth)
  const roles = useSelector(state=>state.Auth.roles)

  const [search,setsearch] = useState({text:'',from_date:'',from_date1:'',to_date:'',to_date1:'',zone:'',country:'',activate:false})

  useEffect(()=>{
     if(user?.roles?.includes('hod') &&  !user?.roles?.includes('admin')){
        setselected_department({label:user?.department_id[0]?.department_name,value:user?.department_id[0]?.id})
        getdata(user?.department_id[0]?.id)
     }else{
        getdepartment()
     }
  },[])

  useEffect(()=>{
    getdata()
  },[page,step,selected_department,selected_user])

  console.log("page",page)

  async function getdepartment(){
    const response = await GetDepartmentService();
    let arr = []
    response?.data?.datas.forEach((d)=>{
        if(d?.department_name !== 'Finance Team'){
            arr.push({label:d?.department_name,value:d?.id})
        }
    })
    setdepartments(arr)
}

  async function getdata(v) {
    if(v !== undefined || selected_department?.value !== undefined){
      const response = await GetAllAssignedLeadsBasedStageService(v !== undefined ? v : selected_department !== null ? selected_department?.value : '',page,step,selected_user !== null ? selected_user?.value : '')
      setdatas(response?.data?.data)
    }else if(user?.roles?.includes('admin')){
      const response = await GetAllAssignedLeadsBasedStageService(selected_department !== null ? selected_department?.value : '',page,step,selected_user !== null ? selected_user?.value : '')
      setdatas(response?.data?.data)
    }
   
  }

  async function applyfilterfunction() {
    setpage(1)
    const response = await GetAllAssignedLeadsBasedStageService(selected_department !== null ? selected_department?.value : '',1,step,selected_user !== null ? selected_user?.value : '')
    setdatas(response?.data?.data)
  }

  async function resetfunc() {
    const response = await GetAllAssignedLeadsBasedStageService('',1,step,'')
    setdatas(response?.data?.data)
  }

  async function handlechange(val,type){
    if(type === 'department'){
        let department = departments.find(d=>d.value === val)
        setselected_department(department)
        setpage(1)
    }else if(type === 'user'){
        setpage(1)
        setselected_user(users.find(d=>d.value === val))
    }
}

  return (
    <div className='flex max-h-screen min-h-screen'>
        <DashboardMenu />
        <div className='mx-5 mt-5 w-[83%] mb-10'>

            <div>
                <div className='flex items-center justify-between border-b pb-2 '>
                   <h6 className='font-[700] text-[14px] '>MD Leads ({datas?.pagination?.total})</h6>

                   <div className='flex items-center'>
              <div className='flex items-center text-[12px] mr-2'>

                <h6 className='mr-2 font-[600]'>{page == 1 ? datas?.datas?.length > 0 ? 1 : 0 :  (page - 1) * datas?.pagination?.limit } - {datas?.pagination?.limit} of {datas?.pagination?.total} </h6>
                <IconButton onClick={resetfunc}><BsArrowRepeat size={16} /></IconButton>

                <div>
                <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                <IconButton onClick={()=>{ page < datas?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(datas?.pagination?.totalPages === page || datas?.datas?.length === 0)  ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>

                </div>
              </div>


              <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.from_date} onChange={(v,v1) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,from_date:v,from_date1:v1})}} /> 

              <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.to_date} onChange={(v,v1) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,to_date:v,to_date1:v1})}} /> 

            
            <div className='mr-1'>
                

              <input  id="search_text" placeholder='Search text' type='text' value={search.text} onChange={(e)=>setsearch({...search,text:e.target.value})} className='border py-[3px] focus:ring-0 focus:outline-0 text-[14px]  w-28 px-1 rounded-md border-slate-300' />
            
            
            </div> 

            {(user?.roles?.includes('admin')) &&
                <div className='border border-slate-300 mr-1 rounded-md py-[2px]'>
                <Select
                    placeholder='Assigned Department'
                    bordered={false}
                    allowClear={true}
                    onClear={()=>setselected_department(null)}
                    size='small'
                    defaultValue={selected_department}
                    value={selected_department}
                    style={{ width: 110 }}
                    onChange={(v)=>handlechange(v,'department')}
                    options={departments}
                />
            </div>}
           

          <ButtonOutlinedAutoWidth btnName="Add Filter" onClick={()=>applyfilterfunction(1)} /> 
        
        
            </div>
                </div>
            </div>
            
            {selected_department?.label === 'Transaction Team' &&
            <div className='border-b'>
                <span onClick={()=>setstep(1)} className={`text-[13px] font-[500] cursor-pointer px-4 p-1.5 w-[100px] ${step === 1 && 'bg-slate-700 text-[#fff]'}`}>Commercial</span>
                <span onClick={()=>setstep(2)} className={`text-[13px] font-[500] cursor-pointer px-4 p-1.5 w-[100px] ${step === 2 && 'bg-slate-700 text-[#fff]'}`}>Resedentail</span>
                <span onClick={()=>setstep(3)} className={`text-[13px] font-[500] cursor-pointer px-4 p-1.5 w-[100px] ${step === 3 && 'bg-slate-700 text-[#fff]'}`}>ILS</span>
                <span onClick={()=>setstep(4)} className={`text-[13px] font-[500] cursor-pointer px-4 p-1.5 w-[100px] ${step === 4 && 'bg-slate-700 text-[#fff]'}`}>Database</span>
                <span onClick={()=>setstep(5)} className={`text-[13px] font-[500] cursor-pointer px-4 p-1.5 w-[100px] ${step === 5 && 'bg-slate-700 text-[#fff]'}`}>All Data</span>
            </div>}


            {datas?.datas?.length > 0 &&
            <div className='max-h-[82vh] mt-4 border-t   border-l border-r overflow-y-scroll'>
                 <div className='sticky top-0 z-50 flex  py-[-5px] border-b bg-white border-slate-200 relative'>
                        <h6 className='sticky top-0 z-50 text-[12px] w-[5%]  px-2 py-1 font-[600] text-slate-600 border-r '>Sl No</h6>
                        <h6 className='sticky top-0 z-50  text-[12px] w-[15%] px-2 py-1 font-[600] text-slate-600 border-r'>Company Name</h6>
                        <h6 className='sticky top-0 z-50  text-[12px] w-[15%]  px-2 py-1 bg-gray-50 font-[600] text-slate-600 border-r'>Contact Person Info</h6>
                        <h6 className='sticky top-0 z-50  text-[12px] w-[15%] px-2 py-1 font-[600] text-slate-600 border-r'>Created Department</h6>
                        <h6 className='sticky top-0 z-50  text-[12px] w-[15%]  px-2 py-1 font-[600] text-slate-600 border-r'>Assigned Department</h6>
                        <h6 className='sticky top-0 z-50  text-[12px] w-[15%]  px-2 py-1 font-[600] text-slate-600  border-r'>Remarks</h6>
                        <h6 className='sticky top-0 z-50  text-[12px] w-[10%]  px-2 py-1 font-[600] text-slate-600  border-r'>Stage</h6>
                        <h6 className='sticky top-0 z-50  text-[12px] w-[10%]  px-2 py-1 font-[600] text-slate-600 sticky right-0'>Status</h6>
                    </div>
                <div>

                {datas?.datas?.map((d,i)=>(
                    <div key={d?._id} className='flex z-50 border-b border-slate-200 '  >
                        <h6 className='text-[12px] font-[500] w-[5%]  px-2 py-1  border-r border-slate-200 '>{(page > 1 ? i+1+ (25 * (page - 1)) : i+1 )}</h6>
                        <h6 className='text-[12px] font-[500] w-[15%] px-2 py-1 font-[800] border-r border-slate-200'>{d?.fidelitus_lead?.company_name}
                        {d?.zone !== undefined && <h6 className='text-[10px]'>Zone : {d?.fidelitus_lead?.zone}</h6>}</h6>
                        <h6 className='text-[12px] font-[500] w-[15%] bg-gray-50  px-2 py-1 break-all border-r border-slate-200'>
                            <div>
                            <h6>{d?.fidelitus_lead?.contact_name}</h6>  
                            <span>{d?.fidelitus_lead?.phone} / {d?.fidelitus_lead?.email}  </span>
                            </div>
                        </h6>
                        <h6 className='text-[11px] font-[500] w-[15%]  px-2 py-1 font-[800] border-r border-slate-200'>
                            {d?.fidelitus_lead?.department_id?.department_name}
                            <h6 className='text-[10px]'>Created By : {d?.fidelitus_lead?.lead_owner?.name}</h6>
                            {step == 4 && <h6 className='text-[10px] bg-green-100 p-1'>Lead Type : {d?.lead_type}</h6>}
                            <h6 className='text-[10px] font-[500]'> Previously Handled by : {d?.fidelitus_lead?.previously_handled_by}</h6>
                        </h6>
                        <h6 className='text-[11px] font-[500] w-[15%]  px-2 py-1 font-[800] border-r border-slate-200'>
                        {d?.department_id?.department_name} <br></br>
                        Handled by : {d?.assigned_to !== undefined ? d?.assigned_to?.name : 'Not Added'}
                        
                        </h6>
                        <h6 className='text-[11px]  w-[15%]  px-2 py-1  border-r border-slate-200'>
                            Requirment :  <span className='font-[800]'>{d?.requirment}</span>  /
                            Remarks :  <span className='font-[800]'>{d?.remarks}</span>

                           {d?.converted == '1' && <h6 onClick={()=>navigate('/leads/contacts/detail',{state:d?.lead_id?._id === undefined ? d?.lead_id : d?.lead_id?._id})} className='text-[10px] cursor-pointer font-[500]  mt-1 underline text-blue-500'>View Details</h6>}

                        </h6>  
                        <h6 className='text-[11px]  w-[10%]  px-2 py-1  border-r border-slate-200'>
                           {d?.converted == '1' && <h6>{(d?.lead_id?.stage?.name)} 
                            <br></br>{d?.lead_id?.requirment !== null  && `Remarks : ${d?.lead_id?.requirment}`}
                            </h6>}
                        </h6> 

                        
                        <h6 className='text-[12px]  font-[500] w-[10%]  px-2 py-1 truncate border-slate-200 '>
                            {d?.converted == '3' && <span className='bg-blue-100 flex w-max items-center h-max px-[6px] py-[2px] rounded text-[10px] text-blue-600 font-[700]'>Pending</span>}
                            {d?.converted == '2' && <span className='bg-red-100 flex w-max items-center h-max px-[6px] py-[2px] rounded text-[10px] text-red-600 font-[700]'>Rejected</span>}
                            {d?.converted == '1' && <span className='bg-green-100 flex w-max items-center h-max px-[6px] py-[2px] rounded text-[10px] text-green-600 font-[700]'>Converted</span>}
                            {roles?.includes('admin') &&
                            <AiOutlineEdit className='mt-1 ml-1' onClick={()=>navigate('/assign_leads_remark/list/edit',{state:d})} />}
                        </h6>
                    </div>))}

                </div>
            </div>}   
      




        </div>      
    </div>
  )
}

export default MdLeadsData