import React, { useEffect, useState } from 'react'
import DashboardMenu from './DashboardMenu'
import { Modal,Select } from 'antd';
import { CreateCalendarService, DeleteCalendarService, GetCalendarTodayWorkService, UpdateCalendarService, UploadCalendarService } from '../../services/CalendarServices'
import { IconButton } from "@mui/material";
import { BsArrowRepeat } from 'react-icons/bs';
import { FiChevronRight,FiChevronLeft } from 'react-icons/fi';
import { BiFilterAlt } from "react-icons/bi";
import { RxReset } from "react-icons/rx";
import { AiOutlineEdit,AiOutlineDownload,AiOutlineClose,AiOutlineDelete,AiOutlineClockCircle } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { TbNotes } from "react-icons/tb";
import { ButtonFilledAutoWidth } from '../../components/button';
import fileDownload from "js-file-download";
import axios from 'axios';
import { TbBrandGoogleDrive } from "react-icons/tb";
import { MdOutlineLocationOn,MdOutlineDescription } from "react-icons/md";
import ErrorComponent from '../../components/errorDesign/ErrorComponent';
import toast from 'react-hot-toast';
import { LuUserSquare2 } from "react-icons/lu";
import { MdOutlineGroup } from "react-icons/md";
import { HiOutlineMenuAlt4 } from "react-icons/hi";
import { RiChatFollowUpLine } from "react-icons/ri";
import { useSelector } from 'react-redux';
import { GetSearchService } from '../../services/AuthServices';
import moment from 'moment';


function DashboardReport() {

  const roles = useSelector(state=>state.Auth.roles)
  const userId = useSelector(state=>state.Auth.id)
  
      

  const navigate = useNavigate()  
  const [page,setpage] = useState(1)

  const [users,setusers] = useState([])
  const [departments,setdepartments] = useState([])

  const [datas,setdatas] = useState({})
  const [dataModal,setdataModal] = useState(false)   
  const [showMore,setshowMore] = useState(false)   

  const [calendar_report,setcalendar_report] = useState({year:'',month:'',no_of_days:'',start_date_index:'',from_date:'',to_date:''})

  const [data,setdata] = useState({reminderTo:[],remarks:'',title:'',description:'',location:'',photos:[],time:'',date:'',year:'',date_time:'',type:'',date1:'',time:'',date2:'',time1:'',department:''})
  const [error,seterror] = useState({reminderTo:'',remarks:'',title:'',description:'',location:'',photos:'',time:'',date:'',year:'',date_time:'',type:'',date1:'',time:'',date2:'',time1:'',department:''})
  

  useEffect(()=>{
    getdata()
  },[page])
  
  async function getdata() {
    const response = await GetCalendarTodayWorkService(page)
    setdatas(response?.data)
  }  

  async function searchuser(v) {
    const response = await GetSearchService(v,1)
    let arr = []
    let d = response?.data?.datas
    d?.forEach((d1)=>{
        arr?.push({label:d1?.name,value:d1?._id})
    })
    setusers(arr)
  }

  async function handleSelect(v) {
    let arr  = []
    v?.forEach((d)=>{
        if(users.find((f)=>f?.value == d) !== null){
            arr?.push(users.find((f)=>f?.value == d)) 
        }else{
            if(data?.reminderTo.find((f)=>f?.value == d) !== null){
                arr?.push(data?.reminderTo.find((f)=>f?.value == d)) 
            }
        }
    })

    
    setdata({...data,reminderTo:arr})
  }

  async function resetfilter(){

  }

  async function applyfilterfunction() {
    
  }

  async function uploadFile(v) {
    const fd = new FormData()
    fd.append('file',v); 
    const response = await UploadCalendarService(fd)
    if(response?.status === 200){
      let datas = [...data.photos] 
      datas.push(response?.data?.data)
      setdata({...data,photos:datas})
      seterror({...error,photos:''})
    }
  }

  async function submit() {
    if(!data.title){
        seterror({...error,title:'This Field is required *'})
    }else if(!data.date1){
        seterror({...error,date1:'This Field is required *'})
    }else if(!data.time){
        seterror({...error,time:'This Field is required *'})
    }else{
        

            if(data?._id === undefined){
                let sendData = {...data}
                sendData['date'] = parseInt(data?.date1?.slice(8,10))
                sendData['year'] = parseInt(data?.date1?.slice(0,4))
                sendData['month'] = parseInt(data?.date1?.slice(5,7)) - 1

                if(data?.date1 !== '' && data?.time !== ''){
                    sendData['date_time'] = data?.date1 !== undefined && data?.date1 + ' ' + data?.time
                }

                if(data?.reminderTo?.length > 0){
                    let arr = []
                    data?.reminderTo?.forEach((d1)=>{
                        arr?.push(d1?.value)
                    })
                    sendData['reminderTo'] = arr

                }else{
                    delete sendData?.reminderTo
                }

                if(data?.department !== ''){
                    sendData['department'] = data?.department?.value
                }else{
                    delete sendData?.department
                }

            const response = await CreateCalendarService(sendData)
            if(response?.status === 201){
                toast.success("Calendar Created Successfully!")
                resetform()
                setdataModal(false)
            }
        }else{
            let sendData = {...data}
            sendData['date'] = parseInt(data?.date2?.slice(8,10))
            sendData['year'] = parseInt(data?.date2?.slice(0,4))
            sendData['month'] = parseInt(data?.date2?.slice(5,7)) - 1

            if(data?.date1 !== '' && data?.time !== ''){
                sendData['date_time'] = data?.date1 !== undefined && data?.date1 + ' ' + data?.time
            }
      
            if(data?.reminderTo?.length > 0){
                let arr = []
                data?.reminderTo?.forEach((d1)=>{
                    arr?.push(d1?.value)
                })
                sendData['reminderTo'] = arr

            }else{
                delete sendData?.reminderTo
            }

            if(data?.department !== ''){
                sendData['department'] = data?.department?.value
            }else{
                delete sendData?.department
            }

            const response = await UpdateCalendarService(sendData,data?._id)
            if(response?.status === 200){
                toast.success("Calendar Updated Successfully!")
                resetform()
                setdataModal(false)
            }
        }
    }
    getdata(calendar_report?.month,calendar_report?.year)
  }

  function removeimage(i){
    let photos = [...data?.photos]
    photos.splice(i,1)
    setdata({...data,photos:photos})
  }

  function resetform(){
    setdata({reminderTo:[],title:'',remarks:'',description:'',location:'',photos:[],time:'',date:'',year:'',date_time:'',type:'',date1:'',time:'',date2:'',time1:'',department:''})
    seterror({reminderTo:'',title:'',remarks:'',description:'',location:'',photos:'',time:'',date:'',year:'',date_time:'',type:'',date1:'',time:'',date2:'',time1:'',department:''})
  }

  const handleDownload = (url, filename) => {
    axios.get(`${process.env.REACT_APP_AWS_IMAGE_URL}${url}`, {
        responseType: 'blob',
    })
        .then((res) => {
            fileDownload(res.data, filename)
        })
  }

  function handleChange(e){
    setdata({...data,[e.target.name]:e.target.value})
    seterror({...error,[e.target.name]:''})
  }

  async function deleteData() {
    const response = await DeleteCalendarService(data?._id)
    if(response?.status == 200){
        toast.success("Calendar Deleted Successfully!")
        getdata(calendar_report?.month,calendar_report?.year)
    }else{
        toast.success("Calendar Deleted Successfully!")
        getdata(calendar_report?.month,calendar_report?.year)
    }
    setdataModal(false)
  }

  function datamodaldata(d){
    let sendData = {...d}
    let arr = []
    d?.reminderTo?.forEach((d1)=>{
        arr?.push({label:d1?.name,value:d1?._id})
    })
    sendData['reminderTo'] = arr

    if(d?.department !== ''){
        sendData['department'] = {label:d?.department?.department_name,value:d?.department?._id}
    }else{
        sendData['department'] = ''
    }

    if(d?.date_time !== '' && d?.date_time !== null && d?.date_time !== undefined){
        sendData['date1'] = d?.date_time?.slice(0,10)
        sendData['date2'] = d?.date_time?.slice(0,10)
        sendData['time'] = moment(d?.date_time?.slice(11,16), "HH:mm").add(0, 'hours').add(0, 'minutes').format("HH:mm");
        sendData['time1'] =  moment(d?.date_time?.slice(11,16), "HH:mm").add(0, 'hours').add(0, 'minutes').format("HH:mm"); 
    }else{
        sendData['date1'] = ''
        sendData['date2'] = ''
        sendData['time'] = ''
        sendData['time1'] = ''
    }

    setdata(sendData)
  }

  return (
    <div className='h-screen relative min-h-screen max-h-screen overflow-hidden'>

        <Modal width={400} className='relative' open={dataModal} footer={false} closable={false}>
              
              <div className='bg-slate-100 flex items-center justify-between p-1.5 relative'>
                  <HiOutlineMenuAlt4 onClick={()=>navigate(data?._id !== undefined ? '/calendar/list/edit' : '/calendar/list/create',{state:data})}  size={14} />
  
                  <div className='flex'>
                  <AiOutlineClose size={13} onClick={()=>setdataModal(!dataModal)} className='cursor-pointer ml-2' />
                  {data?._id !== undefined &&
                  <RiChatFollowUpLine onClick={()=>navigate('/calendar/list/create',{state:{...data,followup_calendar:data?._id}})} size={13} className='cursor-pointer ml-2'  />}
                  {data?._id !== undefined && (roles?.includes('admin') || (data?.created_by?._id == userId)) &&
                  <AiOutlineDelete size={13} onClick={()=>deleteData()} className='cursor-pointer ml-2' />}
                  </div>
                   
              </div>
  
            
  
              <input name="title" value={data?.title} onChange={handleChange} className=' w-[100%] mt-4 focus:outline-none border-b border-slate-300 p-1 placeholder:text-[16px]' type='text' placeholder='Add title and time' />
              <ErrorComponent error={error?.title} />
              
              <div className='flex mt-2'>
                  <span onClick={()=>{setdata({...data,type:'Event'})}} className={`border cursor-pointer ${data?.type == 'Event' && 'bg-blue-200 border-blue-200 text-blue-600'} text-[12px] py-1 px-1.5 rounded mr-2`}>Event</span>
                  <span onClick={()=>{setdata({...data,type:'Out of office'})}} className={`border cursor-pointer ${data?.type == 'Out of office' && 'bg-blue-200 border-blue-200 text-blue-600'} text-[12px] py-1 px-1.5 rounded mr-2`}>Out of office</span>
                  <span onClick={()=>{setdata({...data,type:'Task'})}} className={`border cursor-pointer ${data?.type == 'Task' && 'bg-blue-200 border-blue-200 text-blue-600'} text-[12px] py-1 px-1.5 rounded mr-2`}>Task</span>
                  <span onClick={()=>{setdata({...data,type:'Meeting'})}} className={`border cursor-pointer ${data?.type == 'Meeting' && 'bg-blue-200 border-blue-200 text-blue-600'} text-[12px] py-1 px-1.5 rounded mr-2`}>Meeting</span>
              </div>
  
              {(data?.calendar_type !== undefined && data?.calendar_type !== null && data?.calendar_type !== '') && <h6 className='mt-3 text-[11px]'>Calendar Type : <span className='bg-green-100 font-[800] rounded px-2 text-green-800 p-1'>{data?.calendar_type}</span></h6>}
  
  
              <div className='flex  my-1 mt-4 items-center'>
                  <LuUserSquare2 size={16} />
                  <button className='ml-2 hover:bg-slate-100 rounded p-1 text-[12px] px-2 cursor-pointer'>Add guests</button>
              </div>
  
  
              <div className='ml-[8%]'>
                  <Select 
                  placeholder="Search to add guest"
                  bordered={false}
                  mode='multiple'
                  value={data?.reminderTo}
                  filterOption={false}
                  showSearch
                  onSearch={searchuser}
                  onChange={handleSelect}
                  options={users}
                  showArrow={false}
                  className='bg-slate-100 w-[100%] border-b border-slate-300'
                  />
              </div> 
  
              <div className='flex  my-1 mt-4 items-center'>
                  <MdOutlineGroup size={16} />
                  <button className='ml-2 hover:bg-slate-100 rounded p-1 text-[12px] px-2 cursor-pointer'>Select Department</button>
              </div>
  
              <div className='ml-[8%]'>
                  <Select 
                  placeholder="Select department if applicable"
                  bordered={false}
                  value={(data?.department == null || data?.department == '' || data?.department == undefined) ? null : data?.department}
                  filterOption={false}
                  showSearch
                  onChange={(v)=>setdata({...data,department:departments?.find((f)=>f?.value == v)})}
                  options={departments}
                  showArrow={false}
                  className='bg-slate-100 w-[100%] border-b border-slate-300'
                  />
              </div> 
  
              <div className='flex  my-1 mt-4 items-center'>
                  <AiOutlineClockCircle size={18} />
  
                  <div className='ml-3 w-[100%] flex'>
                  <div className='w-[100%]'>
                  <div className='border-b w-[100%] bg-slate-100 border-slate-300'>
                  <input type='date' className='bg-slate-100 text-[13px] p-1 w-[100%] outline-none' width={'100%'} placeholder={'Select Date'} bordered={false}  ampm={false} sx={{height:'40px'}} inputFormat="MM/dd/yyyy" value={data?.date1} onChange={(e) => {setdata({...data,date1:e.target.value});seterror({...error,date1:''})}} />
                  <ErrorComponent error={error?.date1} />
                  </div>
                  </div>
                  <p style={{width:10}}></p>
                  <div className='w-[100%]'>
                  <div  className='border-b w-[100%]  border-slate-300'>
                  <input type='time' className='bg-slate-100 text-[13px] p-1 w-[100%] outline-none' width={'100%'} placeholder={'Select Time'} bordered={false} format="hh:mm" ampm={false} sx={{height:'40px'}} value={data?.time} onChange={(e) => {setdata({...data,time:e.target.value});seterror({...error,time:''})}} />
                  <ErrorComponent error={error?.time} />
                  </div>
                  </div>
                  </div>
              </div>
  
  
              <div className='flex  my-1 mt-4 items-center'>
                  <MdOutlineLocationOn size={20} />
                  <input name="location" value={data?.location} onChange={handleChange} className='bg-slate-100 ml-2 text-[13px] w-[100%] focus:outline-none border-b border-slate-300 p-1 px-2 placeholder:text-[13px]' type='text' placeholder='Add location' />
              </div>
  
              <div className='flex  my-1 mt-4 '>
                  <MdOutlineDescription size={20} />
                  <textarea name="description" value={data?.description} onChange={handleChange} className='bg-slate-100 ml-2 text-[13px] w-[100%] focus:outline-none border-b border-slate-300 p-1 px-2 placeholder:text-[13px]' type='text' placeholder='Add description' />
              </div>
  
              <div className='flex  my-1 mt-4 items-center'>
                  <TbBrandGoogleDrive size={20} />
  
                  <form onClick={()=>{document.querySelector('.input-field').click()}} >
                    <h6 className='ml-2 hover:bg-slate-100 rounded p-1 text-[12px] px-2 cursor-pointer'>Upload File
                      <input type='file' onChange={({target:{files}})=>{
                      files[0] && uploadFile(files[0])
                      }} accept="*" className='input-field' hidden />
                    </h6>
                 </form> 
  
              </div>  
              <div className='ml-[8%] flex flex-wrap mb-1.5'>
                  {data?.photos?.map((p,i)=>(
                      <div key={p} className='px-2 rounded w-max flex items-center py-1 mr-1 mb-1 bg-blue-100'> 
                          <h6 className='text-[11px]'>{p?.split('/')[p?.split('/')?.length - 1]?.slice(0,10)}</h6>
                          <AiOutlineDownload onClick={()=>handleDownload(p,p?.split('/')[p?.split('/')?.length - 1])} size={12} className='ml-2' />
                          <AiOutlineClose onClick={()=>removeimage(i)} size={12} className='ml-2' />
                      </div>
                  ))}
              </div>  
  
  
             {showMore &&
              <div className='flex  my-1 mt-4 items-center'>
                  <TbNotes size={20} />
                  <textarea name="remarks" value={data?.remarks} onChange={handleChange} className='bg-slate-100 ml-2 text-[13px] w-[100%] focus:outline-none border-b border-slate-300 p-1 px-2 placeholder:text-[13px]' type='text' placeholder='Meeting Remarks' />
              </div>}
  
  
              <div className='flex items-center justify-end'>
                  <h6 onClick={()=>setshowMore(!showMore)} className='mx-2 cursor-pointer  hover:bg-slate-100 rounded p-1 px-2' >More options</h6>
                  <ButtonFilledAutoWidth onClick={submit} btnName="Save" />
              </div>
             
        </Modal>

        <div className='flex'>
            <DashboardMenu />
            <div className='w-[86%] p-5 h-screen min-h-screen max-h-screen overflow-y-scroll'>

                <div className='w-[100%]'>
                <div className='flex items-center justify-between border-b pb-2 mb-5'>
                <h6 className='font-[700] text-[14px] '>Today Task Report ({datas?.pagination?.total})</h6>

                <div className='flex items-center  justify-end' >
                        <h6 className='mr-2 text-[12px] font-[600]'>{page == 1 ? datas?.datas?.length > 0 ? 1 : 0 :  (page - 1) * datas?.pagination?.limit } - {datas?.pagination?.limit} of {datas?.pagination?.total} </h6>
                        <div className='flex items-center  text-[12px] mr-2'>
                        <IconButton  onClick={resetfilter} ><BsArrowRepeat size={16} /></IconButton>
                        <div>
                        <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                        <IconButton onClick={()=>{ page < datas?.pagination?.totalPages  ? setpage(page+1) : console.log('')}} ><FiChevronRight className={`${(datas?.pagination?.totalPages === page || datas?.datas?.length === 0) ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
                        </div>
                    </div>

                   
                    <div className='ml-1 mr-1'>
                    <BiFilterAlt  onClick={()=>applyfilterfunction(1)} size={25} className='bg-slate-600 p-[5px] text-white cursor-pointer rounded ml-1' />
                    </div> 

                    <RxReset  onClick={resetfilter} size={25} className='bg-slate-200 p-[5px] text-salte-800 cursor-pointer rounded ' />

                </div>
                </div>
                </div>  

                {datas?.datas?.length > 0 &&
                    <div className='max-h-[82vh] mt-4 border-t   border-l border-r overflow-y-scroll'>
                        <div className='sticky top-0 z-50 flex  py-[-5px] border-b bg-white border-slate-200 relative'>
                            <h6 className='sticky top-0 z-50 text-[12px] w-[5%]  px-2 py-1 font-[600] text-slate-600 border-r '>Sl No</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[15%] px-2 py-1 font-[600] text-slate-600 border-r'>Title / Description</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[15%]  px-2 py-1 bg-gray-50 font-[600] text-slate-600 border-r'>Calendar Type</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[15%] px-2 py-1 font-[600] text-slate-600 border-r'>Created By</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[15%]  px-2 py-1 font-[600] text-slate-600 border-r'>Department</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[15%]  px-2 py-1 font-[600] text-slate-600  border-r'>Remarks</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[10%]  px-2 py-1 font-[600] text-slate-600  border-r'>Reminder To</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[10%]  px-2 py-1 font-[600] text-slate-600 sticky right-0'>Action</h6>
                        </div>
                        {datas?.datas?.map((d,i)=>(
                        <div key={d?._id} className='flex z-50 border-b border-slate-200 '  >
                            <h6 className='text-[12px] font-[500] w-[5%]  px-2 py-1  border-r border-slate-200 '>{(page > 1 ? i+1+ (datas?.pagination?.limit * (page - 1)) : i+1 )}</h6>
                            <h6 className='text-[12px] font-[500] w-[15%] px-2 py-1 font-[800] border-r border-slate-200'>Title: <span className='font-[400] '>{d?.title}</span> <br></br> Description: <span className='font-[400] '>{d?.description}</span> <br></br> Location: <span className='font-[400] '>{d?.location}</span>  <br></br> Date: <span className='font-[400] '>{moment(d?.date_time)?.format('ll')}</span> <br></br> Time: <span className='font-[400] '>{moment(d?.date_time?.slice(11,16), "HH:mm").add(0, 'hours').add(0, 'minutes').format("LT")}</span></h6>
                            <h6 className='text-[10px] w-[15%] bg-gray-50 text-[12px] px-2 border-r border-slate-200'>Calendar Type : <span className='font-[800]'>{d?.calendar_type}</span> <br></br> Type : <span className='font-[800]'>{d?.type}</span> </h6>
                            <h6 className='text-[12px] font-[500] w-[15%]  px-2 py-1 break-all border-r border-slate-200'>{d?.created_by?.name}</h6>
                            <h6 className='text-[11px] font-[500] w-[15%]  px-2 py-1 font-[800] border-r border-slate-200'>{d?.department?.department_name}</h6>
                            <h6 className='text-[11px] font-[500] w-[15%]  px-2 py-1 font-[800] border-r border-slate-200'>{d?.remarks} <br></br></h6>
                            <h6 className='text-[11px]  w-[10%]  px-2 py-1  border-r border-slate-200'>
                                {d?.reminderTo?.map((r)=>(
                                    <div> <span className='font-[800]'>{r?.name}</span> </div>
                                ))}
                            </h6>  
                           
                            <h6 className='text-[12px]  font-[500] w-[10%]  px-2 py-1 truncate border-slate-200 '>
                                <AiOutlineEdit className='mt-1 ml-1' onClick={()=>{setdataModal(true);datamodaldata(d);}} />
                            </h6>
                        </div>))}
                    </div>}        


            </div>
        </div>
    </div>
  )
}

export default DashboardReport