import React, { useEffect, useState } from 'react'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button'

import { CreateReimbusmentService, GetReimbusmentDetailService, GetReimbusmentService, UpdateReimbusmentItemService, UpdateReimbusmentService } from '../../services/ReimbusmentServices';
import GoBack from '../../components/back/GoBack';
import { useSelector } from 'react-redux';
import { TextAreaInput1, TextInput } from '../../components/input';
import Uploader1 from '../../components/Uploader1';
import { SearchUser, uploadFile } from '../../services/AuthServices';
import Priceconstants from '../../constants/imageConstants';
import { FiFile } from "react-icons/fi";
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import toast from 'react-hot-toast';
import { Modal,Select } from 'antd';
import { IoMdAdd } from "react-icons/io";
import {BiCheckbox,BiCheckboxSquare} from 'react-icons/bi'
import FinanceMenu from './FinanceMenu';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import ErrorComponent from '../../components/errorDesign/ErrorComponent';


function ApprovalReimbusmentCE() {

  const navigator = useNavigate()

  const user = useSelector(state=>state.Auth)  
  const [data,setdata] = useState({})
  const [reimbusmentItem,setreimbusmentItem] = useState({})
  const [error,seterror] = useState({particular:'',other_particular:'',amount:'',file:''})
  const [datas,setdatas] = useState([])
  const [modal,setmodal] = useState('')



  const [approval,setapproval] = useState({payment:'',completed:false,finance_remarks:'',finance_approved_status:''})
  const [approvalErr,setapprovalErr] = useState({payment:'',finance_remarks:'',finance_approved_status:''})

  const {state} = useLocation()

  const [data1,setdata1] = useState({required_approval:false,approvals:[]})
  const [searchEmployee,setsearchEmployee] = useState([])

  console.log("user",user)
  
  const paymentStatus = [{label:'Cash',value:'Cash'},{label:'Online',value:'Online'}]
  const status = [{label:'Pending',value:'Pending'},{label:'Approved',value:'Approved'},{label:'Rejected',value:'Rejected'}]

  const particulars = [
    {label:'Petrol',value:'Petrol'},
    {label:'Trip',value:'Trip'},
    {label:'Lunch With Client',value:'Lunch With Client'},
    {label:'Team Lunch',value:'Team Lunch'},
    {label:'OLA / Travel Bill',value:'OLA / Travel Bill'},
    {label:'Other',value:'Other'},
  ]


  useEffect(()=>{
    getData()
  },[])

  async function getData(){
    const response = await GetReimbusmentDetailService(state?._id)

    let d = response?.data?.datas?.datas[0]

    setapproval({...approval,payment:d?.payment !== '' ? {label:d?.payment,value:d?.payment} : '',finance_approved_status:d?.finance_approved_status !== '' ? {label:d?.finance_approved_status,value:d?.finance_approved_status} : '',finance_remarks:d?.finance_remarks,})
    setdata(response?.data?.datas?.datas[0])
  }

  async function handlefileupload(e){
     const response = await uploadFile({file:e})
    //  console.log("response",response?.data)
     setreimbusmentItem({...reimbusmentItem,file:response?.data?.data})
     
  }

  async function resetform(){
       setreimbusmentItem({})
       setmodal('')
  }

  async function submiform(){

    if(reimbusmentItem?.particular === ''){
      seterror({...error,particular:'This Field is required'})
    }else if(reimbusmentItem?.particular === 'Other' && (reimbusmentItem?.other_particular === '' || reimbusmentItem?.other_particular === undefined)){
      seterror({...error,other_particular:'This Field is required'})
    }else if(reimbusmentItem.amount === ''){
      seterror({...error,amount:'This Field is required'})
    }else{
      let amount =  0;
      datas.forEach((d)=>{
        amount += parseInt(d?.amount)
      })
      let sendData = {...reimbusmentItem}
      
      delete sendData?._id
      sendData['reimbusment'] = state?._id

      const response = await UpdateReimbusmentItemService(reimbusmentItem?._id,{...sendData})
      // console.log("response?.kp",response?.data)
      if(response?.status === 200){
        getData()
        setmodal('')
      }else{
        toast.error(response?.data?.data)
      }
    } 
      
  }

  async function deleteform(){
    let sendData={...reimbusmentItem}
    // if(sendData.is_deleted){
    sendData['is_deleted'] = true
    sendData['deleted_by'] = user?.id
    sendData['reimbusment'] = state?._id
    // }
    const response = await UpdateReimbusmentItemService(reimbusmentItem?._id,{...sendData})
      // console.log("response?.kp",response?.data)
      if(response?.status === 200){
        getData()
        setmodal('')
      }else{
        toast.error(response?.data?.data)
      }
  }

  async function handleChange(e){
    setreimbusmentItem({...reimbusmentItem,[e.target.name]:e.target.value})
    seterror({...error,[e.target.name]:''})
  }

  async function handleChange1(e){
    setapproval({...approval,[e.target.name]:e.target.value})
    setapprovalErr({...approvalErr,[e.target.name]:''})
  }

  async function submitData(){
    if(approval?.finance_approved_status === ''){
      setapprovalErr({...approvalErr,finance_approved_status:'This Field is required'})
    }else if(approval?.finance_approved_status?.label === 'Rejected' && approval?.finance_remarks === ''){
      setapprovalErr({...approvalErr,finance_remarks:'This Field is required'})
    }else if(approval?.finance_approved_status?.label === 'Approved' && approval?.payment === '' && approval?.payment?.label === undefined){
      setapprovalErr({...approvalErr,payment:'This Field is required'})
    }else{
      let sendData = {
        finance_approved_status:approval?.finance_approved_status?.label,
        payment:approval?.payment?.label,
        finance_remarks:approval?.finance_remarks
      }
      if(approval?.finance_approved_status?.label === 'Approved'){
        sendData['completed'] = true
        sendData['finance_approved_by'] = user?.id
      }
      sendData['reimbusment'] = state?._id


      const response = await UpdateReimbusmentService(data?._id,sendData)
      if(response?.status === 200){
        navigator(-1)
        toast.success(response?.data?.data)
      }else{
        toast.error(response?.data?.data)
      }
    }
  }

  return (
    <div className='flex mx-0 box-border  max-h-screen overflow-y-scroll overflow-x-hidden'>
        <div className='block max-w-[180px] sm:flex'>
        <FinanceMenu />
        </div>
        <div className='w-full ml-4  pt-2'>
          <Modal open={modal !== ''} width={300} closable={false} footer={false} className='absolute top-0 left-[40%]' >
             
             {modal == 1 &&
             <>
              <h6 className="font-bold text-[13px]  mb-1 w-full">Create / Edit Reimbusment</h6>
              <h6 className="text-[11px] bg-slate-100  font-[600] p-[5px] mb-2 w-full">Use the below form to create or edit the reimbusment of your money</h6>


               <h6 className='text-[10px] border-t border-slate-100 pt-1 font-[600] mb-1 mt-1'>Particular </h6>
              <div className='border border-slate-200'>
              
              <Select
                bordered={false}
                value={reimbusmentItem?.particular} 
                filterOption={false}
                placeholder="" 
                onChange={(e)=>{setreimbusmentItem({...reimbusmentItem,particular:e});seterror({...error,particular:''})}} 
                className='w-full rounded-[0px]' 
                options={particulars} 
                />

              
              </div>

              {reimbusmentItem?.particular === 'Other' &&
              <TextInput 
                    label="Particulars"
                    name="other_particular"
                    type="text"
                    value={reimbusmentItem?.other_particular}
                    error={error?.other_particular}
                    handlechange={handleChange}

                />}

              <TextInput 
                    label="Amount"
                    name="amount"
                    type="number"
                    min={0}
                    value={reimbusmentItem?.amount}
                    error={error?.amount}
                    handlechange={handleChange}

                />

        

              <h6 className='text-[11px] font-[600] mb-1 mt-2' >Attachment</h6>
              <Uploader1 
                image={data?.file}
                removeimageuploadfunc ={()=>setreimbusmentItem({...reimbusmentItem,file:''})}
                setimagefunc={(e)=>{handlefileupload(e)}}
              
              />

              <div className='flex justify-end pt-2 mt-2 border-t border-slate-100'>
                <ButtonOutlinedAutoWidth onClick={resetform} btnName="Clear" />
                <h6 className='w-[10px]'></h6>
                <ButtonFilledAutoWidth onClick={submiform} btnName="Save" />
              </div>
             </>}

             {modal == 2 &&
             <>
                <h6 className="font-bold text-[13px]  mb-1 w-full">Delete Reimbusment</h6>
                <h6 className="text-[11px] bg-slate-100  font-[600] p-[5px] mb-2 w-full">Are yous ure want to delted the selected reimbusment item for the employee</h6>
                
                <h6 className='text-[11px] font-[600]'>Particular : {reimbusmentItem?.particular}</h6>
                <h6 className='text-[11px] font-[600]'>Amount : {Priceconstants(parseInt(reimbusmentItem?.amount))}</h6>

                <div className='flex justify-end pt-2 mt-2 border-t border-slate-100'>
                <ButtonOutlinedAutoWidth onClick={resetform} btnName="Clear" />
                <h6 className='w-[10px]'></h6>
                <ButtonFilledAutoWidth onClick={deleteform} btnName="Save" />
              </div>
             </>}

           </Modal>
            
            <div className='flex'>
            <div className='w-64 pr-2 mr-2'>
            <GoBack />

            <h6 className='text-[13px] font-[700]'>Approve or Reject the Reimbusment Request</h6>
            <h6 className='text-[11px] p-1 font-[500] leading-[14px] my-2 bg-slate-100'>Use the below form to approve or reject the reimbusment request for the finance team</h6>

            <div className='flex'>
                <div className='w-[49%] mr-[1%]'>
                <TextInput 
                    readOnly={true}
                    label="Request By"
                    name="name"
                    value={data?.user?.name}
                />
                </div>

                <div className='w-[49%] ml-[1%]'>
                <TextInput 
                    readOnly={true}
                    label="Request Date" 
                    name="name"
                    // type="date"
                    value={moment(data?.createdAt?.slice(0,10))?.format('LL')}
                />
                </div>
            </div>

            <div className='w-[100%] ml-[1%]'>
                <TextInput 
                    readOnly={true}
                    label="Request Department" 
                    name="name"
                    type="text"
                    value={data?.department?.department_name}
                />
            </div>    

            <div className='w-[100%] ml-[1%]'>
                <TextInput 
                    readOnly={true}
                    label="Expenses Department" 
                    name="name"
                    type="text"
                    value={data?.expense_department?.department_name}
                />
            </div>    


            <h6 className='text-[11px] font-[700] mt-2'>Total Reimbusment Amount : {Priceconstants(data?.amount)}.</h6>

            <h6 className='text-[11px] font-[700] bg-gray-100 p-2 mt-2'>Summary : {data?.summary}.</h6>


            <h6 className='text-[11px] bg-slate-100 mt-2 p-[4px]'>Use the below steps to complete the approval verification for the employee reimbusment.</h6>

           

            {/* {data1?.required_approval && */}
            <>
             <h6 className='text-[10px] border-t border-slate-100 pt-1 font-[600] mb-1 mt-1'>Approval Status </h6>
              <div className='border border-slate-200'>
              
              <Select
                bordered={false}
                value={approval?.finance_approved_status} 
                filterOption={false}
                placeholder="" 
                onChange={(e)=>{setapproval({...approval,finance_approved_status:status.find((f)=>f?.label === e)});setapprovalErr({...approvalErr,finance_approved_status:''})}} 
                className='w-full rounded-[0px]' 
                options={status} 
                />

              
              </div>
              <ErrorComponent error={approvalErr?.finance_approved_status} />

                

              <h6 className='text-[10px] border-t border-slate-100 pt-1 font-[600] mb-1 mt-1'>Payment Type </h6>
              <div className='border border-slate-200'>
              
              <Select
                bordered={false}
                value={approval?.payment} 
                filterOption={false}
                placeholder="" 
                onChange={(e)=>{setapproval({...approval,payment:paymentStatus.find((f)=>f?.label === e)});setapprovalErr({...approvalErr,payment:''})}} 
                className='w-full rounded-[0px]' 
                options={paymentStatus} 
                />

              </div>  
              <ErrorComponent error={approvalErr?.payment} />



              <TextAreaInput1 
                label={'Remarks'}
                name={'finance_remarks'}
                error={approvalErr?.finance_remarks}
                value={approval?.finance_remarks}
                handlechange={handleChange1}
                />


            </>
            {/* } */}

           


           


            </div> 
            <div className='border-l mt-10 pl-2'>
                <div className='flex items-center justify-between'>
                <h6 className='text-[12px] font-[600]'>Reimbusment Listed ({data?.reimbursmentitems?.length})</h6>
                </div>
                
                <div className='border mt-2'>
                <div className='flex bg-slate-100 text-[11px]'>
                    <h6 className='w-[40px] p-1 border-r'>Sl No </h6>
                    <h6 className='w-[150px] p-1 border-r'>Particulars </h6>
                    <h6 className='w-[60px] p-1 border-r'>Amount </h6>
                    <h6 className='w-[60px] p-1 border-r'>File </h6>
                    <h6 className='w-[170px] p-1 border-r'>Remarks </h6>
                    <h6 className='w-[60px] p-1'>Action </h6>
                </div>
                {data?.reimbursmentitems?.map((d,i)=>(
                <div className={`flex  border-t text-[11px] ${d?.is_deleted ? 'opacity-70 bg-red-100' : 'bg-white'} `}>
                    <h6 className='w-[40px] p-1 border-r'>{i+1}</h6>
                    <h6 className='w-[150px] p-1 border-r'>{d?.particular === 'Other' ? d?.other_particular : d?.particular} </h6>
                    <h6 className='w-[60px] p-1 font-[800] border-r'>{Priceconstants(parseInt(d?.amount))} </h6>
                    <h6 className='w-[60px] p-1 border-r'>{(d?.file !== '' && d?.file !== null && d?.file !== undefined) &&  <a href={`${process.env.REACT_APP_AWS_IMAGE_URL}${d?.file}`} target='_blank'><FiFile /></a>}  </h6>
                    <h6 className='w-[170px] p-1 border-r'>{d?.is_deleted && <span className='text-[11px]'>Item Deleted By : <span className='font-[800]'>{d?.deleted_by?.name}</span></span> } </h6>
                    <h6 className='w-[60px] p-1 flex'>
                     {/* {!d?.is_deleted && <> */}
                      <AiOutlineEdit onClick={()=>{setreimbusmentItem(d);setmodal(1)}} className='mr-2'/> 
                      <AiOutlineDelete onClick={()=>{setreimbusmentItem(d);setmodal(2)}} /> 
                      {/* </>} */}
                      </h6>

                </div>
                ))}
                </div>

                <div className='flex items-center mt-5 justify-between'>
                <h6 className='text-[12px] font-[600]'>Approvals Listed ({data?.reimbursmentapprovals?.length})</h6>
                </div>

                <div className='border mt-2'>
                <div className='flex bg-slate-100 text-[11px]'>
                    <h6 className='w-[40px] p-1 border-r'>Sl No </h6>
                    <h6 className='w-[150px] p-1 border-r'>Name </h6>
                    <h6 className='w-[120px] p-1 border-r'>Approval Status </h6>
                    <h6 className='w-[170px] p-1 '>Remarks </h6>
                </div>

                {data?.reimbursmentapprovals?.map((d,i)=>(
                <div className='flex border-t text-[11px]'>
                    <h6 className='w-[40px] p-1 border-r'>{i+1}</h6>
                    <h6 className='w-[150px] p-1 border-r'>{d?.user?.name} </h6>
                    <h6 className='w-[120px] font-[700]  p-1 border-r'>{d?.approvedStatus} </h6>
                    <h6 className='w-[170px] p-1 '>{d?.remarks} </h6>
                </div>))}
                </div>

            </div>
            </div>
            {!data?.completed &&
            <div className='pt-2 mt-2 border-t border-slate-100'>
            <ButtonFilledAutoWidth onClick={submitData} btnName="Submit" />
            </div>}
        </div>    
    </div>
  )
}

export default ApprovalReimbusmentCE