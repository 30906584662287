import React, { useEffect, useState } from 'react'
import { Tooltip } from '@mui/material'
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import DatabaseMenu from '../DatabaseMenu';
// import { DeletePlotTypeService, GetPlotTypeService } from '../../../../../services/database/databaseoptions/PlotTypeServices';
import {AiOutlineDelete, AiOutlineEdit} from 'react-icons/ai';
import { useLocation, useNavigate } from 'react-router-dom';
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../../components/button';
import moment from 'moment';
import { DatePicker,Modal,Select } from 'antd';
import { IconButton, } from '@mui/material'
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { MdOutlineCloudUpload } from "react-icons/md";
import { AiOutlineFilter,AiOutlineReload,AiOutlineFilePdf,AiOutlineFileExcel,AiOutlinePlus } from "react-icons/ai";
import { GetManagedOfficeService } from '../../../services/database/mainoptions/ManagedOfficeServices';
import {BiCheckbox, BiCheckboxSquare} from 'react-icons/bi';
import {RiBillLine} from 'react-icons/ri';

// import { DeleteAdminPropertyTypeService, DeletePropertyTypeService, GetPropertyTypeService } from '../../../../services/database/databaseoptions/PropertyTypeServices';
// import { DeleteAdminApprovedTypeService, DeleteApprovedTypeService, GetApprovedTypeService } from '../../../../services/database/databaseoptions/ApprovedTypeServices';

function ManagedOfficeList() {

  const user = useSelector(state=>state.Auth)
  const location = useLocation();

  const roles = useSelector(state=>state.Auth.roles)
  let path = location?.pathname?.split('/')[2]


  const [table_heading,settable_heading] = useState({base_type:true,property_category:true,managed_office_name:true,zone:true,location_of_property:true,longitude:false,latitude:false,google_coordinate_link:true,managed_office_address:false,landmark_to_property:false,seater_offered:false,rent_per_seat:false,escalation:false,amenities_available:false,amenities:true,no_of_car_parking_slots:true,car_parking_charges:false,security_deposit:false,lock_in_period:false,lease_term_tenure:false,possession_type:false,facility_type:false,power_and_backup:false,total_built_up_area:false,no_of_floors:false,per_floor_area:false,area_offered:false,floor_offered:false,rent_per_sqft:false,maintenace_charges:false,interior_details:false,oc_availability:false,status:false,primary_contact_name:false,primary_contact_no:false,secondary_contact_name:false,secondary_contact_no:false})

  const navigate = useNavigate()
  const [data,setdata] = useState({pagination:{total:0}})
  const [selected_data,setselected_data] = useState({})
  const [data_selected_list,setdata_selected_list] = useState([])
  const [modal,setmodal] = useState(false)
  const [page,setpage] = useState(1)
  const [step,setstep] = useState(1)
  const [search,setsearch] = useState({from_date:'',to_date:'',from_date1:'',to_date1:'',text:''})


  async function deleteData(){
    
  }

  useEffect(()=>{
    getdata()
  },[page,step])


  async function getdata(){
    const response = await GetManagedOfficeService(page,search?.from_date1,search?.to_date1,search?.text,step)
    setdata(response?.data)
  }

  // console.log("DATA",data)

  async function resetfunc(){
    // setsearch({from_date:'',to_date:'',from_date1:'',to_date1:'',text:''})
    // setpage(1)
    // const response = await GetApprovedTypeService(1,'','','',1)
    // setdata(response?.data)
  }

  async function applyfilterfunction(){
    // setpage(1)
    // const response = await GetApprovedTypeService(1,search?.from_date1,search?.to_date1,search?.text,step)
    // setdata(response?.data)

  }

  async function deletefunc(){
    // const response = await DeleteApprovedTypeService(selected_data?._id)
    // if(response?.status === 200){
    //   toast.success("Managed Office Deleted Successfully!")
    //   getdata()
    //   setmodal(!modal)
    // }
  }

  async function admindeletefunc(){
    // const response = await DeleteAdminApprovedTypeService(selected_data?._id)
    // if(response?.status === 200){
    //   toast.success("Managed Office Deleted Successfully!")
    //   getdata()
    //   setmodal(!modal)
    // }
  }


  return (
    <div className='overflow-hidden h-screen max-h-screen'>
        <Modal open={modal} className='absolute top-0 left-[42%]' width={300} footer={false} closable={false}>
           <div>
             <h6 className='text-[12px] font-[700]'>Delete Managed Office Data</h6>
             <h6 className='text-[10px] bg-slate-100 p-[4px] leading-[14px]'>Are you sure want to delete the selected managed office data once deleted will not be retrieved</h6>
             
             <h6 className='text-[12px] mt-2'>Name : <span className='font-[700]'>{selected_data?.name}</span></h6>
             <h6 className='text-[12px]'>Sort : <span className='font-[700]'>{selected_data?.sort}</span></h6>

             <div className='flex mt-2 justify-end border-t pt-2'>
               <ButtonOutlinedAutoWidth  btnName="Cancel" onClick={()=>setmodal(false)} />  
               <h6 className='w-[10px]'></h6>
               <ButtonFilledAutoWidth  btnName="Save" onClick={step == 1 ? ()=>deletefunc() : ()=>admindeletefunc()} />  
              </div>    
           </div>
        </Modal>
        
        <div  className="w-[100%] flex">
            <div className='min-w-[180px] max-w-[180px]'>
            <DatabaseMenu />
            </div>
            <div  className='px-5 py-4 w-[87%] '>
              <div className='border-b pb-2'>
                <div className='flex items-center justify-between '>
                    <h6 className='font-[700] text-[14px] '>Total Managed Office List ({data?.pagination?.total})</h6>
                    <div className='flex items-center'>
                    <div className='flex items-center text-[12px] mr-2'>
                    
                        <h6 className='mr-2 font-[600]'>{page == 1 ? data?.datas?.length > 0 ? 1 : 0 : (page - 1) * data?.pagination?.limit } - {data?.pagination?.limit} of {data?.pagination?.total} </h6>
                        <IconButton onClick={resetfunc}><BsArrowRepeat size={16} /></IconButton>

                        <div>
                        <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                        <IconButton onClick={()=>{ page < data?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(data?.pagination?.totalPages === page || data?.datas?.length === 0)  ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>

                        </div>
                    </div>
                    
                    <div className='ml-2'>
                        <ButtonFilledAutoWidth btnName="Add Data" onClick={()=>navigate('create')}/> 
                    </div>
                    </div>
                </div>

                <div className='flex mt-2 border-t pt-2 -pl-2'>
                    <div className='mt-0 -ml-2 mr-[4px]'>
                        <Select 
                        bordered={false}
                        placeholder="Base Type"
                        value={search?.zone === '' ? null : search?.zone}
                        options={[]}
                        onChange={(e)=>setsearch({...search,zone:e})}
                        className='w-[110px] border h-[28px] border-slate-300 ml-2 rounded-[5px]'

                        />

            
                    </div>

                    <div className='mt-0 mr-[4px]'>
                        <Select 
                            bordered={false}
                            placeholder="Status"
                            value={search?.zone === '' ? null : search?.zone}
                            options={[]}
                            onChange={(e)=>setsearch({...search,zone:e})}
                            className='w-[110px] border h-[28px] border-slate-300 ml-2 rounded-[5px]'

                        />

                
                    </div>

                    <div className='mt-0 mr-[4px]'>
                        <Select 
                        bordered={false}
                        placeholder="Zone"
                        value={search?.zone === '' ? null : search?.zone}
                        options={[]}
                        onChange={(e)=>setsearch({...search,zone:e})}
                        className='w-[110px] border h-[28px] border-slate-300 ml-2 rounded-[5px]'

                        />

            
                    </div>  

                    <div className='mt-0 mr-[4px]'>
                        <Select 
                        bordered={false}
                        placeholder="Location"
                        value={search?.zone === '' ? null : search?.zone}
                        options={[]}
                        onChange={(e)=>setsearch({...search,zone:e})}
                        className='w-[110px] border h-[28px] border-slate-300 ml-2 rounded-[5px]'

                        />

            
                    </div>  

                    <div className='mt-0 mr-[4px]'>
                        <Select 
                        bordered={false}
                        placeholder="User"
                        value={search?.zone === '' ? null : search?.zone}
                        options={[]}
                        onChange={(e)=>setsearch({...search,zone:e})}
                        className='w-[110px] border h-[28px] border-slate-300 ml-2 rounded-[5px]'

                        />

            
                    </div>


                    <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.from_date} onChange={(v,v1) => {setsearch({...search,from_date:v,from_date1:v1})}} /> 

                    <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.to_date} onChange={(v,v1) => {setsearch({...search,to_date:v,to_date1:v1})}} /> 

                    
                    <div className='mr-2'>
                        

                    <input  id="search_text" placeholder='Search text' type='text' value={search.text} onChange={(e)=>setsearch({...search,text:e.target.value})} className='border py-[3px] focus:ring-0 focus:outline-0 text-[14px]  w-28 px-1 rounded-md border-slate-300' />
                
                    </div>   

                    <AiOutlineFilter size={24} className='bg-slate-600 mr-[5px] text-white p-[6px] rounded-[4px]' /> 
                    <AiOutlineReload size={24} className='bg-slate-600 mr-[5px] text-white p-[6px] rounded-[4px]' /> 
                    <AiOutlineFileExcel size={24} className='bg-slate-600 mr-[5px] text-white p-[6px] rounded-[4px]' /> 
                    <AiOutlineFilePdf size={24} className='bg-slate-600 mr-[5px] text-white p-[6px] rounded-[4px]' /> 
                    <MdOutlineCloudUpload size={24} className='bg-slate-600 mr-[5px] text-white p-[6px] rounded-[4px]' /> 
                    <AiOutlinePlus size={24} className='bg-slate-600 mr-[5px] text-white p-[6px] rounded-[4px]' /> 

                {/* <ButtonOutlinedAutoWidth btnName="Add Filter" onClick={()=>applyfilterfunction(1)} />  */}
                </div>  
               </div>  

            {roles?.includes('admin') &&
            <div className='flex  border-b text-[11px]'>
              <h1 onClick={()=>setstep(1)} className={`w-[70px] cursor-pointer p-[4px] text-center ${step == 1 && 'bg-slate-600 text-white'} font-[600]`}>Active</h1>
              <h1 onClick={()=>setstep(2)} className={`w-[70px] cursor-pointer p-[4px] text-center ${step == 2 && 'bg-slate-600 text-white'} font-[600]`}>In Active</h1>
            </div>}
            
            {data?.datas?.length === 0 &&
              <div className='grid place-items-center mt-20  items-center justify-center'>
              <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' /> 
              <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
              <h6 className='font-[500] w-[70%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any data based on your current stage please add the data to see list here.</h6>
            </div>
            }

            {data?.datas?.length > 0 &&
            <div className='max-h-[85vh] min-w-[100%] max-w-[100%] mt-4 border-t   border-l border-r '>
            <div className='sticky top-0 z-50 flex  bg-white py-[-5px] border-b border-slate-200 relative'>
                <h6  className='sticky bg-white top-0 bg-white z-50 text-[12px] w-10  px-2 py-1 font-[600] text-slate-600 border-r flex items-center justify-center'> <p className='w-[13px] h-[13px] rounded-[2px] border p-[1.6px] flex items-center justify-center' > </p> </h6>
                {table_heading?.base_type && <h6 className='sticky top-0 z-50 bg-white text-[12px] min-w-[170px] max-w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'>Base Type</h6>}
                {table_heading?.managed_office_name && <h6 className='sticky top-0 z-50 bg-white text-[12px]  min-w-[200px]  max-w-[200px] px-2 py-1 font-[600] text-slate-600 border-r'>Managed Office Name</h6>}
                {table_heading?.managed_office_address && <h6 className='sticky top-0 z-50 bg-white text-[12px] min-w-[170px] max-w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'>Managed Office Address</h6>}
                {table_heading?.zone && <h6 className='sticky top-0 z-50 bg-white text-[12px] min-w-[170px] max-w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'>Zone</h6>}
                {table_heading?.location_of_property && <h6 className='sticky top-0 z-50 bg-white text-[12px] min-w-[170px] max-w-[170px] px-2 py-1 font-[600] text-slate-600 border-r'>Location of Property</h6>}
                {table_heading?.google_coordinate_link && <h6 className='sticky top-0 z-50 bg-white text-[12px] min-w-[170px] max-w-[170px] px-2 py-1 font-[600] text-slate-600 border-r'>Google Coordinate</h6>}
                {table_heading?.landmark_to_property && <h6 className='sticky top-0 z-50 bg-white text-[12px] min-w-[170px] max-w-[170px] px-2 py-1 font-[600] text-slate-600 border-r'>LandMark To Property</h6>}
                {table_heading?.seater_offered && <h6 className='sticky top-0 z-50 bg-white text-[12px] min-w-[170px] max-w-[170px] px-2 py-1 font-[600] text-slate-600 border-r'>Seater Offered</h6>}
                {table_heading?.rent_per_seat && <h6 className='sticky top-0 z-50 bg-white text-[12px] min-w-[170px] max-w-[170px] px-2 py-1 font-[600] text-slate-600 border-r'>Rent Per Seat</h6>}
                {table_heading?.amenities && <h6 className='sticky top-0 z-50 bg-white text-[12px] min-w-[170px] max-w-[170px] px-2 py-1 font-[600] text-slate-600 border-r'>Amenities</h6>}
                {table_heading?.car_parking_charges && <h6 className='sticky top-0 z-50 bg-white text-[12px] min-w-[170px] max-w-[170px] px-2 py-1 font-[600] text-slate-600 border-r'>Car Parking Charges</h6>}
                {table_heading?.security_deposit && <h6 className='sticky top-0 z-50 bg-white text-[12px] min-w-[170px] max-w-[170px] px-2 py-1 font-[600] text-slate-600 border-r'>Security Deposit</h6>}
                {table_heading?.escalation && <h6 className='sticky top-0 z-50 bg-white text-[12px] min-w-[120px] max-w-[120px] px-2 py-1 font-[600] text-slate-600 border-r'>Escalation</h6>}
                {table_heading?.lock_in_period && <h6 className='sticky top-0 z-50 bg-white text-[12px] min-w-[120px] max-w-[120px] px-2 py-1 font-[600] text-slate-600 border-r'>Lock In Period</h6>}
                {table_heading?.lease_term_tenure && <h6 className='sticky top-0 z-50 bg-white text-[12px] min-w-[140px] max-w-[140px] px-2 py-1 font-[600] text-slate-600 border-r'>Lease Term Tenure</h6>}
                {table_heading?.possession_type && <h6 className='sticky top-0 z-50 bg-white text-[12px] min-w-[170px] max-w-[170px] px-2 py-1 font-[600] text-slate-600 border-r'>Possession Type</h6>}
                {table_heading?.total_built_up_area && <h6 className='sticky top-0 z-50 bg-white text-[12px] min-w-[170px] max-w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'>Total Built Up Area</h6>}
                {table_heading?.no_of_floors && <h6 className='sticky top-0 z-50 bg-white text-[12px] min-w-[150px] max-w-[150px]  px-2 py-1 font-[600] text-slate-600 border-r'>No Of Floors</h6>}
                {table_heading?.per_floor_area && <h6 className='sticky top-0 z-50 bg-white text-[12px] min-w-[170px] max-w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'>Per Floor Area</h6>}
                {table_heading?.interior_details && <h6 className='sticky top-0 z-50 bg-white text-[12px] min-w-[170px] max-w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'>Interior Details</h6>}
                {table_heading?.area_offered && <h6 className='sticky top-0 z-50 bg-white text-[12px] min-w-[170px] max-w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'>Area Offered</h6>}
                {table_heading?.floor_offered && <h6 className='sticky top-0 z-50 bg-white text-[12px] min-w-[170px] max-w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'>Floor Offered</h6>}
                {table_heading?.rent_per_sqft && <h6 className='sticky top-0 z-50 bg-white text-[12px] min-w-[170px] max-w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'>Rent Per Sqft</h6>}
                {table_heading?.power_and_backup && <h6 className='sticky top-0 z-50 bg-white text-[12px] min-w-[170px] max-w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'>Power & BackUp</h6>}
                {table_heading?.maintenace_charges && <h6 className='sticky top-0 z-50 bg-white text-[12px] min-w-[170px] max-w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'>Maintenance Charges</h6>}
                {table_heading?.facility_type && <h6 className='sticky top-0 z-50 bg-white text-[12px] min-w-[170px] max-w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'>Facility Type</h6>}
                {table_heading?.oc_availability && <h6 className='sticky top-0 z-50 bg-white text-[12px] min-w-[170px] max-w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'>Oc Availablility</h6>}
                {table_heading?.status && <h6 className='sticky top-0 z-50 bg-white text-[12px] min-w-[170px] max-w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'>Status</h6>}
                {table_heading?.primary_contact_name && <h6 className='sticky top-0 z-50 bg-white text-[12px] min-w-[170px] max-w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'>Primary Contact Name</h6>}
                {table_heading?.primary_contact_no && <h6 className='sticky top-0 z-50 bg-white text-[12px] min-w-[150px] max-w-[150px]  px-2 py-1 font-[600] text-slate-600 border-r'>Primary Contact No</h6>}
                {table_heading?.added_by && <h6 className='sticky top-0 z-50 bg-white text-[12px] min-w-[140px] max-w-[140px]  px-2 py-1 font-[600] text-slate-600 border-r'>Added By</h6>}
                {table_heading?.created_At && <h6 className='sticky top-0 z-50 bg-white text-[12px] min-w-[120px] max-w-[120px]  px-2 py-1 font-[600] text-slate-600 '>Created At</h6>}
                <h6 className='sticky border-l top-0 z-50 bg-white text-[12px] w-[120px]  px-2 py-1 font-[600] text-slate-600 sticky right-0'>Actions</h6>
            </div>
      
        {data?.datas?.map((d,i)=>(
        <div  key={d?._id} className={`flex z-50 border-b border-slate-200 z-0`}  >
              <h6 className='text-[11px] max-w-10  px-2 py-1 font-[600] text-slate-600 cursor-pointer border-r flex justify-center'> <p className='w-[13px] h-[13px] rounded-[2px] border p-[1.6px] flex items-center justify-center' >{data_selected_list?.includes(d?._id) &&  <p className='bg-slate-600 flex justify-center items-center rounded-[2px] w-[10px] h-[8px]'></p>} </p> </h6>
              {table_heading?.base_type && <h6 className='text-[11px] min-w-[170px] max-w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'>{d?.base_type?.name}</h6>}
              {table_heading?.managed_office_name && <h6 className='text-[11px] min-w-[200px] max-w-[200px]  px-2 py-1 font-[600] text-slate-600 border-r'>{d?.managed_office_name}</h6>}
              {table_heading?.managed_office_address && <h6 className='text-[11px] min-w-[170px] max-w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'>{d?.managed_office_address}</h6>}
              {table_heading?.zone && <h6 className='text-[11px] min-w-[170px] max-w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'>{d?.zone?.name}</h6>}
              {table_heading?.location_of_property && <h6 className='text-[11px] min-w-[170px] max-w-[170px] px-2 py-1 font-[600] text-slate-600 border-r'>{d?.location_of_property?.name}</h6>}
              {table_heading?.google_coordinate_link && <h6 className='text-[11px] min-w-[170px] max-w-[170px] px-2 py-1 font-[600] text-slate-600 border-r'>{d?.google_coordinate_link}</h6>}
              {table_heading?.landmark_to_property && <h6 className='text-[11px] min-w-[170px] max-w-[170px]] px-2 py-1 font-[600] text-slate-600 border-r'>{d?.landmark_to_property}</h6>}
              {table_heading?.seater_offered && <h6 className='text-[11px] min-w-[170px] max-w-[170px] px-2 py-1 font-[600] text-slate-600 border-r'>{d?.seater_offered}</h6>}
              {table_heading?.rent_per_seat && <h6 className='text-[11px] min-w-[170px] max-w-[170px] px-2 py-1 font-[600] text-slate-600 border-r'>{d?.rent_per_seat}</h6>}
              {table_heading?.amenities && <h6 className='text-[11px] min-w-[170px] max-w-[170px] px-2 py-1 font-[600] text-slate-600 border-r flex flex-wrap'>{d?.amenities?.map((a)=><p className='bg-slate-200 mr-[2px] mb-[2px] p-[4px] rounded text-[11px] font-[700]'>{a?.name}</p>)}</h6>}
              {table_heading?.car_parking_charges && <h6 className='text-[11px] min-w-[170px] max-w-[170px] px-2 py-1 font-[600] text-slate-600 border-r'>{d?.car_parking_charges}</h6>}
              {table_heading?.security_deposit && <h6 className='text-[11px] min-w-[170px] max-w-[170px] px-2 py-1 font-[600] text-slate-600 border-r'>{d?.security_deposit}</h6>}
              {table_heading?.escalation && <h6 className='text-[11px] min-w-[120px] max-w-[120px] px-2 py-1 font-[600] text-slate-600 border-r'>{d?.escalation}</h6>}
              {table_heading?.lock_in_period && <h6 className='text-[11px] min-w-[120px] max-w-[120px] px-2 py-1 font-[600] text-slate-600 border-r'>{d?.lock_in_period}</h6>}
              {table_heading?.lease_term_tenure && <h6 className='text-[11px] min-w-[140px] max-w-[140px] px-2 py-1 font-[600] text-slate-600 border-r'>{d?.lease_term_tenure}</h6>}
              {table_heading?.possession_type && <h6 className='text-[11px] min-w-[170px] max-w-[170px] px-2 py-1 font-[600] text-slate-600 border-r'>{d?.possession_type}</h6>}
              {table_heading?.total_built_up_area && <h6 className='text-[11px] min-w-[170px] max-w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'>{d?.total_built_up_area}</h6>}
              {table_heading?.no_of_floors && <h6 className='text-[11px] min-w-[150px] max-w-[150px]  px-2 py-1 font-[600] text-slate-600 border-r'>{d?.no_of_floors}</h6>}
              {table_heading?.per_floor_area && <h6 className='text-[11px] min-w-[170px] max-w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'>{d?.per_floor_area}</h6>}
              {table_heading?.interior_details && <h6 className='text-[11px] min-w-[170px] max-w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'>{d?.interior_details}</h6>}
              {table_heading?.area_offered && <h6 className='text-[11px] min-w-[170px] max-w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'>{d?.area_offered}</h6>}
              {table_heading?.floor_offered && <h6 className='text-[11px] min-w-[170px] max-w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'>{d?.floor_offered}</h6>}
              {table_heading?.rent_per_sqft && <h6 className='text-[11px] min-w-[170px] max-w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'>{d?.rent_per_sqft}</h6>}
              {table_heading?.power_and_backup && <h6 className='text-[11px] min-w-[170px] max-w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'>{d?.power_and_backup}</h6>}
              {table_heading?.maintenace_charges && <h6 className='text-[11px] min-w-[170px] max-w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'>{d?.maintenace_charges}</h6>}
              {table_heading?.facility_type && <h6 className='text-[11px] min-w-[170px] max-w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'>{d?.facility_type?.name}</h6>}
              {table_heading?.oc_availability && <h6 className='text-[11px] min-w-[170px] max-w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'>Oc Availablility</h6>}
              {table_heading?.status && <h6 className='text-[11px] min-w-[170px] max-w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'>{d?.status?.name}</h6>}
              {table_heading?.primary_contact_name && <h6 className='text-[11px] min-w-[170px] max-w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'>{d?.primary_contact_name}</h6>}
              {table_heading?.primary_contact_no && <h6 className='text-[11px] min-w-[150px] max-w-[150px]  px-2 py-1 font-[600] text-slate-600 border-r'>{d?.primary_contact_no}</h6>}
              {table_heading?.added_by && <h6 className='text-[11px] min-w-[140px] max-w-[140px]  px-2 py-1 font-[600] text-slate-600 border-r'>Added By</h6>}
              {table_heading?.created_At && <h6 className='text-[11px] min-w-[120px] max-w-[120px]  px-2 py-1 font-[600] text-slate-600 border-r'>{moment(d?.createdAt)?.format('LLL')}</h6>}
              <h6  className='text-[11px] w-[120px]  px-2 py-1 flex'>
              <Tooltip title="Detail">
              <span><RiBillLine size={13} onClick={()=>navigate(`detail`,{state:d?._id})} className=" z-10"/></span>
              </Tooltip>

              {path !== 'invoice_raised' &&
              <Tooltip title="Edit">
              <span><AiOutlineEdit size={13} className="z-10 ml-2" onClick={()=>navigate('edit',{state:d?._id})}/></span>
              </Tooltip>}

            
              {user?.roles?.includes('delete_data') &&
              <Tooltip title="Delete">
              <span><AiOutlineDelete size={13} className="z-10 ml-2 -mt-[1px]" onClick={()=>{deleteData(d?._id)}}/></span>
              </Tooltip>}
             
              

            
            </h6>
        </div>))}
            </div>}
          
           
            </div>

        
      


      </div>
    </div>
  )
}

export default ManagedOfficeList