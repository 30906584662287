import React, { useEffect, useState } from 'react'
import ProfileMenu from './ProfileMenu'
import { GetMyAssetAssignedService } from '../../services/ITServices/AssetAssignedOption/AssetAssignedService'
import { useSelector } from 'react-redux'

function MyAsset() {

  const user = useSelector(state=>state.Auth)
  const [data,setdata] = useState({}) 

  useEffect(()=>{
    getData()
  },[])

  async function getData() {
    const response = await GetMyAssetAssignedService(user?.id)
    setdata(response?.data?.datas[0])
  }

  // console.log("data",data)



  return (
    <div className='px-2 mx-0 box-border max-h-screen min-h-screen h-screen overflow-hidden'>
        <div className='block sm:flex max-h-screen min-h-screen h-screen overflow-hidden'>
        <ProfileMenu />         
        <div className='w-full max-h-screen min-h-screen h-screen overflow-y-scroll'>
            <div className='ml-6  mb-4  ml-4 mt-4 pt-5'>
            <h6 className='border-b mt-1 pb-2 mb-4 font-[700] text-[17px] border-[#d0d7de]'>My Asset Info</h6>
        </div>

        {data !== null &&
        <div className='px-[2%] flex'>
         
         <div className='w-[20%]  mt-2 border-r pr-4'>

            <h6 className='text-[13px] mb-2 font-[700]'>Laptop / Desktop / CPU / Mouse / Keybord / Charger Assigned</h6>

            {data?.laptop?.map((l)=>(    
            <div key={l?._id} className='border p-2 mb-2 relative'>
              <h6 className='font-[800] bg-slate-700 text-white text-[8px] px-1 py-[2px] absolute left-0 top-0'>Laptop</h6>
              <div className='w-[100%] h-[100px] bg-slate-100'>
              {![null,undefined,'']?.includes(l?.image) &&  <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${l?.image}`} alt={l?.image} className='w-[100%] h-[100%] object-contain' />}
              </div>
              <h6 className='text-[13px] mt-1 capitalize'>{l?.name}</h6>
              <h6 className='text-[10px] flex  capitalize'>Device Id : <span className='font-[700] mr-1'>{l?.device_id}</span> </h6>
              <h6 className='text-[10px] capitalize'>Asset Id : <span className='font-[700]'>{l?.asset_id}</span></h6>
            </div>))}

            {data?.desktop?.map((l)=>(    
            <div key={l?._id} className='border p-2 mb-2 relative'>
              <h6 className='font-[800] bg-slate-700 text-white text-[8px] px-1 py-[2px] absolute left-0 top-0'>Desktop</h6>
              <div className='w-[100%] h-[100px] bg-slate-100'>
              {![null,undefined,'']?.includes(l?.image) &&  <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${l?.image}`} alt={l?.image} className='w-[100%] h-[100%] object-contain' />}
              </div>
              <h6 className='text-[13px] mt-1 capitalize'>{l?.name}</h6>
              <h6 className='text-[10px] flex  capitalize'>Device Id : <span className='font-[700] mr-1'>{l?.device_id}</span> </h6>
              <h6 className='text-[10px] capitalize'>Asset Id : <span className='font-[700]'>{l?.asset_id}</span></h6>
            </div>))}

            {data?.cpu?.map((l)=>(    
            <div key={l?._id} className='border p-2 mb-2 relative'>
              <h6 className='font-[800] bg-slate-700 text-white text-[8px] px-1 py-[2px] absolute left-0 top-0'>CPU</h6>
              <div className='w-[100%] h-[100px] bg-slate-100'>
              {![null,undefined,'']?.includes(l?.image) &&  <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${l?.image}`} alt={l?.image} className='w-[100%] h-[100%] object-contain' />}
              </div>
              <h6 className='text-[13px] mt-1 capitalize'>{l?.name}</h6>
              <h6 className='text-[10px] flex  capitalize'>Device Id : <span className='font-[700] mr-1'>{l?.device_id}</span> </h6>
              <h6 className='text-[10px] capitalize'>Asset Id : <span className='font-[700]'>{l?.asset_id}</span></h6>
            </div>))}

            {data?.mouse?.map((l)=>(    
            <div key={l?._id} className='border p-2 mb-2 relative'>
              <h6 className='font-[800] bg-slate-700 text-white text-[8px] px-1 py-[2px] absolute left-0 top-0'>Mouse</h6>
              <div className='w-[100%] h-[100px] bg-slate-100'>
              {![null,undefined,'']?.includes(l?.image) &&  <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${l?.image}`} alt={l?.image} className='w-[100%] h-[100%] object-contain' />}
              </div>
              <h6 className='text-[13px] mt-1 capitalize'>{l?.name}</h6>
              <h6 className='text-[10px] flex  capitalize'>Device Id : <span className='font-[700] mr-1'>{l?.device_id}</span> </h6>
              <h6 className='text-[10px] capitalize'>Asset Id : <span className='font-[700]'>{l?.asset_id}</span></h6>
            </div>))}

            {data?.keyboard?.map((l)=>(    
            <div key={l?._id} className='border p-2 mb-2 relative'>
              <h6 className='font-[800] bg-slate-700 text-white text-[8px] px-1 py-[2px] absolute left-0 top-0'>Keyboard</h6>
              <div className='w-[100%] h-[100px] bg-slate-100'>
              {![null,undefined,'']?.includes(l?.image) &&  <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${l?.image}`} alt={l?.image} className='w-[100%] h-[100%] object-contain' />}
              </div>
              <h6 className='text-[13px] mt-1 capitalize'>{l?.name}</h6>
              <h6 className='text-[10px] flex  capitalize'>Device Id : <span className='font-[700] mr-1'>{l?.device_id}</span> </h6>
              <h6 className='text-[10px] capitalize'>Asset Id : <span className='font-[700]'>{l?.asset_id}</span></h6>
            </div>))}

            {data?.laptop_charger?.map((l)=>(    
            <div key={l?._id} className='border p-2 mb-2 relative'>
              <h6 className='font-[800] bg-slate-700 text-white text-[8px] px-1 py-[2px] absolute left-0 top-0'>Laptop Charger</h6>
              <div className='w-[100%] h-[100px] bg-slate-100'>
              {![null,undefined,'']?.includes(l?.image) &&  <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${l?.image}`} alt={l?.image} className='w-[100%] h-[100%] object-contain' />}
              </div>
              <h6 className='text-[13px] mt-1 capitalize'>{l?.name}</h6>
              <h6 className='text-[10px] flex  capitalize'>Device Id : <span className='font-[700] mr-1'>{l?.device_id}</span> </h6>
              <h6 className='text-[10px] capitalize'>Asset Id : <span className='font-[700]'>{l?.asset_id}</span></h6>
            </div>))}

            
         </div>

         <div className='w-[20%] ml-4 mt-2 border-r pr-4'>

          <h6 className='text-[13px] mb-2 font-[700]'>Mobile / Charger Assigned</h6>

          {data?.mobile?.map((l)=>(    
          <div key={l?._id} className='border p-2 mb-2 relative'>
            <h6 className='font-[800] bg-slate-700 text-white text-[8px] px-1 py-[2px] absolute left-0 top-0'>Mobile</h6>
            <div className='w-[100%] h-[100px] bg-slate-100'>
            {![null,undefined,'']?.includes(l?.image) &&  <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${l?.image}`} alt={l?.image} className='w-[100%] h-[100%] object-contain' />}
            </div>
            <h6 className='text-[13px] mt-1 capitalize'>{l?.name}</h6>
            <h6 className='text-[10px] flex  capitalize'>Device Id : <span className='font-[700] mr-1'>{l?.device_id}</span> </h6>
            <h6 className='text-[10px] capitalize'>Asset Id : <span className='font-[700]'>{l?.asset_id}</span></h6>
          </div>))}

     
          {data?.mobile_charger?.map((l)=>(    
          <div key={l?._id} className='border p-2 mb-2 relative'>
            <h6 className='font-[800] bg-slate-700 text-white text-[8px] px-1 py-[2px] absolute left-0 top-0'>Mobile Charger</h6>
            <div className='w-[100%] h-[100px] bg-slate-100'>
            {![null,undefined,'']?.includes(l?.image) &&  <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${l?.image}`} alt={l?.image} className='w-[100%] h-[100%] object-contain' />}
            </div>
            <h6 className='text-[13px] mt-1 capitalize'>{l?.name}</h6>
            <h6 className='text-[10px] flex  capitalize'>Device Id : <span className='font-[700] mr-1'>{l?.device_id}</span> </h6>
            <h6 className='text-[10px] capitalize'>Asset Id : <span className='font-[700]'>{l?.asset_id}</span></h6>
          </div>))}


          </div>

          <div className='w-[20%] ml-4 mt-2 border-r pr-4'>

          <h6 className='text-[13px] mb-2 font-[700]'>HardDisk / Pendrive / Cable Connectors Assigned</h6>

          {data?.pendrive?.map((l)=>(    
          <div key={l?._id} className='border p-2 mb-2 relative'>
            <h6 className='font-[800] bg-slate-700 text-white text-[8px] px-1 py-[2px] absolute left-0 top-0'>Pendrive</h6>
            <div className='w-[100%] h-[100px] bg-slate-100'>
            {![null,undefined,'']?.includes(l?.image) &&  <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${l?.image}`} alt={l?.image} className='w-[100%] h-[100%] object-contain' />}
            </div>
            <h6 className='text-[13px] mt-1 capitalize'>{l?.name}</h6>
            <h6 className='text-[10px] flex  capitalize'>Device Id : <span className='font-[700] mr-1'>{l?.device_id}</span> </h6>
            <h6 className='text-[10px] capitalize'>Asset Id : <span className='font-[700]'>{l?.asset_id}</span></h6>
          </div>))}

     
          {data?.harddisk?.map((l)=>(    
          <div key={l?._id} className='border p-2 mb-2 relative'>
            <h6 className='font-[800] bg-slate-700 text-white text-[8px] px-1 py-[2px] absolute left-0 top-0'>HardDisk</h6>
            <div className='w-[100%] h-[100px] bg-slate-100'>
            {![null,undefined,'']?.includes(l?.image) &&  <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${l?.image}`} alt={l?.image} className='w-[100%] h-[100%] object-contain' />}
            </div>
            <h6 className='text-[13px] mt-1 capitalize'>{l?.name}</h6>
            <h6 className='text-[10px] flex  capitalize'>Device Id : <span className='font-[700] mr-1'>{l?.device_id}</span> </h6>
            <h6 className='text-[10px] capitalize'>Asset Id : <span className='font-[700]'>{l?.asset_id}</span></h6>
          </div>))}


          {data?.cable?.map((l)=>(    
          <div key={l?._id} className='border p-2 mb-2 relative'>
            <h6 className='font-[800] bg-slate-700 text-white text-[8px] px-1 py-[2px] absolute left-0 top-0'>Cable</h6>
            <div className='w-[100%] h-[100px] bg-slate-100'>
            {![null,undefined,'']?.includes(l?.image) &&  <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${l?.image}`} alt={l?.image} className='w-[100%] h-[100%] object-contain' />}
            </div>
            <h6 className='text-[13px] mt-1 capitalize'>{l?.name}</h6>
            <h6 className='text-[10px] flex  capitalize'>Device Id : <span className='font-[700] mr-1'>{l?.device_id}</span> </h6>
            <h6 className='text-[10px] capitalize'>Asset Id : <span className='font-[700]'>{l?.asset_id}</span></h6>
          </div>))}



          </div>

          <div className='w-[40%] ml-4 mt-2'>

            <h6 className='text-[13px] mb-2 font-[700]'>Total Asset Bifurcation</h6>
            
            <div className='flex items-center border text-[11px]'>
              <h6 className='w-[40%] p-[4px] border-r'>Device Type </h6>
              <h6 className='w-[40%] p-[4px] border-r'>Device Id </h6>
              <h6 className='w-[20%] p-[4px] '>Count </h6>
            </div>

            {data?.laptop?.length > 0 &&
            <div className='flex items-center border-l border-b border-r text-[11px]'>
              <h6 className='w-[40%] p-[4px] border-r'>Laptop</h6>
              <h6 className='w-[40%] p-[4px] border-r'>{data?.laptop?.map((l,i)=><span key={i} className='mr-2 font-[700]'>{l?.asset_id} {i < data?.laptop?.length - 1 && ','}</span>)}</h6>
              <h6 className='w-[20%] p-[4px]'>{data?.laptop?.length}</h6>
            </div>}

            {data?.desktop?.length > 0 &&
            <div className='flex items-center border-l border-b border-r text-[11px]'>
              <h6 className='w-[40%] p-[4px] border-r'>Desktop</h6>
              <h6 className='w-[40%] p-[4px] border-r'>{data?.desktop?.map((l,i)=><span key={i} className='mr-2 font-[700]'>{l?.asset_id} {i < data?.desktop?.length - 1 && ','}</span>)}</h6>
              <h6 className='w-[20%] p-[4px]'>{data?.desktop?.length}</h6>
            </div>}

            {data?.mouse?.length > 0 &&
            <div className='flex items-center border-l border-b border-r text-[11px]'>
              <h6 className='w-[40%] p-[4px] border-r'>Mouse</h6>
              <h6 className='w-[40%] p-[4px] border-r'>{data?.mouse?.map((l,i)=><span key={i} className='mr-2 font-[700]'>{l?.asset_id} {i < data?.mouse?.length - 1 && ','}</span>)}</h6>
              <h6 className='w-[20%] p-[4px]'>{data?.mouse?.length}</h6>
            </div>}

            {data?.keyboard?.length > 0 &&
            <div className='flex items-center border-l border-b border-r text-[11px]'>
              <h6 className='w-[40%] p-[4px] border-r'>Keyboard</h6>
              <h6 className='w-[40%] p-[4px] uppercase border-r'>{data?.keyboard?.map((l,i)=><span key={i} className='mr-2 font-[700]'>{l?.asset_id} {i < data?.keyboard?.length - 1 && ','}</span>)}</h6>
              <h6 className='w-[20%] p-[4px]'>{data?.keyboard?.length}</h6>
            </div>}

            {data?.laptop_charger?.length > 0 &&
            <div className='flex items-center border-l border-b border-r text-[11px]'>
              <h6 className='w-[40%] p-[4px] border-r'>Laptop Charger</h6>
              <h6 className='w-[40%] p-[4px] uppercase border-r'>{data?.laptop_charger?.map((l,i)=><span key={i} className='mr-2 font-[700]'>{l?.asset_id} {i < data?.laptop_charger?.length - 1 && ','}</span>)}</h6>
              <h6 className='w-[20%] p-[4px]'>{data?.laptop_charger?.length}</h6>
            </div>}

            {data?.mobile?.length > 0 &&
            <div className='flex items-center border-l border-b border-r text-[11px]'>
              <h6 className='w-[40%] p-[4px] border-r'>Mobile</h6>
              <h6 className='w-[40%] p-[4px] uppercase border-r'>{data?.mobile?.map((l,i)=><span key={i} className='mr-2 font-[700]'>{l?.asset_id} {i < data?.mobile?.length - 1 && ','}</span>)}</h6>
              <h6 className='w-[20%] p-[4px]'>{data?.mobile?.length}</h6>
            </div>}

            {data?.mobile_charger?.length > 0 &&
            <div className='flex items-center border-l border-b border-r text-[11px]'>
              <h6 className='w-[40%] p-[4px] border-r'>Mobile Charger</h6>
              <h6 className='w-[40%] p-[4px] uppercase border-r'>{data?.mobile_charger?.map((l,i)=><span key={i} className='mr-2 font-[700]'>{l?.asset_id} {i < data?.mobile_charger?.length - 1 && ','}</span>)}</h6>
              <h6 className='w-[20%] p-[4px]'>{data?.mobile_charger?.length}</h6>
            </div>}

            {data?.harddisk?.length > 0 &&
            <div className='flex items-center border-l border-b border-r text-[11px]'>
              <h6 className='w-[40%] p-[4px] border-r'>Hard Disk</h6>
              <h6 className='w-[40%] p-[4px] uppercase border-r'>{data?.harddisk?.map((l,i)=><span key={i} className='mr-2 font-[700]'>{l?.asset_id} {i < data?.harddisk?.length - 1 && ','}</span>)}</h6>
              <h6 className='w-[20%] p-[4px]'>{data?.harddisk?.length}</h6>
            </div>}

            {data?.harddisk?.length > 0 &&
            <div className='flex items-center border-l border-b border-r text-[11px]'>
              <h6 className='w-[40%] p-[4px] border-r'>Hard Disk</h6>
              <h6 className='w-[40%] p-[4px] uppercase border-r'>{data?.harddisk?.map((l,i)=><span key={i} className='mr-2 font-[700]'>{l?.asset_id} {i < data?.harddisk?.length - 1 && ','}</span>)}</h6>
              <h6 className='w-[20%] p-[4px]'>{data?.harddisk?.length}</h6>
            </div>}

            {data?.pendrive?.length > 0 &&
            <div className='flex items-center border-l border-b border-r text-[11px]'>
              <h6 className='w-[40%] p-[4px] border-r'>PenDrive</h6>
              <h6 className='w-[40%] p-[4px] uppercase border-r'>{data?.pendrive?.map((l,i)=><span key={i} className='mr-2 font-[700]'>{l?.asset_id} {i < data?.pendrive?.length - 1 && ','}</span>)}</h6>
              <h6 className='w-[20%] p-[4px]'>{data?.pendrive?.length}</h6>
            </div>}

            {data?.cable?.length > 0 &&
            <div className='flex items-center border-l border-b border-r text-[11px]'>
              <h6 className='w-[40%] p-[4px] border-r'>Cable</h6>
              <h6 className='w-[40%] p-[4px] uppercase border-r'>{data?.cable?.map((l,i)=><span key={i} className='mr-2 font-[700]'>{l?.asset_id} {i < data?.cable?.length - 1 && ','}</span>)}</h6>
              <h6 className='w-[20%] p-[4px]'>{data?.cable?.length}</h6>
            </div>}

          </div> 

        </div>}


          
        </div>
        </div>
    </div>
  )
}

export default MyAsset