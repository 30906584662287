export const institueUrl = 'https://fidelituscorp.azurewebsites.net/'

export const appVersion = "1.00.00.000"

export const instituteArr = [
    {label:'Facility Management Service',value:'a9ba1f6b-690c-4292-8c78-c8fb6bb98dda'},
    {label:'Fidelitus Gallery',value:'3a6c41db-fef4-4f37-a374-54733c8bc6c2'},
    {label:'HR Labs',value:'4c7caeca-d2bf-4dee-91c2-b8bff93c0414'},
    {label:'Projects',value:'c7e06c3d-61dd-4abc-914c-abef412cbf0f'},
    {label:'Shilpa Foundation (NGO)',value:'7cb3bf51-bbf1-4847-b871-9ce5476a1775'},
    {label:'Transaction Team',value:'ba227744-f6d1-44c0-b1df-efe85971dee8'},
]