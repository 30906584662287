import { deleteRequest, get, post, postfd, put } from "../helpers/apihelpers";



export const CreateAssignLeadsService=async(data)=>{
    try {
        const result = await post(`api/fidelitus_lead/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}
export const CreateAssignLeadsService1=async(data)=>{
    try {
        const result = await post(`api/fidelitus_lead/create_lead`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const CreateLeadsListBasedonIdService=async(data)=>{
    try {
        const result = await post(`api/assign_lead/convert_data_to_contact`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetAssignedLeadsService=async(page)=>{
    try {
        const result = await get(`api/fidelitus_lead/get/null/${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetAllAssignedLeadsService=async(department='',users='',created_department='',page=1,from_date='',to_date='')=>{
    try {
        const result = await get(`api/fidelitus_lead/getallleads/${department}/${users}/${page}?created_department=${created_department}&from_date=${from_date}&to_date=${to_date}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetAllAssignedLeadsFilterService=async(department=null,created_department=null,users=null,page=1,search_text='',from_date='',to_date='',zone='')=>{
   
    try {
        const result = await get(`api/fidelitus_lead/getallleads_filter/${department}/${users}/${page}?search_text=${search_text}&from_date=${from_date}&to_date=${to_date}&zone=${zone}&created_department=${created_department}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetAllAssignedLeadsToMeService=async(department,page)=>{
    try {
        const result = await get(`api/fidelitus_lead/fidelitus_leads_shared_to_me/${department}/${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetAllAssignedLeadsBasedStageService=async(department,page,step,assigned_to)=>{
    try {
        const result = await get(`api/fidelitus_lead/getbased_stage?department=${department}&page=${page}&step=${step}&assigned_to=${assigned_to}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetAllAssignedLeadsGraphService=async(department,users='',created_department='',from_date='',to_date='')=>{
    try {
        const result = await get(`api/fidelitus_lead/getallleads_graph/${department}?user=${users}&created_department=${created_department}&from_date=${from_date}&to_date=${to_date}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetAllFidelitusLeadsIndivisualDateService=async(department,users='',created_department='',from_date='',to_date='')=>{
    try {
        const result = await get(`api/fidelitus_lead/getfidelitusleads_indivisualdate/${department}?user=${users}&created_department=${created_department}&from_date=${from_date}&to_date=${to_date}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetAllFidelitusLeadsBasedDateService=async(department,users='',created_department='',search_text='',from_date='',to_date='',page=1)=>{
    try {
        const result = await get(`api/fidelitus_lead/getfidelitusleads_basedDate/${department}?user=${users}&created_department=${created_department}&search_text=${search_text}&from_date=${from_date}&to_date=${to_date}&page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetAllAssignedLeadsCalendarService=async(department='',from_date='',to_date='',created_department='',users='',)=>{
    try {
        const result = await get(`api/fidelitus_lead/getallleads_calendar/${department}?from_date=${from_date}&to_date=${to_date}&created_department=${created_department}&user=${users}`);
        return result;
    } catch (err) {
        return err.response;
    }
}



export const GetAssignLeadsDetailService=async(id)=>{
    try {
        const result = await get(`api/fidelitus_lead/get/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const SearchAssignLeadsService = async({search_text,status,from_date,to_date,zone,page,department=''})=>{
    try {
        const result = await get(`api/fidelitus_lead/search?search_text=${search_text}&status=${status}&from_date=${from_date}&to_date=${to_date}&zone=${zone}&page=${page}&department=${department}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const SearchAssignLeadsService1 = async({search_text,status,from_date,to_date,zone,page,department=''})=>{
    try {
        const result = await get(`api/fidelitus_lead/search1?search_text=${search_text}&status=${status}&from_date=${from_date}&to_date=${to_date}&zone=${zone}&page=${page}&department=${department}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateAssignLeadsService=async(data,id)=>{
    try {
        const result = await put(`api/fidelitus_lead/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateImportantLeads=async(data)=>{
    try {
        const result = await put(`api/assign_lead/update_importance/${data._id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}


export const SearchLeadService = async({search_text,status,from_date,to_date,zone,page})=>{
    try {
        const result = await get(`api/assign_lead/search?search_text=${search_text}&status=${status}&from_date=${from_date}&to_date=${to_date}&zone=${zone}&page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteAssignLeadService = async(id)=>{
    try {
        const result = await deleteRequest(`api/fidelitus_lead/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UploadFidelitusLeadExcel = async(file) => {
    try {
        const result = await postfd(`api/fidelitus_lead/upload_excel`,{file:file});
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteFidelitusLead = async() => {
    try {
        const result = await deleteRequest(`api/fidelitus_lead/deleteData`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateFidelitusLeadAssigned = async({id,data})=>{
   
    try {
        const result = await put(`api/fidelitus_lead/update_fidelitus_lead_Assigned/${id}`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}












