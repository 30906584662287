import React,{useEffect, useState} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Select } from 'antd';
import { TextAreaInput1, TextInput } from '../../components/input';
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button';
import toast from 'react-hot-toast';
import GovtMenu from './GovtMenu';
import GoBack from '../../components/back/GoBack';
import { GetDepartmentService } from '../../services/DepartmentServices';
import { CreateNoticesService, UpdateNoticesService, UploadNoticesFileService } from '../../services/govtservices/NoticeServices';
import Uploader from '../../components/Uploader';

function NoticeListCE() {

  const user = useSelector(state=>state.Auth) 

  const {pathname} = useLocation()

  const path = pathname.split('/')[pathname.split('/')?.length - 1]

  const [loader,setloader] = useState(false)
  const [loader1,setloader1] = useState(false)
  const [departmentArr,setdepartmentArr] = useState([])

  
  const navigate = useNavigate()
  const {state} = useLocation()

  const [data,setdata] = useState({
    noticeType:'',
    name:'',
    noticeDescription:'',
    noticeRecievedDate:'',
    attachment:'',
    department:'',
    // caseRehearingDate:'',
    remarks:'',
    stage:{label:'Pending',value:'Pending'}
  })

  const [error,seterror] = useState({
    noticeType:'',
    name:'',
    noticeDescription:'',
    noticeRecievedDate:'',
    attachment:'',
    department:'',
    // caseRehearingDate:'',
    remarks:'',
    stage:''
  })


  useEffect(()=>{
    if(path === 'edit'){
      setdata({
        noticeType:{label:state?.noticeType,value:state?.noticeType},
        name:state?.name,
        noticeDescription:state?.noticeDescription,
        noticeRecievedDate:state?.noticeRecievedDate?.slice(0,10),
        attachment:state?.attachment,
        department:(state?.department !== null && state?.department !== undefined && state?.department !== '') ? {label:state?.department?.department_name,value:state?.department?._id} : '',
        // caseRehearingDate:state?.caseRehearingDate !== '' ? state?.caseRecievedDate?.slice(0,10) : '' ,
        remarks:state?.remarks,
        stage:{label:state?.stage,value:state?.stage}
      })
    }
    getdepartment()
  },[])



 

 async function getdepartment(){
  const response = await GetDepartmentService()
  let arr = []

  response?.data?.datas?.forEach((d)=>{
      arr.push({value:d?.id,label:d?.department_name})
  })

  setdepartmentArr(arr)
} 




    const noticeType = [
      {label:'Company Notice',value:'Company Notice'},
      {label:'Financial Notice',value:'Financial Notice'},
      {label:'Government Notice',value:'Government Notice'},
    ]

    const stage = [
      {label:'Pending',value:'Pending'},
      {label:'Process',value:'Process'},
      {label:'Completed',value:'Completed'},
    ]

   
    function handlechange(e){
      setdata({...data,[e.target.name] : e.target.value})
      seterror({...error,[e.target.name] : ''})
    }
      
       



     async function submitForm(){
          
      if(!data?.name){
        seterror({...error,name:'This Field is required!'})
      }else if(!data?.noticeDescription){
        seterror({...error,noticeDescription:'This Field is required!'})
      }else if(!data?.noticeRecievedDate){
        seterror({...error,noticeRecievedDate:'This Field is required!'})
      }else if(!data?.noticeType){
        seterror({...error,noticeType:'This Field is required!'})
      }else{

        let sendData = {
          noticeType:data?.noticeType,
          name:data?.name,
          noticeDescription:data?.noticeDescription,
          noticeRecievedDate:data?.noticeRecievedDate,
          attachment:data?.attachment,
          // caseRehearingDate:data?.caseRehearingDate,
          remarks:data?.remarks,
        }

        if(data?.department?.value !== undefined){
          sendData['department'] = data?.department?.value
        }
        if(data?.noticeType?.value !== undefined){
          sendData['noticeType'] = data?.noticeType?.value
        }

        if(data?.stage?.value !== undefined){
          sendData['stage'] = data?.stage?.value
        }
      
        if(path === 'create'){
          const response = await CreateNoticesService(sendData)
          if(response?.status === 201){
            resetform()
            toast.success("Notice Created Successfully1")
          }
        }
        if(path === 'edit'){
          const response = await UpdateNoticesService(state?._id,sendData)
          if(response?.status === 200){
            resetform()
            toast.success("Notice Updated Successfully1")
            navigate(-1)
          }
        }  

        // else {
        //   toast.success("Case Updated Successfully1")

        // }

      }
        

     }


     function resetform(){
        setdata({
          noticeType:'',
          name:'',
          noticeDescription:'',
          noticeRecievedDate:'',
          attachment:'',
          department:'',
          // caseRehearingDate:'',
          remarks:'',
          stage:{label:'Pending',value:'Pending'}
        })
        seterror({
          noticeType:'',
          name:'',
          noticeDescription:'',
          noticeRecievedDate:'',
          attachment:'',
          department:'',
          // caseRehearingDate:'',
          remarks:'',
          stage:''
        })
     }


     async function uploadfile(v,name){
      const fd = new FormData()
      fd.append('file',v); 
      const response = await UploadNoticesFileService(fd)
      if(response?.status === 200){
        setdata({...data,[name]:response?.data?.data})
        seterror({...error,[name]:''})
      }
    }

  


  return (
    <div  className="w-[100%] h-screen overflow-hidden">
      
    
        <div className='flex'>
          <div className='w-[44] max-h-screen overflow-hidden'>
            <GovtMenu />   
          </div>  
          <div className='w-80 min-h-screen max-h-screen  overflow-y-scroll p-4 px-6'>
            
          <GoBack />

          <div className='border-b pb-2'>
                <h6 className='text-[13px] mb-1 font-[800]'>Create/Edit the Notices Recieved By You</h6>
                <h6 className='text-[12px] bg-slate-200 p-2'>Use the Below form to add or edit the daily notices recieved by you! </h6>      
          </div>

            <TextInput 
              label={'Name'}
              name={'name'}
              mandatory={true}
              value={data?.name}
              error={error.name}
              handlechange={handlechange}
            />

            <TextAreaInput1 
              label={'Notice Description'}
              name={'noticeDescription'}
              mandatory={true}
              value={data?.noticeDescription}
              error={error.noticeDescription}
              handlechange={handlechange}
            />


            <TextInput 
              label={'Notice Receive Date'}
              name={'noticeRecievedDate'}
              mandatory={true}
              type="date"
              value={data?.noticeRecievedDate}
              error={error.noticeRecievedDate}
              handlechange={handlechange}
            />

            

          <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>Notice Type</h6>
          <Select 
            value={data?.noticeType}
            bordered={false}
            onChange={(v)=>{setdata({...data,noticeType:{label:v,value:v}});seterror({...error,noticeType:''})}}
            className='w-full border-l-4 border-l-slate-600  border h-[32px] border-slate-300'
            options={noticeType} />


          {(data?.noticeType?.label === 'Company Notice' || data?.noticeType?.label === 'Government Notice') &&
          <>
          <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>Department</h6>

          <Select 
          value={data?.department}
          bordered={false}
          onChange={(v)=>{setdata({...data,department:departmentArr?.find((f)=>f?.value === v)});seterror({...error,department:''})}}
          className='w-full border h-[32px] border-slate-300'
          options={departmentArr} />
          </>}

         {/* {path !== 'create' && */}
           <>
          <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>Stage</h6>
          <Select 
          value={data?.stage}
          bordered={false}
          onChange={(v)=>{setdata({...data,stage:{label:v,value:v}});seterror({...error,stage:''})}}
          className='w-full border h-[32px] border-slate-300'
          options={stage} />
          </>
          {/* } */}

          <div className='relative mb-1 mt-2'>
          <h6 className='text-[11px] font-[600] mb-1 mt-2' >Attachment</h6>    
          {(data.attachment !== '' && data.attachment !== null) && <h6 onClick={()=>window.open(`${process.env.REACT_APP_AWS_IMAGE_URL}${data.attachment}`, "_blank")} className='text-[10px] hover:underline cursor-pointer font-[600] absolute top-0 right-0' >View File</h6>}
          <Uploader image={data?.attachment}  setimagefunc={(e)=>{uploadfile(e,'attachment');seterror({...error,attachment:''})}}  removeimageuploadfunc = {()=>{setdata({...data,attachment:''});seterror({...error,attachment:''})}}/>    
          </div>
          
         


        {/* <TextInput 
              label={'Case Re-Hearing Date'}
              name={'caseRehearingDate'}
              type="date"
              value={data?.caseRehearingDate}
              error={error.caseRehearingDate}
              handlechange={handlechange}
            /> */}


          <TextAreaInput1 
              label={'Remarks'}
              name={'remarks'}
              type="text"
              value={data?.remarks}
              error={error.remarks}
              handlechange={handlechange}
            />


      

      <div className='flex justify-end border-t pt-5 my-5'>
        <ButtonOutlinedAutoWidth btnName="Cancel" onClick={()=>navigate(-1)} />
          <h6 className='w-2'></h6>
        {loader ? 
        <ButtonFilledAutoWidth btnName="Loading"  />
          :
        <ButtonFilledAutoWidth btnName="Save" onClick={submitForm} />}
        </div>
   
          </div>


   

  
          </div>
        </div>
  )
}

export default NoticeListCE