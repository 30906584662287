import React,{useState,useEffect} from 'react'
import ItMenu from '../ItMenu'
import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../../components/button';
import { Drawer, Modal} from 'antd';
import { IconButton } from '@mui/material'
import moment from 'moment';
import { AiOutlineEdit,AiOutlineDelete, AiOutlineClose } from 'react-icons/ai';
import { Tooltip } from '@mui/material';
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { DeleteAssetAssignedService, GetAssetAssignedDetailService, GetAssetAssignedService, GetMyAssetAssignedUsageService } from '../../../services/ITServices/AssetAssignedOption/AssetAssignedService';
import { RiBillLine } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import GoBack from '../../../components/back/GoBack';
import { CgPlayTrackNextR } from "react-icons/cg";
import { FaRegCalendar } from "react-icons/fa6";


function AssignAssetOptionDetail() {


  const navigate = useNavigate()

  const [data,setdata] = useState([])
  const [assetAssign,setassetAssign] = useState([])
  const [drawer,setdrawer] = useState(false)

  const {state} = useLocation()

  useEffect(()=>{
    getdata()
    getassignedassetusage()
  },[])

  async function getdata() {
    const response = await GetAssetAssignedDetailService(state?._id)
    let d = response?.data?.datas[0]
    setdata(d)
  }

  async function getassignedassetusage() {
    const response = await GetMyAssetAssignedUsageService()
    setassetAssign(response?.data)
  }


  return (
    <div className='flex mx-0 box-border  max-h-screen overflow-y-scroll overflow-x-hidden'>
      <Drawer open={drawer} closable={false}>
        <div className='relative'>
           <h6 className='text-[14px] font-[700]'>User Asset Usage Info ({assetAssign?.pagination?.total})</h6>
           <AiOutlineClose onClick={()=>setdrawer(false)} className='absolute right-0 top-1 cursor-pointer' />
        </div>

        
        <div className='mt-4'>
          {assetAssign?.datas?.map((a)=>(
            <div key={a?._id} className='border border-slate-100 relative rounded p-2 mb-2'>
             {a?.asset_status?.name != "Assigned" && <h6 className='absolute right-2 p-[4px] h-[18px] text-[7px] font-[700] bg-black text-white'>{new Date(a?.retrieved_date).getDate() - new Date(a?.assigned_date).getDate()} Days Used</h6>}
              <div className='bg-sky-50 p-2'>
              <h6 className='capitalize text-[11px]'><span className='font-[800]'>{a?.type}</span>  - {a?.asset?.name}</h6>
              <h6 onClick={()=>navigate('/it/inventory/laptop/detail',{state:a?.asset})} className='capitalize text-[11px] underline text-blue-500 font-[800] cursor-pointer'>View Device</h6>
              <h6 className='text-[10px]'>Asset Id : {<span className='font-[800]'>{a?.asset?.asset_id}</span>}</h6>

              <h6 className={`text-[12px] flex items-center font-[800] ${a?.asset_status?.name == "Assigned" ? 'text-blue-400' : 'text-violet-500'}`}><p className={`min-w-[8px] max-h-[8px] max-w-[8px] rounded-full ${a?.asset_status?.name == "Assigned" ? 'bg-blue-400 text-blue-400' : 'bg-violet-500 text-violet-500'} overflow-hidden mr-2`}>1</p> {a?.asset_status?.name == "Assigned" ? "In use" : "Retrieved"}</h6>
              </div>

              <div className='flex mt-1 items-center'> 
                <h6 className='text-[10px] flex items-center text-slate-400 font-[600] mr-1'>Assign <FaRegCalendar className='mx-1' /> <span className='text-black'>{moment(a?.assigned_date)?.format('LL')}</span></h6> 
                {a?.asset_status?.name != "Assigned" && <h6 className='text-[10px] flex items-center text-slate-400 font-[600]'>/ Retrieve<FaRegCalendar className='mx-1' /> <span className='text-black'>{moment(a?.retrieved_date)?.format('LL')}</span></h6>}
                </div>
            </div>
          ))}
        </div>




      </Drawer>
        <div>
            <ItMenu />
        </div>
        <div className='px-2 w-[100%] py-5'>
          <GoBack />

          <div className='flex items-center justify-between'>
           <h6 className='text-[16px] font-[700]'>User Asset Assigned</h6>
           <div className='flex'>
            <div onClick={()=>setdrawer(!drawer)} className='flex mr-2 border rounded p-1 cursor-pointer items-center'>
            <CgPlayTrackNextR size={13} /> 
            <h6 className='mx-2 text-[12px]'>History</h6>
            </div> 
            <div onClick={()=>navigate('/it/assign_asset/edit',{state:state})} className='flex border rounded p-1 cursor-pointer items-center'>
            <AiOutlineEdit size={13} /> 
            <h6 className='mx-2 text-[12px]'>Edit</h6>
            </div> 
           </div>
          </div>
           <div>
            <div className='border-t border-slate-100 min-w-[100%] mt-2'>
                <h6 className='text-[13px] bg-slate-100 p-1 font-[800] mt-2'>User Info</h6>   
                
                <div className='flex border-b border-slate-100 pb-2 mt-2'>
                  
                  <div className='w-[100px] p-1'>
                   {![null,undefined,'null','undefined','']?.includes(data?.employee?.profile_photo) ?
                    <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${data?.employee?.profile_photo}`} className='bg-slate-100 w-[100%] h-[100%] ' /> :
                    <div className='bg-slate-100 w-[100%] h-[100%] '></div>}
                  </div>

                  <div className='mx-2 p-2 border-l border-r border-slate-200 '>
                    <h6 className='text-[12px] mb-[2px]'>Employee Name : <span className='font-[900]'>{data?.employee?.name}</span></h6>
                    <h6 className='text-[12px] mb-[2px]'>Employee Id : <span className='font-[900]'>{data?.employee?.employee_id}</span></h6>
                    <h6 className='text-[12px] mb-[2px]'>Official Phone : <span className='font-[900]'>{data?.employee?.official_phone}</span></h6>
                    <h6 className='text-[12px] mb-[2px]'>Official Email : <span className='font-[900]'>{data?.employee?.official_email}</span></h6>
                  </div>

                  <div className='p-2 '>
                    <h6 className='text-[12px] mb-[2px]'>Deparment : <span className='font-[900]'>{data?.employee?.department_id !== undefined && data?.employee?.department_id[0]?.department_name}</span></h6>
                    <h6 className='text-[12px] mb-[2px]'>Designation : <span className='font-[900]'>{data?.employee?.designation_id?.designation_name}</span></h6>
                    <h6 className='text-[12px] mb-[2px]'>DOJ : <span className='font-[900]'>{moment(data?.employee?.doj)?.format('LL')}</span></h6>
                  </div>
                </div>

                <h6 className='text-[13px] bg-slate-100 p-1 font-[800] mt-2'>Asset Info</h6>   
                 
                 <div className='grid grid-cols-5 mt-4'>

                    <div className='border-r px-2 border-slate-100'>

                    {data?.laptop?.length > 0 &&
                      <>
                      <h6 className='font-[900] text-[12px] my-2'>Laptop Assigned ({data?.laptop?.length})</h6>
                      {data?.laptop?.map((d)=>(
                        <div key={d?._id} className='border border-slate-100 mb-2 p-2'>
                          <div className='h-[100px] flex items-center justify-center bg-slate-100'>
                          {![null,undefined,'','null','undefined']?.includes(d?.image) && <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${d?.image}`} alt="No Img" className='h-[80px] bg-slate-100' />}
                          </div>
                          <h6 className='text-[13px] font-[500] '>{d.name}</h6>
                          <h6 className='text-[12px] font-[500] '>ASSET ID : <span className='font-[800]'>{d.asset_id}</span></h6>
                          <h6 className='text-[10px] font-[500] '>Type : <span className='font-[800]'>{d.type}</span></h6>
                          <h6 className='text-[10px] cursor-pointer underline font-[900] text-blue-500'>View Device Usage History</h6>
                       </div>   
                      ))}
                      </>}

                      {data?.desktop?.length > 0 &&
                      <>
                      <h6 className='font-[900] text-[12px] my-2'>Desktop Assigned ({data?.desktop?.length})</h6>
                      {data?.desktop?.map((d)=>(
                        <div key={d?._id} className='border border-slate-100 mb-2 p-2'>
                          <div className='h-[100px] flex items-center justify-center bg-slate-100'>
                          {![null,undefined,'','null','undefined']?.includes(d?.image) && <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${d?.image}`} alt="No Img" className='h-[80px] bg-slate-100' />}
                          </div>
                          <h6 className='text-[13px] font-[500] '>{d.name}</h6>
                          <h6 className='text-[12px] font-[500] '>ASSET ID : <span className='font-[800]'>{d.asset_id}</span></h6>
                          <h6 className='text-[10px] font-[500] '>Type : <span className='font-[800]'>{d.type}</span></h6>
                          <h6 className='text-[10px] cursor-pointer underline font-[900] text-blue-500'>View Device Usage History</h6>
                       </div>   
                      ))}
                      </>}

                      {data?.cpu?.length > 0 &&
                      <>
                      <h6 className='font-[900] text-[12px] my-2'>CPU Assigned ({data?.cpu?.length})</h6>
                      {data?.cpu?.map((d)=>(
                        <div key={d?._id} className='border border-slate-100 mb-2 p-2'>
                          <div className='h-[100px] flex items-center justify-center bg-slate-100'>
                          {![null,undefined,'','null','undefined']?.includes(d?.image) && <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${d?.image}`} alt="No Img" className='h-[80px] bg-slate-100' />}
                          </div>
                          <h6 className='text-[13px] font-[500] '>{d.name}</h6>
                          <h6 className='text-[12px] font-[500] '>ASSET ID : <span className='font-[800]'>{d.asset_id}</span></h6>
                          <h6 className='text-[10px] font-[500] '>Type : <span className='font-[800]'>{d.type}</span></h6>
                          <h6 className='text-[10px] cursor-pointer underline font-[900] text-blue-500'>View Device Usage History</h6>

                       </div>   
                      ))}
                      </>}

                      {data?.mouse?.length > 0 &&
                      <>
                      <h6 className='font-[900] text-[12px] my-2'>Mouse Assigned ({data?.mouse?.length})</h6>
                      {data?.mouse?.map((d)=>(
                        <div key={d?._id} className='border border-slate-100 mb-2 p-2'>
                          <div className='h-[100px] flex items-center justify-center bg-slate-100'>
                          {![null,undefined,'','null','undefined']?.includes(d?.image) && <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${d?.image}`} alt="No Img" className='h-[80px] bg-slate-100' />}
                          </div>
                          <h6 className='text-[13px] font-[500] '>{d.name}</h6>
                          <h6 className='text-[12px] font-[500] '>ASSET ID : <span className='font-[800]'>{d.asset_id}</span></h6>
                          <h6 className='text-[10px] font-[500] '>Type : <span className='font-[800]'>{d.type}</span></h6>
                          <h6 className='text-[10px] cursor-pointer underline font-[900] text-blue-500'>View Device Usage History</h6>

                       </div>   
                      ))}
                      </>}

                      {data?.keyboard?.length > 0 &&
                      <>
                      <h6 className='font-[900] text-[12px] my-2'>Keyboard Assigned ({data?.keyboard?.length})</h6>
                      {data?.keyboard?.map((d)=>(
                        <div key={d?._id} className='border border-slate-100 mb-2 p-2'>
                          <div className='h-[100px] flex items-center justify-center bg-slate-100'>
                          {![null,undefined,'','null','undefined']?.includes(d?.image) && <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${d?.image}`} alt="No Img" className='h-[80px] bg-slate-100' />}
                          </div>
                          <h6 className='text-[13px] font-[500] '>{d.name}</h6>
                          <h6 className='text-[12px] font-[500] '>ASSET ID : <span className='font-[800]'>{d.asset_id}</span></h6>
                          <h6 className='text-[10px] font-[500] '>Type : <span className='font-[800]'>{d.type}</span></h6>
                          <h6 className='text-[10px] cursor-pointer underline font-[900] text-blue-500'>View Device Usage History</h6>

                       </div>   
                      ))}
                      </>}

                      {data?.laptop_charger?.length > 0 &&
                      <>
                      <h6 className='font-[900] text-[12px] my-2'>Laptop Charger Assigned ({data?.laptop_charger?.length})</h6>
                      {data?.laptop_charger?.map((d)=>(
                        <div key={d?._id} className='border border-slate-100 mb-2 p-2'>
                          <div className='h-[100px] flex items-center justify-center bg-slate-100'>
                          {![null,undefined,'','null','undefined']?.includes(d?.image) && <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${d?.image}`} alt="No Img" className='h-[80px] bg-slate-100' />}
                          </div>
                          <h6 className='text-[13px] font-[500] '>{d.name}</h6>
                          <h6 className='text-[12px] font-[500] '>ASSET ID : <span className='font-[800]'>{d.asset_id}</span></h6>
                          <h6 className='text-[10px] font-[500] '>Type : <span className='font-[800]'>{d.type}</span></h6>
                          <h6 className='text-[10px] cursor-pointer underline font-[900] text-blue-500'>View Device Usage History</h6>

                       </div>   
                      ))}
                      </>}

                    </div>

                    <div className='border-r px-2 border-slate-100'>

                    {data?.mobile?.length > 0 &&
                      <>
                      <h6 className='font-[900] text-[12px] my-2'>Mobile Assigned ({data?.mobile?.length})</h6>
                      {data?.mobile?.map((d)=>(
                        <div key={d?._id} className='border border-slate-100 mb-2 p-2'>
                          <div className='h-[100px] flex items-center justify-center bg-slate-100'>
                          {![null,undefined,'','null','undefined']?.includes(d?.image) && <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${d?.image}`} alt="No Img" className='h-[80px] bg-slate-100' />}
                          </div>
                          <h6 className='text-[13px] font-[500] '>{d.name}</h6>
                          <h6 className='text-[12px] font-[500] '>ASSET ID : <span className='font-[800]'>{d.asset_id}</span></h6>
                          <h6 className='text-[10px] font-[500] '>Type : <span className='font-[800]'>{d.type}</span></h6>
                          <h6 className='text-[10px] cursor-pointer underline font-[900] text-blue-500'>View Device Usage History</h6>

                       </div>   
                      ))}
                      </>}

                      

                      {data?.mobile_charger?.length > 0 &&
                      <>
                      <h6 className='font-[900] text-[12px] my-2'>Mobile Charger Assigned ({data?.mobile_charger?.length})</h6>
                      {data?.mobile_charger?.map((d)=>(
                        <div key={d?._id} className='border border-slate-100 mb-2 p-2'>
                          <div className='h-[100px] flex items-center justify-center bg-slate-100'>
                          {![null,undefined,'','null','undefined']?.includes(d?.image) && <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${d?.image}`} alt="No Img" className='h-[80px] bg-slate-100' />}
                          </div>
                          <h6 className='text-[13px] font-[500] '>{d.name}</h6>
                          <h6 className='text-[12px] font-[500] '>ASSET ID : <span className='font-[800]'>{d.asset_id}</span></h6>
                          <h6 className='text-[10px] font-[500] '>Type : <span className='font-[800]'>{d.type}</span></h6>
                          <h6 className='text-[10px] cursor-pointer underline font-[900] text-blue-500'>View Device Usage History</h6>

                       </div>   
                      ))}
                      </>}

                    </div>

                    <div className='border-r px-2 border-slate-100'>

                       {data?.pendrive?.length > 0 &&
                        <>
                        <h6 className='font-[900] text-[12px] my-2'>Pendrive Assigned ({data?.pendrive?.length})</h6>
                        {data?.pendrive?.map((d)=>(
                          <div key={d?._id} className='border border-slate-100 mb-2 p-2'>
                            <div className='h-[100px] flex items-center justify-center bg-slate-100'>
                            {![null,undefined,'','null','undefined']?.includes(d?.image) && <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${d?.image}`} alt="No Img" className='h-[80px] bg-slate-100' />}
                            </div>
                            <h6 className='text-[13px] font-[500] '>{d.name}</h6>
                            <h6 className='text-[12px] font-[500] '>ASSET ID : <span className='font-[800]'>{d.asset_id}</span></h6>
                            <h6 className='text-[10px] font-[500] '>Type : <span className='font-[800]'>{d.type}</span></h6>
                          <h6 className='text-[10px] cursor-pointer underline font-[900] text-blue-500'>View Device Usage History</h6>

                        </div>   
                        ))}
                        </>}

                        

                        {data?.harddisk?.length > 0 &&
                        <>
                        <h6 className='font-[900] text-[12px] my-2'>HardDisk Assigned ({data?.harddisk?.length})</h6>
                        {data?.harddisk?.map((d)=>(
                          <div key={d?._id} className='border border-slate-100 mb-2 p-2'>
                            <div className='h-[100px] flex items-center justify-center bg-slate-100'>
                            {![null,undefined,'','null','undefined']?.includes(d?.image) && <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${d?.image}`} alt="No Img" className='h-[80px] bg-slate-100' />}
                            </div>
                            <h6 className='text-[13px] font-[500] '>{d.name}</h6>
                            <h6 className='text-[12px] font-[500] '>ASSET ID : <span className='font-[800]'>{d.asset_id}</span></h6>
                            <h6 className='text-[10px] font-[500] '>Type : <span className='font-[800]'>{d.type}</span></h6>
                          <h6 className='text-[10px] cursor-pointer underline font-[900] text-blue-500'>View Device Usage History</h6>

                        </div>   
                        ))}
                        </>}

                        {data?.cable?.length > 0 &&
                        <>
                        <h6 className='font-[900] text-[12px] my-2'>Cable Assigned ({data?.cable?.length})</h6>
                        {data?.cable?.map((d)=>(
                          <div key={d?._id} className='border border-slate-100 mb-2 p-2'>
                            <div className='h-[100px] flex items-center justify-center bg-slate-100'>
                            {![null,undefined,'','null','undefined']?.includes(d?.image) && <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${d?.image}`} alt="No Img" className='h-[80px] bg-slate-100' />}
                            </div>
                            <h6 className='text-[13px] font-[500] '>{d.name}</h6>
                            <h6 className='text-[12px] font-[500] '>ASSET ID : <span className='font-[800]'>{d.asset_id}</span></h6>
                            <h6 className='text-[10px] font-[500] '>Type : <span className='font-[800]'>{d.type}</span></h6>
                          <h6 className='text-[10px] cursor-pointer underline font-[900] text-blue-500'>View Device Usage History</h6>

                        </div>   
                        ))}
                        </>}

                      </div>
                 </div>
            </div>
            <div>
              
            </div>
           </div>

        </div>
    </div>
  )
}

export default AssignAssetOptionDetail