import React,{useEffect} from 'react'
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { TbLayoutDashboard } from "react-icons/tb";
import { HiOutlineTicket } from "react-icons/hi2";
import { LuLaptop2 } from "react-icons/lu";
import { HiOutlineDevicePhoneMobile } from "react-icons/hi2";
import { CgMouse } from "react-icons/cg";
import { SlScreenDesktop } from "react-icons/sl";
import { MdOutlineCable } from "react-icons/md";
import { LuHardDrive } from "react-icons/lu";
import { BsKeyboard } from "react-icons/bs";
import { PiPlugCharging } from "react-icons/pi";
import { RiChargingPile2Line } from "react-icons/ri";
import { AiOutlineUsb } from "react-icons/ai";

import { CiMicrochip } from "react-icons/ci";
import { TbBrandHeadlessui } from "react-icons/tb";
import { TbLayoutList } from "react-icons/tb";
import { CgListTree } from "react-icons/cg";
import { BsViewList } from "react-icons/bs";
import { GrStatusInfo } from "react-icons/gr";

import { MdOutlineInventory2 } from "react-icons/md";
import { MdOutlineUpcoming } from "react-icons/md";
import { PiDesktopTowerBold } from "react-icons/pi";
import { MdOutlineAssignment } from "react-icons/md";

function ItMenu() {

    const {pathname} = useLocation();
    const navigate = useNavigate();
    const auth = useSelector(state=>state.Auth)


    useEffect(()=>{
        if(pathname === '/it'){
            navigate('dashboard')
        }
    },[])

    const menu = [
        {name:'IT Dashboard',icon:TbLayoutDashboard,path:'/it/dashboard',id:1,},
        {name:'User Asset',icon:MdOutlineAssignment,path:'/it/assign_asset',id:1,},
    ] 
    
    const menu1 = [
        {name:'Total Laptop',icon:LuLaptop2,path:'/it/inventory/laptop',id:1,},
        {name:'Total Desktop',icon:SlScreenDesktop,path:'/it/inventory/desktop',id:2,},
        {name:'Total CPU',icon:PiDesktopTowerBold,path:'/it/inventory/cpu',id:2,},
        {name:'Total Mobile',icon:HiOutlineDevicePhoneMobile,path:'/it/inventory/mobile',id:3,},
        {name:'Total Mouse',icon:CgMouse,path:'/it/inventory/mouse',id:4,},
        {name:'Total Keyboard',icon:BsKeyboard,path:'/it/inventory/keyboard',id:5,},
        {name:'Total Laptop Charger',icon:PiPlugCharging,path:'/it/inventory/laptop_charger',id:5,},
        {name:'Total Mobile Charger',icon:RiChargingPile2Line,path:'/it/inventory/mobile_charger',id:5,},
        {name:'Total Pendrive',icon:AiOutlineUsb,path:'/it/inventory/pendrive',id:5,},
        {name:'Total HardDisk',icon:LuHardDrive,path:'/it/inventory/hard_disk',id:6,},
        {name:'Total Connectors',icon:MdOutlineCable,path:'/it/inventory/cable',id:7,},
    ] 

    const menu2 = [
        {name:'Support Request',icon:HiOutlineTicket,path:'/it/ticket_raised',id:1,},
    ] 

    const menu3 = [
        {name:'Processor',icon:CiMicrochip,path:'/it/feature/processor',id:1,},
        {name:'Brand',icon:TbBrandHeadlessui,path:'/it/feature/brand',id:2,},
        {name:'Purchase Type',icon:TbLayoutList,path:'/it/feature/purchase_type',id:3,},
        {name:'Device Type',icon:CgListTree,path:'/it/feature/device_type',id:4,},
        {name:'Device Stage',icon:BsViewList,path:'/it/feature/device_stage',id:5,},
        {name:'Device Status',icon:GrStatusInfo,path:'/it/feature/device_status',id:5,},
    ] 

    const menu4 = [
        {name:'Inventory Puchase',icon:MdOutlineInventory2,path:'/it/inventory_puchase/list',id:1,},
        {name:'Upcoming Puchase',icon:MdOutlineUpcoming,path:'/it/upcoming_puchase/list',id:2,},
    ] 
        
  return (
    <div className='mr-0 min-h-screen max-h-sceen border-r overflow-hidden w-[180px] ml-2 px-2 pt-5'>
         <div className=' mb-4'>
            <h6 className='mb-2 font-[700] text-[12px]'>Basic Option</h6>
                {menu.map((m)=>(
                <div key={m?.path} className={`flex items-center  my-1 hover:bg-[#f4f5f7] px-1.5 py-1 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigate(m?.path)}>
                {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
                <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16}  />
                <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
                </div>
                ))}
         </div>

         <div className='border-t pt-4'>
            <h6 className='mb-2 font-[700] text-[12px]'>Inventory Items</h6>
                {menu1.map((m)=>(
                <div key={m?.path} className={`flex items-center  my-1 hover:bg-[#f4f5f7] px-1.5 py-1 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigate(m?.path)}>
                {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
                <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16}  />
                <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
                </div>
                ))}
         </div>

         <div className='border-t pt-4'>
            <h6 className='mb-2 font-[700] text-[12px]'>Feature Option</h6>
                {menu3.map((m)=>(
                <div key={m?.path} className={`flex items-center  my-1 hover:bg-[#f4f5f7] px-1.5 py-1 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigate(m?.path)}>
                {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
                <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16}  />
                <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
                </div>
                ))}
         </div>

         <div className='border-t pt-4'>
         <h6 className='mb-2 font-[700] text-[12px]'>Purchase Option</h6>
         {menu4.map((m)=>(
                <div key={m?.path} className={`flex items-center  my-1 hover:bg-[#f4f5f7] px-1.5 py-1 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigate(m?.path)}>
                {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
                <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16}  />
                <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
                </div>
                ))}

         </div> 

         <div className='border-t pt-4'>
            <h6 className='mb-2 font-[700] text-[12px]'>Support Option</h6>
                {menu2.map((m)=>(
                <div key={m?.path} className={`flex items-center  my-1 hover:bg-[#f4f5f7] px-1.5 py-1 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigate(m?.path)}>
                {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
                <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16}  />
                <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
                </div>
                ))}
         </div>


    </div>
  )
}

export default ItMenu