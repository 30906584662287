import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import IlsMenu from './IlsMenu'
import { TextAreaInput1, TextInput } from '../../components/input'
import { Select } from 'antd'
import { ButtonFilled, ButtonOutlined } from '../../components/button'
import { CreateILSLandDataService, UpdateILSLandDataService, UploadFileILSLandDataService } from '../../services/IlsLandDataServices'
import toast from 'react-hot-toast'
import Uploader1 from '../../components/Uploader1'
import GoBack from '../../components/back/GoBack'
import DashboardMenu from '../dashboard/DashboardMenu'


function IlsLandDataCE() {


  const {state,pathname} = useLocation() 

  const path = pathname.split('/')[2]
//   console.log("path",path)

//   console.log("state",state)
  const location = useLocation()  
  const navigate = useNavigate()

  const zones = [{label:'North',value:"North"},{label:'South',value:"South"},{label:'East',value:"East"},{label:'West',value:"West"}]
  const type_of_transactions = [{label:'Built-To-Suit',value:"Built-To-Suit"},{label:'Sale',value:"Sale"},{label:'Rent',value:"Rent"},{label:'JV/JD',value:"JV/JD"}]
  
  const [loader,setloader] = useState(false)
  const [data,setdata] = useState({zone:'',location:'',land_area:'',type_of_transaction:'',point_of_contact_name:'',point_of_contact_mobile:'',point_of_contact_email:'',location:'',coordinate:'',attachment:''})
  const [error,seterror] = useState({zone:'',location:'',land_area:'',type_of_transaction:'',point_of_contact_name:'',point_of_contact_mobile:'',point_of_contact_email:'',location:'',coordinate:'',attachment:''})
  

  useEffect(()=>{
      if(state?._id !== undefined){
        setdata({...state})
       
      }
  },[])

 

  async function handlechange(e){
    setdata({...data,[e.target.name]:e.target.value})
    seterror({...error,[e.target.name]:''})
  }

  async function handleSelect(v,name){
    setdata({...data,[name]:v})
    seterror({...error,[name]:''})
  }

  async function submitform(){
    if(!data?.land_area){
        seterror({...error,land_area:'The Land Area Field is required*'})
    }else if(!data?.location){
        seterror({...error,location:'The Location Field is required*'})
    }else if(!data?.zone){
        seterror({...error,zone:'The Zone Field is required*'})
    }else if(!data?.type_of_transaction){
        seterror({...error,type_of_transaction:'The Type of Transaction Field is required*'})
    }else if(!data?.point_of_contact_name){
        seterror({...error,point_of_contact_name:'The Point Of Contact Name Field is required*'})
    }else if(!data?.point_of_contact_mobile){
        seterror({...error,point_of_contact_mobile:'The Point Of Contact Mobile Field is required*'})
    }else{
        
        let sendData = {...data}
        
        if(state?._id === undefined){
            const response = await CreateILSLandDataService(sendData)
            if(response?.status == 201){
                toast.success("ILS Land Data Created Successfully!")
                resetform()
            }
        }else{
            const response = await UpdateILSLandDataService(state?._id,sendData)
            if(response?.status == 200){
                toast.success("ILS Land Data Updated Successfully!")
                resetform()
                navigate(-1)
            }
        }
    }

  }



  async function resetform(){
    setdata({zone:'',location:'',land_area:'',type_of_transaction:'',point_of_contact_name:'',point_of_contact_mobile:'',point_of_contact_email:'',location:'',coordinate:'',attachment:''})
    seterror({zone:'',location:'',land_area:'',type_of_transaction:'',point_of_contact_name:'',point_of_contact_mobile:'',point_of_contact_email:'',location:'',coordinate:'',attachment:''})
  }


  async function handleUpload(v){
    const response = await UploadFileILSLandDataService({file:v})
    setdata({...data,attachment:response?.data?.data?.replace('public','')})
    seterror({...error,attachment:''})
  }

  return (
    <div className='h-screen max-h-screen overflow-hidden'>
    <div className='flex'>
        {path === 'land_data_admin' ? <DashboardMenu />  : <IlsMenu /> }
        <div className='w-[88%] px-4 mt-5'>
            <GoBack />
            <div className='w-[50%]'>

            <h6 className='font-[800] mb-1'>{location?.pathname?.split('/')[location?.pathname?.split('/').length -1] === 'edit' ? 'Edit' : 'Create'}  Land Data</h6> 
            <h6 className='text-[11px] leading-tight font-[500] p-2 bg-slate-100 '>Use the below form to create or edit the Land Data for your reference to get the continous task progress made by you all the time.</h6> 
            
            <div className='flex justify-between'> 
             <div className='w-[46%] relative'>
                <TextInput 
                 mandatory={true}
                 label={'Land Area'}  
                 variant="standard"
                 name="land_area"
                 type="text"
                 value={data.land_area}
                 error={error.land_area}
                 handlechange={handlechange}
                 placeholder="Enter contact name"
                 
                />


                <TextInput 
                 mandatory={true}
                 label={'Location'}  
                 variant="standard"
                 name="location"
                 type="text"
                 value={data.location}
                 error={error.location}
                 handlechange={handlechange}
                 placeholder="Enter contact name"
                 InputLabelProps={{
                     style: { color: '#fff', }, 
                 }}
                />

                <TextInput 
                 label={'Co-ordinates'}  
                 variant="standard"
                 name="coordinate"
                 type="text"
                 value={data.coordinate}
                 error={error.coordinate}
                 handlechange={handlechange}
                 placeholder="Enter contact name"
                 InputLabelProps={{
                     style: { color: '#fff', }, 
                 }}
                />

                <h6 className='text-[11px] font-semibold mb-1 mt-1'>Zone</h6>

                <Select
                    value={data?.zone} 
                    error={error?.zone}
                    bordered={false}
                    placeholder="" 
                    onChange={(e)=>handleSelect(e,'zone')} 
                    options={zones}
                    style={{borderRadius:'0px'}} 
                    className='border-l-4 border-l-slate-600 w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'
                />

                <h6 className='text-[11px] font-semibold mb-1 mt-1'>Type Of Transaction</h6>

                <Select
                    value={data?.type_of_transaction} 
                    error={error?.type_of_transaction}
                    bordered={false}
                    placeholder="" 
                    onChange={(e)=>handleSelect(e,'type_of_transaction')} 
                    options={type_of_transactions}
                    style={{borderRadius:'0px'}} 
                    className='border-l-4 border-l-slate-600 w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'
                />

            <h6 className='text-[11px] font-[600] mt-1 mb-1' >Attachment</h6>  
           {data?.attachment !== '' && <a target="_blank" href={`${process.env.REACT_APP_BACKEND_IMAGE_URL}${data?.attachment}`} ><h6 className='text-[8px] font-[700] cursor-pointer absolute right-0 bottom-9 underline'>View File</h6></a>}
            <Uploader1 image={data?.attachment}  setimagefunc={(e)=>{handleUpload(e)}}  removeimageuploadfunc = {()=>{setdata({...data,attachment:''});seterror({...error,attachment:''})}}/>    

             </div>

             <div className='w-[46%]'>
                <TextInput 
                 mandatory={true}
                 label={'Contact Name'}  
                 variant="standard"
                 name="point_of_contact_name"
                 type="text"
                 value={data.point_of_contact_name}
                 error={error.point_of_contact_name}
                 handlechange={handlechange}
                 placeholder="Enter contact name"
                 
                />

            <TextInput 
                 mandatory={true}
                 label={'Contact Mobile'}  
                 variant="standard"
                 name="point_of_contact_mobile"
                 type="text"
                 value={data.point_of_contact_mobile}
                 error={error.point_of_contact_mobile}
                 handlechange={handlechange}
                 placeholder="Enter contact name"
                 
                />

            <TextInput 
                 label={'Contact Email'}  
                 variant="standard"
                 name="point_of_contact_email"
                 type="text"
                 value={data.point_of_contact_email}
                 error={error.point_of_contact_email}
                 handlechange={handlechange}
                 placeholder="Enter contact name"
                 
                />

            <TextAreaInput1 
                label={'Summary'}  
                variant="standard"
                name="summary"
                type="text"
                value={data.summary}
                error={error.summary}
                handlechange={handlechange}
                placeholder=""
                />
             </div>   
            </div>   

             <div className='flex items-center mt-5 mb-10  border-t pt-5'>
                <div className='mr-2'>
                <ButtonOutlined btnName={'Back'} onClick={()=>navigate(-1)} />
                </div>
                <div>
                {loader ?
                <ButtonFilled btnName={loader ? 'Uploading' : 'Save'}  />
                :
                <ButtonFilled btnName={loader ? 'Uploading' : 'Save'} onClick={()=>submitform()} />}
                </div>
            </div>

            </div>
        </div>
    </div>          
    </div>
  )
}

export default IlsLandDataCE