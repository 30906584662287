import React,{useEffect, useState} from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import GoBack from '../../components/back/GoBack';
import { TextAreaInput1, TextInput } from '../../components/input';
import Uploader from '../../components/Uploader';
import { ButtonFilledAutoWidth } from '../../components/button';
import { toast } from 'react-hot-toast'
import { CreateSupportTicketService, UpdateSupportTicketService, UploadSupportTicketService } from '../../services/supportticketservices/SupportTicketServices';
import { GetDepartmentService } from '../../services/DepartmentServices';
import SupportTicketMenu from './SupportTicketMenu';
import DashboardMenu from '../dashboard/DashboardMenu';
import { Select } from 'antd';
import { useSelector } from 'react-redux';
import ErrorComponent from '../../components/errorDesign/ErrorComponent';

function SupportTicketCE() {

  const user = useSelector(state=>state.Auth)

  const roles = user.roles
  const user_department = user.department_id[0].id
  const {state,pathname} = useLocation();
  const navigate = useNavigate();

  
  const path = pathname.split('/')[1]
  const path_type = pathname.split('/')[pathname.split('/').length - 1]

  const validate_edit_option = (path_type !== 'create')



  const [data,setdata] = useState({ticket_raised_by:'',requested_department:'',assigned_department:'',ticket_resolved_by:'',issue:'',image:'',image1:'',status:'Pending',remarks:'',priority:''});  
  const [error,seterror] = useState({ticket_raised_by:'',requested_department:'',assigned_department:'',ticket_resolved_by:'',issue:'',image:'',image1:'',status:'',remarks:'',priority:''}); 
  
  const statusOption = [
      {label:'Pending',value:'Pending'},
      {label:'Progress',value:'Progress'},
      {label:'Resolved',value:'Resolved'},
  ]

  const [departmentArr,setdepartmentArr] = useState([])

  function handlechange(e){
    setdata({...data,[e.target.name] : e.target.value})
    seterror({...error,[e.target.name]:''})
  }

  useEffect(()=>{
    if(state?._id !== undefined){
        let d = state 
        setdata({
            ...data,
            ...state,
            assigned_department:{label:state?.assigned_department?.department_name,value:state?.assigned_department?._id}
        })
    }
    getdepartmentlist()
  },[state])

  async function getdepartmentlist(){
    const response = await GetDepartmentService()
    let arr = []
    let d = response?.data?.datas
    d?.forEach((d1)=>{
      if(['Software Team','Backend Team','Finance Team','Digital Media']?.includes(d1?.department_name)){
         arr?.push({label:d1?.department_name,value:d1?.id})
      }
    })
    setdepartmentArr(arr)
  }


  async function uploadfile(v,name){
    const fd = new FormData()
    fd.append('file',v); 
    const response = await UploadSupportTicketService(fd)
    if(response?.status === 200){
      setdata({...data,[name]:response?.data?.data})
      seterror({...error,[name]:''})
    }
  }

  async function submitform(){
    if(!data.assigned_department){
        seterror({...error,assigned_department:'This field is required *'})
    }else if(!data.issue){
      seterror({...error,issue:'This field is required *'})
    }else if(!data.priority){
      seterror({...error,priority:'This field is required *'})
    }else{
        let sendData = {...data}

        if(state?._id === undefined || state?._id === null){
            sendData['assigned_department'] = data?.assigned_department.value
            sendData['priority'] = data?.priority?.value
            sendData['requested_department'] = user?.department_id[0]?.id
            sendData['ticket_raised_by'] = user?.id
            delete sendData.ticket_resolved_by

            const response = await CreateSupportTicketService(sendData)
            if (response.status === 201) {
            resetform()
            toast.success('Support Ticket Created Successfully')
            }   
            if(response.status === 422){
            if(response?.data?.errors?.title){
                seterror({...error,title:response?.data?.errors?.title})
            }
            } 
      }else{
        delete sendData.assigned_department
        delete sendData.requested_department
        delete sendData.ticket_raised_by
        sendData["id"] = state?._id
        sendData["priority"] = data?.priority?.value
        sendData['ticket_resolved_by'] = user?.id
        const response = await UpdateSupportTicketService(sendData,state?._id)
        if (response.status === 200) {
          resetform()
          navigate(-1)
          toast.success('Support Ticket Updated Successfully')
        }   
        if(response.status === 422){
          if(response?.data?.errors?.title){
            seterror({...error,title:response?.data?.errors?.title})
          }
        } 
      }
    }
  }


  function resetform(){
    setdata({ticket_raised_by:'',requested_department:'',assigned_department:'',ticket_resolved_by:'',issue:'',image:'',status:'Pending',remarks:'',priority:''})
    seterror({ticket_raised_by:'',requested_department:'',assigned_department:'',ticket_resolved_by:'',issue:'',image:'',status:'',remarks:'',priority:''})
  }


  function openfile(v){
    window.open(`${process.env.REACT_APP_AWS_IMAGE_URL}${v?.replace('public/','')}`,'_blank')
  }

  const options = [{label:'Low',value:'Low'},{label:'Moderate',value:'Moderate'},{label:'High',value:'High'}] 

  return (
    <div className='flex '>
        {/* {pathname !== '/conveyance_vochuer_create' &&
        <FinanceMenu />} */}
         <div className={`min-w-44 ${path == 'profile' && 'min-w-[180px] pl-2'}`}>
              {path == 'support_ticket' && <SupportTicketMenu />}
              {path == 'dashboard' && <DashboardMenu />}
            </div>

        <div className={`w-full md:w-72 lg:w-72 px-4 pt-5`} >

            <GoBack />
            
            

            <h6 className='font-bold'>{(state?._id !== null && state?._id !== undefined) ? 'Edit' : 'Add'} Support Ticket</h6>
            <h6 className='text-[10px] bg-slate-100 p-2 font-[500] leading-snug' >Use the below form to create or edit the <b> Support Ticket</b> for your issues.</h6>
        
            <h6 className='text-[11px] font-[600] mb-1 mt-2' >Select Department</h6>
                <div className='h-[35px] border'>
                  <Select
                      placeholder=''
                      value={data?.assigned_department}
                      onChange={(v)=>setdata({...data,assigned_department:departmentArr?.find((f)=>f?.value === v)})}
                      options={departmentArr}
                      bordered={false}
                      className='w-[100%] border-l-4 border-slate-700'
                  />
                </div>

                <ErrorComponent error={error?.assigned_department} />



            <TextAreaInput1 
                label={'Issue'}  
                variant="standard"
                name="issue"
                type="date"
                mandatory={true}
                error={error.issue}
                value={data.issue}
                handlechange={handlechange}
                placeholder=""
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>

            <div className='relative'>
              {data?.image !== '' && <h6 onClick={()=>openfile(data?.image)} className='absolute right-0 top-0 cursor-pointer font-[800] underline text-[11px]'>View File</h6>}
              <h6 className='text-[11px] mt-2 font-[600] mb-1' >Image</h6>
              <Uploader image={data?.image}  setimagefunc={(e)=>{uploadfile(e,'image')}}  removeimageuploadfunc = {()=>setdata({...data,image:''})}/>
            </div>  

            <div>
               <h6 className='text-[11px] font-[600] mb-1 mt-2' >Ticket Priority</h6>
               
               <div className='border border-slate-200'>
               <Select
                      placeholder=''
                      value={data?.priority}
                      onChange={(v)=>setdata({...data,priority:options?.find((f)=>f?.value === v)})}
                      options={options}
                      bordered={false}
                      className='w-[100%]  border-l-4 border-slate-700'
                  />
                </div>

                <ErrorComponent error={error?.priority} />

            </div>
           

         
            
  
            {state?._id !== undefined && roles?.includes('ticket_resolver') && path_type === 'edit' &&
             <>
              <TextAreaInput1 
                label={'Remarks'}  
                variant="standard"
                name="remarks"
                mandatory={true}
                type="text"
                error={error.remarks}
                value={data.remarks}
                handlechange={handlechange}
                placeholder=""
                InputLabelProps={{
                    style: { color: '#fff', }, 
              }}/>
              
              <div className='relative'>
                <h6 className='text-[11px] mt-2 font-[600] mb-1' >Resolve Image</h6>
                {data?.image1 !== '' && <h6 onClick={()=>openfile(data?.image1)} className='absolute right-0 top-0 cursor-pointer font-[800] underline text-[11px]'>View File</h6>}
                <Uploader image={data?.image1}  setimagefunc={(e)=>{uploadfile(e,'image1')}}  removeimageuploadfunc = {()=>setdata({...data,image1:''})}/>
              </div> 
           


            <div className='mt-1'>
                
                <h6 className='text-[11px] font-[600] mb-1 mt-2' >Issue Status</h6>
                <div className='h-[35px] border border-slate-100'>
                  <Select
                      placeholder=''
                      value={data?.status}
                      onChange={(v)=>setdata({...data,status:v})}
                      options={statusOption}
                      bordered={false}
                      className='w-[100%]'
                  />
                </div>
            </div>
            </>}
            
            {(path_type === 'create' || (roles?.includes('ticket_resolver') && user_department === data?.assigned_department?.value) || roles?.includes('admin')) &&
            <div className='mt-5'>
            <ButtonFilledAutoWidth btnName={(state?._id !== null && state?._id !== undefined) ? "UPDATE SUPPORT TICKET" : "ADD SUPPORT TICKET"}  onClick={submitform} />  
            </div>}
            
        </div>
   </div> 
  )
}

export default SupportTicketCE