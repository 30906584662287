import React,{useEffect, useState} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { IoStopwatchOutline } from "react-icons/io5";
import { Modal, Select, Tag } from 'antd';
import { TextAreaInput1, TextInput } from '../../components/input';
import {BiErrorCircle,BiCheckbox,BiCheckboxSquare} from 'react-icons/bi'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button';
import { GetExitUserFormDetails, GetOnboardDetailService, UpdateExitUserFormDetails, UpdateUserApprovalByAdmin } from '../../services/OnboardServices';
import toast from 'react-hot-toast';
import GovtMenu from './GovtMenu';
import GoBack from '../../components/back/GoBack';
import { GetDepartmentService } from '../../services/DepartmentServices';
import { CreateCasesService, UpdateCasesService, UploadCasesFileService } from '../../services/govtservices/CaseServices';
import Uploader from '../../components/Uploader';

function CasesListCE() {

  const user = useSelector(state=>state.Auth) 

  const {pathname} = useLocation()

  const path = pathname.split('/')[pathname.split('/')?.length - 1]

  const [loader,setloader] = useState(false)
  const [loader1,setloader1] = useState(false)
  const [departmentArr,setdepartmentArr] = useState([])

  
  const navigate = useNavigate()
  const {state} = useLocation()

  const [data,setdata] = useState({
    caseType:'',
    caseBoughtBy:'',
    caseDescription:'',
    caseRecievedDate:'',
    attachment:'',
    department:'',
    caseRehearingDate:'',
    remarks:'',
    stage:{label:'Pending',value:'Pending'}
  })

  const [error,seterror] = useState({
    caseType:'',
    caseBoughtBy:'',
    caseDescription:'',
    caseRecievedDate:'',
    attachment:'',
    department:'',
    caseRehearingDate:'',
    remarks:'',
    stage:''
  })


  useEffect(()=>{
    if(path === 'edit'){
      setdata({
        caseType:{label:state?.caseType,value:state?.caseType},
        caseBoughtBy:state?.caseBoughtBy,
        caseDescription:state?.caseDescription,
        caseRecievedDate:state?.caseRecievedDate?.slice(0,10),
        attachment:state?.attachment,
        department:(state?.department !== null && state?.department !== undefined && state?.department !== '') ? {label:state?.department?.department_name,value:state?.department?._id} : '',
        caseRehearingDate:state?.caseRehearingDate !== '' ? state?.caseRecievedDate?.slice(0,10) : '' ,
        remarks:state?.remarks,
        stage:{label:state?.stage,value:state?.stage}
      })
    }
    getdepartment()
  },[])



 

 async function getdepartment(){
  const response = await GetDepartmentService()
  let arr = []

  response?.data?.datas?.forEach((d)=>{
      arr.push({value:d?.id,label:d?.department_name})
  })

  setdepartmentArr(arr)
} 




    const caseType = [
      {label:'Company Case',value:'Company Case'},
      {label:'Litigation Case',value:'Litigation Case'},
    ]

    const stage = [
      {label:'Pending',value:'Pending'},
      {label:'Process',value:'Process'},
      {label:'Completed',value:'Completed'},
    ]

   
    function handlechange(e){
      setdata({...data,[e.target.name] : e.target.value})
      seterror({...error,[e.target.name] : ''})
    }
      
       



     async function submitForm(){
          
      if(!data?.caseBoughtBy){
        seterror({...error,caseBoughtBy:'This Field is required!'})
      }else if(!data?.caseDescription){
        seterror({...error,caseDescription:'This Field is required!'})
      }else if(!data?.caseRecievedDate){
        seterror({...error,caseRecievedDate:'This Field is required!'})
      }else if(!data?.caseType){
        seterror({...error,caseType:'This Field is required!'})
      }else{

        let sendData = {
          caseType:data?.caseType,
          caseBoughtBy:data?.caseBoughtBy,
          caseDescription:data?.caseDescription,
          caseRecievedDate:data?.caseRecievedDate,
          attachment:data?.attachment,
          caseRehearingDate:data?.caseRehearingDate,
          remarks:data?.remarks,
        }

        if(data?.department?.value !== undefined){
          sendData['department'] = data?.department?.value
        }
        if(data?.caseType?.value !== undefined){
          sendData['caseType'] = data?.caseType?.value
        }

        if(data?.stage?.value !== undefined){
          sendData['stage'] = data?.stage?.value
        }
      
        if(path === 'create'){
          const response = await CreateCasesService(sendData)
          if(response?.status === 201){
            resetform()
            toast.success("Case Created Successfully1")
          }
        }
        if(path === 'edit'){
          const response = await UpdateCasesService(state?._id,sendData)
          if(response?.status === 200){
            resetform()
            toast.success("Case Updated Successfully1")
            navigate(-1)
          }
        }  

        // else {
        //   toast.success("Case Updated Successfully1")

        // }

      }
        

     }


     function resetform(){
        setdata({
          caseType:'',
          caseBoughtBy:'',
          caseDescription:'',
          caseRecievedDate:'',
          attachment:'',
          department:'',
          caseRehearingDate:'',
          remarks:'',
          stage:{label:'Pending',value:'Pending'}
        })
        seterror({
          caseType:'',
          caseBoughtBy:'',
          caseDescription:'',
          caseRecievedDate:'',
          attachment:'',
          department:'',
          caseRehearingDate:'',
          remarks:'',
          stage:''
        })
     }

     async function uploadfile(v,name){
      const fd = new FormData()
      fd.append('file',v); 
      const response = await UploadCasesFileService(fd)
      if(response?.status === 200){
        setdata({...data,[name]:response?.data?.data})
        seterror({...error,[name]:''})
      }
    }

  


  return (
    <div  className="w-[100%] h-screen overflow-hidden">
      
    
        <div className='flex'>
          <div className='w-[44] max-h-screen overflow-hidden'>
            <GovtMenu />   
          </div>  
          <div className='w-80 min-h-screen max-h-screen  overflow-y-scroll p-4 px-6'>
            
          <GoBack />

          <div className='border-b pb-2'>
                <h6 className='text-[13px] mb-1 font-[800]'>Create/Edit the Cases Handled By You</h6>
                <h6 className='text-[12px] bg-slate-200 p-2'>Use the Below form to add or edit the daily cases  handeled by you! </h6>      
          </div>

            <TextInput 
              label={'Name'}
              name={'caseBoughtBy'}
              mandatory={true}
              value={data?.caseBoughtBy}
              error={error.caseBoughtBy}
              handlechange={handlechange}
            />

            <TextAreaInput1 
              label={'Case Description'}
              name={'caseDescription'}
              mandatory={true}
              value={data?.caseDescription}
              error={error.caseDescription}
              handlechange={handlechange}
            />


            <TextInput 
              label={'Case Receive Date'}
              name={'caseRecievedDate'}
              mandatory={true}
              type="date"
              value={data?.caseRecievedDate}
              error={error.caseRecievedDate}
              handlechange={handlechange}
            />

            

          <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>Case Type</h6>
          <Select 
            value={data?.caseType}
            bordered={false}
            onChange={(v)=>{setdata({...data,caseType:{label:v,value:v}});seterror({...error,caseType:''})}}
            className='w-full border-l-4 border-l-slate-600  border h-[32px] border-slate-300'
            options={caseType} />


          {(data?.caseType?.label === 'Company Case' || data?.caseType?.label === 'Government Case') &&
          <>
          <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>Department</h6>

          <Select 
          value={data?.department}
          bordered={false}
          onChange={(v)=>{setdata({...data,department:departmentArr?.find((f)=>f?.value === v)});seterror({...error,department:''})}}
          className='w-full border h-[32px] border-slate-300'
          options={departmentArr} />
          </>}

         {/* {path !== 'create' && */}
           <>
          <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>Stage</h6>
          <Select 
          value={data?.stage}
          bordered={false}
          onChange={(v)=>{setdata({...data,stage:{label:v,value:v}});seterror({...error,stage:''})}}
          className='w-full border h-[32px] border-slate-300'
          options={stage} />
          </>
          {/* } */}


          <div className='relative mb-1 mt-2'>
          <h6 className='text-[11px] font-[600] mb-1 mt-2' >Attachment</h6>    
          {(data.attachment !== '' && data.attachment !== null) && <h6 onClick={()=>window.open(`${process.env.REACT_APP_AWS_IMAGE_URL}${data.attachment}`, "_blank")} className='text-[10px] hover:underline cursor-pointer font-[600] absolute top-0 right-0' >View File</h6>} 
          <Uploader image={data?.attachment}  setimagefunc={(e)=>{uploadfile(e,'attachment');seterror({...error,attachment:''})}}  removeimageuploadfunc = {()=>{setdata({...data,attachment:''});seterror({...error,attachment:''})}}/>    
          </div>
         


        <TextInput 
              label={'Next Hearing Date'}
              name={'caseRehearingDate'}
              type="date"
              value={data?.caseRehearingDate}
              error={error.caseRehearingDate}
              handlechange={handlechange}
            />


          <TextAreaInput1 
              label={'Remarks'}
              name={'remarks'}
              type="text"
              value={data?.remarks}
              error={error.remarks}
              handlechange={handlechange}
            />


      

      <div className='flex justify-end border-t pt-5 my-5'>
        <ButtonOutlinedAutoWidth btnName="Cancel" onClick={()=>navigate(-1)} />
          <h6 className='w-2'></h6>
        {loader ? 
        <ButtonFilledAutoWidth btnName="Loading"  />
          :
        <ButtonFilledAutoWidth btnName="Save" onClick={submitForm} />}
        </div>
   
          </div>


   

  
          </div>
        </div>
  )
}

export default CasesListCE