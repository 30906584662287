import React, { useEffect, useState } from 'react'
import { DeleteSupportTicketService, DownloadSupportTicketService, GetSupportTicketBasedDepartmentService } from '../../../services/supportticketservices/SupportTicketServices'
import { DatePicker, Modal } from 'antd';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../../components/button'
import ItMenu from '../ItMenu'
import toast from 'react-hot-toast'
import { IconButton } from '@material-ui/core';
import axios from 'axios';
import fileDownload from "js-file-download";
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AiOutlineEdit,AiOutlineDelete,AiOutlineFileExcel } from 'react-icons/ai';

function SupportOptionList() {

    const navigator = useNavigate()
    const roles = useSelector(state=>state.Auth.roles)

    
    const [modal,setmodal] = useState(false)
    const [data,setdata] = useState({})
    const [page,setpage] = useState(1)
    const [step,setstep] = useState(1)
    const [selecteddata,setselecteddata] = useState({})


   const [search,setsearch] = useState({text:'',from_date:'',to_date:'',from_date1:'',to_date1:'',activate:false})

   useEffect(()=>{
     getdata()
   },[page,step])

    async function getdata(){ 
        const response =  await GetSupportTicketBasedDepartmentService(page,search?.text,search?.from_date1,search?.to_date1,step)
        setdata(response?.data)
    }

    async function applyFilter(){
        setsearch({...search,activate:true})
        setpage(1)
        const response =  await GetSupportTicketBasedDepartmentService(1,search?.text,search?.from_date1,search?.to_date1,step)
        setdata(response?.data)
    }   
    
    async function resetfilter(){
        setsearch({text:'',from_date:'',to_date:'',from_date1:'',to_date1:'',activate:false})
        setpage(1)
        const response =  await GetSupportTicketBasedDepartmentService(1,'','','',step)
        setdata(response?.data)
    }

    async function deleteData(){
        const response = await DeleteSupportTicketService(selecteddata?._id)
        if(response?.status === 200){
            toast.success("Deleted Successfully")
        }else{
            toast.success("Deleted Successfully")
        }
        setmodal(false)
        getdata()
      }
       
      async function downloaddata(){
        const response = await DownloadSupportTicketService(1,search?.text,search?.from_date1,search?.to_date1,step)
        let path = response.data.path.replace('public/','')
        handleDownload(`${process.env.REACT_APP_BACKEND_IMAGE_URL}${path}`,path.split('/')[path.split('/')?.length - 1])
      }
    
      const handleDownload = (url, filename) => {
        axios.get(url, {
            responseType: 'blob',
        })
            .then((res) => {
                fileDownload(res.data, filename)
            })
      }

  return (
    <div className='w-[98%] h-screen '>

            <Modal
            keepMounted
            open={modal}
            onClose={()=>setmodal(false)}
            width={300}
            footer={false}
            closable={false}
            >
            <div >
                <h6 className="font-bold text-[15px] text-center mb-2 w-full">Are you sure?</h6>
                <h6 className='bg-slate-100 text-center text-[12px] p-1.5 font-[400]'>After deleting you cannot retrieve it back before deleting check once whether you have used it in some data</h6>
                <div className='flex justify-end mt-3 '>
                <ButtonOutlinedAutoWidth btnName={'Cancel'} onClick={()=>setmodal(false)} />
                <div  className='ml-2'>
                <ButtonFilledAutoWidth btnName={'Confirm'} onClick={()=>deleteData()}  /> 
                </div>
                </div>
            </div>
            </Modal>

            <div className='flex'>
                <div >
                    <ItMenu />
                </div>
                <div className='w-[100%] pl-5 pt-4'>
                <div className='flex items-center -mt-1 border-b justify-between pb-1'>
                    <h6 className='font-[800] text-[13px]'>Total Support Ticket Received ({data?.pagination?.total})</h6>

                    <div className='flex items-center text-[12px]'>
                        <AiOutlineFileExcel onClick={()=>downloaddata()} size={24}  className='mx-2 bg-gray-200 p-1.5' />
                        <h6 className='mr-2 font-[600]'>{page == 1 ? data?.datas?.length > 0 ? 1 : 0 : (page - 1) * data?.pagination?.limit } - {data?.pagination?.limit} of {data?.pagination?.total}  </h6>
                        <div>
                        <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                        <IconButton onClick={()=>{ page < data?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(data?.pagination?.totalPages === page || data?.datas?.length === 0) ? 'opacity-50 ' : 'opacity-100'}`}  size={16} /></IconButton>

                        </div>
                        <DatePicker  size='small' style={{fontSize:'11px'}} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.from_date} onChange={(v,v1) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,from_date:v,from_date1:v1})}} /> 

                        <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.to_date} onChange={(v,v1) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,to_date:v,to_date1:v1})}} /> 

                        
                        <div className='mr-2'>
                            

                        <input  id="search_text" placeholder='Search text' type='text' value={search.text} onChange={(e)=>setsearch({...search,text:e.target.value})} className='border py-[3px] focus:ring-0 focus:outline-0 text-[14px]  w-[100px] px-1 rounded-md border-slate-300' />
                    

                        </div>  
                        <div className='mr-2'>
                        <ButtonOutlinedAutoWidth btnName="Apply Filter" onClick={()=>applyFilter()} /> 
                        </div>
                        <div className='mr-2'>
                        <ButtonOutlinedAutoWidth btnName="Reset Filter" onClick={()=>resetfilter()} /> 
                        </div>
                        
                        <ButtonFilledAutoWidth btnName="Add New" onClick={()=>navigator(`/support_ticket/ticket_raised/create`)} />

                    </div>
                </div>

                <div className='flex  items-center border-b'>
                    <h6 onClick={()=>{setstep(1);setpage(1)}} className={`font-[600] ${step === 1 && 'bg-slate-600 text-white'} text-center cursor-pointer py-1 w-[90px] text-[11px]`}>Pending</h6>
                    <h6 onClick={()=>{setstep(2);setpage(1)}} className={`font-[600] ${step === 2 && 'bg-slate-600 text-white'} text-center cursor-pointer py-1 w-[80px] text-[11px]`}>Progress</h6>
                    <h6 onClick={()=>{setstep(3);setpage(1)}} className={`font-[600] ${step === 3 && 'bg-slate-600 text-white'} text-center cursor-pointer py-1 w-[80px] text-[11px]`}>Resolved</h6>
              </div>

                <div className='overflow-x-hidden h-[90vh] mb-[50px] overflow-y-scroll'>
                                
                    {data?.datas?.length === 0 &&
                    <div className='grid place-items-center mt-20  items-center justify-center'>
                        <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' /> 
                        <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
                        <h6 className='font-[500] w-[70%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any data based on your current stage please add the data to see list here.</h6>
                    </div>}

                    <div className='h-[88vh] w-[100%] overflow-x-hidden overflow-y-scroll'>
                        {data?.datas?.length > 0 &&
                        <div className='max-h-[85vh] w-[100%] mt-4 border-t  overflow-x-hidden  border-l border-r overflow-y-scroll'>
                              <div className='sticky top-0 z-50 flex  bg-white py-[-5px] border-b border-slate-200 relative'>
                                  <h6  className='top-0 bg-white z-50 text-[12px] min-w-[5%]  max-w-[5%]  px-2 py-1 font-[600] text-slate-600 border-r flex items-center justify-center'>Sl No</h6>
                                  <h6 className='sticky top-0 z-50  text-[12px]  min-w-[10%] max-w-[10%]  px-2 py-1 font-[600] text-slate-600 border-r'>Raised By</h6>
                                  <h6 className='sticky top-0 z-50  text-[12px]  min-w-[12%] max-w-[12%]  px-2 py-1 font-[600] text-slate-600 border-r'>Support Department</h6>
                                  <h6 className='sticky top-0 z-50  text-[12px]  min-w-[16%] max-w-[16%]  px-2 py-1 font-[600] text-slate-600 border-r'>Issue</h6>
                                  <h6 className='sticky top-0 z-50  text-[12px] min-w-[13%] max-w-[13%]  px-2 py-1 font-[600] text-slate-600 border-r'>Raised Date</h6>
                                  <h6 className='sticky top-0 z-50  text-[12px] min-w-[8%] max-w-[8%]  px-2 py-1 font-[600] text-slate-600 border-r'>Status</h6>
                                  <h6 className='sticky top-0 z-50  text-[12px] min-w-[16%] max-w-[16%]  px-2 py-1 font-[600] text-slate-600 border-r'>Remarks</h6>
                                  <h6 className='sticky top-0 z-50  text-[12px] min-w-[13%] max-w-[13%]  px-2 py-1 font-[600] text-slate-600 border-r'>Resolved Date</h6>
                                  <h6 className='sticky top-0 z-50  text-[12px] min-w-[10%] max-w-[10%]  px-2 py-1 font-[600] text-slate-600 '>Actions</h6>
                              </div>
                              {data?.datas?.map((d,i)=>(
                              <div className='flex  bg-white py-[-5px] border-b border-slate-200 relative'>
                                  <h6  className='bg-white text-[12px] min-w-[5%]  max-w-[5%]   px-2 py-1 font-[500] border-r flex items-center justify-center'>{(page > 1 ? i+1+ (data?.pagination?.limit * (page - 1)) : i+1 )}</h6>
                                  <h6 className=' text-[12px] truncate min-w-[10%] max-w-[10%] px-2 py-1 font-[500] border-r'>{d?.ticket_raised_by?.name}</h6>
                                  <h6 className=' text-[12px] truncate min-w-[12%] max-w-[12%] px-2 py-1 font-[500] border-r'>{d?.assigned_department?.department_name}</h6>
                                  <h6 className=' text-[12px] truncate min-w-[16%] max-w-[16%]  px-2 py-1 font-[500] border-r'>{d?.issue}</h6>
                                  <h6 className=' text-[12px] truncate  min-w-[13%] max-w-[13%]  px-2 py-1 font-[500] border-r'>{moment(d?.createdAt)?.format('lll')}</h6>
                                  <h6 className=' text-[12px] truncate  min-w-[8%] max-w-[8%] px-2 py-1 font-[500] border-r'>{d?.status}</h6>
                                  <h6 className=' text-[12px] truncate min-w-[16%] max-w-[16%]  px-2 py-1 font-[500] border-r'>{d?.remarks}</h6>
                                  <h6 className=' text-[12px] truncate  min-w-[13%] max-w-[13%]  px-2 py-1 font-[500] border-r'>{d?.status === 'Resolved' && moment(d?.updatedAt)?.format('lll')}</h6>
                                  <h6 className='flex items-center text-[12px]  min-w-[10%] max-w-[10%]  px-2 py-1 font-[500] '>
                                    <AiOutlineEdit onClick={()=>navigator(`/it/ticket_raised/edit`,{state:d})} className='mr-2' />
                                    {(roles?.includes('admin') || roles?.includes('delete_data')) && step !== 2 &&
                                    <AiOutlineDelete onClick={()=>{setselecteddata(d);setmodal(true)}} />}
                                  </h6>
                              </div>))}
                        </div>}
                    </div>

                </div> 
            </div>
            </div>    
    </div>
  )
}

export default SupportOptionList