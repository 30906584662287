import React, { useEffect, useState } from 'react'
import ProfileMenu from './ProfileMenu'
import { useSelector } from 'react-redux'
import { GetKRABasedDesignationService } from '../../services/KRAServices'

function MyKRA() {

    let user = useSelector(state=>state.Auth)
    const [data,setdata] = useState({})
    console.log("user",user)

    useEffect(()=>{
        getkradata()
    },[])

    async function getkradata(){
        const response = await GetKRABasedDesignationService(user?.designation_id?.id)
        setdata(response?.data?.datas)
    }

    function openFile(a){
        window.open(`${process.env.REACT_APP_AWS_IMAGE_URL}${a?.replace('public/','')}`,'_blank')
    }


  return (
    <div className='px-2 mx-0 box-border max-h-screen min-h-screen h-screen overflow-hidden'>
        <div className='block sm:flex max-h-screen min-h-screen h-screen overflow-hidden'>
        <ProfileMenu />         
        <div className='w-full max-h-screen min-h-screen h-screen overflow-y-scroll'>
            <div className='ml-6  mb-4  sm:w-8/12 ml-4 mt-4 pt-5'>
            <h6 className='border-b mt-1 pb-2 mb-4 font-[700] text-[17px] border-[#d0d7de]'>MY KRA Info</h6>
        </div>

           {['',null,undefined,'null','undefined']?.includes(data) &&
           <div  className='border ml-6 w-[18%] items-center relative justify-between px-2 py-1 border-b'>
                <h6 className='text-[12px] font-[500] '>Designation : <span className='font-[700]'>{data?.designation?.designation_name}</span></h6>
                <h6 className='text-[11px] mb-1'>Attachments</h6>
                <div className='text-[10px] font-[700] break-all'>
                    {data?.attachments?.map((a1)=> <span onClick={()=>openFile(a1)} className='cursor-pointer text-slate-600 underline'>{a1?.split('/')[a1?.split('/')?.length - 1]}</span>)}
                </div>    
            </div>}
        </div>
        </div>
    </div>
  )
}

export default MyKRA