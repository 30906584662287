import React,{useState,useEffect} from 'react'
import ItMenu from '../ItMenu'
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { DeviceInventoryHistoryOptionService, DeviceInventoryUsageHistoryOptionService, GetInventoryOptionDetailService, UpdateInventoryHistoryOptionService, UploadInventoryOptionService } from '../../../services/ITServices/InventoryOption/InventoryOptionServices';
import { IoChevronForward } from "react-icons/io5";
import Priceconstants from '../../../constants/imageConstants';
import { MdOutlineEdit } from "react-icons/md";
import { AiOutlineClose } from "react-icons/ai";
import { FaRegCalendar } from "react-icons/fa6";
import GoBack from '../../../components/back/GoBack';
import { VscDebugReverseContinue } from "react-icons/vsc";
import { Modal, Select } from 'antd';
import { TextAreaInput1, TextInput } from '../../../components/input';
import Uploader from '../../../components/Uploader';
import { ButtonFilledAutoWidth } from '../../../components/button';
import toast from 'react-hot-toast';

function InventoryOptionDetail() {

  const navigator = useNavigate()
  const {state}  = useLocation()


  const [itemInfo,setitemInfo] = useState({})
  const [page,setpage] = useState(1)

  const [serviceModal,setserviceModal] = useState(false)
  const [selectedHistory,setselectedHistory] = useState({})
  const [error,seterror] = useState({remarks:'',service_info:'',bill:'',amount:''})


  const [datas,setdatas] = useState({})
  const [usages,setusages] = useState({})

  useEffect(()=>{
    getdata()
  },[])

  async function getdata() {
    const response0 = await GetInventoryOptionDetailService(state?._id)
    let d = response0?.data?.datas[0]
    setitemInfo(d)
    const response = await DeviceInventoryHistoryOptionService(state?._id,page)
    const response1 = await DeviceInventoryUsageHistoryOptionService(state?._id,page)
    setusages(response1?.data?.data)
    setdatas(response?.data?.data)
  }


  function handlechange(e){
    setselectedHistory({...selectedHistory,[e.target.name]:e.target.value})
    seterror({...error,[e.target.name]:''})
  }

  async function uploadfilefunc(v,name){
    const fd = new FormData()
    fd.append('file',v); 
    const response = await UploadInventoryOptionService(fd)
    console.log("response",response)
    if(response?.status === 200){
      setselectedHistory({...selectedHistory,[name]:response?.data?.data})
    }
  }

  async function updatehistory() {
    let sendData = {...selectedHistory}
    delete sendData?.device_stage_id
    delete sendData?.inventory_item
    const response = await UpdateInventoryHistoryOptionService(selectedHistory?._id,sendData)
    if(response?.status == 200){
      toast.success("History Updated")
      setserviceModal(false)
    }
    
  }


  let rupeeIndian = Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });

  
  return (
    <div className='flex mx-0 box-border  max-h-screen overflow-y-scroll overflow-x-hidden'>

       <Modal closable={false} footer={false} open={serviceModal} width={300}>
        <div className='relative'>
          <h6 className='font-[700]'>Update The History</h6>
          <AiOutlineClose onClick={()=>setserviceModal(false)} className='absolute cursor-pointer right-0 top-0' /> 
         

          <TextAreaInput1 
            label={'Service Info'}  
            variant="standard"
            name="service_info"
            type="text"
            mandatory={true}
            error={error?.service_info}
            value={selectedHistory?.service_info}
            handlechange={handlechange}
            placeholder=""
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>
          
          <TextInput 
            label={'Amount'}  
            variant="standard"
            name="amount"
            type="text"
            mandatory={true}
            error={error?.amount}
            value={selectedHistory?.amount}
            handlechange={handlechange}
            placeholder=""
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>  


          <div className='relative  mb-1 mt-2'>
          <h6 className='text-[11px] font-[600]' >Bill</h6> 
          {selectedHistory?.bill != '' && <h6 onClick={()=>window.open(`${process.env.REACT_APP_AWS_IMAGE_URL}${selectedHistory.bill}`, "_blank")} className='text-[9px] underline font-[700] absolute  right-0 top-0 cursor-pointer' >View File</h6>}
          <Uploader image={selectedHistory?.bill}  setimagefunc={(e)=>{uploadfilefunc(e,'bill');seterror({...error,bill:''})}}  removeimageuploadfunc = {()=>{setselectedHistory({...selectedHistory,bill:''});seterror({...error,bill:''})}}/>    
          </div>

          <TextAreaInput1 
            label={'Remarks'}  
            variant="standard"
            name="remarks"
            type="text"
            mandatory={true}
            error={error?.remarks}
            value={selectedHistory?.remarks}
            handlechange={handlechange}
            placeholder=""
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>


          <h6 className='text-[11px] font-[600] mb-1 mt-2' >Status</h6>    
          <div className='border border-l-4 border-l-slate-700'>
          <Select
                placeholder='Select Status'
                bordered={false}
                size='small'
                defaultValue={selectedHistory?.status !== '' ? selectedHistory?.status : null}
                value={selectedHistory?.status !== '' ? selectedHistory?.status : null}
                style={{ width: '100%',padding:'5px'}}
                onChange={(v)=>setselectedHistory({...selectedHistory,status:v})}
                options={[{label:'Pending',value:'Pending'},{label:'Progress',value:'Progress'},{label:'Complete',value:'Complete'}]}
            />
          </div>  

        <div className='mt-2 flex justify-end'>
        <ButtonFilledAutoWidth btnName={`Update`}  onClick={updatehistory} />  
        </div>

       
        </div>
       </Modal>       
 
        <div>
            <ItMenu />
        </div>
        <div className=' w-[100%]'>
        <div className='flex min-h-screen'>
          

         

          <div className='bg-slate-100 pb-8 min-h-screen h-[100%] px-4 w-screen'>

          <div className='flex items-center  py-2 text-[10px] font-[700] text-gray-500'>
            <h6 onClick={()=>navigator(-1)} className='w-max mr-2 cursor-pointer'>Devices</h6>
            <IoChevronForward className='mr-2' />
            <h6 className='w-max mr-2 bg-gray-200 text-black p-1 uppercase rounded'>{itemInfo?.device_id}</h6>
          </div>

          <GoBack /> 
             


             <div>

             
              <div className='flex relative'>
                <div className='w-[70px] h-[70px] bg-slate-100 border-[4px] border-white rounded-full'>
                {itemInfo?.image !== '' &&  <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${itemInfo?.image}`} className='w-[100%] h-[100%] rounded-full' />}
                </div>
                
                <div className='ml-2 mt-2'>
                    <h6 className='capitalize text-[20px] font-[800]'>{itemInfo?.name}</h6>
                    <h6 className='text-[11px] -mt-1 font-[700] text-gray-400'>{itemInfo?.type}</h6>
                </div>

                
                  <div onClick={()=>{navigator('/it/inventory/laptop/edit',{state:itemInfo})}} className='absolute right-[14%] rounded-[5px] flex items-center bg-white px-[10px] py-[7px] border-[1px] border-gray-200  cursor-pointer'>
                    <h6 className='mr-1 text-[11px] font-[600]' >Edit Device</h6>
                    <MdOutlineEdit size={12} />
                  </div>
              </div>

              <div className='flex w-[79.5vw]'>

              <div className='w-[65%]'>
              <div className='bg-white p-5 rounded-[10px] mt-5 max-w-[80vw]'>
                 <h6 className='text-[12px] font-[900]'>Device Details</h6>

                 <div className='grid border rounded-[10px] p-5 mt-2 grid-cols-5'>

                  <div className='-mt-2'>
                    <h6 className='text-[11px] font-[600] text-gray-400'>Name</h6>
                    <h6 className='capitalize text-[12px] text-black font-[600] break-all w-[90%]'>{itemInfo?.name}</h6>
                  </div>

                  <div className='-mt-2'>
                    <h6 className='text-[11px] font-[600] text-gray-400'>Category</h6>
                    <h6 className='capitalize text-[12px] text-black font-[600] break-all w-[90%]'>{itemInfo?.type}</h6>
                  </div>

                  <div className='-mt-2'>
                    <h6 className='text-[11px] font-[600] text-gray-400'>Created At</h6>
                    <h6 className='capitalize text-[12px] text-black font-[600] break-all w-[90%]'>{moment(itemInfo?.createdAt)?.format('LL')}</h6>
                  </div>

                  <div className='-mt-2'>
                    <h6 className='text-[11px] font-[600] text-gray-400'>Status</h6>
                    <h6 className='capitalize text-[9px] text-black bg-purple-50 w-max p-1 text-purple-500 rounded-[5px] font-[600]'>{itemInfo?.device_status?.name}</h6>
                  </div>

                  <div className='-mt-2'>
                    <h6 className='text-[11px] font-[600] text-gray-400'>Assigned To</h6>
                    <h6 className='capitalize text-[12px] font-[600] text-blue-400'>{itemInfo?.name}</h6>
                  </div>

                  <div className='mt-1'>
                    <h6 className='text-[11px] font-[600] text-gray-400'>Brand</h6>
                    <h6 className='capitalize text-[12px] text-black font-[600] break-all w-[90%]'>{itemInfo?.brand?.name}</h6>
                  </div>

                  <div className='mt-1'>
                    <h6 className='text-[11px] font-[600] text-gray-400'>Device Stage</h6>
                    <h6 className='capitalize text-[12px] text-black font-[600] break-all w-[90%]'>{itemInfo?.device_stage?.name}</h6>
                  </div>

                  <div className='mt-1'>
                    <h6 className='text-[11px] font-[600] text-gray-400'>Asset Number</h6>
                    <h6 className='capitalize text-[9px] text-black bg-green-50 text-green-500 p-1 rounded-[5px] w-max font-[600]'>{itemInfo?.asset_id}</h6>
                  </div>

                  <div className='mt-1'>
                    <h6 className='text-[11px] font-[600] text-gray-400'>MRP</h6>
                    <h6 className='capitalize text-[12px] text-black font-[600] break-all w-[90%]'>{Priceconstants(!['',null,undefined,'null','undefined']?.includes(itemInfo?.mrp) ? itemInfo?.mrp : 0)}</h6>
                  </div>

                  <div className='mt-1'>
                    <h6 className='text-[11px] font-[600] text-gray-400'>Rent Price</h6>
                    <h6 className='capitalize text-[12px] text-black font-[600] break-all w-[90%]'>{Priceconstants(parseInt(!['',null,undefined,'null','undefined']?.includes(itemInfo?.monthly_rent_price) ? itemInfo?.monthly_rent_price : 0))}</h6>
                  </div>

                 </div>
              </div>

              <div className='bg-white p-5  rounded-[10px] mt-5 max-w-[80vw]'>
                 <h6 className='text-[12px] font-[900]'>Other Information</h6>

                 <div className='border rounded-[10px] mt-5 p-5'>
                    <div className='grid grid-cols-5 -mt-2'>
                    
                      {['Laptop','Desktop','Mobile']?.includes(itemInfo?.type) &&
                      <div>
                        <h6 className='text-[11px] font-[600] text-gray-400'>Processor</h6>
                        <h6 className='capitalize text-[12px] text-black font-[600] break-all w-[90%]'>{itemInfo?.processor?.name}</h6>
                      </div>}

                      {['Laptop','Desktop','Mobile','HardDisk']?.includes(itemInfo?.type) &&
                      <div>
                        <h6 className='text-[11px] font-[600] text-gray-400'>Storage</h6>
                        <h6 className='capitalize text-[12px] text-black font-[600] break-all w-[90%]'>{itemInfo?.storage}</h6>
                      </div> }

                      {['Laptop','Desktop','Mobile',]?.includes(itemInfo?.type) &&
                      <div>
                        <h6 className='text-[11px] font-[600] text-gray-400'>RAM</h6>
                        <h6 className='capitalize text-[12px] text-black font-[600] break-all w-[90%]'>{itemInfo?.ram}</h6>
                      </div>}

                      <div>
                        <h6 className='text-[11px] font-[600] text-gray-400'>Purchase Type</h6>
                        <h6 className='capitalize text-[12px] text-black font-[600] break-all w-[90%]'>{itemInfo?.purchase_type?.name}</h6>
                      </div> 

                      <div>
                        <h6 className='text-[11px] font-[600] text-gray-400'>Device Type</h6>
                        <h6 className='capitalize text-[12px] text-black font-[600] break-all w-[90%]'>{itemInfo?.device_type?.name}</h6>
                      </div> 

                    
                    </div>   

                    <div className='mt-2'>
                        <h6 className='text-[11px] font-[600] text-gray-400'>Configuration</h6>
                        <h6 className='capitalize text-[12px] text-black font-[600] break-all w-[90%]'>{itemInfo?.configuration}</h6>
                    </div> 
                  </div>
              </div>

              <div className='bg-white p-5 rounded-[10px] mt-5 max-w-[80vw]'>
                 <h6 className='text-[12px] font-[900]'>Device History ({datas?.pagination?.total})</h6>

                  {datas?.data?.map((d)=>(
                    <div className='border p-2 relative rounded-[10px] mt-2' key={d?._id}>
                       <h6 className='text-[8px] bg-blue-100 mb-1 text-blue-600 font-[800] w-max p-1 '>Status : <span className='font-[800] text-[#203963]'>{d?.status}</span></h6>
                     

                       {d?.device_stage_id?.name == "Service" &&
                       <div className='bg-slate-100 p-1 mb-1'>
                          <h6 className='text-[11px]'>Service Info : <span className='font-[700]'>{d?.service_info}</span> / Remarks : <span className='font-[700]'>{d?.remarks}</span></h6> 
                          <h6 className='text-[11px]'>Amount : <span className='font-[700]'>{rupeeIndian?.format(d?.amount)?.split('.')[0]}</span></h6>
                          {(d?.bill !== '' && d?.bill !== null) && <h6 onClick={()=>window.open(`${process.env.REACT_APP_AWS_IMAGE_URL}${d?.bill}`, "_blank")} className='text-[10px] hover:underline cursor-pointer underline font-[600]' >View Bill</h6>}        

                       </div>}
                     
                       {d?.device_stage_id?.name == "Service" &&
                       <div onClick={()=>{setselectedHistory({...d});setserviceModal(true)}} className='absolute cursor-pointer right-1 top-1'>
                          <div className='bg-slate-100 flex items-center p-1 w-max rounded'>
                              <VscDebugReverseContinue size={14} /> 
                              <h6 className='text-[8px] font-[800]'>{d?.device_stage_id?.name == "Service" && 'Device In Service'}</h6>
                          </div>
                       </div>}

                       <h6 className='text-[10px] font-[700]'>Updation Remarks : </h6>
                       <div className='flex items-center'>
                         <h6 className='text-[12px]' dangerouslySetInnerHTML={{__html:d?.updatedFields}}></h6>
                       </div>
                    </div>  

                  ))}

              </div>
              </div> 

              <div className='w-[35%]'>

                <div className='bg-white rounded-[10px] mt-5 w-[93%] ml-[7%] p-5'>
                 <h6 className='text-[12px] font-[900] mb-2'>Usage History ({usages?.pagination?.total})</h6>
                 {usages?.data?.map((u)=>(
                  <div className='border rounded-[10px] p-2 mb-2' key={u?._id}> 
                     <h6 className='text-[13px] font-[800]'>{u?.employee?.name} - [<span className='uppercase text-[10px]'>{u?.employee?.employee_id}</span>]</h6>
                     <h6 className='text-[12px] text-slate-400 font-[800]'>Department - <span className='text-black'>{u?.employee?.department_id?.department_name}</span> / Designation  - <span className='text-black'>[<span className='uppercase text-[10px]'>{u?.employee?.designation_id?.designation_name}</span>]</span></h6>
                     <h6 className={`text-[10px] flex items-center font-[800] ${u?.asset_status?.name == "Assigned" ? 'text-blue-400' : 'text-violet-800'}`}><p className={`min-w-[8px] max-h-[8px] max-w-[8px] rounded-full ${u?.asset_status?.name == "Assigned" ? 'bg-blue-400 text-blue-400' : 'bg-violet-800 text-violet-800'} overflow-hidden mr-2`}>1</p> {u?.asset_status?.name == "Assigned" ? "In use" : "Retrieved"}</h6>
                     <div className='flex items-center'> 
                      <h6 className='text-[10px] flex items-center text-slate-400 font-[600] mr-1'>Assign <FaRegCalendar className='mx-1' /> <span className='text-black'>{moment(u?.assigned_date)?.format('LL')}</span></h6> 
                      <h6 className='text-[10px] flex items-center text-slate-400 font-[600]'>/ Retrieve<FaRegCalendar className='mx-1' /> <span className='text-black'>{moment(u?.retrieved_date)?.format('LL')}</span></h6>
                     </div>
                  </div> 
                 ))}
                </div>

              </div>
              </div>
             </div>
          </div>  

       
          </div>
        </div>
    </div>
  )
}

export default InventoryOptionDetail