import React, { useEffect } from 'react'
import { useLocation,useNavigate } from 'react-router-dom'
import {BsGraphUpArrow} from 'react-icons/bs';
import {HiOutlineBuildingOffice} from 'react-icons/hi2';
import {TbMoneybag} from 'react-icons/tb';
import {RiListCheck,RiPlayList2Line,RiUser6Line} from 'react-icons/ri';
import {AiOutlineControl,AiOutlineUserAdd,AiOutlineTeam,AiOutlineFileZip} from 'react-icons/ai';
import {GrSteps} from 'react-icons/gr';
import {BiMoneyWithdraw} from 'react-icons/bi';
import {MdFileDownload,MdPayment} from 'react-icons/md';
import { useSelector } from 'react-redux';
import { PiTreeStructure } from "react-icons/pi";
import {BiFoodMenu} from 'react-icons/bi';
import { LuDoorOpen } from "react-icons/lu";
import { MdOutlineWorkOutline } from "react-icons/md";
import { MdBrowserUpdated } from "react-icons/md";

function SettingsMenu() {

  const {pathname} = useLocation()
  const roles = useSelector(state=>state.Auth.roles)
  
  // console.log("roles",roles)
  // const roles = 
  const navigator = useNavigate()

  const menu = [
    {name:'Incentive Requ',icon:BsGraphUpArrow,path:'/settings/incentive_requirment',id:1,color:''},
  ] 

  const menu4 = [
    {name:'Our Teams',icon:AiOutlineTeam,path:'/settings/team',id:1,color:''},
    {name:'Reporting Teams',icon:PiTreeStructure,path:'/settings/reporting',id:3,color:''},
    {name:'Employee Target',icon:TbMoneybag,path:'/settings/employee_target',id:2,color:''}
  ] 

  const menu1 = [
    {name:'Our Department',icon:HiOutlineBuildingOffice,path:'/settings/department',id:1,color:''},
    {name:'Our Designation',icon:RiListCheck,path:'/settings/designation',id:2,color:''},
    {name:'Our Roles',icon:AiOutlineControl,path:'/settings/roles',id:3,color:''},
    {name:'Lead Stages',icon:GrSteps,path:'/settings/stages',id:4,color:''},
    {name:'Our CSDT',icon:RiPlayList2Line,path:'/settings/csdt_list',id:5,color:''},
  ] 

  const menu2 = [
    {name:'Finance Report',icon:TbMoneybag,path:'/settings/finance_dashboard',id:1,color:''},
    {name:'Finance Status',icon:BiMoneyWithdraw,path:'/settings/finance_dashboard_data',id:2,color:''},
  ]

  const menu3 = [
    {name:'OnBoard Employee',icon:AiOutlineUserAdd,path:'/settings/onboard_list',id:1,color:'',roles:[]},
    {name:'App Update',icon:MdBrowserUpdated,path:'/settings/app_update',id:1,color:'',roles:['admin']},
    {name:'Report Download',icon:MdFileDownload,path:'/settings/reports',id:2,color:'',roles:['admin','controller']},
    {name:'KRA Designation',icon:MdOutlineWorkOutline,path:'/settings/kra',id:2,color:'',roles:['admin','controller']},
  ]

  const menu5 = [
    {name:'Invoice Request',icon:AiOutlineUserAdd,path:'/settings/invoice_request',id:1,color:''},
    {name:'Invoice Attachments',icon:AiOutlineFileZip,path:'/settings/invoice_attachments',id:2,color:''},
    {name:'Payment Share',icon:MdPayment,path:'/settings/payment_shares',id:3,color:''},
  ]

  const menu6 = [
    {name:'Event List',icon:BiFoodMenu,path:`/settings/events`,id:1,color:''},
    {name:'User List',icon:RiUser6Line,path:`/settings/users`,id:2,color:''},
    {name:'Visitor List',icon:LuDoorOpen,path:`/settings/visitors`,id:3,color:''},
  ]

  // useEffect(()=>{
  //   if(roles?.includes('onboard_executive')){
  //      navigator('/settings/onboard_list')
  //   }
  // },[])

  // console.log("roles",roles)


  return (
    <div >

      
     
      <div className='mr-0 min-h-screen max-h-screen border-r overflow-y-scroll w-44 px-2'>
           

           {(roles?.includes('admin') || roles?.includes('controller') || roles?.includes('hr_admin') ) &&
           <>
          <h1 className=' ml-2 mb-2 font-[700] text-[12px] pt-3'> Basic Options</h1>
           {menu1.map((m)=>(
                <div key={m?.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigator(m?.path)}>
                {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
               <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16}  />
               <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
              </div>    
      
            ))}
            </>}

          {(roles?.includes('admin') || roles?.includes('controller')) &&
           <>
           <h1 className=' ml-2 mb-2 font-[700] text-[12px] pt-3 border-t'>Insentive Options</h1>
           {menu.map((m)=>(
                <div key={m?.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigator(m?.path)}>
                {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
               <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16}  />
               <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
              </div>    
            ))}
            </>}

            {(roles?.includes('admin') || roles?.includes('controller')) &&
            <>
           <h1 className=' ml-2 mb-2 font-[700] text-[12px] pt-3 border-t'>Team Options</h1>
           {menu4.map((m)=>(
                <div key={m?.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigator(m?.path)}>
                {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
               <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16}  />
               <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
              </div>    
            ))}
            </>}

        {(roles?.includes('admin') || roles?.includes('controller')) &&
        <>
        <h1 className=' ml-2 mb-2 font-[700] text-[12px] pt-3 border-t'>Finance Options</h1>
           {menu2.map((m)=>(
                <div key={m?.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigator(m?.path)}>
                {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
               <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16}  />
               <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
              </div>    
            ))}
         </>}   

        {(roles?.includes('admin')  || roles?.includes('controller') || roles?.includes('hr_admin') || roles?.includes('onboard_executive')) &&
        <>
        <h1 className=' ml-2 mb-2 font-[700] text-[12px] pt-3 border-t'>Controller Options</h1>
           {menu3.map((m)=>(   
              roles?.filter((f)=>m?.roles?.includes(f))?.length > 0 ?
              <>
                <div key={m?.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigator(m?.path)}>
                  {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
                <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16}  />
                <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
                </div>  
              </>  
              :
              <>
                {m?.roles?.length === 0 &&
                <div key={m?.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigator(m?.path)}>
                  {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
                <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16}  />
                <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
                </div>}
              </>  
            ))}  
        </>}  

        {(roles?.includes('admin') || roles?.includes('controller')) &&
        <>
        <h1 className=' ml-2 mb-2 font-[700] text-[12px] pt-3 border-t'>Invoice Options</h1>
           {menu5.map((m)=>(
                <div key={m?.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigator(m?.path)}>
                {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
               <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16}  />
               <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
              </div>    
            ))}  
        </>}

        {(roles?.includes('admin') || roles?.includes('controller')) &&
        <>
        <h1 className=' ml-2 mb-2 font-[700] text-[12px] pt-3 border-t'>Visitor Options</h1>
           {menu6.map((m)=>(
                <div key={m?.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigator(m?.path)}>
                {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
               <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16}  />
               <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
              </div>    
            ))}  
        </>}

      </div>
    </div>
  )
}

export default SettingsMenu