import { IconButton, Tooltip } from '@mui/material'
import React,{useState,useEffect} from 'react'
import { useLocation,useNavigate } from 'react-router-dom'
import {AiOutlineEdit,AiOutlineFileExcel} from 'react-icons/ai';
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';

import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button';
import { Modal,Select } from 'antd';
import { DatePicker } from 'antd';
// import DailyTask from './dailyTask/DailyTask';
import {AiOutlineDelete} from 'react-icons/ai';
import { toast } from 'react-hot-toast';
import fileDownload from "js-file-download";
import axios from 'axios';
import { DeletePropertyDBService, DownloadPropertyDBAdminService, FilterPropertyDBAdminService, GetPropertyDBAdminService, GetPropertyDBUserRoleWithAccessService } from '../../services/PropertDbWebsiteServices';
import DashboardMenu from '../dashboard/DashboardMenu';

function NMRAdminlist() {

  const location = useLocation();
  const navigate = useNavigate();


  const [step,setstep] = useState(1)

  const [datas,setdatas]  = useState([])
  const [users,setusers]  = useState([])
  const [leads_selected_list,setleads_selected_list] = useState([])
  

  const [page,setpage] = useState(1)

  const [selected_data,setselected_data] = useState({})
  const [delete_modal,setdelete_modal] = useState(false)

  const [search,setsearch] = useState({text:'',from_date:'',from_date1:'',to_date:'',to_date1:'',user:'',activate:false})

  useEffect(()=>{
    setpage(1)
    getdatas(1) 
    getuserswithAccess()
  },[])

  useEffect(()=>{
    setpage(1)
    getdatas(1) 
    // getReportingMemberDetails(user?.id)    
  },[location.pathname])





  useEffect(()=>{
      if(search.activate){
        applyfilterfunction(page)
      }else{
        getdatas(page)
      }
  },[page])



  async function getuserswithAccess(){
    const response = await GetPropertyDBUserRoleWithAccessService()
    let arr = []
    let users_datas = response?.data?.data
    // console.log("users_datas",users_datas)
    users_datas.forEach((d)=>{
      arr.push({label:d?.user_id?.name,value:d?.user_id?.id})
    })
    setusers(arr)
  }
  
  async function getdatas(page){
      const response =  await GetPropertyDBAdminService(page,search?.user);
      setdatas(response.data)  
  }

  async function applyfilterfunction(page){
   
    setsearch({...search,activate:true})
    const response = await FilterPropertyDBAdminService(page,search?.from_date1,search?.to_date1,search.text,search?.user)
    setdatas(response.data)
  }

  async function resetfunc(){
    setsearch({text:'',from_date:'',from_date1:'',to_date:'',to_date1:'',user:'',activate:false})
    getdatas(1)
    setpage(1)
  }


  function selected_lead_check_to_group(v){
      if(leads_selected_list?.includes(v)){
        setleads_selected_list([...leads_selected_list.filter(e => e!== v)])
      }else{
        if(leads_selected_list.length < 10){
           setleads_selected_list([...leads_selected_list,v])
        }else{
          toast.error('You can select upto max 10 items')
        }
      }      
  }



  async function downloaddata(){
    const response = await DownloadPropertyDBAdminService(search?.from_date1,search?.to_date1,search.text,search?.user)
    let path = response.data.path.replace('public/','')
    handleDownload(`${process.env.REACT_APP_AWS_IMAGE_URL}${path}`,path.split('/')[path.split('/')?.length - 1])

  }

  const handleDownload = (url, filename) => {
    axios.get(url, {
        responseType: 'blob',
    })
        .then((res) => {
            fileDownload(res.data, filename)
        })
  }

  async function deleteData(id){
    const response = await DeletePropertyDBService(id)
    if(response.status === 200){
      toast.success("Deleted Successfully")
      setdelete_modal(false)
      setselected_data({})
      getdatas(page)
    }

  }

  // console.log("users",users)

  return (
    <div className='h-screen flex w-[100%] max-h-screen '>
      <div className='flex w-[100%] items-start'>
        <div className='w-44'>
        <DashboardMenu />
        </div>
        <div className='ml-5 w-[100%]'>
     

        <Modal open={delete_modal} width={350} closable={false} footer={false} className='absolute top-0 left-[40%]'>
            <h6 className="font-bold text-[13px]  mb-2 w-full">Delete Data</h6>
            <h6 className="text-[11px]  mb-2 w-full">Are you sure want to delete the selected data which has  name : <span className='font-[800]'>{selected_data?.name}</span></h6>

            <div className='mt-2 flex items-center'>
              <ButtonOutlinedAutoWidth btnName="Close" onClick={()=>setdelete_modal(false)}/>
              <div className='ml-2'>
              <ButtonFilledAutoWidth btnName="Confirm" onClick={()=>deleteData(selected_data?._id)} />
              </div>
            </div>
        </Modal> 

        

    
        

      
        {/* {list?.includes(path) ?  */}
          <div  className="w-[98%] pt-4">
            <div  >
              <div className='flex items-center justify-between border-b pb-2'>
                <h6 className='font-[700] text-[14px] '>Total Client Data ({datas?.pagination?.total})</h6>
                <div className='flex items-center'>
                  <div className='flex items-center text-[12px] mr-2'>

                    <AiOutlineFileExcel onClick={()=>downloaddata()} size={24}  className='mx-2 bg-gray-200 p-1.5' />

                    <h6 className='mr-2 font-[600]'>{page == 1 ? datas?.datas?.length > 0 ? 1 : 0 :  (page - 1) * datas?.pagination?.limit } - {datas?.pagination?.limit} of {datas?.pagination?.total} </h6>
                    <IconButton onClick={resetfunc}><BsArrowRepeat size={16} /></IconButton>

                    <div>
                    <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                    <IconButton onClick={()=>{ page < datas?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(datas?.pagination?.totalPages === page || datas?.datas?.length === 0)  ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>

                    </div>
                  </div>


                  <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.from_date} onChange={(v,v1) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,from_date:v,from_date1:v1})}} /> 

                  <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.to_date} onChange={(v,v1) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,to_date:v,to_date1:v1})}} /> 

                
                <div >


                
                    

                  <input  id="search_text" placeholder='Search text' type='text' value={search.text} onChange={(e)=>setsearch({...search,text:e.target.value})} className='border py-[3px] focus:ring-0 mr-2 focus:outline-0 text-[14px]  w-28 px-1 rounded-md border-slate-300' />
                
                
                  
                </div> 

                <Select
                    value={search?.user == '' ? null : search?.user}
                    placeholder="User"
                    bordered={false}
                    onChange={(e)=>{setsearch({...search,user:e})}}
                    options={users}

                    className='border border-slate-300 rounded-[5px] w-[100px] h-[30px] mr-[10px]'
                  />
                  
              

              <ButtonOutlinedAutoWidth btnName="Add Filter" onClick={()=>applyfilterfunction(1)} /> 
            

              <div className='ml-2'>
              <ButtonFilledAutoWidth btnName="Add Data" onClick={()=>navigate('/nmr/list/create')}/> 
              </div>
                </div>
            </div>


            {/* <div className='flex  items-center border-b'>
              <h6 onClick={()=>{setstep(1);setpage(1)}} className={`font-[600] ${step === 1 && 'bg-slate-600 text-white'} text-center cursor-pointer py-1 w-[80px] text-[11px]`}>Pending</h6>
              <h6 onClick={()=>{setstep(2);setpage(1)}} className={`font-[600] ${step === 2 && 'bg-slate-600 text-white'} text-center cursor-pointer py-1 w-[80px] text-[11px]`}>Called</h6>
              <h6 onClick={()=>{setstep(3);setpage(1)}} className={`font-[600] ${step === 3 && 'bg-slate-600 text-white'} text-center cursor-pointer py-1 w-[80px] text-[11px]`}>Follow Up</h6>
            </div> */}
            
            {datas?.datas?.length === 0 &&
              <div className='grid place-items-center mt-20  items-center justify-center'>
              <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' /> 
              <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
              <h6 className='font-[500] w-[70%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any data based on your current stage please add the data to see list here.</h6>
            </div>
            }


            {datas?.datas?.length > 0 &&
            <div className='max-h-[82vh] mt-4 border-t   border-l border-r overflow-y-scroll'>
            <div className='sticky top-0 z-50 flex  bg-white py-[-5px] border-b border-slate-200 relative'>
                <h6  className='top-0 bg-white z-50 text-[12px] w-[60px]  px-2 py-1 font-[600] text-slate-600 border-r flex items-center justify-center'>SL NO</h6>
                <h6 className='sticky top-0 z-50  text-[12px] w-[138px] px-2 py-1 font-[600] text-slate-600 border-r'>Name</h6>
                <h6 className='sticky top-0 z-50  text-[12px] w-[138px] px-2 py-1 font-[600] text-slate-600 border-r'>Address</h6>
                <h6 className='sticky top-0 z-50  text-[12px] w-[90px]  px-2 py-1 font-[600] text-slate-600 border-r'>Mobile</h6>
                <h6 className='sticky top-0 z-50  text-[12px] w-[130px]  px-2 py-1 font-[600] text-slate-600 border-r'>Email</h6>
                {/* <h6 className='sticky top-0 z-50  text-[12px] w-[120px] px-2 py-1 font-[600] text-slate-600 border-r'>Department</h6> */}
                <h6 className='sticky top-0 z-50  text-[12px] w-[340px] px-2 py-1 font-[600] text-slate-600 border-r'>Visit Information</h6>
                <h6 className='sticky top-0 z-50  text-[12px] w-[150px]  px-2 py-1 font-[600] text-slate-600 border-r'>Created By</h6>
                <h6 className='sticky top-0 z-50  text-[12px] w-[90px]  px-2 py-1 font-[600] text-slate-600 sticky right-0'>Actions</h6>
            </div>
          
            {datas?.datas?.map((d,i)=>(
            <div  key={d?._id} className={`flex z-50 h-full border-b border-slate-200 z-0`}  >
                <h6 onClick={()=>selected_lead_check_to_group(d?._id)} className='text-[12px] w-[60px]  px-2 py-1 font-[600] text-slate-600 cursor-pointer border-r flex items-center justify-center'>{page === 1 ? i+1 : (i+1) + ((page-1)* datas?.pagination?.limit)}</h6>
                <h6 className='text-[12px] font-[500] w-[138px] px-2 py-1 truncate border-r  border-slate-200'>{d?.name}</h6>
                <h6 className='text-[12px] font-[500] w-[138px] px-2 py-1 truncate border-r  border-slate-200'>{d?.address}</h6>
                <h6 className='text-[12px] font-[500] w-[90px]  px-2 py-1 truncate border-r  border-slate-200'>{d?.mobile}</h6>
                <h6 className='text-[12px] font-[500] w-[130px]  px-2 py-1 truncate border-r  border-slate-200'>{d?.email}</h6>
                {/* <h6 className='text-[12px] font-[500] flex w-[120px] px-2 py-1 truncate border-r border-slate-200'>
                  {d?.department_id?.department_name !== undefined &&  <p className='border-2 border-white bg-gray-100 px-[4px] py-[2px] text-[8px] dark:border-gray-800 font-[800]'>{d?.department_id?.department_name !== undefined && d?.department_id?.department_name}</p>}
                </h6> */}

                <h6 className={`text-[12px] font-[500] w-[340px] px-2 py-1  truncate  border-r  border-slate-200 `}>{d?.message ? d?.message : 'Not Added'}</h6>
                <h6 className={`text-[12px] font-[500] w-[150px] px-2 py-1  truncate  border-r  border-slate-200 `}>{d?.created_by?.name}</h6>
                
                {/* <h6 className='text-[12px] font-[500] w-[100px]  px-2 py-1 truncate border-r  border-slate-200 sticky right-0'>{moment(d?.createdAt).format('ll')}</h6> */}
                <h6  className='text-[12px] w-[90px]  px-2 py-1 flex'>
              

                  <Tooltip title="Edit">
                  <span><AiOutlineEdit size={13} className="z-10 ml-2" onClick={()=>navigate('/nmr/list/edit',{state:d?._id})}/></span>
                  </Tooltip>

                  <Tooltip title="Delete">
                  <span><AiOutlineDelete size={13} className="z-10 ml-2 -mt-[1px]" onClick={()=>{setselected_data(d);setdelete_modal(true)}}/></span>
                  </Tooltip>
                  

                
                </h6>
            </div>))}
            </div>}

          
            </div>

            
          


          </div>
        
        
      
        </div>
      </div>
    </div>
  )
}

export default NMRAdminlist