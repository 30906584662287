import { deleteRequest, get, publicPost, put } from "../helpers/apihelpers";

export const CreateAppUpdateService=async(data)=>{
    try {
        const result = await publicPost(`api/app_update/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateAppUpdateService=async(data,id)=>{
    try {
        const result = await put(`api/app_update/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetAppUpdateService=async()=>{
    try {
        const result = await get(`api/app_update/get`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteAppUpdateService=async(id)=>{
    try {
        const result = await deleteRequest(`api/app_update/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

