import React, { useEffect, useState } from 'react'
import GoBack from '../../../components/back/GoBack'
import toast from 'react-hot-toast'
import { useLocation, useNavigate } from 'react-router-dom'
import { TextAreaInput1, TextInput } from '../../../components/input'
import DatabaseMenu from '../DatabaseMenu'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../../components/button'
// import { CreatePropertyTypeService, UpdatePropertyTypeService } from '../../../../services/database/databaseoptions/PropertyTypeServices'
import { CreateApprovedTypeService, UpdateApprovedTypeService } from '../../../services/database/databaseoptions/ApprovedTypeServices'
import { Select } from 'antd'
import {BiCheckbox,BiCheckboxSquare} from 'react-icons/bi'
import {IoClose} from 'react-icons/io5'
import { Tooltip } from '@mui/material'
import { GetPropertyCategoryService } from '../../../services/database/databaseoptions/PropertyCategoryServices'
import { GetPropertyZoneService } from '../../../services/database/databaseoptions/PropertyZoneServices'
import { GetLocationBasedZoneService } from '../../../services/database/databaseoptions/LocationBasedZoneServices'
import { GetPropertyAmenitiesService } from '../../../services/database/databaseoptions/PropertyAmenitiesServices'
import { GetPropertyTypeService } from '../../../services/database/databaseoptions/PropertyTypeServices'
import { GetPropertyStatusService } from '../../../services/database/databaseoptions/PropertyStatusServices'
import { CreateManagedOfficeService, UpdateManagedOfficeService, UploadManagedOfficeAttachmentService } from '../../../services/database/mainoptions/ManagedOfficeServices'
import { GetFurnishTypeService } from '../../../services/database/databaseoptions/FurnishTypeServices'

function OfficeSpaceCE() {
  
  const [data,setdata] = useState({main_image:'',image1:'',image2:'',image3:'',image4:'',base_type:'',property_category:'',managed_office_name:'',zone:'',location_of_property:'',longitude:'',latitude:'',google_coordinate_link:'',managed_office_address:'',landmark_to_property:'',seater_offered:'',rent_per_seat:'',escalation:'',amenities_available:false,amenities:[],no_of_car_parking_slots:'',car_parking_charges:'',security_deposit:'',lock_in_period:'',lease_term_tenure:'',possession_type:'',facility_type:'',power_and_backup:'',total_built_up_area:'',no_of_floors:'',per_floor_area:'',area_offered:'',floor_offered:'',rent_per_sqft:'',maintenace_charges:'',interior_details:'',oc_availability:false,status:'',primary_contact_name:'',primary_contact_no:'',secondary_contact_name:'',secondary_contact_no:''})  
  const [error,seterror] = useState({main_image:'',image1:'',image2:'',image3:'',image4:'',base_type:'',property_category:'',managed_office_name:'',zone:'',location_of_property:'',longitude:'',latitude:'',google_coordinate_link:'',managed_office_address:'',landmark_to_property:'',seater_offered:'',rent_per_seat:'',escalation:'',amenities_available:false,amenities:[],no_of_car_parking_slots:'',car_parking_charges:'',security_deposit:'',lock_in_period:'',lease_term_tenure:'',possession_type:'',facility_type:'',power_and_backup:'',total_built_up_area:'',no_of_floors:'',per_floor_area:'',area_offered:'',floor_offered:'',rent_per_sqft:'',maintenace_charges:'',interior_details:'',oc_availability:false,status:'',primary_contact_name:'',primary_contact_no:'',secondary_contact_name:'',secondary_contact_no:''})  

  const [step,setstep] = useState(1)

  const [statusArr,setstatusArr] = useState([])
  const [baseTypeArr,setbaseTypeArr] = useState([])
  const [amenitiesArr,setamenitiesArr] = useState([])
  const [zonesArr,setzonesArr] = useState([])
  const [furnishTypeArr,setfurnishTypeArr] = useState([])
  const [propertyCategoryArr,setpropertyCategoryArr] = useState([])
  const [locationOfPropertyArr,setlocationOfPropertyArr] = useState([])


  const navigate = useNavigate()
  const  {state} = useLocation()


  useEffect(()=>{
    if(state?._id !== undefined){
        setdata(state)
    }
  },[state])


  async function submitform(){
    if(!data?.managed_office_name){
        seterror({...error,managed_office_name:'This Field is required*'})
    }else{
        let send_data = {...data}
        if(data?.base_type?.value !== undefined){
            send_data['base_type'] = data?.base_type?.value
        }
        if(data?.property_category?.value !== undefined){
            send_data['property_category'] = data?.property_category?.value
        }
        if(data?.zone?.value !== undefined){
            send_data['zone'] = data?.zone?.value
        }
        if(data?.location_of_property?.value !== undefined){
            send_data['location_of_property'] = data?.location_of_property?.value
        }
        if(data?.status?.value !== undefined){
            send_data['status'] = data?.status?.value
        }
        if(data?.facility_type?.value !== undefined){
            send_data['facility_type'] = data?.facility_type?.value
        }

        let arr = []
        if(data?.amenities?.length > 0){
            data?.amenities?.forEach((d)=>arr.push(d?.value))
            send_data['amenities'] = arr
        }

        if(data?._id === undefined){
            const response = await CreateManagedOfficeService(send_data)
            if(response?.status === 201){
                toast.success("Managed Office Created Successfully")
                resetform()
            }
        }else{
            const response = await UpdateManagedOfficeService(send_data,state?._id)
            if(response?.status === 200){
                toast.success("Managed Office Updated Successfully")
                resetform()
                navigate(-1)
            }
        }
    }
  }

  async function handlechange(e){
        setdata({...data,[e.target.name]:e.target.value})
        seterror({...error,[e.target.name]:''})
  }

  async function uploadfilefunc(v,name){

    const fd = new FormData()
    fd.append('image',v); 
    const response = await UploadManagedOfficeAttachmentService(fd)
    if(response?.status === 200){
      setdata({...data,[name]:response?.data?.data})
    }
  }

  async function getoptions(v,type) {
    if(type == 'base_type'){
        const response = await GetPropertyTypeService(1,'','',v,1)
        let arr = []
        let d = response?.data?.datas
        d?.forEach((d1)=>{
            arr?.push({label:d1?.name,value:d1?._id})
        })
        setbaseTypeArr(arr)
    }

    if(type == 'status'){
        const response = await GetPropertyStatusService(1,'','',v,1)
        let arr = []
        let d = response?.data?.datas
        d?.forEach((d1)=>{
            arr?.push({label:d1?.name,value:d1?._id})
        })
        setstatusArr(arr)
    }

    if(type == 'facility_type'){
        const response = await GetFurnishTypeService(1,'','',v,1)
        let arr = []
        let d = response?.data?.datas
        d?.forEach((d1)=>{
            arr?.push({label:d1?.name,value:d1?._id})
        })
        setfurnishTypeArr(arr)
    }

    if(type == 'amenities'){
        const response = await GetPropertyAmenitiesService(1,'','',v,1)
        let arr = []
        let d = response?.data?.datas
        d?.forEach((d1)=>{
            arr?.push({label:d1?.name,value:d1?._id})
        })
        setamenitiesArr(arr)
    }

    if(type == 'location_of_property'){
        const response = await GetLocationBasedZoneService(1,'','',v,1)
        let arr = []
        let d = response?.data?.datas
        d?.forEach((d1)=>{
            arr?.push({label:d1?.name,value:d1?._id})
        })
        setlocationOfPropertyArr(arr)
    }
    if(type == 'property_category'){
        const response = await GetPropertyCategoryService(1,'','',v,1)
        let arr = []
        let d = response?.data?.datas
        d?.forEach((d1)=>{
            arr?.push({label:d1?.name,value:d1?._id})
        })
        setpropertyCategoryArr(arr)
    }
    if(type == 'zone'){
        const response = await GetPropertyZoneService(1,'','',v,1)
        let arr = []
        let d = response?.data?.datas
        d?.forEach((d1)=>{
            arr?.push({label:d1?.name,value:d1?._id})
        })
        setzonesArr(arr)
    }
  }

  async function onChange(v,type){
    if(type == 'amenities'){
        let arr = []
        v?.forEach((v1) =>{
            let amenitiesFind = amenitiesArr?.find((f)=>f?.value == v1)
            if(amenitiesFind !== null && amenitiesFind !== undefined){
                arr?.push(amenitiesFind)
            }else{
             let amenitiesFind = data.amenities?.find((f)=>f?.value == v1)
              arr?.push(amenitiesFind)
            }
        })
        setdata({...data,amenities:arr})
    }
    if(type == 'zone'){
        let zoneFind = zonesArr?.find((f)=>f?.value == v)
        setdata({...data,zone:zoneFind})
    }
    if(type == 'facility_type'){
        let facilityTypeFind = furnishTypeArr?.find((f)=>f?.value == v)
        setdata({...data,facility_type:facilityTypeFind})
    }
    if(type == 'status'){
        let statusFind = statusArr?.find((f)=>f?.value == v)
        setdata({...data,status:statusFind})
    }
    if(type == 'base_type'){
        let baseTypeFind = baseTypeArr?.find((f)=>f?.value == v)
        setdata({...data,base_type:baseTypeFind})
    }
    if(type == 'property_category'){
        let propertyCategoryFind = propertyCategoryArr?.find((f)=>f?.value == v)
        setdata({...data,property_category:propertyCategoryFind})
    }
    if(type == 'location_of_property'){
        let locationofPropertyFind = locationOfPropertyArr?.find((f)=>f?.value == v)
        setdata({...data,location_of_property:locationofPropertyFind})
    }
 }

 function resetform(){
    setdata({main_image:'',image1:'',image2:'',image3:'',image4:'',base_type:'',property_category:'',managed_office_name:'',zone:'',location_of_property:'',longitude:'',latitude:'',google_coordinate_link:'',managed_office_address:'',landmark_to_property:'',seater_offered:'',rent_per_seat:'',escalation:'',amenities_available:false,amenities:[],no_of_car_parking_slots:'',car_parking_charges:'',security_deposit:'',lock_in_period:'',lease_term_tenure:'',possession_type:'',facility_type:'',power_and_backup:'',total_built_up_area:'',no_of_floors:'',per_floor_area:'',area_offered:'',floor_offered:'',rent_per_sqft:'',maintenace_charges:'',interior_details:'',oc_availability:false,status:'',primary_contact_name:'',primary_contact_no:'',secondary_contact_name:'',secondary_contact_no:''})
    seterror({main_image:'',image1:'',image2:'',image3:'',image4:'',base_type:'',property_category:'',managed_office_name:'',zone:'',location_of_property:'',longitude:'',latitude:'',google_coordinate_link:'',managed_office_address:'',landmark_to_property:'',seater_offered:'',rent_per_seat:'',escalation:'',amenities_available:false,amenities:[],no_of_car_parking_slots:'',car_parking_charges:'',security_deposit:'',lock_in_period:'',lease_term_tenure:'',possession_type:'',facility_type:'',power_and_backup:'',total_built_up_area:'',no_of_floors:'',per_floor_area:'',area_offered:'',floor_offered:'',rent_per_sqft:'',maintenace_charges:'',interior_details:'',oc_availability:false,status:'',primary_contact_name:'',primary_contact_no:'',secondary_contact_name:'',secondary_contact_no:''})
 }

  return (
    <div className='flex'>
        <div className='min-w-[180px] w-[180px] max-w-[180px]'>
        <DatabaseMenu /> 
        </div>
        <div className='ml-4 w-2/4 mt-4 pr-5 min-h-screen max-h-screen h-screen overflow-y-scroll'>
        <div>
        <GoBack />

        <div className='border-b   pb-2'>
        <h6 className='font-[800] mb-1'>Create / Edit Office Space</h6> 
        <h6 className='text-[11px] leading-tight font-[500] p-2 bg-slate-100 '>Use the below form to create or edit the office space for the property function.</h6> 
        </div> 


       <div className=''>
        <div className='flex  border-b text-[11px]'>
           <h6 onClick={()=>setstep(1)} className={`w-[90px] cursor-pointer p-[4px] text-center ${step == 1 && 'bg-slate-600 text-white'} font-[600]`}>Basic Info</h6>
           <h6 onClick={()=>setstep(2)} className={`w-[90px] cursor-pointer p-[4px] text-center ${step == 2 && 'bg-slate-600 text-white'} font-[600]`}>More Info</h6>
           <h6 onClick={()=>setstep(3)} className={`w-[90px] cursor-pointer p-[4px] text-center ${step == 3 && 'bg-slate-600 text-white'} font-[600]`}>Building Image</h6>
        </div>    

        {step === 1 && 
        <div className='flex items-start'>
            <div className='min-w-[48%] w-[48%] max-w-[48%] mr-[2%]'>


            <TextInput 
                mandatory={true}
                label={'Managed Office Name'}  
                variant="standard"
                name="managed_office_name"
                type="text"
                value={data.managed_office_name}
                error={error.managed_office_name}
                handlechange={handlechange}
                placeholder="Enter contact name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>

            <TextAreaInput1 
                mandatory={false}
                label={'Managed Office Address'}  
                variant="standard"
                name="managed_office_address"
                type="text"
                value={data.managed_office_address}
                error={error.managed_office_address}
                handlechange={handlechange}
                placeholder=""
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>  

            <TextInput 
                mandatory={false}
                label={'Longitude'}  
                variant="standard"
                name="longitude"
                type="text"
                value={data.longitude}
                error={error.longitude}
                handlechange={handlechange}
                placeholder="Enter contact name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>

            <TextInput 
                mandatory={false}
                label={'Latitude'}  
                variant="standard"
                name="latitude"
                type="text"
                value={data.latitude}
                error={error.latitude}
                handlechange={handlechange}
                placeholder="Enter contact name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>  

            <TextInput 
                mandatory={false}
                label={'Google Coordinate Link'}  
                variant="standard"
                name="google_coordinate_link"
                type="text"
                value={data.google_coordinate_link}
                error={error.google_coordinate_link}
                handlechange={handlechange}
                placeholder="Enter contact name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>  

                <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>Status*</h6>
                <Select 
                     bordered={false}
                     filterOption={false}
                     showSearch={true}
                     allowClear={true} 
                     options={statusArr}
                     value={data?.status}
                     optionFilterProp="children"
                     onChange={(v)=>onChange(v,'status')}
                     onSearch={(v)=>getoptions(v,'status')}
                    className='border-l-4 max-w-[400px] border-l-slate-600 w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'/>




            </div>

            <div className='min-w-[48%] w-[48%] max-w-[48%] ml-[2%]'>
                <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>Base Type*</h6>
                <Select 
                    bordered={false}
                    filterOption={false}
                    showSearch={true}
                    allowClear={true} 
                    options={baseTypeArr}
                    value={data?.base_type}
                    optionFilterProp="children"
                    onChange={(v)=>onChange(v,'base_type')}
                    onSearch={(v)=>getoptions(v,'base_type')}
                    className='border-l-4 max-w-[400px] border-l-slate-600 w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'/>

                <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>Property Category*</h6>
                <Select 
                     bordered={false}
                     filterOption={false}
                     showSearch={true}
                     allowClear={true} 
                     options={propertyCategoryArr}
                     value={data?.property_category}
                     optionFilterProp="children"
                     onChange={(v)=>onChange(v,'property_category')}
                     onSearch={(v)=>getoptions(v,'property_category')}
                    className='border-l-4 max-w-[400px] border-l-slate-600 w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'/>

                <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>Zone*</h6>
                <Select 
                    bordered={false}
                    filterOption={false}
                    showSearch={true}
                    allowClear={true} 
                    options={zonesArr}
                    value={data?.zone}
                    optionFilterProp="children"
                    onChange={(v)=>onChange(v,'zone')}
                    onSearch={(v)=>getoptions(v,'zone')}
                    style={{fontSize:'12px',height:'32px'}}
                    className='border-l-4 max-w-[400px] border-l-slate-600 w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'/>

<h6 className='text-[12px] font-semibold mb-1 mt-1.5'>Facility Type*</h6>
                <Select 
                    bordered={false}
                    filterOption={false}
                    showSearch={true}
                    allowClear={true} 
                    options={furnishTypeArr}
                    value={data?.facility_type}
                    optionFilterProp="children"
                    onChange={(v)=>onChange(v,'facility_type')}
                    onSearch={(v)=>getoptions(v,'facility_type')}
                    style={{fontSize:'12px',height:'32px'}}
                    className='border-l-4 max-w-[400px] border-l-slate-600 w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'/>


                <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>Location of Property*</h6>
                <Select 
                     bordered={false}
                     filterOption={false}
                     showSearch={true}
                     allowClear={true} 
                     options={locationOfPropertyArr}
                     value={data?.location_of_property}
                     optionFilterProp="children"
                     onChange={(v)=>onChange(v,'location_of_property')}
                     onSearch={(v)=>getoptions(v,'location_of_property')}
                    className='border-l-4 max-w-[400px] border-l-slate-600 w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'/>

                <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>Amenities Available*</h6>
                <div onClick={()=>setdata({...data,amenities_available:!data?.amenities_available})}>
                {!data?.amenities_available ?   
                <BiCheckbox size={20} className='text-slate-300' /> : 
                <BiCheckboxSquare size={20} className='text-slate-700' /> }
                </div>

                
                {data?.amenities_available &&
                <>
                <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>Amenities List*</h6>
                <div className='max-w-[400px]'>
                <Select 
                   mode='multiple'
                   bordered={false}
                   filterOption={false}
                   showSearch={true}
                   allowClear={true} 
                   options={amenitiesArr}
                   value={data?.amenities}
                   optionFilterProp="children"
                   onChange={(v)=>onChange(v,'amenities')}
                   onSearch={(v)=>getoptions(v,'amenities')}
                    className='w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'/>
                </div>
               
                </>}
            </div>


        </div>}

        {step === 2 && 
        <div className='flex items-start'>
            <div className='min-w-[48%] w-[48%] max-w-[48%] mr-[2%]'>


            <TextInput 
                mandatory={false}
                label={'No. of Car Parking Slots'}  
                variant="standard"
                name="no_of_car_parking_slots"
                type="text"
                value={data.no_of_car_parking_slots}
                error={error.no_of_car_parking_slots}
                handlechange={handlechange}
                placeholder="Enter contact name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>

            <TextAreaInput1 
                mandatory={false}
                label={'Car Parking Charges'}  
                variant="standard"
                name="car_parking_charges"
                type="text"
                value={data.car_parking_charges}
                error={error.car_parking_charges}
                handlechange={handlechange}
                placeholder=""
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>  

            <TextInput 
                mandatory={false}
                label={'Security Deposit'}  
                variant="standard"
                name="security_deposit"
                type="text"
                value={data.security_deposit}
                error={error.security_deposit}
                handlechange={handlechange}
                placeholder="Enter contact name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>

            <TextInput 
                mandatory={false}
                label={'Lock-in Period'}  
                variant="standard"
                name="lock_in_period"
                type="text"
                value={data.lock_in_period}
                error={error.lock_in_period}
                handlechange={handlechange}
                placeholder="Enter contact name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>  

            <TextInput 
                mandatory={false}
                label={'Lease Term / Tenure'}  
                variant="standard"
                name="lease_term_tenure"
                type="text"
                value={data.lease_term_tenure}
                error={error.lease_term_tenure}
                handlechange={handlechange}
                placeholder="Enter contact name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>  


<TextInput 
                mandatory={false}
                label={'Possession By'}  
                variant="standard"
                name="possession_type"
                type="text"
                value={data.possession_type}
                error={error.possession_type}
                handlechange={handlechange}
                placeholder="Enter contact name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>  

                


            </div>

            <div className='min-w-[48%] w-[48%] max-w-[48%] ml-[2%]'>
                
            <TextInput 
                mandatory={false}
                label={'Power & Backup'}  
                variant="standard"
                name="power_and_backup"
                type="text"
                value={data.power_and_backup}
                error={error.power_and_backup}
                handlechange={handlechange}
                placeholder="Enter contact name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>  

                
                <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>OC Availablility*</h6>
                <div onClick={()=>setdata({...data,oc_availability:!data?.oc_availability})}>
                {!data?.oc_availability ?   
                <BiCheckbox size={20} className='text-slate-300' /> : 
                <BiCheckboxSquare size={20} className='text-slate-700' /> }
                </div>

                <TextInput 
                mandatory={false}
                label={'Primary Contact Name'}  
                variant="standard"
                name="primary_contact_name"
                type="text"
                value={data.primary_contact_name}
                error={error.primary_contact_name}
                handlechange={handlechange}
                placeholder="Enter contact name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/> 

                {data?.base_type?.label === 'Seater Base' &&
                <>
                <TextInput 
                mandatory={false}
                label={'Seater Offered'}  
                variant="standard"
                name="seater_offered"
                type="text"
                value={data.seater_offered}
                error={error.seater_offered}
                handlechange={handlechange}
                placeholder="Enter contact name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>   

               <TextInput 
                mandatory={false}
                label={'Rent Per Seat'}  
                variant="standard"
                name="rent_per_seat"
                type="text"
                value={data.rent_per_seat}
                error={error.rent_per_seat}
                handlechange={handlechange}
                placeholder="Enter contact name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>   

               <TextInput 
                mandatory={false}
                label={'Escalation'}  
                variant="standard"
                name="escalation"
                type="text"
                value={data.escalation}
                error={error.escalation}
                handlechange={handlechange}
                placeholder="Enter contact name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>  
                </>}  

                 {data?.base_type?.label === 'Square feet Base' &&
                <>
                <TextInput 
                mandatory={false}
                label={'Security Deposit'}  
                variant="standard"
                name="security_deposit"
                type="text"
                value={data.security_deposit}
                error={error.security_deposit}
                handlechange={handlechange}
                placeholder="Enter contact name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>   

               <TextInput 
                mandatory={false}
                label={'Lock In Period'}  
                variant="standard"
                name="lock_in_period"
                type="text"
                value={data.lock_in_period}
                error={error.lock_in_period}
                handlechange={handlechange}
                placeholder="Enter contact name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>   

               <TextInput 
                mandatory={false}
                label={'Lease Term / Tenure'}  
                variant="standard"
                name="lease_term_tenure"
                type="text"
                value={data.lease_term_tenure}
                error={error.lease_term_tenure}
                handlechange={handlechange}
                placeholder="Enter contact name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>  

                <TextInput 
                mandatory={false}
                label={'Total Built-up Area'}  
                variant="standard"
                name="total_built_up_area"
                type="text"
                value={data.total_built_up_area}
                error={error.total_built_up_area}
                handlechange={handlechange}
                placeholder="Enter contact name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/> 

                <TextInput 
                mandatory={false}
                label={'No. of Floors'}  
                variant="standard"
                name="no_of_floors"
                type="text"
                value={data.no_of_floors}
                error={error.no_of_floors}
                handlechange={handlechange}
                placeholder="Enter contact name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/> 

                <TextInput 
                mandatory={false}
                label={'Per Floor Area'}  
                variant="standard"
                name="per_floor_area"
                type="text"
                value={data.per_floor_area}
                error={error.per_floor_area}
                handlechange={handlechange}
                placeholder="Enter contact name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/> 

                <TextInput 
                mandatory={false}
                label={'Area Offered'}  
                variant="standard"
                name="area_offered"
                type="text"
                value={data.area_offered}
                error={error.area_offered}
                handlechange={handlechange}
                placeholder="Enter contact name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>  

<TextInput 
                mandatory={false}
                label={'Floor Offered'}  
                variant="standard"
                name="floor_offered"
                type="text"
                value={data.floor_offered}
                error={error.floor_offered}
                handlechange={handlechange}
                placeholder="Enter contact name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>  

                <TextInput 
                mandatory={false}
                label={'Rent per Square feet'}  
                variant="standard"
                name="rent_per_sqft"
                type="text"
                value={data.rent_per_sqft}
                error={error.rent_per_sqft}
                handlechange={handlechange}
                placeholder="Enter contact name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>  
                </>}     

                <TextInput 
                mandatory={false}
                label={'Maintenance Charges'}  
                variant="standard"
                name="maintenace_charges"
                type="text"
                value={data.maintenace_charges}
                error={error.maintenace_charges}
                handlechange={handlechange}
                placeholder="Enter contact name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>  

            <TextInput 
                mandatory={false}
                label={'Secondary Contact Name'}  
                variant="standard"
                name="secondary_contact_name"
                type="text"
                value={data.secondary_contact_name}
                error={error.secondary_contact_name}
                handlechange={handlechange}
                placeholder="Enter contact name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/> 


                <TextInput 
                mandatory={false}
                label={'Secondary Contact No'}  
                variant="standard"
                name="secondary_contact_no"
                type="text"
                value={data.secondary_contact_no}
                error={error.secondary_contact_no}
                handlechange={handlechange}
                placeholder="Enter contact name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>  

            </div>


        </div>}

        {step === 3 && 
        <div className='flex flex-wrap'>

        <div className='min-w-[48%] w-[48%] max-w-[48%] mt-2  mr-[2%]'>
        <h6 className='text-[11px] font-[600] mb-1' >Main Image</h6>
        {(data.main_image === '' || data.main_image == null) ?
            <form onClick={()=>document.querySelector(`.input-field`).click()} className={`p-4 flex flex-col items-center justify-center cursor-pointer border border-l-4 border-l-slate-700 border-slate-300 `}>
                <input type='file' onChange={({target:{files}})=>{
                files[0] && uploadfilefunc(files[0],'main_image')
                }} accept="*" className='input-field' hidden />
            </form> 
        :
            <div className='p-2 border border-slate-300 relative border-l-4 border-l-slate-700 flex flex-col  cursor-pointer'>
                <Tooltip title='Delete'><IoClose className='absolute top-3 right-2' onClick={()=>setdata({...data,main_image:''})}/></Tooltip>
                <h6 className='text-[12px] truncate w-[88%] ml-0'>{data?.main_image}</h6>
            </div>
            
        }
        </div>


        <div className='min-w-[48%] w-[48%] max-w-[48%] mt-2  ml-[2%]'>
        <h6 className='text-[11px] font-[600] mb-1' >Image 1</h6>
        {(data.image1 === '' || data.image1 == null) ?
            <form onClick={()=>document.querySelector(`.input-field1`).click()} className={`p-4 flex flex-col items-center justify-center cursor-pointer border border-slate-300 `}>
                <input type='file' onChange={({target:{files}})=>{
                files[0] && uploadfilefunc(files[0],'image1')
                }} accept="*" className='input-field1' hidden />
            </form> 
        :
            <div className='p-2 border border-slate-300 relative flex flex-col  cursor-pointer'>
                <Tooltip title='Delete'><IoClose className='absolute top-3 right-2' onClick={()=>setdata({...data,image1:''})}/></Tooltip>
                <h6 className='text-[12px] w-[88%] truncate w-48 ml-0'>{data?.image1}</h6>
            </div>
            
        }
        </div>

        <div className='w-[48%] mt-2  mr-[2%]'>
        <h6 className='text-[11px] font-[600] mb-1' >Image 2</h6>
        {(data.image2 === '' || data.image2 == null) ?
            <form onClick={()=>document.querySelector(`.input-field2`).click()} className={`p-4 flex flex-col items-center justify-center cursor-pointer border border-slate-300 `}>
                <input type='file' onChange={({target:{files}})=>{
                files[0] && uploadfilefunc(files[0],'image2')
                }} accept="*" className='input-field2' hidden />
            </form> 
        :
            <div className='p-2 border border-slate-300 relative flex flex-col  cursor-pointer'>
                <Tooltip title='Delete'><IoClose className='absolute top-3 right-2' onClick={()=>setdata({...data,image2:''})}/></Tooltip>
                <h6 className='text-[12px] w-[88%] truncate w-48 ml-0'>{data?.image2}</h6>
            </div>
        }
        </div>

        <div className='w-[48%] mt-2  ml-[2%]'>
        <h6 className='text-[11px] font-[600] mb-1' >Image 3</h6>
        {(data.image3 === '' || data.image3 == null) ?
            <form onClick={()=>document.querySelector(`.input-field3`).click()} className={`p-4 flex flex-col items-center justify-center cursor-pointer border border-slate-300 `}>
                <input type='file' onChange={({target:{files}})=>{
                files[0] && uploadfilefunc(files[0],'image3')
                }} accept="*" className='input-field3' hidden />
            </form> 
        :
            <div className='p-2 border border-slate-300 relative flex flex-col  cursor-pointer'>
                <Tooltip title='Delete'><IoClose className='absolute top-3 right-2' onClick={()=>setdata({...data,image3:''})}/></Tooltip>
                <h6 className='text-[12px] w-[88%] truncate w-48 ml-0'>{data?.image3}</h6>
            </div>
        }
        </div>

        <div className='w-[48%] mt-2  mr-[2%]'>
        <h6 className='text-[11px] font-[600] mb-1' >Image 4</h6>
        {(data.image4 === '' || data.image4 == null) ?
            <form onClick={()=>document.querySelector(`.input-field4`).click()} className={`p-4 flex flex-col items-center justify-center cursor-pointer border  border-slate-300 `}>
                <input type='file' onChange={({target:{files}})=>{
                files[0] && uploadfilefunc(files[0],'image4')
                }} accept="*" className='input-field4' hidden />
            </form> 
        :
            <div className='p-2 border border-slate-300 relative flex flex-col  cursor-pointer'>
                <Tooltip title='Delete'><IoClose className='absolute top-3 right-2' onClick={()=>setdata({...data,image4:''})}/></Tooltip>
                <h6 className='text-[12px] w-[88%] truncate w-48 ml-0'>{data?.image4}</h6>
            </div>
        }
        </div>

        </div>}

        <div className='flex mt-2 justify-end border-t pt-2'>
            <ButtonOutlinedAutoWidth  btnName="Cancel" onClick={()=>navigate(-1)} />  
            <h6 className='w-[10px]'></h6>
            <ButtonFilledAutoWidth  btnName="Save" onClick={submitform} />  
        </div>  
        </div>  

        </div> 
    </div >
    </div>

  )
}

export default OfficeSpaceCE