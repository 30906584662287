import { deleteRequest, get, post, postfd, put } from "../helpers/apihelpers";

export const CreateMomTaskService=async(data)=>{
    try {
        const result = await post(`api/mom_task/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateMomTaskService=async(data,id)=>{
    try {
        const result = await put(`api/mom_task/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateMomItemSummaryService=async(id,data)=>{
    try {
        const result = await put(`api/mom_task/update_mom_item_summary/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetMomTaskService=async(page,from_date,to_date,search_text,type)=>{
    try {
        const result = await get(`api/mom_task/get?page=${page}&type=${type}&from_date=${from_date}&to_date=${to_date}&search_text=${search_text}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetMomListTaskService=async(page,from_date,to_date,search_text,type,department)=>{
    try {
        const result = await get(`api/mom_task/get_task?page=${page}&type=${type}&from_date=${from_date}&to_date=${to_date}&search_text=${search_text}&department=${department}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetMomAdminTaskService=async(page,from_date,to_date,search_text,type,user)=>{
    try {
        const result = await get(`api/mom_task/get_admin?page=${page}&type=${type}&from_date=${from_date}&to_date=${to_date}&search_text=${search_text}&user=${user}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetMomAdminListTaskService=async(page,from_date,to_date,search_text,type,department,user)=>{
    try {
        const result = await get(`api/mom_task/get_task_admin?page=${page}&type=${type}&from_date=${from_date}&to_date=${to_date}&search_text=${search_text}&department=${department}&user=${user}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetMomDetailTaskService=async(id)=>{
    try {
        const result = await get(`api/mom_task/get/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const FilterDailyTaskService=async({stage,step,page})=>{
    try {
        const result = await get(`api/mom_task/filter?related_to=${stage}&stage=${step}&page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteMomTaskService=async(id)=>{
    try {
        const result = await deleteRequest(`api/mom_task/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteMomItemTaskService=async(id)=>{
    try {
        const result = await deleteRequest(`api/mom_task/delete_mom_item/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UploadMomTaskAttachmentService=async(data)=>{
    try {
        const result = await postfd(`api/mom_task/upload_file`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}


export const DownloadMOMTaskService=async(user,from_date,to_date,department)=>{
    try {
        const result = await get(`api/mom_task/download_excel?user=${user}&from_date=${from_date}&to_date=${to_date}&department=${department}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetMOMTaskItemSummaryService=async(id,page)=>{
    try {
        const result = await get(`api/mom_task/get_mom_item_summary/${id}?page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteMOMTaskItemSummaryService=async(id)=>{
    try {
        const result = await deleteRequest(`api/mom_task/delete_mom_item_summary/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}
