import React,{useEffect, useState} from 'react'
import { toast } from 'react-hot-toast'
import {  ButtonFilledAutoWidth } from '../../components/button'
import { useNavigate, useLocation } from 'react-router-dom'
import SettingsMenu from '../staticscreens/SettingsMenu'
import GoBack from '../../components/back/GoBack'
import { CreateKRAService, GetKRABasedDesignationService, UpdateKRAService, UplaodKRAService } from '../../services/KRAServices'
import { Select } from 'antd'
import { GetDesignationService } from '../../services/DesignationService'
import ErrorComponent from '../../components/errorDesign/ErrorComponent'
import Uploader from '../../components/Uploader'
import { AiOutlineDelete } from 'react-icons/ai'
import { CiFileOn } from "react-icons/ci";


function KRACE() {

  const [file,setfile] = useState('');  
 
  const [data,setdata] = useState({designation:'',attachments:[]});  
  const [error,seterror] = useState({designation:'',attachments:''}); 
  
  const [designationArr,setdesignationArr] = useState([])

  const {state} = useLocation();
  const navigate = useNavigate();


  useEffect(()=>{
    if(state?._id !== null && state?._id !== undefined){
      setdata({...data,_id:state?._id,designation:{label:state.designation?.designation_name,value:state?.designation?.id},attachments:state?.attachments})
    }
    getdesignation()
  },[state])

  async function getdesignation() {
    const response = await GetDesignationService()
    let d = response?.data?.datas 
    let arr = []
    d?.forEach((d1)=>{
      arr?.push({label:d1?.designation_name,value:d1?.id})
    })
    setdesignationArr(arr)
  }


  function handleSelect(e,e1){
     getdatalist(e)
     setdata({...data,designation:designationArr?.find((i)=>i.value === e)})
     seterror({...error,designation:''})
  }  

  async function submitform(){
    if(!data.designation){
        seterror({...error,designation:'Designation field is required *'})
    }else if(data.attachments.length === 0){
      seterror({...error,attachments:'This field is required *'})
    }else{
        if(data?._id === undefined || data?._id === null){
          let sendData ={
            designation:data?.designation?.value,
            attachments:data?.attachments
          }
        const response = await  CreateKRAService(sendData)
        if (response.status === 201) {
          resetform()
          toast.success('KRA Created Successfully')
        }   
        if(response.status === 422){
          seterror({...error,department_name:response?.data?.errors?.department_name})
        } 
      }else{
        let send_data = {...data}
        send_data['designation'] = data?.designation?.value
        send_data['attachments'] = data?.attachments

        send_data["_id"] = data?._id
        const response = await UpdateKRAService(send_data,data?._id)
        if (response.status === 200) {
          resetform()
          navigate(-1)
          toast.success('KRA Updated Successfully')
        }   
        if(response.status === 422){
          seterror({...error,department_name:response?.data?.errors?.department_name})
        } 
      }
  }
  }

  async function getdatalist(id) {
    const response = await GetKRABasedDesignationService(id)
    if(response?.data?.datas?._id !== undefined){
      setdata({...data,_id:response?.data?.datas?._id,designation:designationArr?.find((d)=>d?.value == id),attachments:response?.data?.datas?.attachments})
    }else{
      setdata({...data,designation:designationArr?.find((d)=>d?.value == id),attachments:[]})
    }
 }   


  async function uploadfile(v,name){
    const fd = new FormData()
    fd.append('file',v); 
    const response = await UplaodKRAService(fd)
    if(response?.status === 200){
      toast.success("File uploaded Successfully!")
      setdata({...data,attachments:[...data.attachments,response?.data?.data]})
      seterror({...error,attachments:''})
    }
  }

  function resetform(){
    setdata({designation:'',attachments:[]})
    seterror({designation:'',attachments:''})
  }

  async function openFile(v){
    window.open(`${process.env.REACT_APP_AWS_IMAGE_URL}${v?.replace('public/','')}`,'_blank')
  }


  function deleteData(index){
    let attachments = [...data.attachments] 
    attachments?.splice(index,1)
    setdata({...data,attachments:attachments})
  }


  return (
    <div className='flex '>


      <SettingsMenu />
   
    <div className='sm:w-full lg:w-72 px-4 pt-5' >


        <GoBack /> 

        <h6 className='font-[700]'>{(state?.id !== null && state?.id !== undefined) ? 'Edit' : 'Add'} KRA</h6>
        <h6 className='text-[10px] bg-slate-100 p-2 font-[500] leading-snug' >Use the below form to create or edit the <b> KRA</b> for your comapny employees.</h6>
     
        <h6 className='text-[12px] font-semibold mb-1 mt-1'>Designation </h6>
              <Select
                value={data.designation} 
                placeholder="" 
                bordered={false}
                className='w-full border border-gray-300 outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'
                onChange={(e)=>handleSelect(e,'Designation')} 
                options={designationArr} 
                
                />

                <ErrorComponent error={error?.designation} />

          
          {data?.designation &&
          <>
              <h6 className='text-[11px] font-[600] mb-1 mt-2' >KRA (Docs)</h6>    
              <Uploader image={file} name="resume"  setimagefunc={(e)=>{uploadfile(e)}}  removeimageuploadfunc = {()=>{setfile('')}}/> 

              <ErrorComponent error={error?.attachments} />
            


           <div>
            <h6 className='text-[11px] font-[600] mb-1 mt-2'>KRA Docs ({data?.attachments?.length})</h6>

           <div className='border'>
            <div className='flex bg-slate-100 text-[11px]'>
               <h6 className='p-1 border-r font-[700] w-[20%]'>SL NO </h6>
               <h6 className='p-1 border-r font-[700] w-[50%]'>FILE </h6>
               <h6 className='p-1 font-[700] w-[30%]'>ACTIONS </h6>
            </div>

            {data?.attachments?.map((a,i)=>(
            <div key={i} className={`flex text-[11px] border-t`}>
               <h6 className='p-1 border-r w-[20%]'>{i+1} </h6>
               <h6 className='p-1 border-r w-[50%]'>
                <CiFileOn onClick={()=>openFile(a)} />
               </h6>
               <h6 className='p-1 w-[30%]'>
                <AiOutlineDelete  onClick={()=>deleteData(i)}/>
               </h6>
            </div>
            ))}
           </div>
           </div>
          </>}
        
    

        <div className='mt-5'>
        <ButtonFilledAutoWidth btnName={(data?._id !== null && data?._id !== undefined) ? "UPDATE KRA" : "ADD KRA"}  onClick={submitform} />  
        </div>


    </div>
    </div>
  )
}

export default KRACE