import React, { useState,useEffect } from 'react'
// import { GetStageService } from '../../../services/StageServices';
import {  ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button';
// import {  GetLeadOptionsService } from '../../../services/LeadOptionServices';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import {AiOutlineClose, AiOutlineDelete, AiOutlineEdit} from 'react-icons/ai';  
import { MdOutlineAlarm } from "react-icons/md";
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { IconButton, Tooltip } from '@material-ui/core';
import TaskMenu from './TaskMenu';
import { GetStageService } from '../../services/StageServices';
import { DownloadDailyTaskService, FilterDailyTaskService, GetDailyTaskService } from '../../services/DailyTaskServices';
import { DatePicker, Modal, Select } from 'antd';
import TeamTaskList from './TeamTaskList';
import { useSelector } from 'react-redux';
import { RiFileExcel2Line } from "react-icons/ri";
import fileDownload from "js-file-download";
import {BiErrorCircle} from 'react-icons/bi'
import axios from 'axios';
import { CreateReminderService, DeleteReminderService, GetReminderService, UpdateReminderService } from '../../services/ReminderServices';
import toast from 'react-hot-toast';
import { TextAreaInput1, TextInput1 } from '../../components/input';
import { GetDepartmentService } from '../../services/DepartmentServices';

function TaskList() {

  const {pathname} = useLocation()
  const navigator = useNavigate()
  const path1 = pathname.split('/')[pathname.split('/').length - 2]
  const [search,setsearch] = useState({text:'',from_date:'',to_date:'',from_date1:'',to_date1:'',activate:false,department:''})

  const roles = useSelector(state=>state.Auth.roles)

  const userId = useSelector(state=>state.Auth.id)



  const [data1,setdata1] = useState({title:'',description:'',date:'',date1:'',time:'',added:false,expired:false})
  const [error1,seterror1] = useState({title:'',description:'',date:'',date1:'',time:'',added:false,expired:false})
  
  const [step,setstep] = useState(1)
  const [modal,setmodal] = useState(false)
  const [modal1,setmodal1] = useState(false)
  

  const [stages,setstages] = useState([])
  const [page,setpage] = useState(1)
  const [selectedData,setselectedData] = useState({})

  const [data,setdata] = useState([])
  const [departments,setdepartments] = useState([])

  const [reminderData,setreminderData] = useState([])



  useEffect(()=>{
    getfollowupStages()
    getdepartments()
  },[])

  useEffect(()=>{
    getdata(stages,1)
  },[step])
  



  useEffect(()=>{
    getfollowupStages()
    setstep(1)
  },[path1])

  useEffect(()=>{
      getdata(stages,page)
  },[page,path1])
  
  const getfollowupStages = async() => {
    const response = await GetStageService('Task_Stages')
    setstages(response.data.data.datas)
    getdata(response.data.data.datas,1)
  }

  async function getdepartments() {
    const response = await GetDepartmentService()
    let d = response?.data?.datas
    let arr = []
    d?.forEach((d1)=>{
      if(!['Finance Team']?.includes(d1?.department_name)){
        arr.push({value:d1.id,label:d1.department_name})
      }
    })
    setdepartments(arr)
  }
  

   async function getdata(stages,page){
     if(stages?.length !== 0){ 
        if(path1 === 'daily_tasks'){
            const response = await GetDailyTaskService({stage:stages?.find(s=>s?.name === 'Daily Tasks')?._id,step:step,page:page,from_date:search?.from_date1,to_date:search?.to_date1,search:search?.text,department:search?.department})
            setdata(response.data)
        }else if(path1 === 'project_work'){
            const response = await GetDailyTaskService({stage:stages?.find(s=>s?.name === 'Project Work')?._id,step:step,page:page})
            setdata(response.data)
        }else if(path1 === 'external_work'){
            const response = await GetDailyTaskService({stage:stages?.find(s=>s?.name === 'External Work')?._id,step:step,page:page})
            setdata(response.data)
        }else if(path1 === 'assigned_work'){
            const response = await GetDailyTaskService({stage:stages?.find(s=>s?.name === 'Assigned Work')?._id,step:step,page:page})
            setdata(response.data)
        }
     }
    
   }

   async function getdata1(stages,page){
    if(stages?.length !== 0){ 
       if(path1 === 'daily_tasks'){
           const response = await FilterDailyTaskService({stage:stages?.find(s=>s?.name === 'Daily Tasks')?._id,step:step,page:page,from_date:search?.from_date1,to_date:search?.to_date1,search:search?.text,department:search?.department})
           setdata(response.data)
       }else if(path1 === 'project_work'){
           const response = await FilterDailyTaskService({stage:stages?.find(s=>s?.name === 'Project Work')?._id,step:step,page:page,from_date:search?.from_date1,to_date:search?.to_date1,search:search?.text})
           setdata(response.data)
       }else if(path1 === 'external_work'){
           const response = await FilterDailyTaskService({stage:stages?.find(s=>s?.name === 'External Work')?._id,step:step,page:page,from_date:search?.from_date1,to_date:search?.to_date1,search:search?.text})
           setdata(response.data)
       }else if(path1 === 'assigned_work'){
           const response = await FilterDailyTaskService({stage:stages?.find(s=>s?.name === 'Assigned Work')?._id,step:step,page:page,from_date:search?.from_date1,to_date:search?.to_date1,search:search?.text})
           setdata(response.data)
       }
    }
   
  }

//    console.log("data",data)

  const gotocreate = () => {
    if(path1 === 'daily_tasks'){
        navigator('create',{state:{type:path1,stage:stages?.find(s=>s?.name === 'Daily Tasks')}})
    }else if(path1 === 'project_work'){
        navigator('create',{state:{type:path1,stage:stages?.find(s=>s?.name === 'Project Work')}})
    }else if(path1 === 'external_work'){
        navigator('create',{state:{type:path1,stage:stages?.find(s=>s?.name === 'External Work')}})
    }else if(path1 === 'assigned_work'){
        navigator('create',{state:{type:path1,stage:stages?.find(s=>s?.name === 'Assigned Work')}})
    }
  }

  const gotoedit = (d) => {
    if(path1 === 'daily_tasks'){
        navigator('edit',{state:{type:path1,stage:stages?.find(s=>s?.name === 'Daily Tasks'),data:d}})
    }else if(path1 === 'project_work'){
        navigator('edit',{state:{type:path1,stage:stages?.find(s=>s?.name === 'Project Work'),data:d}})
    }else if(path1 === 'external_work'){
        navigator('edit',{state:{type:path1,stage:stages?.find(s=>s?.name === 'External Work'),data:d}})
    }else if(path1 === 'assigned_work'){
        navigator('edit',{state:{type:path1,stage:stages?.find(s=>s?.name === 'Assigned Work'),data:d}})
    }
  }

  async function downloadExcel(){
    const response = await DownloadDailyTaskService(userId,search?.from_date1,search?.to_date1)
    let d = response?.data
    if(response?.status === 200){
        handleDownload(`${process.env.REACT_APP_AWS_IMAGE_URL}${d?.path?.replace('public/','')}`,d?.path?.split('/')[d?.path?.split('/')?.length - 1])
    }
   }

   const handleDownload = (url, filename) => {
    axios.get(url, {
        responseType: 'blob',
    })
        .then((res) => {
            fileDownload(res.data, filename)
            toast.success("File Downloaded")
        })
}
   

  async function applyFilter(){
    getdata1(stages,1)
  }

  async function handlechange2(e){
    setdata1({...data1,[e.target.name] : e.target.value})
    seterror1({...error1,[e.target.name] : ''})
  }

  async function resetfilter(){
    setpage(1)
    setsearch({text:'',from_date:'',from_date1:'',to_date:'',to_date1:'',activate:false,department:''})
    getdata(stages,1)
  }

  async function deletereminderfun(v){
   const response = await DeleteReminderService(v)
   if(response?.status === 200){
    toast.success("Reminder Deleted Successfully")
    getReminderList(selectedData?._id)
   }
  }


  async function getReminderList(v){
     const response = await GetReminderService("TaskSchema",v,1)
     setreminderData(response?.data?.datas)
  }

  async function addreminder(){
    if(!data1.date){
        seterror1({...error1,date:'This Field is required'})
        setdata1({...data1,added:false})
      }else if(!data1.time){
        seterror1({...error1,time:'This Field is required'})
        setdata1({...data1,added:false})
      }else if(!data1.title){
        seterror1({...error1,title:'This Field is required'})
        setdata1({...data1,added:false})
      }else if(!data1.description){
        seterror1({...error1,description:'This Field is required'})
        setdata1({...data1,added:false})
      }else{
        // console.log("5")
          setdata1({...data1,added:true})
          let oldData = {...data1}
          oldData['on_date_time'] = data1.date + ' ' + data1.time
          oldData['type'] = 'TaskSchema'
          oldData['type_id'] = selectedData?._id
          oldData['repeat'] = 'Once'
          oldData['notify'] = 'Email'
          delete oldData.date
          delete oldData.time
          delete oldData.added
          delete oldData.expired

          if(data1?.id === undefined){
            const response = await CreateReminderService(oldData)
            if(response?.status === 200){
                toast.success("Reminder Created Successfully")
                setmodal1(false)
                getReminderList(selectedData?._id)
                setdata1({title:'',description:'',date:'',date1:'',time:'',added:false,expired:false})
                seterror1({title:'',description:'',date:'',date1:'',time:'',added:false,expired:false})
            }
          }else{
            const response = await UpdateReminderService(oldData,data?.id)
            if(response?.status === 200){
                toast.success("Reminder Updated Successfully")
                setmodal1(false)
                getReminderList(selectedData?._id)
                setdata1({title:'',description:'',date:'',date1:'',time:'',added:false,expired:false})
                seterror1({title:'',description:'',date:'',date1:'',time:'',added:false,expired:false})
            }
          }
      }
  }

  async function resetreminderdata(){
    setdata1({title:'',description:'',date:'',date1:'',time:'',added:false,expired:false})
    seterror1({title:'',description:'',date:'',date1:'',time:'',added:false,expired:false})
  }




  return (
    <div className='w-[98%] h-screen overflow-hidden'>
        <Modal className='absolute top-0 left-[32%]' open={modal} footer={false} closable={false}>
            <div>
                <div className='flex justify-between'>
                    <h6 className='text-[13px] font-[700]'>Task Reminder ({reminderData?.length})</h6>
                     <div className='flex items-center'>
                    <h6 onClick={()=>{resetreminderdata();setmodal1(!modal1)}} className="cursor-pointer text-[9.5px] bg-green-700  p-[4px] font-[700] rounded px-[10px] text-white  ml-1">Reminder</h6> 
                    <AiOutlineClose className='ml-2 bg-slate-200 p-[5px] rounded-[4px]' size={20} onClick={()=>setmodal(!modal)} />
                    </div>
                </div>
                
                <div className='grid mt-2 max-h-[200px] overflow-y-scroll  no-scrollbar grid-cols-2 gap-2'>
                {reminderData?.map((r)=>(
                <div className='border p-2 relative'>
                    <div className='flex absolute top-0 right-0 border-l border-b'>
                        <AiOutlineDelete onClick={()=>deletereminderfun(r?._id,r?.type_id)} size={20} className="border-r p-[4px]" />
                        <AiOutlineEdit onClick={()=>{setdata1({id:r?._id,title:r?.title,description:r?.description,date:r?.on_date_time?.slice(0,10),date1:'',time:r?.on_date_time?.slice(11,16),added:false,expired:false});setmodal1(true)}} size={20} className='p-[4px]' />
                    </div>
                    <h6 className='text-[11px]'>Title : <span className='font-[700]'>{r?.title}</span> </h6>
                    <h6 className='text-[10px]'>Description : {r?.description}</h6>
                    
                    <h6 className="text-[8px] bg-slate-100 p-2">Reminder Date Time : <span className='font-[700]'>{moment(r?.on_date_time)?.format('LLL')}</span></h6>
                </div>))}
                </div>
            </div>

        </Modal>

        <Modal className='absolute top-0 left-[42%]' open={modal1} width={280} footer={false} closable={false}>
           <h6 className='text-[14px] font-[700]'>Create / Update Reminder for the task</h6>  
            <h6 className='bg-slate-100 text-[10px] font-[500] p-1'>Use the below form to create the reminder to trigger for you</h6> 
           
              <div className='flex justify-between'>
              <div className='w-[48%]'>
              <h6 className='text-[11px] font-[600] mb-1 mt-2' >Date</h6>   
              <div className='border border-slate-300 '>
              <input type='date' value={data1?.date} onChange={(v) => {setdata1({...data1,date:v.target.value});seterror1({...error1,date:''})}}  placeholder='' bordered={false} className='text-[12px] px-1 py-1.5 focus:outline-none w-[100%]' /> 
             
              </div>
              {error1?.date !== '' &&
              <div className='flex items-center mt-1'>
                  <BiErrorCircle className='text-red-500' size={14} />
                  <span className='text-[10px] text-red-500 ml-1'>{error1?.date}</span>
              </div>}
              </div>

              <div className='w-[48%]'>
              <h6 className='text-[11px] font-[600] mb-1 mt-2' >Time</h6>   
              <div className='border border-slate-300 '>
              <input type='time' value={data1?.time} onChange={(v) => {setdata1({...data1,time:v.target.value});seterror1({...error1,time:''})}}  placeholder='' bordered={false} className='text-[12px] px-1 py-1.5 focus:outline-none w-[100%]' /> 
              
              </div>
              {error1?.time !== '' &&
              <div className='flex items-center mt-1'>
                  <BiErrorCircle className='text-red-500' size={14} />
                  <span className='text-[10px] text-red-500 ml-1'>{error1?.time}</span>
              </div>}
            </div>
            </div>

            <TextInput1 
             mandatory={true}
             label={'Title'}  
             variant="standard"
             name="title"
             type="text"
             value={data1?.title}
             error={error1?.title}
             handlechange={handlechange2}
             placeholder=""
             InputLabelProps={{
                 style: { color: '#fff', }, 
             }}
            />


            <TextAreaInput1 
                mandatory={true}
                label={'Description'}  
                variant="standard"
                name="description"
                type="text"
                value={data1?.description}
                error={error1?.description}
                handlechange={handlechange2}
                placeholder=""
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>


<div className='flex mt-2 border-t pt-2 w-full items-end '>
                <div className='mr-2'>
                <ButtonOutlinedAutoWidth btnName="Cancel" onClick={()=>setmodal1(false)} />
                </div>
                <ButtonFilledAutoWidth btnName="Save" onClick={()=>addreminder()} />
            </div>

        </Modal>
        
        {path1  !== 'projects' ?
        <div className='flex'>
            <div className='min-w-44'>
            <TaskMenu />
            </div>
            <div className='w-[100%] pl-5 mt-4'>
            <div className='flex items-center -mt-1 border-b justify-between pb-1'>
                <h6 className='font-[800] text-[13px]'>Total {path1 === 'assigned_work' && 'Assigned Work'} {path1 === 'daily_tasks' && 'Daily Tasks'} {path1 === 'project_work' && 'Project Work'} {path1 === 'external_work' && 'External Work'}   ({data?.pagination?.total})</h6>

                <div className='flex items-center text-[12px]'>

                    <h6 className='mr-2 font-[600]'>{page == 1 ? data?.datas?.length > 0 ? 1 : 0 : (page - 1) * data?.pagination?.limit } - {data?.pagination?.limit} of {data?.pagination?.total}  </h6>

                    <div>
                    <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                    <IconButton onClick={()=>{ page < data?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(data?.pagination?.totalPages === page || data?.datas?.length === 0) ? 'opacity-50 ' : 'opacity-100'}`}  size={16} /></IconButton>

                    </div>
                    <DatePicker  size='small' style={{fontSize:'11px'}} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.from_date} onChange={(v,v1) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,from_date:v,from_date1:v1})}} /> 

                    <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.to_date} onChange={(v,v1) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,to_date:v,to_date1:v1})}} /> 

                    
                    <div className='mr-2'>
                        

                    <input  id="search_text" placeholder='Search text' type='text' value={search.text} onChange={(e)=>setsearch({...search,text:e.target.value})} className='border py-[3px] focus:ring-0 focus:outline-0 text-[14px]  w-[100px] px-1 rounded-md border-slate-300' />
                

                    </div> 

                    <Select 
                        bordered={false}
                        value={search?.department !== '' ? search?.department : null}
                        options={departments}
                        onChange={(e)=>setsearch({...search,department:e})}
                        placeholder={'Department'}
                        className='border rounded-[6px] border-slate-300 h-[30px] mr-2 w-[100px]' 
                        
                        />   


                    <div className='mr-2'>
                    <ButtonOutlinedAutoWidth btnName="Apply Filter" onClick={()=>applyFilter()} /> 
                    </div>
                    <div className='mr-2'>
                    <ButtonOutlinedAutoWidth btnName="Reset Filter" onClick={()=>resetfilter()} /> 
                    </div>
                    {(path1 === 'assigned_work' && roles?.includes('task_manager')) ? 
                    <ButtonFilledAutoWidth btnName="Add New" onClick={()=>gotocreate()} />
                    :
                    path1 !== 'assigned_work' && 
                    <ButtonFilledAutoWidth btnName="Add New" onClick={()=>gotocreate()} />
                     }

                    <Tooltip title="Download Excel">
                    <span onClick={downloadExcel}>
                    <RiFileExcel2Line size={28} className='bg-slate-600 cursor-pointer text-white p-[7px] rounded-[4px] ml-2' />
                    </span>
                    </Tooltip>
                   

                </div>
            </div>

          
            <div className='flex  items-center border-b'>
                <h6 onClick={()=>setstep(1)} className={`font-[600] ${step === 1 && 'bg-slate-600 text-white'} text-center cursor-pointer py-1 w-[80px] text-[11px]`}>Pending</h6>
                <h6 onClick={()=>setstep(2)} className={`font-[600] ${step === 2 && 'bg-slate-600 text-white'} text-center cursor-pointer py-1 w-[80px] text-[11px]`}>Progress</h6>
                <h6 onClick={()=>setstep(3)} className={`font-[600] ${step === 3 && 'bg-slate-600 text-white'} text-center cursor-pointer py-1 w-[80px] text-[11px]`}>Completed</h6>
            </div>
            <div className='overflow-x-hidden h-[90vh] mb-[50px] overflow-y-scroll'>
            
            {data?.datas?.length === 0 &&
            <div className='grid place-items-center mt-20  items-center justify-center'>
                <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' /> 
                <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
                <h6 className='font-[500] w-[70%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any data based on your current stage please add the data to see list here.</h6>
            </div>}

            {data?.datas?.length > 0 &&
            <div className='border-t max-h-[84vh] overflow-y-scroll border-l border-r mt-2'>
            <div className='flex border-b sticky top-0 z-50 bg-white '>
                <h6 className='text-[12px] font-[700] min-w-[50px] max-w-[50px] p-1 border-r'>SL No</h6>
                <h6 className='text-[12px] font-[700] min-w-[15%] max-w-[15%] p-1 border-r'>Department</h6>
                <h6 className='text-[12px] font-[700] min-w-[35%] max-w-[35%] p-1 border-r'>Summary</h6>
                <h6 className='text-[12px] font-[700]  min-w-[15%] max-w-[15%] p-1 border-r'>DeadLine</h6>
                <h6 className='text-[12px] font-[700]  min-w-[10%] max-w-[10%] p-1 border-r'>Status</h6>
                <h6 className='text-[12px] font-[700]  min-w-[15%] max-w-[15%] p-1 border-r'>Created</h6>
                <h6 className='text-[12px] font-[700] p-1'>Action</h6>
            </div>  
             
             {data?.datas?.map((d,i)=>(
             <div className={`flex  border-b  cursor-pointer`}>
                <h6 className='text-[12px] min-w-[50px] max-w-[50px] p-1 border-r'>{(page > 1 ? i+1+ (25 * (page - 1)) : i+1 )}</h6>
                <h6 className='text-[12px] min-w-[15%] font-[700] max-w-[15%] p-1 border-r'>{d?.department?.department_name}</h6>
                <h6 className='text-[12px] min-w-[35%] max-w-[35%] p-1 border-r'>{(d?.slots !== undefined && d?.slots !== null && d?.slots !== '') && <span className='font-[900] text-[11px]'> SLOT : {d?.slots} <br></br></span>} {d?.work_description}</h6>
                <h6 className='text-[10px] min-w-[15%] max-w-[15%] p-1 border-r'>{(d?.dead_line !== '' && d?.dead_line !== null && d?.dead_line !== undefined) && moment(d?.dead_line)?.format('LL')}</h6>
                <h6 className='text-[12px] min-w-[10%] max-w-[10%] p-1 border-r'><span className='text-[10px] uppercase font-[500] bg-slate-100 p-1'> {step == 1 ? 'Pending' : step == 2 ? 'Progress' : 'Completed'}</span></h6>
                <h6 className='text-[11px] min-w-[15%] max-w-[15%] p-1 border-r'>{moment(d?.createdAt)?.format('LL')}</h6>
                <h6 className='flex text-[12px] p-1'>
                    <AiOutlineEdit onClick={()=>{gotoedit(d)}} className='mr-2' />
                    <MdOutlineAlarm onClick={()=>{setmodal(true);setselectedData(d);getReminderList(d?._id)}} className='mr-2' />
                </h6>
            </div>   
            ))} 
            </div>}

            {/* {path1 === 'daily_tasks' &&
            <div className="grid -mx-1 grid-cols-5 mt-3">
                {data?.datas?.map((d)=>(
                    <div  key={d?._id} className='border px-1.5 py-1 mt-1 relative mx-1 relative cursor-pointer' >
                        <div className='bg-yellow-100 p-2'>
                        <Tooltip title="Edit"><span className='absolute bg-white p-1 right-0  top-0'><AiOutlineEdit size={20} className='absolute bg-white p-1 right-1 top-0' onClick={()=>{gotoedit(d)}} /></span></Tooltip>
                        <h6 className='text-[11px] ' >Message : <span className='font-[600] text-[11px] line-clamp-2'>{d?.work_description}</span> </h6>
                        </div>

                        {(d?.department !== '' && d?.department !== null && d?.department !== undefined)  &&
                        <h6 className='text-[11px] flex px-2 pt-1' >Department : <span className='pl-1 font-[600] text-[11px] line-clamp-2'>{d?.department?.department_name}</span> </h6>}

                        {(d?.remarks !== '' && d?.remarks !== null && d?.remarks !== undefined)  &&
                        <h6 className='text-[11px] flex px-2 pt-1' >Remarks : <span className='pl-1 text-[11px] line-clamp-2'>{d?.remarks}</span> </h6>}

                        <h6 className='text-[10px] ml-1 font-[400] mt-0.5' >Created At : {moment(d?.createdAt).format('LLL')}</h6>

                </div>))}
            </div>
            } */}

            {/* {path1 === 'project_work' &&
            <div className="grid -mx-1 grid-cols-5 mt-3">
                    {data?.datas?.map((d)=>(
                    <div  key={d?._id} className='border px-1.5 py-1 mt-1 relative mx-1 relative cursor-pointer' >
                         <div className='bg-orange-100 p-2'>
                        <Tooltip title="Edit"><span className='absolute bg-white p-1 right-0  top-0'><AiOutlineEdit size={20} className='absolute bg-white p-1 right-1 top-0' onClick={()=>{gotoedit(d)}} /></span></Tooltip>
                        <h6 className='text-[11px] ' >Message : <span className='font-[600] text-[11px] line-clamp-2'>{d?.work_description}</span> </h6>
                        </div>

                        {(d?.department !== '' && d?.department !== null && d?.department !== undefined)  &&
                        <h6 className='text-[11px] flex px-2 pt-1' >Department : <span className='pl-1 font-[600] text-[11px] line-clamp-2'>{d?.department?.department_name}</span> </h6>}

                        {(d?.remarks !== '' && d?.remarks !== null && d?.remarks !== undefined)  &&
                        <h6 className='text-[11px] flex px-2 pt-1' >Remarks : <span className='pl-1 text-[11px] line-clamp-2'>{d?.remarks}</span> </h6>}

                        <h6 className='text-[10px] ml-1 font-[400] mt-0.5' >Created At : {moment(d?.createdAt).format('LLL')}</h6>

                </div>))}
            </div>}

            {path1 === 'external_work' &&
            <div className="grid -mx-1 grid-cols-5 mt-3">
                    {data?.datas?.map((d)=>(
                    <div key={d?._id} className='border px-1.5 py-1 mt-1 relative mx-1 relative cursor-pointer' >
                        <div className='bg-blue-100 p-2'>
                        <Tooltip title="Edit"><span className='absolute bg-white p-1 right-0  top-0'><AiOutlineEdit size={20} className='absolute bg-white p-1 right-1 top-0' onClick={()=>{gotoedit(d)}} /></span></Tooltip>
                        <h6 className='text-[11px] ' >Message : <span className='font-[600] text-[11px] line-clamp-2'>{d?.work_description}</span> </h6>
                        </div>

                        {(d?.department !== '' && d?.department !== null && d?.department !== undefined)  &&
                        <h6 className='text-[11px] flex px-2 pt-1' >Department : <span className='pl-1 font-[600] text-[11px] line-clamp-2'>{d?.department?.department_name}</span> </h6>}

                        {(d?.remarks !== '' && d?.remarks !== null && d?.remarks !== undefined)  &&
                        <h6 className='text-[11px] flex px-2 pt-1' >Remarks : <span className='pl-1 text-[11px] line-clamp-2'>{d?.remarks}</span> </h6>}
         
                        <h6 className='text-[10px] ml-1 font-[400] mt-0.5' >Created At : {moment(d?.createdAt).format('LLL')}</h6>
                        </div>
                    ))}
            </div>}

            {path1 === 'assigned_work' &&
            <div className="grid -mx-1 grid-cols-5 mt-3">
                {data?.datas?.map((d)=>(
                    <div  key={d?._id} className='border px-1.5 py-1 mt-1 relative mx-1 relative cursor-pointer' >
                        <div className='bg-yellow-100 p-2'>
                        <Tooltip title="Edit"><span className='absolute bg-white p-1 right-0  top-0'><AiOutlineEdit size={20} className='absolute bg-white p-1 right-1 top-0' onClick={()=>{gotoedit(d)}} /></span></Tooltip>
                        <h6 className='text-[11px] ' >Message : <span className='font-[600] text-[11px] line-clamp-2'>{d?.work_description}</span> </h6>
                        <h6 className='text-[11px] flex' >DeadLine : <span className='font-[600] text-[11px] line-clamp-2'> {moment(d?.dead_line)?.format('LL')}</span> </h6>
                        </div>

                        {(d?.department !== '' && d?.department !== null && d?.department !== undefined)  &&
                        <h6 className='text-[11px] flex px-2 pt-1' >Department : <span className='pl-1 font-[600] text-[11px] line-clamp-2'>{d?.department?.department_name}</span> </h6>}

                        {(d?.assigned_to !== '' && d?.assigned_to !== null && d?.assigned_to !== undefined)  &&
                        <h6 className='text-[11px] flex px-2' >Assigned To : <span className='pl-1 font-[600] text-[11px] line-clamp-2'>{d?.assigned_to?.name}</span> </h6>}

                        {(d?.remarks !== '' && d?.remarks !== null && d?.remarks !== undefined)  &&
                        <h6 className='text-[11px] flex px-2 pt-1' >Remarks : <span className='pl-1 text-[11px] line-clamp-2'>{d?.remarks}</span> </h6>}

                        <h6 className='text-[10px] ml-1 font-[400] mt-0.5' >Created At : {moment(d?.createdAt).format('LLL')}</h6>

                </div>))}
            </div>
            } */}

            </div>
            </div>
        </div>
        :
        <TeamTaskList />}

    </div>
  )
}

export default TaskList