import { deleteRequest, get, post, postfd, publicPost, put } from "../helpers/apihelpers";

export const CreateMDCallService=async(data)=>{
    try {
        const result = await post(`api/md_calls/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const FilterMDCallService=async(page,from_date,to_date,search_text,step,type)=>{
    try {
        const result = await get(`api/md_calls/filter?page=${page}&type=${type}&from_date=${from_date}&to_date=${to_date}&search_text=${search_text}&step=${step}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteMDManyCallService=async(step)=>{
    try {
        const result = await deleteRequest(`api/md_calls/delete_many?step=${step}`);
        return result;
    } catch (err) {
        return err.response;
    }
}


export const UploadMDExcelCallService=async(file)=>{
    try {
        const result = await postfd(`api/md_calls/upload_excel`,{file:file});
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DownloadMDExcelCallService=async(step)=>{
    try {
        const result = await get(`api/md_calls/download_excel?step=${step}`);
        return result;
    } catch (err) {
        return err.response;
    }
}


export const GetMDCallService=async(page,step)=>{
    try {
        const result = await get(`api/md_calls/get?page=${page}&step=${step}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetMDCallDetailService=async(id)=>{
    try {
        const result = await get(`api/md_calls/get?id=${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateMDCallService=async(id,data)=>{
    try {
        const result = await put(`api/md_calls/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteMDCallService=async(id)=>{
    try {
        const result = await deleteRequest(`api/md_calls/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetAdminMDCallService=async(page,stage,type,from_date,to_date,search,user)=>{
    try {
        const result = await get(`api/md_calls/get_team_calls?page=${page}&type=${type}&from_date=${from_date}&to_date=${to_date}&stage=${stage}&search_text=${search}&user=${user}`);
        return result;
    } catch (err) {
        return err.response;
    }
}