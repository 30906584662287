import React, { useEffect, useState } from 'react'
import DashboardMenu from '../dashboard/DashboardMenu'
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { AiOutlineReload} from 'react-icons/ai';  
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { IconButton, Tooltip } from '@material-ui/core';
import { DownloadDailyTaskService, GetDailyTaskAdminService } from '../../services/DailyTaskServices';
import { DatePicker,Select } from 'antd';
import { ButtonFilledAutoWidth } from '../../components/button';
import { GetDepartmentService, GetUsersByDepartment } from '../../services/DepartmentServices';
import { RiFileExcel2Line } from "react-icons/ri";
import fileDownload from "js-file-download";
import axios from 'axios';
import toast from 'react-hot-toast';


function BackendTeamTasks() {


  const {pathname} = useLocation()

  const [page,setpage] = useState(1)
  const [search,setsearch] = useState({text:'',from_date:'',to_date:'',from_date1:'',to_date1:'',activate:false})
  const [data,setdata] = useState({datas:[],pagination:{total:0,totalPages:0,limit:25}})
  const [department,setdepartment] = useState([])
  const [department1,setdepartment1] = useState([])
  const [selected_department,setselected_department] = useState('')
  const [worked_department,setworked_department] = useState('')
  const [users,setusers] = useState([])
  const [selectedusers,setselectedusers] = useState('')
  

  useEffect(()=>{
    getdata()
    getDepartments()
  },[page])

  async function getDepartments(){
    const response = await GetDepartmentService()
    let arr = []
    let arr1 = []
    let d = response?.data?.datas
    d?.forEach((d)=>{
        if(d?.department_name == 'Software Team' || d?.department_name == 'Digital Media' || d?.department_name == 'Corp Team' || d?.department_name == 'Fidelitus Gallery'){
           arr.push({label:d?.department_name,value:d?.id})
           arr1.push({label:d?.department_name,value:d?.id})
        }else{
           arr1.push({label:d?.department_name,value:d?.id})
        }
    })
    setdepartment(arr)
    setdepartment1(arr1)
   }

   async function getUsers(v){
    const response = await GetUsersByDepartment(v)
    let arr = []
    let d = response?.data?.datas
    d?.forEach((d)=>{
        arr.push({label:d?.name,value:d?._id})
    })
    setusers(arr)
   }

//    console.log("users",users)

   async function getdata(){
    if(selected_department !== ''){
        const response = await GetDailyTaskAdminService(page,search?.from_date1,search?.to_date1,search?.text,selected_department,selectedusers,worked_department)
        setdata(response?.data)
    }
   }


   async function resetfunc(){
    setpage(1)
    setselected_department('')
    setworked_department('')
    setselectedusers('')
    setsearch({text:'',from_date:'',to_date:'',from_date1:'',to_date1:'',activate:false})
    setdata({datas:[],pagination:{total:0,totalPages:0,limit:25}})
    // if(selected_department == ''){
    // const response = await GetDailyTaskAdminService(1,'','','','','')
    // setdata(response?.data)
   }

   async function applyfilter(){
    setpage(1)
    const response = await GetDailyTaskAdminService(1,search?.from_date1,search?.to_date1,search?.text,selected_department,selectedusers,worked_department)
    setdata(response?.data)
   }


   async function downloadExcel(){
    const response = await DownloadDailyTaskService(selectedusers,search?.from_date1,search?.to_date1)
    let d = response?.data
    console.log("d here",d)
    if(response?.status === 200){
        handleDownload(`${process.env.REACT_APP_AWS_IMAGE_URL}${d?.path?.replace('public/','')}`,d?.path?.split('/')[d?.path?.split('/')?.length - 1])
    }
   }

   const handleDownload = (url, filename) => {
    axios.get(url, {
        responseType: 'blob',
    })
        .then((res) => {
            fileDownload(res.data, filename)
            toast.success("File Downloaded")

        })
    }
   
   
  return (

    
    <div className='flex'>
        <DashboardMenu />
        <div className='mx-5 w-[85%]'>
            <div className='flex items-center mt-1 border-b justify-between pb-1'>
                <h6 className='font-[800] text-[13px]'>Total Task ({data?.pagination?.total})</h6>

                <div className='flex items-center text-[12px]'>

                    <h6 className='mr-2 font-[600]'>{page == 1 ? data?.datas?.length > 0 ? 1 : 0 : (page - 1) * data?.pagination?.limit } - {data?.pagination?.limit} of {data?.pagination?.total}  </h6>


                    <div>
                    <IconButton onClick={()=>{resetfunc()}}><AiOutlineReload className={'opacity-100'}  size={16} /></IconButton>

                    <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                    <IconButton onClick={()=>{ page < data?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(data?.pagination?.totalPages === page || data?.datas?.length === 0) ? 'opacity-50 ' : 'opacity-100'}`}  size={16} /></IconButton>

                    </div>
                    <DatePicker  size='small' style={{fontSize:'11px'}} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.from_date} onChange={(v,v1) => {setsearch({...search,from_date:v,from_date1:v1})}} /> 

                    <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.to_date} onChange={(v,v1) => {setsearch({...search,to_date:v,to_date1:v1})}} /> 

                    
                    <div className='mr-2 flex items-center'>
                        

                    <input  id="search_text" placeholder='Search text' type='text' value={search.text} onChange={(e)=>setsearch({...search,text:e.target.value})} className='border py-[3px] focus:ring-0 focus:outline-0 text-[14px]  w-[100px] px-1 rounded-md border-slate-300' />
                    <div className='mt-0'>
                    <Select 
                     bordered={false}
                     placeholder="Department"
                     value={selected_department === '' ? null : selected_department}
                     options={department}
                     onChange={(e)=>{setselected_department(e);getUsers(e)}}
                     className='w-[100px] border h-[28px] border-slate-300 ml-2 rounded-[5px]'

                    />
                   </div>

                   <div className='mt-0'>
                    <Select 
                     bordered={false}
                     placeholder="Users"
                     value={selectedusers === '' ? null : selectedusers}
                     options={users}
                     onChange={(e)=>{console.log("e here",e);setselectedusers(e)}}
                     className='w-[100px] border h-[28px] border-slate-300 ml-2 rounded-[5px]'

                    />
                   </div>

                   <div className='mt-0'>
                    <Select 
                     bordered={false}
                     placeholder="Work Department"
                     value={worked_department === '' ? null : worked_department}
                     options={department1}
                     onChange={(e)=>{console.log("e here",e);setworked_department(e)}}
                     className='w-[100px] border h-[28px] border-slate-300 ml-2 rounded-[5px]'

                    />
                   </div>

                    </div>  
                    <div className='mr-2'>
                    <ButtonFilledAutoWidth btnName="Apply Filter" onClick={()=>{applyfilter()}} /> 
                    </div>

                   <Tooltip title="Download Excel">
                    <span onClick={downloadExcel}>
                    <RiFileExcel2Line size={28} className='bg-slate-600 cursor-pointer text-white p-[7px] rounded-[4px]' />
                    </span>
                    </Tooltip>
                   
                   

                </div>

            </div>

            {data?.datas?.length === 0 &&
            <div className='grid place-items-center mt-20  items-center justify-center'>
                <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' /> 
                <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
                <h6 className='font-[500] w-[70%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any data based on your current stage please add the data to see list here.</h6>
            </div>}

            {data?.datas?.length > 0 &&
            <div className='border-t max-h-[84vh] ovrflow-x-hidden overflow-y-scroll border-l border-r mt-2'>
            <div className='flex border-b sticky top-0 z-50 bg-white '>
                <h6 className='text-[12px] font-[700] min-w-[50px] max-w-[50px] p-1 border-r'>SL No</h6>
                <h6 className='text-[12px] font-[700] min-w-[15%] max-w-[15%] p-1 border-r'>Department</h6>
                <h6 className='text-[12px] font-[700] min-w-[30%] max-w-[30%] p-1 border-r'>Summary</h6>
                <h6 className='text-[12px] font-[700]  min-w-[15%] max-w-[15%] p-1 border-r'>DeadLine</h6>
                <h6 className='text-[12px] font-[700]  min-w-[10%] max-w-[10%] p-1 border-r'>Status</h6>
                <h6 className='text-[12px] font-[700]  min-w-[10%] max-w-[10%] p-1 border-r'>Employee</h6>
                <h6 className='text-[12px] font-[700]  min-w-[15%] max-w-[15%] p-1 '>Created</h6>
                {/* <h6 className='text-[12px] font-[700] p-1'>Action</h6> */}
            </div>  
             
             {data?.datas?.map((d,i)=>(
             <div className={`flex  border-b  cursor-pointer`}>
                <h6 className='text-[12px] min-w-[50px] max-w-[50px] p-1 border-r'>{(page > 1 ? i+1+ (25 * (page - 1)) : i+1 )}</h6>
                <h6 className='text-[12px] min-w-[15%] font-[700] max-w-[15%] p-1 border-r'> {d?.department?.department_name}</h6>
                <h6 className='text-[12px] min-w-[30%] max-w-[30%] p-1 border-r'>{(d?.slots !== undefined && d?.slots !== null && d?.slots !== '') && <span className='font-[900] text-[11px]'> SLOT : {d?.slots} <br></br></span>} {d?.work_description}</h6>
                <h6 className='text-[11px] min-w-[15%] max-w-[15%] p-1 border-r'>{(d?.dead_line !== '' && d?.dead_line !== null && d?.dead_line !== undefined) && moment(d?.dead_line)?.format('LL')}</h6>
                <h6 className={`text-[12px] min-w-[10%] max-w-[10%] p-1 border-r ${d?.stage === '1' ? 'bg-yellow-100' : d?.stage === '2' ? 'bg-slate-100' : 'bg-green-200'}`}><span className='text-[10px] uppercase font-[500] font-[800] p-1'> {d?.stage === '1' ? 'Pending' : d?.stage === '2' ? 'Progress' : 'Completed'}</span></h6>
                <h6 className='text-[11px] font-[600] min-w-[10%] max-w-[10%] p-1 border-r'>{d?.created_by?.name}</h6>
                <h6 className='text-[11px] min-w-[15%] max-w-[15%] p-1 '>{moment(d?.createdAt)?.format('LL')}</h6>
                {/* <h6 className='text-[12px] p-1'>
                    <AiOutlineEdit onClick={()=>{gotoedit(d)}} className='mr-2' />
                </h6> */}
            </div>   
            ))} 
            </div>}

        </div>

    </div>
  )
}

export default BackendTeamTasks