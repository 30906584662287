import React,{useState,useEffect} from 'react'
import { HiChevronLeft,HiChevronRight } from "react-icons/hi";
import DOMPurify from "dompurify";
import CalendarMenu from './CalendarMenu';
import { IoMenu } from "react-icons/io5";
import { PiPlusBold } from "react-icons/pi";
import { DatePicker, Modal,Select, TimePicker } from 'antd';
import { MdOutlineLocationOn,MdOutlineDescription } from "react-icons/md";
import { TbBrandGoogleDrive } from "react-icons/tb";
import { AiOutlineClockCircle, AiOutlineClose, AiOutlineCloudDownload, AiOutlineDelete, AiOutlineDownload } from "react-icons/ai";
import { ButtonFilledAutoWidth } from '../../components/button';
import { CreateCalendarService, DeleteCalendarService, GetCalendarService, UpdateCalendarService, UploadCalendarService } from '../../services/CalendarServices';
import toast from 'react-hot-toast';
import { LuUserSquare2 } from "react-icons/lu";
import { GetSearchService } from '../../services/AuthServices';
import fileDownload from "js-file-download";
import axios from 'axios';
import ErrorComponent from '../../components/errorDesign/ErrorComponent';
import moment from 'moment';
import { TbNotes } from "react-icons/tb";
import { useSelector } from 'react-redux';
import { GetDepartmentService } from '../../services/DepartmentServices';
import { MdOutlineGroup } from "react-icons/md";
import { RiChatFollowUpLine } from "react-icons/ri";
import { HiOutlineMenuAlt4 } from "react-icons/hi";
import { useNavigate } from 'react-router-dom';

function Calendar() {


  const roles = useSelector(state=>state.Auth.roles)
  const userId = useSelector(state=>state.Auth.id)

    
  const navigator = useNavigate()
  const [modal,setmodal] = useState(false)   
  const [dataModal,setdataModal] = useState(false)   
  const [showMore,setshowMore] = useState(false)   
 
  const [date,setdate] = useState('')

  const [data,setdata] = useState({reminderTo:[],remarks:'',title:'',description:'',location:'',photos:[],time:'',date:'',year:'',date_time:'',type:'',date1:'',time:'',date2:'',time1:'',department:''})
  const [error,seterror] = useState({reminderTo:'',remarks:'',title:'',description:'',location:'',photos:'',time:'',date:'',year:'',date_time:'',type:'',date1:'',time:'',date2:'',time1:'',department:''})
  
  const [users,setusers] = useState([])
  const [departments,setdepartments] = useState([])

  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];  

  const weekDays = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday']

  const [calendarData,setcalendarData]  = useState({})
  const [calendar_report,setcalendar_report] = useState({year:'',month:'',no_of_days:'',start_date_index:'',from_date:'',to_date:''})

  useEffect(()=>{
    getdeparmtent()
    let month = new Date().getMonth()
    let year = new Date().getFullYear()
    let days = getDaysInMonth(month,year)

    setdate(new Date().getDate())

    let firstDayOfMonth = new Date(year,month,1)
    let dayOfWeek = firstDayOfMonth.getDay()

    let from_date = `${year}-${JSON.stringify(month)?.length == 1 ? `0${month+1}` : month+1}-01`
    let to_date = `${year}-${JSON.stringify(month)?.length == 1 ? `0${month+1}` : month+1}-${days}`

    setcalendar_report({...calendar_report,year:year,month:month,no_of_days:days,start_date_index:dayOfWeek,from_date,to_date})
    getdata(month,year)
  },[])


  async function getdata(month,year) {
     const response = await GetCalendarService(month,year,1)
     setcalendarData(response?.data)
  }

  async function  getdeparmtent() {
    const response = await  GetDepartmentService()
    let arr = []
    let d = response?.data?.datas 
    d?.forEach((d1)=>{
        arr?.push({label:d1?.department_name,value:d1?.id})
    })
    setdepartments(arr)
  }

  function getDaysInMonth(month, year) {
    return new Date(year, month + 1, 0).getDate();
  }

  async function resetmonth(v){
    setdate('')
    if(v === 0){

      let month = calendar_report.month 

      if(month > 0){
        month = month - 1
        let year = calendar_report.year 
        let days = getDaysInMonth(month,year)

        let firstDayOfMonth = new Date(year,month,1)
        let dayOfWeek = firstDayOfMonth.getDay()
        

        let from_date = `${year}-${JSON.stringify(month)?.length == 1 ? `0${month+1}` : month+1}-01`
        let to_date = `${year}-${JSON.stringify(month)?.length == 1 ? `0${month+1}` : month+1}-${days-1}`


        setcalendar_report({...calendar_report,year:year,month:month,no_of_days:days,start_date_index:dayOfWeek,from_date,to_date})
        getdata(month,year)

      }else{
        let year = calendar_report.year - 1
        let month = 11
        let days = new Date(year, month, 0).getDate()

        let firstDayOfMonth = new Date(year,month,1)
        let dayOfWeek = firstDayOfMonth.getDay()
       
        let from_date = `${year}-${JSON.stringify(month)?.length == 1 ? `0${month+1}` : month+1}-01`
        let to_date = `${year}-${JSON.stringify(month)?.length == 1 ? `0${month+1}` : month+1}-${days-1}`

        setcalendar_report({...calendar_report,year:year,month:month,no_of_days:days,start_date_index:dayOfWeek,from_date,to_date})
        getdata(month,year)

      }

    }else{
      let month = calendar_report.month 
      console.log("month la la",month)


      if(month < 11){
        month = month + 1
        let year = calendar_report.year
        let days = getDaysInMonth(month,year)

        // console.log("year",year)
        // console.log("month",month)
        // console.log("days",days)

        let firstDayOfMonth = new Date(year,month,1)
        let dayOfWeek = firstDayOfMonth.getDay()

        let from_date = `${year}-${JSON.stringify(month)?.length == 1 ? `0${month+1}` : month+1}-01`
        let to_date = `${year}-${JSON.stringify(month)?.length == 1 ? `0${month+1}` : month+1}-${days-1}`


        setcalendar_report({...calendar_report,year:year,month:month,no_of_days:days,start_date_index:dayOfWeek,from_date,to_date})
        getdata(month,year)

      }else{
        let year = calendar_report.year + 1
        let month = 0
        let days = getDaysInMonth(month,year)

        let firstDayOfMonth = new Date(year,month,1)
        let dayOfWeek = firstDayOfMonth.getDay()
        let from_date = `${year}-${JSON.stringify(month)?.length == 1 ? `0${month+1}` : month+1}-01`
        let to_date = `${year}-${JSON.stringify(month)?.length == 1 ? `0${month+1}` : month+1}-${days-1}`

       
        setcalendar_report({...calendar_report,year:year,month:month,no_of_days:days,start_date_index:dayOfWeek,from_date,to_date})
        getdata(month,year)

      }
    }
   }

  function returnCount(v){
    let formatDate = `${calendar_report.year}-${JSON.stringify(calendar_report?.month)?.length == 1 ? `0${calendar_report.month+1}` : calendar_report.month+1}-${JSON.stringify(v).length == 1 ? `0${v}` : v}`
    // let filterData = calendarData?.datas?.filter((f)=>f?.date == formatDate)
    return 0
  }

  async function uploadFile(v) {
      const fd = new FormData()
      fd.append('file',v); 
      const response = await UploadCalendarService(fd)
      if(response?.status === 200){
        let datas = [...data.photos] 
        datas.push(response?.data?.data)
        setdata({...data,photos:datas})
        seterror({...error,photos:''})
      }
  }

  function handleChange(e){
    setdata({...data,[e.target.name]:e.target.value})
    seterror({...error,[e.target.name]:''})
  }

  async function handleSelect(v) {
        let arr  = []
        v?.forEach((d)=>{
            if(users.find((f)=>f?.value == d) !== null){
                arr?.push(users.find((f)=>f?.value == d)) 
            }else{
                if(data?.reminderTo.find((f)=>f?.value == d) !== null){
                    arr?.push(data?.reminderTo.find((f)=>f?.value == d)) 
                }
            }
        })

        
        setdata({...data,reminderTo:arr})
  }

  async function searchuser(v) {
        const response = await GetSearchService(v,1)
        let arr = []
        let d = response?.data?.datas
        d?.forEach((d1)=>{
            arr?.push({label:d1?.name,value:d1?._id})
        })
        setusers(arr)
  }

  async function submit() {
        if(!data.title){
            seterror({...error,title:'This Field is required *'})
        }else if(!data.date1){
            seterror({...error,date1:'This Field is required *'})
        }else if(!data.time){
            seterror({...error,time:'This Field is required *'})
        }else{
            

                if(data?._id === undefined){
                    let sendData = {...data}
                    sendData['date'] = parseInt(data?.date1?.slice(8,10))
                    sendData['year'] = parseInt(data?.date1?.slice(0,4))
                    sendData['month'] = parseInt(data?.date1?.slice(5,7)) - 1

                    if(data?.date1 !== '' && data?.time !== ''){
                        sendData['date_time'] = data?.date1 !== undefined && data?.date1 + ' ' + data?.time
                    }

                    if(data?.reminderTo?.length > 0){
                        let arr = []
                        data?.reminderTo?.forEach((d1)=>{
                            arr?.push(d1?.value)
                        })
                        sendData['reminderTo'] = arr

                    }else{
                        delete sendData?.reminderTo
                    }

                    if(data?.department !== ''){
                        sendData['department'] = data?.department?.value
                    }else{
                        delete sendData?.department
                    }

                const response = await CreateCalendarService(sendData)
                if(response?.status === 201){
                    toast.success("Calendar Created Successfully!")
                    resetform()
                    setdataModal(false)
                }
            }else{
                let sendData = {...data}
                sendData['date'] = parseInt(data?.date2?.slice(8,10))
                sendData['year'] = parseInt(data?.date2?.slice(0,4))
                sendData['month'] = parseInt(data?.date2?.slice(5,7)) - 1

                if(data?.date1 !== '' && data?.time !== ''){
                    sendData['date_time'] = data?.date1 !== undefined && data?.date1 + ' ' + data?.time
                }
          
                if(data?.reminderTo?.length > 0){
                    let arr = []
                    data?.reminderTo?.forEach((d1)=>{
                        arr?.push(d1?.value)
                    })
                    sendData['reminderTo'] = arr

                }else{
                    delete sendData?.reminderTo
                }

                if(data?.department !== ''){
                    sendData['department'] = data?.department?.value
                }else{
                    delete sendData?.department
                }

                const response = await UpdateCalendarService(sendData,data?._id)
                if(response?.status === 200){
                    toast.success("Calendar Updated Successfully!")
                    resetform()
                    setdataModal(false)
                }
            }
        }
        getdata(calendar_report?.month,calendar_report?.year)
  }

  function resetform(){
       setdata({reminderTo:[],title:'',remarks:'',description:'',location:'',photos:[],time:'',date:'',year:'',date_time:'',type:'',date1:'',time:'',date2:'',time1:'',department:''})
       seterror({reminderTo:'',title:'',remarks:'',description:'',location:'',photos:'',time:'',date:'',year:'',date_time:'',type:'',date1:'',time:'',date2:'',time1:'',department:''})
  }

  function removeimage(i){
    let photos = [...data?.photos]
    photos.splice(i,1)
    setdata({...data,photos:photos})
  }

  const handleDownload = (url, filename) => {
        axios.get(`${process.env.REACT_APP_AWS_IMAGE_URL}${url}`, {
            responseType: 'blob',
        })
            .then((res) => {
                fileDownload(res.data, filename)
            })
  }

  function returnData(date){
    if(date > 0){
        let data = calendarData?.datas
        let filterData = data?.filter((d)=>{
            return  d?._id?.year == calendar_report.year,
             d?._id?.month == calendar_report.month,
             d?._id?.date == date
        })

        return filterData?.length > 0 && filterData[0]?.data

        // let returnString = `` 
        // filterData?.length > 0 && filterData[0]?.data?.forEach((d)=>(
        //    returnString += `<li style="background:#334155;border-radius:4px;padding:0px 4px;color:white;margin-bottom:2px">${d?.title}</li>`
        // ))
        // return returnString !== '' && `<ul>${returnString}</ul>`
    }
  }

  function datamodaldata(d){
    let sendData = {...d}
    let arr = []
    d?.reminderTo?.forEach((d1)=>{
        arr?.push({label:d1?.name,value:d1?._id})
    })
    sendData['reminderTo'] = arr

    if(d?.department !== ''){
        sendData['department'] = {label:d?.department?.department_name,value:d?.department?._id}
    }else{
        sendData['department'] = ''
    }

    if(d?.date_time !== '' && d?.date_time !== null && d?.date_time !== undefined){
        sendData['date1'] = d?.date_time?.slice(0,10)
        sendData['date2'] = d?.date_time?.slice(0,10)
        sendData['time'] = moment(d?.date_time?.slice(11,16), "HH:mm").add(0, 'hours').add(0, 'minutes').format("HH:mm");
        sendData['time1'] =  moment(d?.date_time?.slice(11,16), "HH:mm").add(0, 'hours').add(0, 'minutes').format("HH:mm"); 
    }else{
        sendData['date1'] = ''
        sendData['date2'] = ''
        sendData['time'] = ''
        sendData['time1'] = ''
    }

    setdata(sendData)
  }


  async function deleteData() {
    const response = await DeleteCalendarService(data?._id)
    if(response?.status == 200){
        toast.success("Calendar Deleted Successfully!")
        getdata(calendar_report?.month,calendar_report?.year)
    }else{
        toast.success("Calendar Deleted Successfully!")
        getdata(calendar_report?.month,calendar_report?.year)
    }
    setdataModal(false)
  }




  return (
    <div className='h-screen flex w-[100%] max-h-screen '>

        <Modal width={400} className='relative' open={dataModal} footer={false} closable={false}>
              
            <div className='bg-slate-100 flex items-center justify-between p-1.5 relative'>
                <HiOutlineMenuAlt4 onClick={()=>navigator(data?._id !== undefined ? 'edit' : 'create',{state:data})}  size={14} />

                <div className='flex'>
                <AiOutlineClose size={13} onClick={()=>setdataModal(!dataModal)} className='cursor-pointer ml-2' />
                {data?._id !== undefined &&
                <RiChatFollowUpLine onClick={()=>navigator('create',{state:{...data,followup_calendar:data?._id}})} size={13} className='cursor-pointer ml-2'  />}
                {data?._id !== undefined && (roles?.includes('admin') || (data?.created_by?._id == userId)) &&
                <AiOutlineDelete size={13} onClick={()=>deleteData()} className='cursor-pointer ml-2' />}
                </div>
                 
            </div>

          

            <input name="title" value={data?.title} onChange={handleChange} className=' w-[100%] mt-4 focus:outline-none border-b border-slate-300 p-1 placeholder:text-[16px]' type='text' placeholder='Add title and time' />
            <ErrorComponent error={error?.title} />
            
            <div className='flex mt-2'>
                <span onClick={()=>{setdata({...data,type:'Event'})}} className={`border cursor-pointer ${data?.type == 'Event' && 'bg-blue-200 border-blue-200 text-blue-600'} text-[12px] py-1 px-1.5 rounded mr-2`}>Event</span>
                <span onClick={()=>{setdata({...data,type:'Out of office'})}} className={`border cursor-pointer ${data?.type == 'Out of office' && 'bg-blue-200 border-blue-200 text-blue-600'} text-[12px] py-1 px-1.5 rounded mr-2`}>Out of office</span>
                <span onClick={()=>{setdata({...data,type:'Task'})}} className={`border cursor-pointer ${data?.type == 'Task' && 'bg-blue-200 border-blue-200 text-blue-600'} text-[12px] py-1 px-1.5 rounded mr-2`}>Task</span>
                <span onClick={()=>{setdata({...data,type:'Meeting'})}} className={`border cursor-pointer ${data?.type == 'Meeting' && 'bg-blue-200 border-blue-200 text-blue-600'} text-[12px] py-1 px-1.5 rounded mr-2`}>Meeting</span>
            </div>

            {(data?.calendar_type !== undefined && data?.calendar_type !== null && data?.calendar_type !== '') && <h6 className='mt-3 text-[11px]'>Calendar Type : <span className='bg-green-100 font-[800] rounded px-2 text-green-800 p-1'>{data?.calendar_type}</span></h6>}


            <div className='flex  my-1 mt-4 items-center'>
                <LuUserSquare2 size={16} />
                <button className='ml-2 hover:bg-slate-100 rounded p-1 text-[12px] px-2 cursor-pointer'>Add guests</button>
            </div>


            <div className='ml-[8%]'>
                <Select 
                placeholder="Search to add guest"
                bordered={false}
                mode='multiple'
                value={data?.reminderTo}
                filterOption={false}
                showSearch
                onSearch={searchuser}
                onChange={handleSelect}
                options={users}
                showArrow={false}
                className='bg-slate-100 w-[100%] border-b border-slate-300'
                />
            </div> 

            <div className='flex  my-1 mt-4 items-center'>
                <MdOutlineGroup size={16} />
                <button className='ml-2 hover:bg-slate-100 rounded p-1 text-[12px] px-2 cursor-pointer'>Select Department</button>
            </div>

            <div className='ml-[8%]'>
                <Select 
                placeholder="Select department if applicable"
                bordered={false}
                value={(data?.department == null || data?.department == '' || data?.department == undefined) ? null : data?.department}
                filterOption={false}
                showSearch
                onChange={(v)=>setdata({...data,department:departments?.find((f)=>f?.value == v)})}
                options={departments}
                showArrow={false}
                className='bg-slate-100 w-[100%] border-b border-slate-300'
                />
            </div> 

            <div className='flex  my-1 mt-4 items-center'>
                <AiOutlineClockCircle size={18} />

                <div className='ml-3 w-[100%] flex'>
                <div className='w-[100%]'>
                <div className='border-b w-[100%] bg-slate-100 border-slate-300'>
                <input type='date' className='bg-slate-100 text-[13px] p-1 w-[100%] outline-none' width={'100%'} placeholder={'Select Date'} bordered={false}  ampm={false} sx={{height:'40px'}} inputFormat="MM/dd/yyyy" value={data?.date1} onChange={(e) => {setdata({...data,date1:e.target.value});seterror({...error,date1:''})}} />
                <ErrorComponent error={error?.date1} />
                </div>
                </div>
                <p style={{width:10}}></p>
                <div className='w-[100%]'>
                <div  className='border-b w-[100%]  border-slate-300'>
                <input type='time' className='bg-slate-100 text-[13px] p-1 w-[100%] outline-none' width={'100%'} placeholder={'Select Time'} bordered={false} format="hh:mm" ampm={false} sx={{height:'40px'}} value={data?.time} onChange={(e) => {setdata({...data,time:e.target.value});seterror({...error,time:''})}} />
                <ErrorComponent error={error?.time} />
                </div>
                </div>
                </div>
            </div>


            <div className='flex  my-1 mt-4 items-center'>
                <MdOutlineLocationOn size={20} />
                <input name="location" value={data?.location} onChange={handleChange} className='bg-slate-100 ml-2 text-[13px] w-[100%] focus:outline-none border-b border-slate-300 p-1 px-2 placeholder:text-[13px]' type='text' placeholder='Add location' />
            </div>

            <div className='flex  my-1 mt-4 '>
                <MdOutlineDescription size={20} />
                <textarea name="description" value={data?.description} onChange={handleChange} className='bg-slate-100 ml-2 text-[13px] w-[100%] focus:outline-none border-b border-slate-300 p-1 px-2 placeholder:text-[13px]' type='text' placeholder='Add description' />
            </div>

            <div className='flex  my-1 mt-4 items-center'>
                <TbBrandGoogleDrive size={20} />

                <form onClick={()=>{document.querySelector('.input-field').click()}} >
                  <h6 className='ml-2 hover:bg-slate-100 rounded p-1 text-[12px] px-2 cursor-pointer'>Upload File
                    <input type='file' onChange={({target:{files}})=>{
                    files[0] && uploadFile(files[0])
                    }} accept="*" className='input-field' hidden />
                  </h6>
               </form> 

            </div>  
            <div className='ml-[8%] flex flex-wrap mb-1.5'>
                {data?.photos?.map((p,i)=>(
                    <div key={p} className='px-2 rounded w-max flex items-center py-1 mr-1 mb-1 bg-blue-100'> 
                        <h6 className='text-[11px]'>{p?.split('/')[p?.split('/')?.length - 1]?.slice(0,10)}</h6>
                        <AiOutlineDownload onClick={()=>handleDownload(p,p?.split('/')[p?.split('/')?.length - 1])} size={12} className='ml-2' />
                        <AiOutlineClose onClick={()=>removeimage(i)} size={12} className='ml-2' />
                    </div>
                ))}
            </div>  


           {showMore &&
            <div className='flex  my-1 mt-4 items-center'>
                <TbNotes size={20} />
                <textarea name="remarks" value={data?.remarks} onChange={handleChange} className='bg-slate-100 ml-2 text-[13px] w-[100%] focus:outline-none border-b border-slate-300 p-1 px-2 placeholder:text-[13px]' type='text' placeholder='Meeting Remarks' />
            </div>}


            <div className='flex items-center justify-end'>
                <h6 onClick={()=>setshowMore(!showMore)} className='mx-2 cursor-pointer  hover:bg-slate-100 rounded p-1 px-2' >More options</h6>
                <ButtonFilledAutoWidth onClick={submit} btnName="Save" />
            </div>
           
        </Modal>


      <div className='flex w-[100%] '>
        <CalendarMenu /> 
        <div className='w-[87%]'>
            <div className='px-4 flex items-center my-3'>
              <IoMenu className='cursor-pointer' onClick={()=>setmodal(!modal)} />

                <img  className='w-6 h-6 p-1 rounded mx-2 bg-slate-900 flex items-center ' src="https://fidecrmfiles.s3.amazonaws.com/fcpl_white_logo.png" />

                <h6 className='font-[800] text-[15px]'>Calendar</h6>

                <div className='flex ml-4'>
                    <HiChevronLeft className='mx-1 text-slate-700' onClick={()=>resetmonth(0)} />
                    <HiChevronRight className='mx-1 text-slate-700' onClick={()=>resetmonth(1)} />
                </div>  

                <h6 className='font-[800] ml-4 text-[15px]'>{monthNames[calendar_report?.month]} {calendar_report?.year}</h6>

            </div>    

            <div className='flex border-t px-2 w-[100%] '>
                {!modal &&
                <div className='w-[27%] pt-4 overflow-y-scroll h-[85vh] pr-2'>

                       
                       <button onClick={()=>{setdataModal(true);setdata({reminderTo:[],remarks:'',title:'',description:'',location:'',photos:[],time:'',date:'',year:'',date_time:'',type:'',date1:'',time:'',date2:'',time1:''});seterror({reminderTo:'',remarks:'',title:'',description:'',location:'',photos:'',time:'',date:'',year:'',date_time:'',type:'',date1:'',time:'',date2:'',time1:''})}} className='flex cursor-pointer border mb-2 w-max items-center p-2 px-6 rounded-[20px]'>
                         <PiPlusBold size={12} className='-ml-2' />
                         <h6 className='font-[800] ml-2 text-[15px]'>Create</h6>
                       </button>  
                        
                        <div className='flex mt-2 justify-between'>
                            
                            <h6 className='text-[12px] font-[700]'>{monthNames[calendar_report?.month]} - {calendar_report?.year}</h6>

                            <div className='flex'>
                            <HiChevronLeft className='mx-1 text-slate-400' onClick={()=>resetmonth(0)} />
                            <HiChevronRight className='mx-1 text-slate-400' onClick={()=>resetmonth(1)} />
                            </div>  
                        
                        </div>
                        <div className='grid grid-cols-7 border-t border-l  my-2'>
                            {weekDays?.map((d,i)=>(
                            <h6 className={`text-[11px] border-b ${![6,13,20,27,34]?.includes(i) && 'border-r'} border-r p-2 text-slate-500 text-center uppercase font-[700]`}>{d?.slice(0,1)}</h6>
                            ))}

                            {new Array(31 + (calendar_report.start_date_index))?.fill('-')?.map((e,i)=>(
                                <div onClick={()=>{i >= (calendar_report.start_date_index) && ((i + 1) - (calendar_report.start_date_index)) <= calendar_report.no_of_days && setdate((i + 1) - calendar_report.start_date_index)}} className={`min-w-[100%] border-b ${![6,13,20,27,34]?.includes(i) && 'border-r'} border-r cursor-pointer flex items-center justify-center max-w-[100%]`}>
                                <h6 className={`text-[10px] ${date !== '' && (date == (i + 1) - (calendar_report.start_date_index)) && 'bg-slate-700 text-white'} p-2 font-[700] text-center text-gray-800 h-[30px] w-[100%] ${i >= calendar_report.start_date_index ? 'opacity-100' : 'opacity-10'} `}>{(i >= calendar_report.start_date_index && ((i + 1) - calendar_report.start_date_index) <= calendar_report.no_of_days ) && (i + 1) - calendar_report.start_date_index}</h6>
                                </div>  
                            ))}

                        </div>  


                        

                </div>}
                <div className='w-[100%]  h-[85vh]'>
                            <div className='grid border-l border-r grid-cols-7 border-b'>
                            {weekDays?.map((d,i)=>(
                                <div className={`min-w-[100%] max-w-[100%] ${i !== (weekDays.length - 1) && 'border-r'}`}>
                                <h6 className='text-[12px] p-2 font-[700] text-center uppercase text-slate-800'>{d?.slice(0,3)}</h6>
                                </div>
                            ))}
                            </div>

                            <div  className='grid border-l  border-r grid-cols-7 '>
                                {new Array(31 + calendar_report.start_date_index)?.fill('-')?.map((e,i)=>(
                                   
                                ((i + 1) - calendar_report.start_date_index) <= calendar_report.no_of_days && 
                                   <div  className={`min-w-[100%] min-h-[18vh] max-h-[18vh]  overflow-y-hidden cursor-pointer border-b relative max-w-[100%] ${![6,13,20,27,34]?.includes(i) && 'border-r'}`}>
                                    
                                    {returnCount((i + 1) - calendar_report.start_date_index) > 0 && 
                                    <h6 className='bg-slate-700 text-white text-[11px] px-[5px] py-[2px] absolute right-0 top-0'>{returnCount((i + 1) - calendar_report.start_date_index)}</h6>
                                    }
                                    
                                    <h6 className={`text-[10px] p-2 font-[700] text-center text-gray-800 w-[100%] ${i >= calendar_report.start_date_index ? 'opacity-100' : 'opacity-10'} `}>{i >= calendar_report.start_date_index && (i + 1) - calendar_report.start_date_index}</h6>
                                    <div className='h-[18vh] overflow-y-scroll'>
                                    {returnData((i+1) - calendar_report.start_date_index)?.length > 0 ?
                                        <div className=' px-1 text-[11px]'>
                                            {returnData((i+1) - calendar_report.start_date_index)?.length > 0 &&
                                                returnData((i+1) - calendar_report.start_date_index)?.map((d)=>(
                                                <h6 onClick={()=>{datamodaldata(d);setdataModal(true)}}   className='bg-green-700 px-1 mb-[2px] rounded text-white '>{d?.title}</h6>
                                                ))
                                            }
                                            {returnData((i+1) - calendar_report.start_date_index)?.length > 0 &&
                                            <div className='min-h-[50px] max-h-[50px]' onClick={()=>setdataModal(true)}></div>}
                                        </div>
                                        :
                                        <div className='w-[100%] h-[100%]' onClick={()=>{i >= calendar_report.start_date_index && setdataModal(true);resetform()}}>

                                        </div>}      
                                    </div>  
                                    </div>
                                ))}

                        
                            </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Calendar