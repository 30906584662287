import { deleteRequest, get, post, postfd, publicPost, put } from "../../helpers/apihelpers";

export const CreateFidelitusCorpEventVisitorService=async(data)=>{
    try {
        const result = await post(`api/corp/event_visitor/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateFidelitusCorpEventVisitorService=async(data,id)=>{
    try {
        const result = await put(`api/corp/event_visitor/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetFidelitusCorpEventVisitorService=async(page,text,from_date,to_date,id)=>{
    try {
        const result = await get(`api/corp/event_visitor/get?page=${page}&text=${text}&from_date=${from_date}&to_date=${to_date}&event_id=${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteFidelitusCorpEventVisitorService=async(id)=>{
    try {
        const result = await deleteRequest(`api/corp/event_visitor/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DownloadFidelitusCorpEventVisitorService=async(page,text,from_date,to_date,)=>{
    try {
        const result = await get(`api/corp/event_visitor/download_data?page=${page}&text=${text}&from_date=${from_date}&to_date=${to_date}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UploadFidelitusCorpEventVisitorService=async(data)=>{
    try {
        const result = await postfd(`api/corp/event_visitor/upload_file`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}