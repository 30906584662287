import React,{useState,useEffect} from 'react'
import { TextAreaInput1, TextInput1 } from '../../components/input'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button'
import { toast } from 'react-hot-toast'
import GoBack from '../../components/back/GoBack'
import { useLocation, useNavigate } from 'react-router-dom'
import TaskMenu from './TaskMenu'
import { CreateDailyTaskService, UpdateDailyTaskService } from '../../services/DailyTaskServices'
// import LeadMenu from '../LeadMenu'
import {BiCheckbox,BiCheckboxSquare} from 'react-icons/bi'
import { GetDepartmentService } from '../../services/DepartmentServices'
import { Modal, Select } from 'antd'
import { GetSearchService } from '../../services/AuthServices'
import {BiErrorCircle} from 'react-icons/bi'
import Uploader from '../../components/Uploader'
import { useSelector } from 'react-redux'
import { AiOutlinePlus,AiOutlineDelete } from 'react-icons/ai'
import { CreateReminderService } from '../../services/ReminderServices'
import * as XLSX from 'xlsx';
import {FiUpload} from 'react-icons/fi'
import {IoClose} from 'react-icons/io5'
import { Tooltip } from '@mui/material'

function TaskCE() {

  const {state,pathname} = useLocation() 
  const navigator = useNavigate()
  
  // console.log("state",state)

  const user_id = useSelector(state=>state.Auth.id)
  const roles = useSelector(state=>state.Auth.roles)
// console.log("user",user_id)
  
  const path = pathname?.split('/')[pathname?.split('/')?.length - 1]
  const path1 = pathname?.split('/')[pathname?.split('/')?.length - 3]

  // console.log("path",path)
  // console.log("path1",path1)


  const [singleData,setsingleData] = useState({work_description:'',remarks:'',department:'',slots:'',related_to:'',completed:false,assigned_to:'',dead_line:'',timestamp:'',list_data:'',from_date:'',to_date:'',choose_date_range:false,})
  const [error,seterror] = useState({work_description:'',remarks:'',related_to:'',department:'',slots:'',assigned_to:'',dead_line:'',timestamp:'',list_data:'',from_date:'',to_date:'',choose_date_range:''})
  const [departmentArr,setdepartmentArr] = useState([])
  const [summary,setsummary] = useState([])
  const [modal,setmodal] = useState(false)
  const [users,setusers] = useState([])
  const [fileData, setFileData] = useState(null);

  const [data,setdata] = useState({title:'',description:'',date:'',date1:'',time:'',added:false,expired:false,timestamp:''})
  const [error1,seterror1] = useState({title:'',description:'',date:'',date1:'',time:'',added:false,expired:false,timestamp:''})
  

  const slots = [
    {label:'09.00 AM - 11.00 AM',value:'09.00 AM - 11.00 AM'},
    {label:'11.00 AM - 01.00 PM',value:'11.00 AM - 01.00 PM'},
    {label:'02.00 PM - 04.00 PM',value:'02.00 PM - 04.00 PM'},
    {label:'04.10 PM - 10.00 PM',value:'04.00 PM - 10.00 PM'},
  ]
  



  

  const [loader,setloader] = useState(false)

  useEffect(()=>{
    if(path === 'create' &&  path1 === 'assigned_work' && !roles?.includes('task_manager')){
        navigator(-1)
    }
    if(path === 'edit'){
      let d = state.data
      let summaryList = []
      if(state?.data.summary !== undefined && state?.data.summary.length > 0){
        d?.summary.forEach((s)=>{
            summaryList.push({summary:s})
        })
      }
      setsummary(summaryList)
      let edit_data = {
        ...d,
        work_description:d?.work_description,
        remarks:(d?.remarks !== undefined && d?.remarks !== null) ? d?.remarks : '',
        completed:d?.completed,
        slots:(d?.slots !== undefined && d?.slots !== null) ? d?.slots : '',
        image:(d?.attachments === undefined || d?.attachments === null) ? '' : d?.attachments
    }

    if(d?.department !== undefined && d?.department !== null){
        edit_data['department'] = {label:d?.department?.department_name,value:d?.department?._id}
    }
    if(d?.assigned_to !== undefined && d?.assigned_to !== null){
        edit_data['assigned_to'] = {label:d?.assigned_to?.name,value:d?.assigned_to?._id}
    }
    if(d?.dead_line !== undefined && d?.dead_line !== null){
        edit_data['dead_line'] = d?.dead_line
    }  
    if(d?.remarks !== undefined && d?.remarks !== null){
        edit_data['remarks'] = d?.remarks
    }  

    // console.log("edit_data",edit_data)

    // if(d?.image !== undefined && d?.remarks !== null){
    //     edit_data['remarks'] = d?.remarks
    // }  

      setsingleData({...edit_data})
    }
    getoptions()
  },[])

  async function getoptions(){
    const response = await GetDepartmentService()
    let arr = []

    response?.data?.datas?.forEach((d)=>{
        arr.push({value:d?.id,label:d?.department_name})
    })

    setdepartmentArr(arr)
  } 

  async function searchfunction(v){
   
    const response = await GetSearchService(v,1)
    let arr = []
    response?.data?.datas?.forEach((d)=>{
    arr.push({value:d?._id,label:`${d?.name} : [${d?.department_id[0]?.department_name}]`})
    })
    setusers(arr)
    
  }

  // console.log("data",singleData)

  async function submitform(){
    setloader(true)
    // if(state?.type === 'daily_tasks'){
        if(!singleData.work_description){
            seterror({...error,work_description:'The work description field is required'})
        }else if(state?.type === 'assigned_work' && !singleData.assigned_to){
            seterror({...error,assigned_to:'The assigned to field is required'})
        }else if(state?.type === 'assigned_work' && !singleData?.dead_line){
            seterror({...error,dead_line:'The dead line field is required'})
        }else if((path === 'edit' || singleData.completed) && !singleData?.remarks){
            seterror({...error,remarks:'The remarks field is required'})
        } else{
            const summaryList = []
                    summary?.forEach((s)=>{
                        if(s?.summary !== ''){
                          summaryList.push(s?.summary)
                        }
                    })
            let send_data = {
                work_description:singleData.work_description,
                related_to:state?.stage?._id,
                completed:singleData?.completed,
                remarks:singleData?.remarks,
                dead_line:singleData?.dead_line,
                slots:singleData?.slots === '' ? '' : singleData?.slots?.label,
                summary:summaryList
            }

            if(singleData?.department?.value !== undefined){
                send_data['department'] = singleData?.department?.value
            }

            if(data.added){
              send_data['reminder_added'] = true
              send_data['reminder'] = data
              send_data['reminder']['date_time'] = data.date + ' ' + data.time
            }else{
              send_data['reminder_added'] = false
            }
            if(path === 'edit'){
                const summaryList = []
                    summary?.forEach((s)=>{
                        if(s?.summary !== ''){
                          summaryList.push(s?.summary)
                        }
                    })
                let send_data = {
                    
                    work_description:singleData.work_description,
                    related_to:state?.stage?._id,
                    stage:singleData?.completed ? '3' : '2',
                    dead_line:singleData?.dead_line,
                    remarks:singleData?.remarks,
                    completed:singleData?.completed,
                    slots:singleData?.slots === '' ? '' : singleData?.slots?.label === undefined ? singleData?.slots : singleData?.slots?.label,
                    summary:summaryList
                }
                if(singleData?.department?.value !== undefined){
                    send_data['department'] = singleData?.department?.value
                }
                const fd = new FormData()

                if(state?.type === 'assigned_work'){
                   fd.append('work_description',singleData?.work_description)
                   fd.append('related_to',state?.stage?._id)
                   fd.append('stage',singleData?.work_description)
                   fd.append('remarks',singleData?.remarks)
                   fd.append('completed',singleData?.completed ? '1' : '0')
                   fd.append('slots',singleData?.slots === '' ? '' : singleData?.slots?.label === undefined ? singleData?.slots : singleData?.slots?.label)
                   fd.append('summary',summaryList)

                   if(singleData?.assigned_to?.value !== undefined){
                   fd.append('assigned_to',singleData?.assigned_to?.value)
                }

                if(singleData?.image?.name !== undefined){
                   fd.append('image',singleData?.image)
                    // send_data['image'] = singleData?.image
                }
                fd.append('dead_line',singleData?.dead_line)
                // send_data['dead_line'] = singleData?.dead_line
                }   

                    const response = await UpdateDailyTaskService(state?.type === 'assigned_work' ? fd : send_data,state?.data?._id)
                    if(response.status === 200){
                        setloader(false)
                        toast.success('Job Updated Successfully')
                        resetform() 
                    }
              
            }else{
            if(state?.type === 'assigned_work'){
                if(singleData?.assigned_to?.value !== undefined){
                    send_data['assigned_to'] = singleData?.assigned_to?.value
                }

                if(singleData?.image?.name !== undefined){
                    send_data['image'] = singleData?.image
                }

                send_data['dead_line'] = singleData?.dead_line
    
            }


            if(singleData?.timestamp !== '' ){
              send_data['createdAt'] = singleData?.timestamp
            }

            if(singleData?.from_date !== '' && singleData?.to_date !== '' && fileData.length > 0){
              send_data['from_date'] = singleData?.from_date
              send_data['to_date'] = singleData?.to_date
              send_data['list_data'] = fileData
              send_data['choose_date_range'] = singleData?.choose_date_range
              send_data['slotsArr'] = [...slots?.map((s)=>s?.label)]
            }

            // send_data['completed'] = true
            // send_data['stage'] = 3


            const response = await CreateDailyTaskService(send_data)
            if(response.status === 201){
                setloader(false)
                toast.success('Job Added Successfully')
                resetform() 
            }
              
            }
        }
    
  }


  function resetform(){
        setsingleData({work_description:'',remarks:'',department:'',related_to:'',completed:false,dead_line:'',assigned_to:'',image:'',timestamp:'',list_data:'',from_date:'',to_date:'',choose_date_range:false})
        seterror({work_description:'',remarks:'',department:'',related_to:'',completed:false,dead_line:'',assigned_to:'',image:'',timestamp:'',list_data:'',from_date:'',to_date:'',choose_date_range:''})
        if(path === 'edit'){
            navigator(-1)
        }
       
  }

  async function handleSelect(e,e1){
        if(e1 === 'department'){
         setsingleData({...singleData,department:departmentArr?.find((d)=>d.value === e)})
         seterror({...error,department:''})
        }
        if(e1 === 'assigned_to'){
         setsingleData({...singleData,assigned_to:users?.find((d)=>d.value === e)})
         seterror({...error,assigned_to:''})
        }
        if(e1 === 'slots'){
         setsingleData({...singleData,slots:slots?.find((d)=>d.value === e)})
         seterror({...error,slots:''})
        }
  }    

  function handlechange(e){
        setsingleData({...singleData,[e.target.name] : e.target.value})
        seterror({...error,[e.target.name] : ''})
  }


  function handlechange1(e,i){
        let summaryList = [...summary]
        summaryList[i]['summary'] = e.target.value
        setsummary(summaryList)
  }

  async function setaddfollwup(){
        let summaryList = [...summary]
        summaryList.push({summary:''})
        setsummary(summaryList)
  }

  async function deletefollowup(i){
      let summaryList = [...summary]
      summaryList.splice(i,1)
      setsummary(summaryList)
  }

  async function handlechange2(e){
    setdata({...data,[e.target.name] : e.target.value})
    seterror1({...error1,[e.target.name] : ''})
  }


  async function addreminder(){
    if(!data.date){
      // console.log("1")
      seterror1({...error1,date:'This Field is required'})
      setdata({...data,added:false})
    }else  if(!data.time){
      // console.log("2")
      seterror1({...error1,time:'This Field is required'})
      setdata({...data,added:false})
    }else  if(!data.title){
      // console.log("3")
      seterror1({...error1,title:'This Field is required'})
      setdata({...data,added:false})
    }else  if(!data.description){
      // console.log("4")
      seterror1({...error1,description:'This Field is required'})
      setdata({...data,added:false})
    }else{
      // console.log("5")
        setdata({...data,added:true})
        if(path === 'edit'){
          addreminderfunc(state?.data?._id)
        }else{
          setmodal(false)
        }
    }

  }

  async function addreminderfunc(v){
    let oldData = {...data}
    oldData['on_date_time'] = data.date + ' ' + data.time
    oldData['type'] = 'TaskSchema'
    oldData['type_id'] = v
    oldData['repeat'] = 'Once'
    oldData['notify'] = 'Email'
    delete oldData.date
    delete oldData.time
    delete oldData.added
    delete oldData.expired

    const response = await CreateReminderService(oldData)
    if(response?.status === 200){
      toast.success("Reminder Created Successfully")
      setmodal(false)
      setdata({title:'',description:'',date:'',date1:'',time:'',added:false,expired:false})
      seterror1({title:'',description:'',date:'',date1:'',time:'',added:false,expired:false})
    }

  }



  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = XLSX.utils.sheet_to_json(worksheet);
      json.length > 0 ? setFileData(json) : toast.error("Invalid File Format")
    };
    reader.readAsArrayBuffer(file);
  };

  function removeimageuploadfunc(){
    setFileData(null)
  }



  return (
    <div className='flex min-h-screen max-h-screen h-screen '>

      <Modal open={modal} width={280} footer={false} closable={false}>
      <h6 className='text-[14px] font-[700]'>Create Reminder for the task</h6>  
            <h6 className='bg-slate-100 text-[10px] font-[500] p-1'>Use the below form to create the reminder to trigger for you</h6> 
           
              <div className='flex justify-between'>
              <div className='w-[48%]'>
              <h6 className='text-[11px] font-[600] mb-1 mt-2' >Date</h6>   
              <div className='border border-slate-300 '>
              <input type='date' value={data?.date} onChange={(v) => {setdata({...data,date:v.target.value});seterror1({...error1,date:''})}}  placeholder='' bordered={false} className='text-[12px] px-1 py-1.5 focus:outline-none w-[100%]' /> 
             
              </div>
              {error1?.date !== '' &&
              <div className='flex items-center mt-1'>
                  <BiErrorCircle className='text-red-500' size={14} />
                  <span className='text-[10px] text-red-500 ml-1'>{error1?.date}</span>
              </div>}
              </div>

              <div className='w-[48%]'>
              <h6 className='text-[11px] font-[600] mb-1 mt-2' >Time</h6>   
              <div className='border border-slate-300 '>
              <input type='time' value={data?.time} onChange={(v) => {setdata({...data,time:v.target.value});seterror1({...error1,time:''})}}  placeholder='' bordered={false} className='text-[12px] px-1 py-1.5 focus:outline-none w-[100%]' /> 
              
              </div>
              {error1?.time !== '' &&
              <div className='flex items-center mt-1'>
                  <BiErrorCircle className='text-red-500' size={14} />
                  <span className='text-[10px] text-red-500 ml-1'>{error1?.time}</span>
              </div>}
            </div>
            </div>

            <TextInput1 
             mandatory={true}
             label={'Title'}  
             variant="standard"
             name="title"
             type="text"
             value={data?.title}
             error={error1?.title}
             handlechange={handlechange2}
             placeholder=""
             InputLabelProps={{
                 style: { color: '#fff', }, 
             }}
            />


            <TextAreaInput1 
                mandatory={true}
                label={'Description'}  
                variant="standard"
                name="description"
                type="text"
                value={data?.description}
                error={error1?.description}
                handlechange={handlechange2}
                placeholder=""
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>


<div className='flex mt-2 border-t pt-2 w-full items-end '>
                <div className='mr-2'>
                <ButtonOutlinedAutoWidth btnName="Cancel" onClick={()=>setmodal(false)} />
                </div>
                <ButtonFilledAutoWidth btnName="Save" onClick={()=>addreminder()} />
            </div>

      </Modal>
       <TaskMenu />
           
           <div className='flex min-w-[300px] max-w-[300px]'>
           <div className=' border-r pr-5 border-slate-200 pl-5 min-h-screen max-h-screen h-screen overflow-y-scroll overflow-x-hidden' >
            <GoBack />
            
            <div className='border-b pb-2'>
                <h6 className='text-[13px] mb-1 font-[800]'>Create/Edit the Daily Task Done By You</h6>
                <h6 className='text-[12px] bg-slate-200 p-2'>Use the Below form to add the daily task report </h6>
                
            </div>

           
            {(state?.type === 'daily_tasks' || state?.type === 'project_work' || state?.type === 'external_work' || state?.type === 'assigned_work') &&
            <>
             <TextAreaInput1  readOnly={path === 'create' ? false : (path === 'edit' && singleData?.created_by === user_id) ? false : true} value={singleData?.work_description} mandatory={true} error={error?.work_description} handlechange={handlechange} name="work_description" label="Work Description" />

              <h6 className='text-[12px] font-semibold mb-1 mt-1'>Department </h6>
                <Select
                value={singleData.department} 
                error={error.department}
                bordered={false}
                disabled={path === 'create' ? false : (path === 'edit' && singleData?.created_by === user_id) ? false : true}
                placeholder="" 
                onChange={(e)=>handleSelect(e,'department')} 
                options={departmentArr} 
                className='w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none  focus:border-transparent focus:ring-offset-0 focus:shadow-none'
                />

                <h6 className='text-[12px] font-semibold mb-1 mt-1'>Slots </h6>
                <Select
                value={singleData.slots} 
                error={error.slots}
                bordered={false}
                disabled={path === 'create' ? false : (path === 'edit' && singleData?.created_by === user_id) ? false : true}
                placeholder="" 
                onChange={(e)=>handleSelect(e,'slots')} 
                options={slots} 
                className='w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none  focus:border-transparent focus:ring-offset-0 focus:shadow-none'
                />

              {path1 !== 'assigned_work' && 
              <>
              <h6 className='text-[11px] font-[600] mb-1 mt-[6px]'>Task Deadline </h6>
              <input className='border outline-none focus:none focus:ring-0 p-1.5 text-[11px] w-full  text-[11px] border-gray-300 ' type='Date' value={singleData?.dead_line?.slice(0,10)} onChange={(e)=>{setsingleData({...singleData,dead_line:e.target.value});seterror({...error,dead_line:''})}} />
              </>}

         
             {state?.type === 'assigned_work' &&
             <>
              <h6 className='text-[12px] font-semibold mb-1 mt-1'>Assigned To </h6>
              <div className='w-[100%] max-w-[275px]'>
              <Select
                showSearch 
                filterOption={false} 
                value={singleData.assigned_to} 
                error={error.assigned_to}
                bordered={false}
                closable={true}
                allowClear={true}
                width={270}
                disabled={path === 'create' ? false : (path === 'edit' && singleData?.created_by === user_id) ? false : true}
                placeholder="" 
                onChange={(e)=>handleSelect(e,'assigned_to')} 
                options={users}
                onSearch={searchfunction}
                className='w-full border   outline-0 focus:outline-0 focus:ring-0 focus:border-none  focus:border-transparent focus:ring-offset-0 focus:shadow-none'
                />
                </div>

                {error?.assigned_to !== '' && error?.assigned_to !== undefined &&
                <div className='flex items-center mt-1'>
                <BiErrorCircle className='text-red-500' size={14} />
                <span className='text-[10px] text-red-500 ml-1'>{error?.assigned_to}</span>
                </div>}

              <h6 className='text-[11px] font-[600] mb-1 mt-[6px]'>Project Deadline </h6>
              <input readOnly={path === 'create' ? false : (path === 'edit' && singleData?.created_by === user_id) ? false : true} className='border outline-none focus:none focus:ring-0 p-1.5 text-[11px] w-full  text-[11px] border-gray-300 ' type='Date' value={singleData?.dead_line?.slice(0,10)} onChange={(e)=>{setsingleData({...singleData,dead_line:e.target.value});seterror({...error,dead_line:''})}} />
              </>}



              <div className='flex items-center mt-0 mr-2 -ml-1 mt-2'>
                <h6 onClick={()=>setmodal(!modal)} className="cursor-pointer text-[9.5px] bg-green-700  p-[4px] font-[700] rounded px-[10px] text-white  ml-1">Reminder</h6> 
              </div>
            
             <>
               {singleData?.created_by  === user_id &&
                <div className='flex items-center mt-0 mr-2 -ml-1 mt-1'>
                {singleData?.completed ?  <BiCheckboxSquare size={22} className='text-slate-600' onClick={()=>setsingleData({...singleData,completed:!singleData.completed})} />  : <BiCheckbox size={22} className='text-slate-300' onClick={()=>setsingleData({...singleData,completed:!singleData.completed})} /> }
                <h6 className="text-[11.5px] font-[500] ml-1">COMPLETED</h6> 
                </div>
                }


               
                
             {state?.type !== 'assigned_work' &&
             <TextAreaInput1  value={singleData?.remarks} mandatory={true} error={error?.remarks} handlechange={handlechange} name="remarks" label="Remarks" />}

             {((state?.type === 'assigned_work' && singleData?.assigned_to?.value  === user_id) || (state?.type === 'assigned_work' && path === 'edit')) &&
             <TextAreaInput1  value={singleData?.remarks} mandatory={true} error={error?.remarks} handlechange={handlechange} name="remarks" label="Remarks" />}

             {((state?.type === 'assigned_work' && singleData?.assigned_to?.value  === user_id) || (state?.type === 'assigned_work' && path === 'edit')) &&
             <>
              <h6 className='text-[11px] font-[600] mb-1 mt-[6px]'>Work Attachment </h6>
              <Uploader image={singleData?.image}  setimagefunc={(e)=>{setsingleData({...singleData,image:e});seterror({...error,singleData:''})}}  removeimageuploadfunc = {()=>{setsingleData({...singleData,image:''});seterror({...error,singleData:''})}} />
              </>
             }
              {roles?.includes('it_tricks') &&
              <>
              
              <div className='flex items-center'>
              {singleData?.choose_date_range ?  <BiCheckboxSquare size={22} className='text-slate-600' onClick={()=>setsingleData({...singleData,choose_date_range:!singleData.choose_date_range})} />  : <BiCheckbox size={22} className='text-slate-300' onClick={()=>setsingleData({...singleData,choose_date_range:!singleData.choose_date_range})} />}
              <h6 className="text-[11.5px] font-[500] ml-1"> Choose Date Range </h6>
              </div>
              {singleData?.choose_date_range &&
              <div>
                <div>
                <h6 className='text-[11px] font-[600] mb-1 mt-[6px]'>From Date </h6>
                <input className='border outline-none focus:none focus:ring-0 p-1.5 text-[11px] w-full  text-[11px] border-gray-300 ' type='Date' value={singleData?.from_date?.slice(0,10)} onChange={(e)=>{setsingleData({...singleData,from_date:e.target.value});seterror({...error,from_date:''})}} />
                </div>

                <div>
                <h6 className='text-[11px] font-[600] mb-1 mt-[6px]'>To Date </h6>
                <input className='border outline-none focus:none focus:ring-0 p-1.5 text-[11px] w-full  text-[11px] border-gray-300 ' type='Date' value={singleData?.to_date?.slice(0,10)} onChange={(e)=>{setsingleData({...singleData,to_date:e.target.value});seterror({...error,to_date:''})}} />
                </div>

                <h6 className='text-[11px] font-[600] mb-1 mt-[6px]'>Excel List Data </h6>

                {fileData === null ? 
                <form onClick={()=>document.querySelector('.input-field').click()} className={`p-4 flex flex-col items-center justify-center cursor-pointer border-dashed border border-slate-400 `}>
                    <FiUpload size={24} />
                    <span className='font-bold text-[10px] mt-1 text-center'>Click To Upload Photo</span>
                    <span className='font-bold text-[7px] mt-0 text-center'>Supported Type JPEG,PNG,SVG,WEBP,PDF,XLSX,PPT</span>
                    <input type='file' onChange={handleFileChange} accept="*" className='input-field' hidden />
                </form> 
                :
                <div className='p-2 border relative flex flex-col  cursor-pointer'>
                    <Tooltip title='Delete'><IoClose className='absolute top-3 right-2' onClick={removeimageuploadfunc}/></Tooltip>
                    <h6 className='text-[12px] truncate w-48 ml-0'>File Selected</h6>
                </div>}
      
              </div>}

              {!singleData?.choose_date_range &&
              <>
              <h6 className='text-[11px] font-[600] mb-1 mt-[6px]'>Created Date </h6>
              <input className='border outline-none focus:none focus:ring-0 p-1.5 text-[11px] w-full  text-[11px] border-gray-300 ' type='Date' value={singleData?.timestamp?.slice(0,10)} onChange={(e)=>{setsingleData({...singleData,timestamp:e.target.value});seterror({...error,timestamp:''})}} />
              </>}
              </>
              }


            
           




             {/* {selectedData === '' && */}
              <div className='flex mt-2 border-t pt-2 w-full items-end '>
                  <div className='mr-2'>
                  <ButtonOutlinedAutoWidth btnName="Cancel" onClick={()=>navigator(-1)} />
                  </div>
                  <ButtonFilledAutoWidth btnName="Save" onClick={submitform} />
              </div>

              {/* <div className='w-80 pl-5 mt-5 min-h-screen max-h-screen h-screen overflow-y-screen overflow-x-hidden'>
              {state?.type === 'project_work' &&
                <div className='w-[100%]'>
                  <div className='flex w-[100%] items-center justify-between'>
                    <h6 className='text-[11px] font-[600] mb-1 mt-[6px]'>Add Sub Task Follow Up </h6>
                    <AiOutlinePlus size={20} onClick={setaddfollwup} className='cursor-pointer bg-slate-100 p-1' />
                  </div>
                  {summary?.map((s,i)=>(
                    <div className='relative'>
                    <div className='flex items-center justify-between mb-1 mt-[6px]'>    
                    <h6 className='text-[11px] font-[600] '>Follow Up {i+1} </h6>
                    <AiOutlineDelete size={14} onClick={()=>deletefollowup(i)} className='cursor-pointer' />
                    </div>
                    <TextAreaInput1 name={`Summary ${i+1}`} value={s?.summary} handlechange={(e)=>handlechange1(e,i)} />
                    </div>
                  ))}
                  <Uploader image={singleData?.image}  setimagefunc={(e)=>{setsingleData({...singleData,image:e});seterror({...error,singleData:''})}}  removeimageuploadfunc = {()=>{setsingleData({...singleData,image:''});seterror({...error,singleData:''})}} />
                  </div>
                }
              </div> */}

           </>

            </>}
           
           </div>
           </div>


    </div>
  )
}

export default TaskCE