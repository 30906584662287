import React, { useState,useEffect } from 'react'
import { TextInput } from '../../../components/input'
import {BiErrorCircle} from 'react-icons/bi';
import {  ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../../components/button';
import { Select } from 'antd';
import { SearchUser } from '../../../services/AuthServices';
import GoBack from '../../../components/back/GoBack';
import { CreateTargetServices, UpdateTargetServices } from '../../../services/AdminServicesfile/TargetServices';
import { toast } from 'react-hot-toast';
import SettingsMenu from '../../staticscreens/SettingsMenu';
import { useLocation, useNavigate } from 'react-router-dom';



function TargetAdd() {

  const [data,setdata] = useState({user:'',salary:['','','','','','','','','','','',''],achieve_times:['','','','','','','','','','','',''],target:['','','',''],target_achieved_based_period:['','','',''],parked_amount:['0','0','0','0'],target_year:'',vertical_target:'',target_achieved:'',last_year_shortfall:''})   
  const [error,seterror] = useState({user:'',salary:['','','','','','','','','','','',''],achieve_times:['','','','','','','','','','','',''],target:['','','',''],target_achieved_based_period:['','','',''],parked_amount:['','','',''],target_year:'',vertical_target:'',target_achieved:'',last_year_shortfall:''})   
  const [users,setusers] = useState([])
  const navigator = useNavigate()

  const {pathname,state} = useLocation()
  const path = pathname?.split('/')[pathname?.split('/')?.length - 1]


  useEffect(()=>{
    if(path === 'edit'){
      setdata({
        user:{label:state?.user?.name,value:state?.user?._id},
        salary:[],
        achieve_times:[],
        target:[],
        salary:state?.salary?.length === 12 ? state?.salary : ['','','','','','','','','','','',''],
        achieve_times:state?.achieve_times?.length === 12 ? state?.achieve_times : ['','','','','','','','','','','',''],
        target:state?.target?.length === 4 ? state?.target : ['','','',''],
        parked_amount:state?.parked_amount?.length === 4 ? state?.parked_amount : ['','','',''],
        target_achieved_based_period:state?.target_achieved_based_period?.length === 4 ? state?.target_achieved_based_period : ['','','',''],
        target_year:state?.target_year,
        target_achieved:state?.target_achieved !== undefined ? state?.target_achieved : 0,
        last_year_shortfall:state?.last_year_shortfall !== undefined ? state?.last_year_shortfall : 0,
        vertical_target:state?.vertical_target
      })

    }
  },[])

 
  function handlechange(e,k){
    // console.log("e.target.name",e.target.name)
    // console.log("e.target.value",e.target.value)
    // console.log("k",k)
    if(e.target.name === 'achieve_times'){
      // console.log("data",data)
          let target = [...data.target]
          // console.log("target",target)
          let achieves = [...data.achieve_times]
          achieves[k] = e.target.value


          // console.log("achieves",achieves)

          // console.log("achieves",data?.salary,data?.salary)
          if(data?.salary?.filter(f=>f === '')?.length === 0  && achieves?.filter(f=>f === '')?.length === 0){
            target[0] = (parseInt(data?.salary[0]) * parseInt(achieves[0])) + (parseInt(data?.salary[1]) * parseInt(achieves[1])) + (parseInt(data?.salary[2]) * parseInt(achieves[2])) 
            target[1] = (parseInt(data?.salary[3]) * parseInt(achieves[3])) + (parseInt(data?.salary[4]) * parseInt(achieves[4])) + (parseInt(data?.salary[5]) * parseInt(achieves[5])) 
            target[2] = (parseInt(data?.salary[6]) * parseInt(achieves[6])) + (parseInt(data?.salary[7]) * parseInt(achieves[7])) + (parseInt(data?.salary[8]) * parseInt(achieves[8])) 
            target[3] = (parseInt(data?.salary[9]) * parseInt(achieves[9])) + (parseInt(data?.salary[10]) * parseInt(achieves[10])) + (parseInt(data?.salary[11]) * parseInt(achieves[11]))
           setdata({...data,target:target,achieve_times:achieves})
          }else{
            setdata({...data,achieve_times:achieves})
          }

         
       
    }else if(e.target.name === 'salary'){
      let target = [...data.target]
      let salary = [...data.salary]
      salary[k] = e.target.value
      if(salary?.filter(f=>f === '')?.length === 0  && data?.achieve_times?.filter(f=>f === '')?.length === 0){
          target[0] = (parseInt(salary[0]) * parseInt(data.achieve_times[0])) + (parseInt(salary[1]) * parseInt(data.achieve_times[1])) + (parseInt(salary[2]) * parseInt(data.achieve_times[2])) 
          target[1] = (parseInt(salary[3]) * parseInt(data.achieve_times[3])) + (parseInt(salary[4]) * parseInt(data.achieve_times[4])) + (parseInt(salary[5]) * parseInt(data.achieve_times[5])) 
          target[2] = (parseInt(salary[6]) * parseInt(data.achieve_times[6])) + (parseInt(salary[7]) * parseInt(data.achieve_times[7])) + (parseInt(salary[8]) * parseInt(data.achieve_times[8])) 
          target[3] = (parseInt(salary[9]) * parseInt(data.achieve_times[9])) + (parseInt(salary[10]) * parseInt(data.achieve_times[10])) + (parseInt(salary[11]) * parseInt(data.achieve_times[11]))
          setdata({...data,target:target,salary:salary})
        }else{
          setdata({...data,salary:salary})
        }
    }else if(e.target.name === 'target_achieved_based_period'){
    }else if(e.target.name === 'parked_amount'){
      let parked_amount = [...data.parked_amount]
      parked_amount[k] = e.target.value
      setdata({...data,parked_amount:parked_amount})
    }else{
      setdata({...data,[e.target.name] : e.target.value})
      seterror({...error,[e.target.name]:''})
    }
  }
  
 

  

  async function submitdata(){
     if(!data.user){
      seterror({...error,user:'The User Field is required'})
     }else if(data.salary === ''){
      seterror({...error,salary:'The Salary Field is required'})
     }else if(data.achieve_times === ''){
      seterror({...error,achieve_times:'The Achieve Time Field is required'})
     }else if(data.target === ''){
      seterror({...error,target:'The Target Field is required'})
     }else{
      let send_data = {...data}
      send_data['incentive'] = state?.incentive
      send_data['user'] = data?.user?.value

      console.log("send_data",send_data)
      // send_data['target_year'] = JSON.stringify(new Date().getFullYear())
      if(path === 'edit'){
        const response = await UpdateTargetServices(state?._id,send_data)
        if(response.status === 200){
          navigator(-1)
          toast.success('Target Updated Successfully')
          resetform()
        }else if(response.status === 422){
          seterror({...error,user:response?.data?.message})
        }
      }else{
        const response = await CreateTargetServices(send_data)
        if(response.status === 201){
          toast.success('Target Added Successfully')
          resetform()
        }else if(response.status === 422){
          seterror({...error,user:response?.data?.message})
        }
      }
     }
  }

  async function onSearch(v){
    // console.log("value here",v)
    const response = await SearchUser(v)
    let arr = []
    response?.data?.forEach((d)=>{
     arr.push({value:d?._id,label:d?.name})
    })
    setusers(arr)
    // console.log("response kp na",response)
  }

  async function onChange(v){
     setdata({...data,user:users.find((u)=>u.value === v)})
     seterror({...error,user:''})
  }

  function resetform(){
    setdata({user:'',salary:['','','','','','','','','','','',''],achieve_times:['','','','','','','','','','','',''],target:['','','',''],target_achieved_based_period:['','','',''],target_year:'',vertical_target:'',target_achieved:'',last_year_shortfall:'',parked_amount:['0','0','0','0']})
    seterror({user:'',salary:['','','','','','','','','','','',''],achieve_times:['','','','','','','','','','','',''],target:['','','',''],target_achieved_based_period:['','','',''],target_year:'',vertical_target:'',target_achieved:'',last_year_shortfall:'',parked_amount:['','','','']})
  }

  return (
    <div>
        
        <div className='flex'>
          <SettingsMenu />
        <div className='px-4'>
        <div className=' pt-4'>
          <GoBack/>
        <div className='w-60'>
        <h6 className='font-[700] mb-1'>Create/Edit Target</h6>
        <h6 className='text-[11px] bg-slate-100 p-1 font-[500]'>Use the below form to create or update a target for the employee</h6>
        

        <div className='mt-2'>
        <h6 className='text-[12px] font-[600] mb-1' >{'Search User'}</h6>
        </div>
        <Select
            showSearch
            bordered={false}
            className='w-full border border-gray-300  rounded-[0px] outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none' 
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            value={data?.user === '' ? null : data?.user}
            // placeholder={props?.placeholder}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={users}
        />

               
         {error?.user !== '' && error?.user !== undefined &&
         <div className='flex items-center mt-1'>
         <BiErrorCircle className='text-red-500' size={14} />
         <span className='text-[10px] text-red-500 ml-1'>{error?.user}</span>
         </div>}
         </div>    

        <div className='flex'>
        {[...Array(12).keys()]?.map((k)=>(  
        <TextInput 
            label={'Salary' + k}  
            variant="standard"
            name="salary"
            style='mr-2 min-w-[70px] max-w-[70px]'
            type="number"
            error={error?.salary[k]}
            value={data?.salary[k]}
            handlechange={(e)=>handlechange(e,k)}
            placeholder="Enter employee salary"
        />
        ))}
        </div>  

        <div className='flex'>
        {[...Array(12).keys()]?.map((k)=>(  
        <TextInput 
            label={'Times'}  
            variant="standard"
            name="achieve_times"
            style='mr-2 min-w-[50px] max-w-[50px]'
            type="number"
            error={error?.achieve_times[k]}
            value={data?.achieve_times[k]}
            handlechange={(e)=>handlechange(e,k)}
            placeholder="Enter achieve times"
        />
        ))}


        
        </div> 

        <div className='flex'>
        {[...Array(4).keys()]?.map((k,i)=>(  
        <TextInput 
            label={'Target'}  
            variant="standard"
            name="achieve_times"
            style='mr-2 min-w-[100px] max-w-[100px]'
            type="number"
            error={error?.target[i]}
            value={data?.target[i]}
            handlechange={(e)=>handlechange(e,k)}
            placeholder="Enter achieve times"
        />
        ))}
        </div>    

         <div className='flex'>
        {[...Array(4).keys()]?.map((k,i)=>(  
        <TextInput 
            label={'Achieved'}  
            variant="standard"
            name="target_achieved_based_period"
            style='mr-2 min-w-[100px] max-w-[100px]'
            type="number"
            error={error?.target_achieved_based_period[i]}
            value={data?.target_achieved_based_period !== undefined ? data?.target_achieved_based_period[i] === undefined ? '' : data?.target_achieved_based_period[i] : ''}
            handlechange={(e)=>handlechange(e,k)}
            placeholder="Enter achieve times"
        />
        ))}
        </div>     

          <div className='flex'>
        {[...Array(4).keys()]?.map((k,i)=>(  
        <TextInput 
            label={'Parked Amount'}  
            variant="standard"
            name="parked_amount"
            style='mr-2 min-w-[100px] max-w-[100px]'
            type="number"
            error={error?.parked_amount[i] !== undefined ? error?.parked_amount[i] : ''}
            value={data?.parked_amount[i] !== undefined ? data?.parked_amount[i] : 0}
            handlechange={(e)=>handlechange(e,k)}
            placeholder="Enter achieve times"
        />
        ))}
        </div>            
        

        <div className='w-60'>
        {/* <TextInput 
            label={'Achieve Times'}  
            variant="standard"
            name="achieve_times"
            type="text"
            error={error.achieve_times}
            value={data.achieve_times}
            handlechange={handlechange}
            placeholder="Enter achieve times"
         /> */}

        {/* <TextInput 
            readOnly={true}
            label={'Target'}  
            variant="standard"
            name="target"
            type="text"
            error={error.target}
            value={data.target}
            handlechange={handlechange}
            placeholder="Enter employee target"
                /> */}

        <TextInput 
            label={'Vertical Target'}  
            variant="standard"
            name="vertical_target"
            type="text"
            error={error.vertical_target}
            value={data.vertical_target}
            handlechange={handlechange}
            placeholder="Enter employee vertical target assigned"
                /> 

          <TextInput 
            label={'Target Achieved'}  
            variant="standard"
            name="target_achieved"
            type="text"
            error={error.target_achieved}
            value={data.target_achieved}
            handlechange={handlechange}
            placeholder="Enter employee vertical target assigned"
                /> 

          <TextInput 
            label={'Last Year ShortFall'}  
            variant="standard"
            name="last_year_shortfall"
            type="text"
            error={error.last_year_shortfall}
            value={data.last_year_shortfall}
            handlechange={handlechange}
            placeholder="Enter employee vertical target assigned"
                />       

        <TextInput 
            label={'Target Year'}  
            variant="standard"
            name="target_year"
            type="text"
            error={error.target_year}
            value={data.target_year}
            handlechange={handlechange}
            placeholder="Enter employee vertical target assigned"
                /> 
        </div>
       



         <div className='flex mt-4'>
            <ButtonOutlinedAutoWidth btnName={'Back'} onClick={()=>{navigator(-1)}} />
            <h6 style={{width:'8px'}}></h6>
            <ButtonFilledAutoWidth btnName={'Save'} onClick={submitdata} />
         </div>

        </div> 
        </div>
        </div>
    </div>
  )
}

export default TargetAdd