import { IconButton, Tooltip, setRef } from '@mui/material'
import React,{useState,useEffect} from 'react'
import { useLocation,useNavigate } from 'react-router-dom'
import { CreateLeadsListBasedonIdService} from '../../services/LeadServices';
import {AiOutlineEdit,AiOutlineFileExcel} from 'react-icons/ai';
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';

import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button';
import { Modal, Select} from 'antd';
import { DatePicker } from 'antd';
// import DailyTask from './dailyTask/DailyTask';
import {AiOutlineDelete} from 'react-icons/ai';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import Uploader from '../../components/Uploader';
import { DeleteCallService, DeleteManyCallService, DownloadExcelCallService, FilterCallService, GetCallService, UploadExcelCallService } from '../../services/CallsLeadServices';
import { LuHardDriveDownload } from "react-icons/lu";
import fileDownload from "js-file-download";
import axios from 'axios';

function CallsLeadlist() {

  const location = useLocation();
  const user = useSelector(state=>state.Auth)
  const navigate = useNavigate();
  const [excel,setexcel] = useState({file:'',error:''})

  let path = location?.pathname?.split('/')[2]

  const [step,setstep] = useState(1)
  const [modal,setmodal] = useState(false)

  const [leads,setleads]  = useState([])
  const [leads_selected_list,setleads_selected_list] = useState([])
  

  const [page,setpage] = useState(1)
  const [filter_drawer,setfilter_drawer] = useState(false)

  const [selected_lead,setselected_lead] = useState({})
  const [delete_modal,setdelete_modal] = useState(false)

  const [search,setsearch] = useState({text:'',from_date:'',from_date1:'',to_date:'',to_date1:'',zone:'',country:'',activate:false})


  const stages = [
    {label:'Not Recieved',value:'Not Recieved'},
    {label:'Invalid No',value:'Invalid No'},
    {label:'Not Interested',value:'Not Interested'},
    {label:'Lead Generated',value:'Lead Generated'},
    {label:'Not a Concern Person',value:'Not a Concern Person'}
  ]
 
  

  console.log("user?.user",user?.roles)
 
  useEffect(()=>{
    setpage(1)
    getleads(1) 
  },[])

  useEffect(()=>{
    setpage(1)
    getleads(1) 
    // getReportingMemberDetails(user?.id)    
  },[location.pathname,step])


  useEffect(()=>{
      if(search.activate){
        applyfilterfunction(page)
      }else{
        getleads(page)
      }
  },[page,step])


  // function getReportingMemberDetails(){
  //    console.log("okk")
  // }
  
  async function getleads(page){
      setfilter_drawer(false)
      console.log("page",page)
      console.log("step",step)
      const response =  await GetCallService(page,step);
      setleads(response.data)
   
    
  }

  async function applyfilterfunction(page){
   
    setsearch({...search,activate:true})

    const response = await FilterCallService(page,search?.from_date1,search?.to_date1,search.text,step,search?.type !== '' ? search?.type?.value : '')
    setleads(response.data)
    setfilter_drawer(false) 
  }

  async function resetfunc(){
    setsearch({text:'',from_date:'',to_date:'',activate:false,zone:''})
    getleads(1)
    setpage(1)
  }


  function selected_lead_check_to_group(v){
      if(leads_selected_list?.includes(v)){
        setleads_selected_list([...leads_selected_list.filter(e => e!== v)])
      }else{
        if(leads_selected_list.length < 10){
           setleads_selected_list([...leads_selected_list,v])
        }else{
          toast.error('You can select upto max 10 items')
        }
      }      
  }

  async function createManyContactBasedOnlIST(){
    try{
     const response = await CreateLeadsListBasedonIdService({data:leads_selected_list})
     if(response?.status === 200){
        setleads_selected_list([])
        toast.success(response?.data?.data)
        getleads(page)
     }
    }catch(e){
      // console.log("err",e)
    }
  }

  async function deleteData(d){
    const response = await DeleteCallService(d)
    if(response.status === 200){
      toast.success("Deleted Successfully")
      setdelete_modal(false)
      setselected_lead({})
      getleads(page)
    }
  }

  async function uploadExcel(){
    if(!excel?.file){
      setexcel({...excel,error:'This Field is required!'})
    }else{
      const response = await UploadExcelCallService(excel?.file)
      if(response?.status === 201){
        getleads(1)
        toast.success("Excel Uploaded Successfully")
        setexcel({file:'',error:''})
        setmodal(false)
      }else if(response?.status === 422){
        toast.error("Invalid Excel Format")

      }
    }
  }




  // console.log("leads here",leads)



  async function downloaddata(){
    const response = await DownloadExcelCallService(step)
    let path = response.data.path.replace('public/','')
    handleDownload(`${process.env.REACT_APP_AWS_IMAGE_URL}${path}`,path.split('/')[path.split('/')?.length - 1])
    toast.success("File Downloaded")
  }

  const handleDownload = (url, filename) => {
    axios.get(url, {
        responseType: 'blob',
    })
        .then((res) => {
            fileDownload(res.data, filename)
        })
  }

  async function deleteData(){
    const response = await DeleteManyCallService(step)
    console.log("response?.data",response?.data)
    if(response.status === 200){
       getleads(page)
    }

  }

  

  return (
    <div className='h-screen flex w-[100%] max-h-screen '>
     
      <div className='w-[100%]'>
      <Modal open={modal} width={350} closable={false} footer={false} className='absolute top-0 left-[40%]'>
          <div className='absolute right-6'>
           <a href="https://fidecrmfiles.s3.amazonaws.com/Calls%2BExcel%2BFormat.xlsx" downlaod> <h6 className='text-[10px] cursor-pointer font-[700] flex underline items-center'><AiOutlineFileExcel className='mr-1' /> Download Sample</h6></a>
          </div>
          <h6 className="font-bold text-[13px]  mb-2 w-full">Upload Excel</h6>
          <Uploader image={excel?.file}  setimagefunc={(e)=>{setexcel({...excel,file:e,error:''})}}  removeimageuploadfunc = {()=>setexcel({...excel,file:'',error:''})} />

          <div className='mt-2 flex items-center'>
            <ButtonOutlinedAutoWidth btnName="Close" onClick={()=>setmodal(false)}/>
            <div className='ml-2'>
            <ButtonFilledAutoWidth btnName="Save" onClick={()=>uploadExcel()} />
            </div>
          </div>
      </Modal> 

      <Modal open={delete_modal} width={350} closable={false} footer={false} className='absolute top-0 left-[40%]'>
          <h6 className="font-bold text-[13px]  mb-2 w-full">Delete Data</h6>
          <h6 className="text-[11px]  mb-2 w-full">Are you sure want to delete the selected data which has company name : <span className='font-[800]'>{selected_lead?.company_name}</span></h6>

          <div className='mt-2 flex items-center'>
            <ButtonOutlinedAutoWidth btnName="Close" onClick={()=>setdelete_modal(false)}/>
            <div className='ml-2'>
            <ButtonFilledAutoWidth btnName="Save" onClick={()=>deleteData(selected_lead?._id)} />
            </div>
          </div>
      </Modal> 

      

   
      

    
      {/* {list?.includes(path) ?  */}
        <div  className="w-[98%]">
          <div  >
            <div className='flex items-center justify-between border-b pb-2'>
              <h6 className='font-[700] text-[14px] '>Total Calls Data ({leads?.pagination?.total})</h6>
              <div className='flex items-center'>
                <div className='flex items-center text-[12px] mr-2'>

                <>
                {user?.roles?.includes('admin') &&
                <>
                <AiOutlineDelete onClick={()=>deleteData()}  size={24}  className='mr-1 bg-gray-200 p-1.5' />
                <LuHardDriveDownload onClick={()=>downloaddata()} size={24}  className='mr-1 bg-gray-200 p-1.5' />
               </> 
                }
                <AiOutlineFileExcel onClick={()=>setmodal(true)} size={24}  className='mr-1 bg-gray-200 p-1.5' />
                </>

                  <h6 className='mr-2 font-[600]'>{page == 1 ? leads?.datas?.length > 0 ? 1 : 0 :  (page - 1) * leads?.pagination?.limit } - {leads?.pagination?.limit} of {leads?.pagination?.total} </h6>
                  <IconButton onClick={resetfunc}><BsArrowRepeat size={16} /></IconButton>

                  <div>
                  <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                  <IconButton onClick={()=>{ page < leads?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(leads?.pagination?.totalPages === page || leads?.datas?.length === 0)  ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>

                  </div>
                </div>


                <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.from_date} onChange={(v,v1) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,from_date:v,from_date1:v1})}} /> 

                <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.to_date} onChange={(v,v1) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,to_date:v,to_date1:v1})}} /> 

              
              <div >


                <Select
                  defaultValue={search?.type}
                  value={search?.type}
                  options={stages}
                  onChange={(e)=>setsearch({...search,type:stages?.find((f)=>f?.value === e)})}
                  placeholder="Stage"
                  bordered={false}
                  className='border border-slate-300 rounded-[5px] w-[100px] h-[30px] mr-[10px]'
                />
                  

                <input  id="search_text" placeholder='Search text' type='text' value={search.text} onChange={(e)=>setsearch({...search,text:e.target.value})} className='border py-[3px] focus:ring-0 mr-2 focus:outline-0 text-[14px]  w-28 px-1 rounded-md border-slate-300' />
              
              
              </div> 
              {/* <Select 
                bordered={false}
                value={search?.zone}
                options={options}
                onChange={(e)=>setsearch({...search,zone:e})}
                placeholder={'Zone'}
                className='border rounded-[6px] border-slate-300 h-[30px] mx-2 w-[100px]' 
                
                />      */}

            <ButtonOutlinedAutoWidth btnName="Add Filter" onClick={()=>applyfilterfunction(1)} /> 
            {leads_selected_list?.length > 0 &&
            <div className='ml-2'>
            <ButtonFilledAutoWidth onClick={createManyContactBasedOnlIST} btnName="Create Contact" /> 
            </div>}

            <div className='ml-2'>
            <ButtonFilledAutoWidth btnName="Add Data" onClick={()=>navigate('create')}/> 
            </div>
              </div>
          </div>


          <div className='flex  items-center border-b'>
            <h6 onClick={()=>{setstep(1);setpage(1)}} className={`font-[600] ${step === 1 && 'bg-slate-600 text-white'} text-center cursor-pointer py-1 w-[80px] text-[11px]`}>Pending</h6>
            <h6 onClick={()=>{setstep(2);setpage(1)}} className={`font-[600] ${step === 2 && 'bg-slate-600 text-white'} text-center cursor-pointer py-1 w-[80px] text-[11px]`}>Called</h6>
            <h6 onClick={()=>{setstep(3);setpage(1)}} className={`font-[600] ${step === 3 && 'bg-slate-600 text-white'} text-center cursor-pointer py-1 w-[80px] text-[11px]`}>Follow Up</h6>
          </div>
          
          {leads?.datas?.length === 0 &&
            <div className='grid place-items-center mt-20  items-center justify-center'>
            <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' /> 
            <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
            <h6 className='font-[500] w-[70%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any data based on your current stage please add the data to see list here.</h6>
          </div>
          }


          {leads?.datas?.length > 0 &&
          <div className='max-h-[82vh] mt-4 border-t   border-l border-r overflow-y-scroll'>
          <div className='sticky top-0 z-50 flex  bg-white py-[-5px] border-b border-slate-200 relative'>
              <h6  className='top-0 bg-white z-50 text-[12px] w-[60px]  px-2 py-1 font-[600] text-slate-600 border-r flex items-center justify-center'>SL NO</h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[138px] px-2 py-1 font-[600] text-slate-600 border-r'>Company Name</h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[138px] px-2 py-1 font-[600] text-slate-600 border-r'>Address</h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[100px]  px-2 py-1 font-[600] text-slate-600 border-r'>Contact Name</h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[90px]  px-2 py-1 font-[600] text-slate-600 border-r'>Mobile</h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[130px]  px-2 py-1 font-[600] text-slate-600 border-r'>Email</h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[120px]  px-2 py-1 font-[600] text-slate-600 border-r'>Stage</h6>
              {/* <h6 className='sticky top-0 z-50  text-[12px] w-[120px] px-2 py-1 font-[600] text-slate-600 border-r'>Department</h6> */}
              <h6 className='sticky top-0 z-50  text-[12px] w-[140px] px-2 py-1 font-[600] text-slate-600 border-r'>Requirment</h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[140px] px-2 py-1 font-[600] text-slate-600 border-r'>Summary</h6>
              {/* <h6 className='sticky top-0 z-50  text-[12px] w-[100px]  px-2 py-1 font-[600] text-slate-600 border-r'>Created On</h6> */}
              <h6 className='sticky top-0 z-50  text-[12px] w-[90px]  px-2 py-1 font-[600] text-slate-600 sticky right-0'>Actions</h6>
          </div>
        
          {leads?.datas?.map((d,i)=>(
          <div  key={d?._id} className={`flex z-50 h-full border-b border-slate-200 z-0`}  >
              <h6 onClick={()=>selected_lead_check_to_group(d?._id)} className='text-[12px] w-[60px]  px-2 py-1 font-[600] text-slate-600 cursor-pointer border-r flex items-center justify-center'>{page === 1 ? i+1 : (i+1) + ((page-1)* leads?.pagination?.limit)}</h6>
              <h6 className='text-[12px] font-[500] w-[138px] px-2 py-1 truncate border-r  border-slate-200'>{d?.company_name}</h6>
              <h6 className='text-[12px] font-[500] w-[138px] px-2 py-1 truncate border-r  border-slate-200'>{d?.address}</h6>
              <h6 className='text-[12px] font-[500] w-[100px]  px-2 py-1 truncate border-r  border-slate-200'>{d?.contact_name}</h6>
              <h6 className='text-[12px] font-[500] w-[90px]  px-2 py-1 truncate border-r  border-slate-200'>{d?.phone}</h6>
              <h6 className='text-[12px] font-[500] w-[130px]  px-2 py-1 truncate border-r  border-slate-200'>{d?.email}</h6>
              <h6 className='text-[12px] font-[500] w-[120px]  px-2 py-1 truncate border-r  border-slate-200'>{d?.stage}</h6>
              {/* <h6 className='text-[12px] font-[500] flex w-[120px] px-2 py-1 truncate border-r border-slate-200'>
                {d?.department_id?.department_name !== undefined &&  <p className='border-2 border-white bg-gray-100 px-[4px] py-[2px] text-[8px] dark:border-gray-800 font-[800]'>{d?.department_id?.department_name !== undefined && d?.department_id?.department_name}</p>}
              </h6> */}

              <h6 className={`text-[12px] font-[500] w-[140px] px-2 py-1  truncate  border-r  border-slate-200 ${d?.requirment ? 'block' : 'text-[#fff]'}`}>{d?.requirment ? d?.requirment : 'Not Added'}</h6>
              <h6 className={`text-[12px] font-[500] w-[140px] px-2 py-1 truncate border-r  border-slate-200 ${d?.remarks ? 'block' : 'text-[#fff]'}`}>{d?.remarks ? d?.remarks : 'Not Added'}</h6>
              
              {/* <h6 className='text-[12px] font-[500] w-[100px]  px-2 py-1 truncate border-r  border-slate-200 sticky right-0'>{moment(d?.createdAt).format('ll')}</h6> */}
              <h6  className='text-[12px] w-[90px]  px-2 py-1 flex'>
             

                <Tooltip title="Edit">
                <span><AiOutlineEdit size={13} className="z-10 ml-2" onClick={()=>navigate('/calls/list/edit',{state:d?._id})}/></span>
                </Tooltip>

                {user?.roles?.includes('delete_data') &&
                <Tooltip title="Delete">
                <span><AiOutlineDelete size={13} className="z-10 ml-2 -mt-[1px]" onClick={()=>{deleteData(d?._id)}}/></span>
                </Tooltip>
                }
                

              
              </h6>
          </div>))}
          </div>}

        
          </div>

          
        


        </div>
       
      
    
      </div>
    </div>
  )
}

export default CallsLeadlist