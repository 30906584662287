import React, { useEffect, useState } from 'react'
import FcaresMenu from '../FcaresMenu'
import { DeleteFcaresUserService, GetFcaresUserService } from '../../../services/Fcares/FCaresUserServices'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../../components/button'
import { DatePicker, Modal } from 'antd'
import { BsArrowRepeat } from 'react-icons/bs'
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { IconButton, Tooltip, setRef } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai'
import { BiCheckbox,BiCheckboxSquare } from "react-icons/bi";
import { DeleteFcaresUpcomingBillService, GetFcaresUpcomingBillService } from '../../../services/Fcares/FCaresUpcomingBillServices'
import Priceconstants from '../../../constants/imageConstants'
import moment from 'moment'


function FCaresUpcomingBillList() {

  const navigate = useNavigate()

  const [data,setdata] = useState({datas:[],pagination:{total:0}})
  const [selecteddata,setselecteddata] = useState({})
  const [step,setstep] = useState(1)
  const [modal,setmodal] = useState(false)
  const [page,setpage] = useState(1)
  const [search,setsearch] = useState({text:'',from_date:'',from_date1:'',to_date:'',to_date1:''})



  useEffect(()=>{
    getData()
  },[step,page])


  async function getData(){
    const response = await GetFcaresUpcomingBillService(search?.text,search?.from_date1,search?.to_date1,search?.step,page)
    setdata(response?.data?.data)
  }


  async function applyfilterfunction(){
    const response = await GetFcaresUpcomingBillService(search?.from_date1,search?.to_date1,1)
    setdata(response?.data?.data)
  }


  async function resetfunc(){
    setsearch({text:'',from_date:'',from_date1:'',to_date:'',to_date1:''})
    setpage(1)
    const response = await GetFcaresUpcomingBillService('','','',1)
    setdata(response?.data?.data)
  }

  async function deleteFunc(){
   const response = await DeleteFcaresUpcomingBillService(selecteddata?._id)
   if(response?.status === 200){
      getData()
      setmodal(false)
   }else{
      getData()
      setmodal(false)
   }
  }


  async function previewFile(v){
    window.open(`${process.env.REACT_APP_AWS_IMAGE_URL}${v}`, "_blank");
  }

  return (
    <div className='w-[100%] h-[100%] overflow-hidden'>
        <div className='flex'>
            <div className='min-w-44'>
            <FcaresMenu />
            </div>

            <Modal open={modal} width={350} closable={false} footer={false} className='absolute top-0 left-[40%]'>
               
                <h6 className="font-bold text-[13px]  mb-2 w-full">Delete Data</h6>

                <h6 className='text-[10px] font-[500] p-[5px] bg-slate-100 mb-1'>Are you sure want to delete the selected data once deleted will not be retrieved!</h6>
                <h6 className='font-[700] text-[12px]'>Selected Ticket</h6>

                <div className='text-[11px] mb-4'>
                  <h6>Bill Name :<span className='font-[700]'>{selecteddata?.type}</span>  Deadline : <span className='font-[700]'>{selecteddata?.dead_line}</span> </h6>
                </div>
                <div className='mt-2 flex items-center'>
                  <ButtonOutlinedAutoWidth btnName="Close" onClick={()=>setmodal(false)}/>
                  <div className='ml-2'>
                  <ButtonFilledAutoWidth btnName="Save" onClick={()=>deleteFunc()} />
                  </div>
                </div>
           </Modal> 

            <div className='w-[88%] px-5 pt-5'>
              {/* <div className='flex items-center justify-center'>
              
              </div> */}

              <div className='flex items-center justify-between border-b pb-2 p'>
              <h6 className='font-[700] text-[14px] '>Total Upcoming Bill Data ({data?.pagination?.total})</h6>
              <div className='flex items-center'>
                <div className='flex items-center text-[12px] mr-2'>

                

                  <h6 className='mr-2 font-[600]'>{page == 1 ? data?.datas?.length > 0 ? 1 : 0 :  (page - 1) * data?.pagination?.limit } - {data?.pagination?.limit} of {data?.pagination?.total} </h6>
                  <IconButton onClick={resetfunc}><BsArrowRepeat size={16} /></IconButton>

                  <div>
                  <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                  <IconButton onClick={()=>{ page < data?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(data?.pagination?.totalPages === page || data?.datas?.length === 0)  ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>

                  </div>
                </div>


                <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.from_date} onChange={(v,v1) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,from_date:v,from_date1:v1})}} /> 

                <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.to_date} onChange={(v,v1) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,to_date:v,to_date1:v1})}} /> 

              
              <div >
                  

                {/* <input  id="search_text" placeholder='Search text' type='text' value={search.text} onChange={(e)=>setsearch({...search,text:e.target.value})} className='border py-[3px] focus:ring-0 mr-2 focus:outline-0 text-[14px]  w-28 px-1 rounded-md border-slate-300' /> */}
              
              
              </div> 
              {/* <Select 
                bordered={false}
                value={search?.zone}
                options={options}
                onChange={(e)=>setsearch({...search,zone:e})}
                placeholder={'Zone'}
                className='border rounded-[6px] border-slate-300 h-[30px] mx-2 w-[100px]' 
                
                />      */}

            <ButtonOutlinedAutoWidth btnName="Add Filter" onClick={()=>applyfilterfunction(1)} /> 


            <div className='ml-2'>
            <ButtonFilledAutoWidth btnName="Add Data" onClick={()=>navigate('create')}/> 
            </div>
              </div>
          </div>

            
              {data?.datas?.length > 0 &&
              <div className='max-h-[82vh] mt-4 border-t   border-l border-r overflow-y-scroll'>
                {/* {data?.datas?.map((d)=>(
                  <div className='border p-[4px]'>
                    <h6 className='text-[13px]'>Property info : <span className='text-[13px] font-[800]'>{d?.type}</span> </h6>

                    <h6 className='text-[13px]'>Type : <span className='text-[13px] font-[800]'>{d?.type}</span> </h6>
                    <div className='border flex'>
                    <h6 className='border-r w-[50%] px-[4px]'>Amount</h6>
                    <h6 className='px-[4px]'><span className='text-[13px] font-[800]'>{d?.amount}</span></h6>
                    </div>
                    <div className='border flex'>
                    <h6 className='border-r w-[50%] px-[4px]'>Deadline</h6>
                    <h6 className='px-[4px]'><span className='text-[13px] font-[800]'>{moment(d?.dead_line)?.format('LL')}</span></h6>
                    </div>
                  </div>  
                ))} */}
                
                <div className='sticky top-0 z-50 flex  bg-white py-[-5px] border-b border-slate-200 relative'>
                    <h6  className='top-0 bg-white z-50 text-[12px] w-[60px]  px-2 py-1 font-[600] text-slate-600 border-r flex items-center justify-center'>SL NO</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[120px] px-2 py-1 font-[600] text-slate-600 border-r'>Property</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[120px] px-2 py-1 font-[600] text-slate-600 border-r'>Owner</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[120px]  px-2 py-1 font-[600] text-slate-600 border-r'>Type</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[280px]  px-2 py-1 font-[600] text-slate-600 border-r'>Remarks</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[120px] px-2 py-1 font-[600] text-slate-600 border-r'>Amount</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[100px] px-2 py-1 font-[600] text-slate-600 border-r'>Deadline</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[80px] px-2 py-1 font-[600] text-slate-600 border-r'>Visible</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[80px] px-2 py-1 font-[600] text-slate-600 border-r'>Paid</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[90px]  px-2 py-1 font-[600] text-slate-600 sticky right-0'>Actions</h6>
                </div>
                
                {data?.datas?.map((d,i)=>(
                <div className=' z-50 flex  bg-white py-[-5px] border-b border-slate-200 relative'>
                    <h6  className='bg-white z-50 text-[11px] w-[60px]  px-2 py-1 font-[500]  border-r flex justify-center'>{page === 1 ? i+1 : (i+1) + ((page-1)* data?.pagination?.limit)}</h6>
                    <h6 className=' z-50  text-[11px] w-[120px] px-2 py-1 font-[500]  border-r'>{d?.property?.building_name}</h6>
                    <h6 className=' z-50  text-[11px] w-[120px] px-2 py-1 font-[500]  border-r'>{d?.property?.client?.name}</h6>
                    <h6 className=' z-50  text-[11px] w-[120px]  px-2 py-1 font-[500]  border-r'>{d?.type}</h6>
                    <h6 className=' z-50  text-[11px] w-[280px] px-2 py-1 font-[500]  border-r'> 
                    <span onClick={()=>previewFile(d?.attachment)} className='underline font-[700] text-red-600 cursor-pointer'>{(d?.attachment !== '' && d?.attachment !== undefined) && 'View Attachment'}</span><br></br>
                    {d?.remarks}
                    </h6>
                    <h6 className=' z-50  text-[11px] w-[120px] px-2 py-1 font-[500]  border-r'>{Priceconstants(parseInt(d?.amount))}</h6>
                    <h6 className=' z-50  text-[11px] w-[100px] px-2 py-1 font-[500]  border-r'>{moment(d?.dead_line)?.format('LL')}</h6>
                    <h6 className=' z-50  text-[11px] w-[80px] px-2 py-1 font-[500]  border-r'> 
                     {!d?.is_visible ? <BiCheckbox size={20} className='text-slate-200' /> : <BiCheckboxSquare size={20} className='text-slate-800' />}
                    </h6>
                    <h6 className=' z-50  text-[11px] w-[80px] px-2 py-1 font-[500]  border-r'> 
                    {!d?.paid ? <BiCheckbox size={20} className='text-slate-200' /> : <BiCheckboxSquare size={20} className='text-slate-800' />}

                    </h6>
                    <h6 className=' z-50 flex text-[11px] w-[90px] py-1 font-[500]'>
                    <Tooltip title="Edit">
                    <span><AiOutlineEdit size={13} className="z-10 ml-2" onClick={()=>navigate('/fcares/upcoming_bill_list/edit',{state:d})}/></span>
                    </Tooltip>
                    <Tooltip title="Delete">
                    <span><AiOutlineDelete size={13} className="z-10 ml-2" onClick={()=>{setselecteddata(d);setmodal(true)}}/></span>
                    </Tooltip>
                    </h6>
                </div>))}

                </div>} 

                <div className='flex items-center justify-center'>
                  {data?.datas?.length === 0 &&
                  <div className='flex flex-col mt-20 w-[40%] items-center justify-center'>
                  <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' /> 
                  <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
                  <h6 className='font-[500] text-center w-[70%] text-slate-700 text-[12.5px] '>Oops we couldn't find any "Employee" onboarded yet click on the add option to create a new user for your fcares app access.</h6>
                   </div>
                  }
              </div>

            </div>
        </div>
    </div>
  )
}

export default FCaresUpcomingBillList