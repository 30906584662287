import React,{useEffect, useState} from 'react'
import { toast } from 'react-hot-toast'
import {  ButtonFilledAutoWidth } from '../../../../components/button'
import { TextInput } from '../../../../components/input'
import { useNavigate, useLocation } from 'react-router-dom'
import GoBack from '../../../../components/back/GoBack'
import { CreateHRWebsiteStageService, UpdateHRWebsiteStageService } from '../../../../services/Website/HrWebsiteOption/HRStageServices'
import WebsiteMenu from '../../WebsiteMenu'
import { Select } from 'antd'
import ErrorComponent from '../../../../components/errorDesign/ErrorComponent'
import Uploader from '../../../../components/Uploader'
import { UploadHRWebsiteBlogService } from '../../../../services/Website/HrWebsiteOption/HRBlogServices'

function HRStageCE() {
 
  const [data,setdata] = useState({name:'',type:''});  
  const [error,seterror] = useState({name:'',type:''}); 
  
  const {state} = useLocation();
  const navigate = useNavigate();


  const option = [
    {label:'Category',value:'Category'},
    {label:'Tag',value:'Tag'},
    {label:'RelatedTo',value:'RelatedTo'}
  ]



  useEffect(()=>{
    if(state?._id !== null && state?._id !== undefined){
      setdata({...data,name:state.name,type:state?.type,image:state.image})
    }

  },[state])

  function handlechange(e){
    setdata({...data,[e.target.name] : e.target.value})
    seterror({...error,[e.target.name]:''})
  }

  async function submitform(){
    if(!data.name){
        seterror({...error,name:'Stage Name field is required *'})
    }else if(!data.type){
      seterror({...error,type:'Stage Type field is required *'})
    }else{
        if(state?._id === undefined || state?._id === null){
        const response = await CreateHRWebsiteStageService(data)
        if (response.status === 201) {
          setdata({...data,name:'',type:''})
          seterror({...error,name:'',type:''})
          toast.success('HR Stage Created Successfully')
        }   
        if(response.status === 422){
          if(response?.data?.errors?.name){
            seterror({...error,name:response?.data?.errors?.name})
          }
        } 
      }else{
        let send_data = {...data}
        send_data["id"] = state?._id
        const response = await UpdateHRWebsiteStageService(send_data,state?._id)
        if (response.status === 200) {
          setdata({...data,name:'',type:''})
          seterror({...error,name:'',type:''})
          navigate(-1)
          toast.success('HR Stage Updated Successfully')
        }   
        if(response.status === 422){
          if(response?.data?.errors?.name){
            seterror({...error,name:response?.data?.errors?.name})
          }
        } 
      }
  }
  }

  async function uploadfile(v,name){
    const fd = new FormData()
    fd.append('file',v); 
    const response = await UploadHRWebsiteBlogService(fd)
    if(response?.status === 200){
      setdata({...data,[name]:response?.data?.data})
      seterror({...error,[name]:''})
    }
  }

  

  return (
    <div className='flex '>


    <WebsiteMenu />
 
  <div className='sm:w-full lg:w-72 px-4 pt-5' >


      <GoBack /> 
      
      

        <h6 className='font-bold'>{(state?._id !== null && state?._id !== undefined) ? 'Edit' : 'Add'}  Artical Category</h6>
        <h6 className='text-[10px] bg-slate-100 p-2 font-[500] leading-snug' >Use the below form to create or edit the <b> Artical Category</b> for your website.</h6>
     

        <TextInput 
            label={'Name'}  
            variant="standard"
            name="name"
            type="text"
            error={error.name}
            value={data.name}
            handlechange={handlechange}
            placeholder="Enter your Role name"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>


        <h6 className='text-[11px] mt-2 font-[600] mb-1' >Type</h6>
        <Select 
          value={data.type}
          onChange={e=>{setdata({...data,type:e});seterror({...error,type:''})}}
          options={option}
          bordered={false}
          className='w-[100%] border border-slate-300'
        />
        <ErrorComponent error={error?.type} />

        {data?.type === 'Category' &&
        <>
          <div className='relative mb-1 mt-2'>
          <h6 className='text-[11px] font-[600] mb-1 mt-2' >Image</h6>    
          {(data.image !== '' && data.image !== null) && <h6 onClick={()=>window.open(`${process.env.REACT_APP_AWS_IMAGE_URL}${data.image}`, "_blank")} className='text-[10px] hover:underline cursor-pointer font-[600] absolute top-0 right-0' >View File</h6>}
          <Uploader image={data?.image}  setimagefunc={(e)=>{uploadfile(e,'image')}}  removeimageuploadfunc = {()=>setdata({...data,image:''})}/>
          </div>
        </>}

        
<div className='mt-5'>
        <ButtonFilledAutoWidth btnName={(state?._id !== null && state?._id !== undefined) ? "UPDATE HR Stage" : "ADD HR Stage"}  onClick={submitform} />  
        </div>


    </div>
    </div>
  )
}

export default HRStageCE