import { deleteRequest, get, post, postfd, put } from "../helpers/apihelpers";

export const CreateKRAService=async(data)=>{
    try {
        const result = await post(`api/kra/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UplaodKRAService=async(data)=>{
    try {
        const result = await postfd(`api/kra/upload_file`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}


export const UpdateKRAService=async(data,id)=>{
    try {
        const result = await put(`api/kra/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetKRAService=async()=>{
    try {
        const result = await get(`api/kra/get`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetKRABasedDesignationService=async(id)=>{
    try {
        const result = await get(`api/kra/get_designation_based_kra/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteKRAService=async(id)=>{
    try {
        const result = await deleteRequest(`api/kra/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

