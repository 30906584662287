import React,{useEffect, useState} from 'react'
import { toast } from 'react-hot-toast'
import { ButtonFilledAutoWidth } from '../../../components/button'
import { useNavigate, useLocation } from 'react-router-dom'
import GoBack from '../../../components/back/GoBack'
import ItMenu from '../ItMenu'
import { Select } from 'antd'
import { GetInventoryOptionService } from '../../../services/ITServices/InventoryOption/InventoryOptionServices'
import { GetSearchService } from '../../../services/AuthServices'
import ErrorComponent from '../../../components/errorDesign/ErrorComponent'
import { CreateAssetAssignedService, GetMyAssetAssignExistService, UpdateAssetAssignedService } from '../../../services/ITServices/AssetAssignedOption/AssetAssignedService'
import { MdOutlineAssignmentReturned } from "react-icons/md";
import { GetFeatureOptionService } from '../../../services/ITServices/FeatureOption/FeatureOptionServices'

function AssignAssetOptionCE() {
 
  const [data,setdata] = useState({employee:'',asset_list:'',laptop:[],laptop_charger:[],mouse:[],keyboard:[],desktop:[],cpu:[],mobile:[],mobile_charger:[],pendrive:[],harddisk:[],cable:[]});  
  const [error,seterror] = useState({employee:'',asset_list:'',laptop:'',laptop_charger:'',mouse:'',keyboard:'',desktop:'',cpu:'',mobile:'',mobile_charger:'',pendrive:'',harddisk:'',cable:''});

  const [assetAssigned,setassetAssigned] = useState(false)


  const [selectedType,setselectedType] = useState([])

  const [employeeArr,setemployeeArr] = useState([])
  const [laptopArr,setlaptopArr] = useState([])
  const [laptopchargerArr,setlaptopchargerArr] = useState([])
  const [mouseArr,setmouseArr] = useState([])
  const [desktopArr,setdesktopArr] = useState([])
  const [cpuArr,setcpuArr] = useState([])
  const [mobileArr,setmobileArr] = useState([])
  const [keyboardArr,setkeyboardArr] = useState([])
  const [mobilechargerArr,setmobilechargerArr] = useState([])
  const [pendriveArr,setpendriveArr] = useState([])
  const [harddiskArr,setharddiskArr] = useState([])
  const [connectorsArr,setconnectorsArr] = useState([])

  const [status,setstatus] = useState([])





  const {state,pathname} = useLocation();
  const path = pathname.split('/')[pathname.split('/')?.length - 1]
  const navigate = useNavigate();

  useEffect(()=>{
    getstatus()
  },[])
 
  useEffect(()=>{
    if(state?._id !== null && state?._id !== undefined){
      let sendData = {
        employee:{label:state?.employee?.name,value:state?.employee?._id}
      }

      if(state.laptop.length !== 0){
        let arr = []
        state?.laptop?.forEach((d)=>{
          arr.push({label:`[${d?.asset_id}]-${d?.name}`,value:d?._id})
        })
        sendData['laptop'] = arr
      }else{
        sendData['laptop'] = []
      }

      if(state.laptop_charger.length !== 0){
        let arr = []
        state?.laptop_charger?.forEach((d)=>{
          arr.push({label:`[${d?.asset_id}]-${d?.name}`,value:d?._id})
        })
        sendData['laptop_charger'] = arr
      }else{
        sendData['laptop_charger'] = []
      }

      if(state.mouse.length !== 0){
        let arr = []
        state?.mouse?.forEach((d)=>{
          arr.push({label:`[${d?.asset_id}]-${d?.name}`,value:d?._id})
        })
        sendData['mouse'] = arr
      }else{
        sendData['mouse'] = []
      }

      if(state.keyboard.length !== 0){
        let arr = []
        state?.keyboard?.forEach((d)=>{
          arr.push({label:`[${d?.asset_id}]-${d?.name}`,value:d?._id})
        })
        sendData['keyboard'] = arr
      }else{
        sendData['keyboard'] = []
      }

      if(state.desktop.length !== 0){
        let arr = []
        state?.desktop?.forEach((d)=>{
          arr.push({label:`[${d?.asset_id}]-${d?.name}`,value:d?._id})
        })
        sendData['desktop'] = arr
      }else{
        sendData['desktop'] = []
      }

      if(state.cpu.length !== 0){
        let arr = []
        state?.cpu?.forEach((d)=>{
          arr.push({label:`[${d?.asset_id}]-${d?.name}`,value:d?._id})
        })
        sendData['cpu'] = arr
      }else{
        sendData['cpu'] = []
      }

      if(state.mobile.length !== 0){
        let arr = []
        state?.mobile?.forEach((d)=>{
          arr.push({label:`[${d?.asset_id}]-${d?.name}`,value:d?._id})
        })
        sendData['mobile'] = arr
      }else{
        sendData['mobile'] = []
      }

      if(state.mobile_charger.length !== 0){
        let arr = []
        state?.mobile_charger?.forEach((d)=>{
          arr.push({label:`[${d?.asset_id}]-${d?.name}`,value:d?._id})
        })
        sendData['mobile_charger'] = arr
      }else{
        sendData['mobile_charger'] = []
      }

      if(state.pendrive.length !== 0){
        let arr = []
        state?.pendrive?.forEach((d)=>{
          arr.push({label:`[${d?.asset_id}]-${d?.name}`,value:d?._id})
        })
        sendData['pendrive'] = arr
      }else{
        sendData['pendrive'] = []
      }

      if(state.harddisk.length !== 0){
        let arr = []
        state?.harddisk?.forEach((d)=>{
          arr.push({label:`[${d?.asset_id}]-${d?.name}`,value:d?._id})
        })
        sendData['harddisk'] = arr
      }else{
        sendData['harddisk'] = []
      }

      if(state.cable.length !== 0){
        let arr = []
        state?.cable?.forEach((d)=>{
          arr.push({label:`[${d?.asset_id}]-${d?.name}`,value:d?._id})
        })
        sendData['cable'] = arr
      }else{
        sendData['cable'] = []
      }

      sendData['_id'] = state?._id
      sendData['selectedType'] = state?.selectedType

     setselectedType(state?.selectedType)

      setdata({...data,...sendData})
    }
  },[state])


  async function getstatus() {
    const response = await GetFeatureOptionService(1,'','Device Status')
    let arr = []
    let d = response?.data?.datas 
    d.forEach((d1)=>{
      arr.push({label:d1?.name,value:d1?._id})
    })
    setstatus(arr)
  }


  useEffect(()=>{
    if(data?.employee?.value !== undefined){
      checkassetexist()
    }
  },[data?.employee])

  async function checkassetexist() {
    const response = await GetMyAssetAssignExistService(data?.employee?.value)
    if(response?.data?.data == null){
      setassetAssigned(false)
    }else{
      setassetAssigned(true)
    }
  }

  async function submitform(){
    if(!data.employee?.value){
        seterror({...error,employee:'Employee field is required *'})
    }else{
        
      let sendData = {}
      let arr = []


      if(data?.employee?.value !== undefined){
        sendData['employee'] = data?.employee?.value
      }

      if(data?.laptop?.length !== 0){
        arr.push(...data.laptop.map((d)=>({"asset":d.value,"type":"Laptop"}))) 
        sendData['laptop'] = data.laptop.map((d)=>d.value)
      }else{
        sendData['laptop'] = []
      }

      if(data?.laptop_charger?.length !== 0){
        arr.push(...data.laptop_charger.map((d)=>({"asset":d.value,"type":"Laptop Charger"})))
        sendData['laptop_charger'] = data.laptop_charger.map((d)=>d.value)
      }else{
        sendData['laptop_charger'] = []
      }

      if(data?.mouse?.length !== 0){
        arr.push(...data.mouse.map((d)=>({"asset":d.value,"type":"Mouse"})))
        sendData['mouse'] = data.mouse.map((d)=>d.value)
      }else{
        sendData['mouse'] = []
      }

      if(data?.keyboard?.length !== 0){
        arr.push(...data.keyboard.map((d)=>({"asset":d.value,"type":"Keyboard"})))
        sendData['keyboard'] = data.keyboard.map((d)=>d.value)
      }else{
        sendData['keyboard'] = []
      }

      if(data?.desktop?.length !== 0){
        arr.push(...data.desktop.map((d)=>({"asset":d.value,"type":"Desktop"})))
        sendData['desktop'] = data.desktop.map((d)=>d.value)
      }else{
        sendData['desktop'] = []
      }

      if(data?.cpu?.length !== 0){
        arr.push(...data.cpu.map((d)=>({"asset":d.value,"type":"CPU"})))
        sendData['cpu'] = data.cpu.map((d)=>d.value)
      }else{
        sendData['cpu'] = []
      }

      if(data?.mobile?.length !== 0){
        arr.push(...data.mobile.map((d)=>({"asset":d.value,"type":"Mobile"})))
        sendData['mobile'] = data.mobile.map((d)=>d.value)
      }else{
        sendData['mobile'] = []
      }

      if(data?.mobile_charger?.length !== 0){
        arr.push(...data.mobile_charger.map((d)=>({"asset":d.value,"type":"Mobile Charger"})))
        sendData['mobile_charger'] = data.mobile_charger.map((d)=>d.value)
      }else{
        sendData['mobile_charger'] = []
      }

      if(data?.pendrive?.length !== 0){
        arr.push(...data.pendrive.map((d)=>({"asset":d.value,"type":"Pendrive"})))
        sendData['pendrive'] = data.pendrive.map((d)=>d.value)
      }else{
        sendData['pendrive'] = []
      }

      if(data?.harddisk?.length !== 0){
        arr.push(...data.harddisk.map((d)=>({"asset":d.value,"type":"HardDisk"})))
        sendData['harddisk'] = data.harddisk.map((d)=>d.value)
      }else{
        sendData['harddisk'] = []
      }

      if(data?.cable?.length !== 0){
        arr.push(...data.cable.map((d)=>({"asset":d.value,"type":"Cable"})))
        sendData['cable'] = data.cable.map((d)=>d.value)
      }else{
        sendData['cable'] = []
      }


      sendData['selectedType'] = selectedType
      sendData['asset_list'] = arr

      console.log("arr",arr)

      if(data?._id === undefined || data?._id === null){
     
        const response = await CreateAssetAssignedService(sendData)
        if (response.status === 201) {
          resetform()
          toast.success(`Asset Assigned Successfully`)
        }   
        if(response.status === 422){
          seterror({...error,name:response?.data?.errors?.name})
        } 
      }else{
        sendData["_id"] = data?._id
        const response = await UpdateAssetAssignedService(sendData,data?._id)
        if (response.status === 200) {
          resetform()
          navigate(-1)
          toast.success(`Asset Assigned  Updated Successfully`)
        }   
        if(response.status === 422){
          seterror({...error,name:response?.data?.errors?.name})
        } 
      }
  }
  }

  function resetform(){
    setdata({employee:'',asset_list:'',laptop:[],laptop_charger:[],mouse:[],keyboard:[],desktop:[],cpu:[],mobile:[],mobile_charger:[],pendrive:[],harddisk:[],cable:[]})
    seterror({employee:'',asset_list:'',laptop:'',laptop_charger:'',mouse:'',keyboard:'',desktop:'',cpu:'',mobile:'',mobile_charger:'',pendrive:'',harddisk:'',cable:''})
  }

  async function searchData(v,type){

    let statusNotAssigned = status?.find(f=>f?.label == 'Not Assigned') !== null ? status?.find(f=>f?.label == 'Not Assigned')?.value : ''
    if(type == 'Laptop'){
      const response = await GetInventoryOptionService(1,v,type,'',statusNotAssigned)
      let d = response?.data?.datas
      let arr = []
      d.forEach((d1)=>{
         arr.push({label:`[${d1?.asset_id}]-${d1?.name}`,value:d1?._id})
      })
      setlaptopArr(arr)
    }
    if(type == 'Laptop Charger'){
      const response = await GetInventoryOptionService(1,v,type,'',statusNotAssigned)
      let d = response?.data?.datas
      let arr = []
      d.forEach((d1)=>{
         arr.push({label:`[${d1?.asset_id}]-${d1?.name}`,value:d1?._id})
      })
      setlaptopchargerArr(arr)
    }
    if(type == 'Mouse'){
      const response = await GetInventoryOptionService(1,v,type,'',statusNotAssigned)
      let d = response?.data?.datas
      let arr = []
      d.forEach((d1)=>{
         arr.push({label:`[${d1?.asset_id}]-${d1?.name}`,value:d1?._id})
      })
      setmouseArr(arr)
    }
    if(type == 'Keyboard'){
      const response = await GetInventoryOptionService(1,v,type,'',statusNotAssigned)
      let d = response?.data?.datas
      let arr = []
      d.forEach((d1)=>{
         arr.push({label:`[${d1?.asset_id}]-${d1?.name}`,value:d1?._id})
      })
      setkeyboardArr(arr)
    }
    if(type == 'Desktop'){
      const response = await GetInventoryOptionService(1,v,type,'',statusNotAssigned)
      let d = response?.data?.datas
      let arr = []
      d.forEach((d1)=>{
         arr.push({label:`[${d1?.asset_id}]-${d1?.name}`,value:d1?._id})
      })
      setdesktopArr(arr)
    }
    if(type == 'CPU'){
      const response = await GetInventoryOptionService(1,v,type,'',statusNotAssigned)
      let d = response?.data?.datas
      let arr = []
      d.forEach((d1)=>{
         arr.push({label:`[${d1?.asset_id}]-${d1?.name}`,value:d1?._id})
      })
      setcpuArr(arr)
    }
    if(type == 'Mobile'){
      const response = await GetInventoryOptionService(1,v,type,'',statusNotAssigned)
      let d = response?.data?.datas
      let arr = []
      d.forEach((d1)=>{
         arr.push({label:`[${d1?.asset_id}]-${d1?.name}`,value:d1?._id})
      })
      setmobileArr(arr)
    }
    if(type == 'Mobile Charger'){
      const response = await GetInventoryOptionService(1,v,type,'',statusNotAssigned)
      let d = response?.data?.datas
      let arr = []
      d.forEach((d1)=>{
         arr.push({label:`[${d1?.asset_id}]-${d1?.name}`,value:d1?._id})
      })
      setmobilechargerArr(arr)
    }
    if(type == 'Pendrive'){
      const response = await GetInventoryOptionService(1,v,type,'',statusNotAssigned)
      let d = response?.data?.datas
      let arr = []
      d.forEach((d1)=>{
         arr.push({label:`[${d1?.asset_id}]-${d1?.name}`,value:d1?._id})
      })
      setpendriveArr(arr)
    }
    if(type == 'HardDisk'){
      const response = await GetInventoryOptionService(1,v,type,'',statusNotAssigned)
      let d = response?.data?.datas
      let arr = []
      d.forEach((d1)=>{
         arr.push({label:`[${d1?.asset_id}]-${d1?.name}`,value:d1?._id})
      })
      setharddiskArr(arr)
    }
    if(type == 'Cable'){
      const response = await GetInventoryOptionService(1,v,type,'',statusNotAssigned)
      let d = response?.data?.datas
      let arr = []
      d.forEach((d1)=>{
         arr.push({label:`[${d1?.asset_id}]-${d1?.name}`,value:d1?._id})
      })
      setconnectorsArr(arr)
    }
    if(type == 'Employee'){
      const response = await GetSearchService(v,1)
      let d = response?.data?.datas
      let arr = []
      d.forEach((d1)=>{
         arr.push({label:`[${d1?.employee_id}]-${d1?.name}`,value:d1?._id})
      })
      setemployeeArr(arr)
    }
  }

  async function handleselect(v,type){
    if(type == 'Laptop'){
      let arr = []
      console.log("v here",v)
      v?.forEach((d)=>{
        let findData = laptopArr.find((f)=>f?.value === d)
        if(findData !== null && findData !== undefined){
          arr.push(findData)
        }else{
          let findData = data?.laptop?.find((f)=>f?.value === d)
          arr.push(findData)
        }
      })
      setdata({...data,'laptop':arr})
    }
    if(type == 'Laptop Charger'){
      let arr = []
      v?.forEach((d)=>{
        let findData = laptopchargerArr.find((f)=>f.value === d)
        if(findData !== null && findData !== undefined){
          arr.push(findData)
        }else{
          let findData = data.laptop_charger.find((f)=>f.value === d)
          arr.push(findData)
        }
      })
      setdata({...data,'laptop_charger':arr})
    }
    if(type == 'Mouse'){
      let arr = []
      v?.forEach((d)=>{
        let findData = mouseArr.find((f)=>f.value === d)
        if(findData !== null && findData !== undefined){
          arr.push(findData)
        }else{
          let findData = data.mouse.find((f)=>f.value === d)
          arr.push(findData)
        }
      })
      setdata({...data,'mouse':arr})
    }
    if(type == 'Keyboard'){
      let arr = []
      v?.forEach((d)=>{
        let findData = keyboardArr.find((f)=>f.value === d)
        if(findData !== null && findData !== undefined){
          arr.push(findData)
        }else{
          let findData = data.keyboard.find((f)=>f.value === d)
          arr.push(findData)
        }
      })
      setdata({...data,'keyboard':arr})
    }
    if(type == 'Desktop'){
      let arr = []
      v?.forEach((d)=>{
        let findData = desktopArr.find((f)=>f.value === d)
        if(findData !== null && findData !== undefined){
          arr.push(findData)
        }else{
          let findData = data.desktop.find((f)=>f.value === d)
          arr.push(findData)
        }
      })
      setdata({...data,'desktop':arr})
    }
    if(type == 'CPU'){
      let arr = []
      v?.forEach((d)=>{
        let findData = cpuArr.find((f)=>f.value === d)
        if(findData !== null && findData !== undefined){
          arr.push(findData)
        }else{
          let findData = data.cpu.find((f)=>f.value === d)
          arr.push(findData)
        }
      })
      setdata({...data,'cpu':arr})
    }
    if(type == 'Mobile'){
      let arr = []
      v?.forEach((d)=>{
        let findData = mobileArr.find((f)=>f.value === d)
        if(findData !== null && findData !== undefined){
          arr.push(findData)
        }else{
          let findData = data.mobile.find((f)=>f.value === d)
          arr.push(findData)
        }
      })
      setdata({...data,'mobile':arr})
    }
    if(type == 'Mobile Charger'){
      let arr = []
      v?.forEach((d)=>{
        let findData = mobilechargerArr.find((f)=>f.value === d)
        if(findData !== null && findData !== undefined){
          arr.push(findData)
        }else{
          let findData = data.mobile_charger.find((f)=>f.value === d)
          arr.push(findData)
        }
      })
      setdata({...data,'mobile_charger':arr})
    }
    if(type == 'Pendrive'){
      let arr = []
      v?.forEach((d)=>{
        let findData = pendriveArr.find((f)=>f.value === d)
        if(findData !== null && findData !== undefined){
          arr.push(findData)
        }else{
          let findData = data.pendrive.find((f)=>f.value === d)
          arr.push(findData)
        }
      })
      setdata({...data,'pendrive':arr})
    }
    if(type == 'HardDisk'){
      let arr = []
      v?.forEach((d)=>{
        let findData = harddiskArr.find((f)=>f.value === d)
        if(findData !== null && findData !== undefined){
          arr.push(findData)
        }else{
          let findData = data.harddisk.find((f)=>f.value === d)
          arr.push(findData)
        }
      })
      setdata({...data,'harddisk':arr})
    }
    if(type == 'Cable'){
      let arr = []
      v?.forEach((d)=>{
        let findData = connectorsArr.find((f)=>f.value === d)
        if(findData !== null && findData !== undefined){
          arr.push(findData)
        }else{
          let findData = data.cable.find((f)=>f.value === d)
          arr.push(findData)
        }
      })
      setdata({...data,'cable':arr})
    }
    if(type == 'Employee'){
      setdata({...data,employee:employeeArr?.find((f)=>f?.value == v)})
    }
  }

  function selectedTypeFunc(v){
    if(selectedType?.includes(v)){
      setselectedType([...selectedType?.filter((f)=>f !== v)])
    }else{
      setselectedType([...selectedType,v])
    }
  }

  async function returnAsset() {
    
  }

  return (
    <div className='flex w-[100%]'>

    <div >
      <ItMenu />
    </div> 
    <div className=' px-4 relative pt-5' >


        {path == 'edit' &&
        <div onClick={()=>returnAsset()} className='flex border absolute right-5 rounded p-1 cursor-pointer items-center'>
            <MdOutlineAssignmentReturned size={13} /> 
            <h6 className='mx-2 text-[12px]'>Retrieve Asset</h6>
        </div>}


        <div className='sm:w-full lg:w-72'>
          <GoBack /> 
          <h6 className='font-[700]'>{path == 'edit' ? 'Edit' : 'Create'} Asset Assigned List</h6>
          <h6 className='text-[10px] bg-slate-100 p-2 font-[500] leading-snug' >Use the below form to create or edit the <b>Asset Assigned List</b> for your comapny employees.</h6>
        </div>


        <>
         <div className='min-w-[200px] max-w-[200px] mr-4'>
              <h6 className='text-[11px] mt-2 font-[600] mb-1' >Employee</h6>
                <div className='w-[100%]'>
                  <Select 
                    value={data.employee}
                    allowClear={true}
                    type="employee"
                    filterOption={false}
                    showSearch={true}
                    disabled={path == 'edit'}
                    onSearch={(e)=>searchData(e,'Employee')}
                    onChange={e=>{handleselect(e,'Employee')}}
                    options={employeeArr}
                    bordered={false}
                    className='w-[100%] border-l-4 border-l-slate-700 border border-slate-300'
                  />
                </div>

                <ErrorComponent error={error?.employee} />
            </div>
        </>

        {(path == 'create' && assetAssigned)  && 

        <ErrorComponent error={'Asset already assigned for this user and you cant repeatly create asset tracker for the existing user!'} />}


       {((path == 'create' && !assetAssigned) || path == 'edit') && 
        <>
          {data?.employee?.value !== undefined &&
          <>
          <div className='flex my-2 cursor-pointer'>
            <h6 onClick={()=>selectedTypeFunc('Desktop / Laptop')} className={`text-[11.5px] font-[600] px-1 bg-slate-100 p-1 mr-1 ${selectedType?.includes('Desktop / Laptop') && 'bg-slate-700 text-white' }`}>Desktop / Laptop</h6>
            <h6 onClick={()=>selectedTypeFunc('Mobile')} className={`text-[11.5px] font-[600] px-1 bg-slate-100 p-1 mr-1 ${selectedType?.includes('Mobile') && 'bg-slate-700 text-white' }`}>Mobile </h6>
            <h6 onClick={()=>selectedTypeFunc('Storage / Connectors')} className={`text-[11.5px] font-[600] px-1 bg-slate-100 p-1 mr-1 ${selectedType?.includes('Storage / Connectors') && 'bg-slate-700 text-white' }`}>Storage / Connectors </h6>
          </div>


        
            
          {selectedType?.includes("Desktop / Laptop") && 
          <>
          <h6 className='bg-slate-100 p-1 mt-4 text-[12px] font-[700]'>Destop / Laptop Assign</h6>
          <div className='flex flex-wrap min-w-[83%]'>
            <div className='min-w-[200px] mr-4'>
              <h6 className='text-[11px] mt-2 font-[600] mb-1' >Laptop</h6>
                <div className='w-[100%]'>
                  <Select 
                    value={data.laptop}
                    mode="multiple"
                    allowClear={true}
                    type="laptop"
                    filterOption={false}
                    showSearch={true}
                    onSearch={(e)=>searchData(e,'Laptop')}
                    onChange={e=>{handleselect(e,'Laptop')}}
                    options={laptopArr}
                    bordered={false}
                    className='min-w-[200px] max-w-[200px] border-l-4 border-l-slate-700 border border-slate-300'
                  />
                </div>
            </div>

            <div className='min-w-[200px] mr-4'>
              <h6 className='text-[11px] mt-2 font-[600] mb-1' >Laptop Charger</h6>
                <div className='w-[100%] '>
                  <Select 
                    value={data.laptop_charger}
                    mode="multiple"
                    allowClear={true}
                    type="laptop_charger"
                    filterOption={false}
                    showSearch={true}
                    onSearch={(e)=>searchData(e,'Laptop Charger')}
                    onChange={e=>{handleselect(e,'Laptop Charger')}}
                    options={laptopchargerArr}
                    bordered={false}
                    className='min-w-[200px] max-w-[200px] border-l-4 border-l-slate-700 border border-slate-300'
                  />
                </div>
            </div>

            <div className='min-w-[200px] mr-4'>
              <h6 className='text-[11px] mt-2 font-[600] mb-1' >Mouse</h6>
                <div className='w-[100%]'>
                  <Select 
                    value={data.mouse}
                    mode="multiple"
                    allowClear={true}
                    type="mouse"
                    filterOption={false}
                    showSearch={true}
                    onSearch={(e)=>searchData(e,'Mouse')}
                    onChange={e=>{handleselect(e,'Mouse')}}
                    options={mouseArr}
                    bordered={false}
                    className='min-w-[200px] max-w-[200px] border-l-4 border-l-slate-700 border border-slate-300'
                  />
                </div>
            </div>

            <div className='min-w-[200px] mr-4'>
              <h6 className='text-[11px] mt-2 font-[600] mb-1' >Keyboard</h6>
                <div className='w-[100%]'>
                  <Select 
                    value={data.keyboard}
                    mode="multiple"
                    allowClear={true}
                    type="keyboard"
                    filterOption={false}
                    showSearch={true}
                    onSearch={(e)=>searchData(e,'Keyboard')}
                    onChange={e=>{handleselect(e,'Keyboard')}}
                    options={keyboardArr}
                    bordered={false}
                    className='min-w-[200px] max-w-[200px] border-l-4 border-l-slate-700 border border-slate-300'
                  />
                </div>
            </div>

            <div className='min-w-[200px] mr-4'>
              <h6 className='text-[11px] mt-2 font-[600] mb-1' >Desktop</h6>
                <div className='w-[100%]'>
                  <Select 
                    value={data.desktop}
                    mode="multiple"
                    allowClear={true}
                    type="desktop"
                    filterOption={false}
                    showSearch={true}
                    onSearch={(e)=>searchData(e,'Desktop')}
                    onChange={e=>{handleselect(e,'Desktop')}}
                    options={desktopArr}
                    bordered={false}
                    className='min-w-[200px] max-w-[200px] border-l-4 border-l-slate-700 border border-slate-300'
                  />
                </div>
            </div>

            <div className='min-w-[200px] mr-4'>
              <h6 className='text-[11px] mt-2 font-[600] mb-1' >CPU</h6>
                <div className='w-[100%]'>
                  <Select 
                    value={data.cpu}
                    mode="multiple"
                    allowClear={true}
                    type="cpu"
                    filterOption={false}
                    showSearch={true}
                    onSearch={(e)=>searchData(e,'CPU')}
                    onChange={e=>{handleselect(e,'CPU')}}
                    options={cpuArr}
                    bordered={false}
                    className='min-w-[200px] max-w-[200px] border-l-4 border-l-slate-700 border border-slate-300'
                  />
                </div>
            </div>
          </div>
          </>}

          {selectedType?.includes("Mobile") && 
          <>
          <h6 className='bg-slate-100 p-1 mt-4 text-[12px] font-[700]'>Mobile Assign</h6>
          <div className='flex flex-wrap min-w-[83%]'>
            <div className='min-w-[200px] mr-4'>
                <h6 className='text-[11px] mt-2 font-[600] mb-1' >Mobile</h6>
                  <div className='w-[100%]'>
                    <Select 
                      value={data.mobile}
                      mode="multiple"
                      allowClear={true}
                      type="laptop"
                      filterOption={false}
                      showSearch={true}
                      onSearch={(e)=>searchData(e,'Mobile')}
                      onChange={e=>{handleselect(e,'Mobile')}}
                      options={mobileArr}
                      bordered={false}
                      className='min-w-[200px] max-w-[200px] border-l-4 border-l-slate-700 border border-slate-300'
                    />
                  </div>
            </div>

            <div className='min-w-[200px] mr-4'>
                <h6 className='text-[11px] mt-2 font-[600] mb-1' >Mobile Charger</h6>
                  <div className='w-[100%]'>
                    <Select 
                      value={data.mobile_charger}
                      mode="multiple"
                      allowClear={true}
                      type="mobile_charger"
                      filterOption={false}
                      showSearch={true}
                      onSearch={(e)=>searchData(e,'Mobile Charger')}
                      onChange={e=>{handleselect(e,'Mobile Charger')}}
                      options={mobilechargerArr}
                      bordered={false}
                      className='min-w-[200px] max-w-[200px] border-l-4 border-l-slate-700 border border-slate-300'
                    />
                  </div>
            </div>
          </div>   
          </>}

          {selectedType?.includes("Storage / Connectors") && 
          <>
          <h6 className='bg-slate-100 p-1 mt-4 text-[12px] font-[700]'>Storage / Cable Assign</h6>
          <div className='flex flex-wrap min-w-[83%]'>
            <div className='min-w-[200px] mr-4'>
                <h6 className='text-[11px] mt-2 font-[600] mb-1' >Pendrive</h6>
                  <div className='w-[100%]'>
                    <Select 
                      value={data.pendrive}
                      mode="multiple"
                      allowClear={true}
                      type="pendrive"
                      filterOption={false}
                      showSearch={true}
                      onSearch={(e)=>searchData(e,'Pendrive')}
                      onChange={e=>{handleselect(e,'Pendrive')}}
                      options={pendriveArr}
                      bordered={false}
                      className='min-w-[200px] max-w-[200px] border-l-4 border-l-slate-700 border border-slate-300'
                    />
                  </div>
            </div>

            <div className='min-w-[200px] mr-4'>
                <h6 className='text-[11px] mt-2 font-[600] mb-1' >HardDisk</h6>
                  <div className='w-[100%]'>
                    <Select 
                      value={data.harddisk}
                      mode="multiple"
                      allowClear={true}
                      type="harddisk"
                      filterOption={false}
                      showSearch={true}
                      onSearch={(e)=>searchData(e,'Hard Disk')}
                      onChange={e=>{handleselect(e,'Hard Disk')}}
                      options={harddiskArr}
                      bordered={false}
                      className='min-w-[200px] max-w-[200px] border-l-4 border-l-slate-700 border border-slate-300'
                    />
                  </div>
            </div>

            <div className='min-w-[200px] mr-4'>
                <h6 className='text-[11px] mt-2 font-[600] mb-1' >Cable / Connectors</h6>
                  <div className='w-[100%]'>
                    <Select 
                      value={data.cable}
                      mode="multiple"
                      allowClear={true}
                      type="cable"
                      filterOption={false}
                      showSearch={true}
                      onSearch={(e)=>searchData(e,'Cable')}
                      onChange={e=>{handleselect(e,'Cable')}}
                      options={connectorsArr}
                      bordered={false}
                      className='min-w-[200px] max-w-[200px] border-l-4 border-l-slate-700 border border-slate-300'
                    />
                  </div>
            </div>
          </div> 
          </>} 
          </>}

          {selectedType?.length > 0 &&
          <div className='mt-5'>
          <ButtonFilledAutoWidth btnName={(state?._id !== null && state?._id !== undefined) ? `Update Asset Assign` : `Add Asset Assign`}  onClick={submitform} />  
          </div>}
        </>}


    </div>
    </div>
  )
}

export default AssignAssetOptionCE