import React,{useEffect, useState} from 'react'
import { toast } from 'react-hot-toast'
import { ButtonFilled, ButtonFilledAutoWidth } from '../../components/button'
import { TextInput } from '../../components/input'
import { CreateDepartmentService, UpdateDepartmentService } from '../../services/DepartmentServices'
import { useNavigate, useLocation } from 'react-router-dom'
import SettingsMenu from '../staticscreens/SettingsMenu'
import GoBack from '../../components/back/GoBack'
import { CreateAppUpdateService, UpdateAppUpdateService } from '../../services/AppUpdateService'
import { Select } from 'antd'
import ErrorComponent from '../../components/errorDesign/ErrorComponent'

function AppUpdateCE() {
 
  const [data,setdata] = useState({version:'',update_priority:''});  
  const [error,seterror] = useState({version:'',update_priority:''}); 
  
  const {state} = useLocation();
  const navigate = useNavigate();


  const options = [{label:'Low',value:'Low'},{label:'Medium',value:'Medium'},{label:'High',value:'High'}]

  useEffect(()=>{
    if(state?._id !== null && state?._id !== undefined){
      setdata({...data,version:state.version,update_priority:state.update_priority})
    }

  },[state])

  function handlechange(e){
    setdata({...data,[e.target.name] : e.target.value})
    seterror({...error,[e.target.name]:''})
  }

  async function submitform(){
    if(!data.version){
        seterror({...error,version:'Version field is required *'})
    }else if(!data.update_priority){
        seterror({...error,update_priority:'Update Priority field is required *'})
    }else{
        if(state?._id === undefined || state?._id === null){
        const response = await CreateAppUpdateService(data)
        if (response.status === 201) {
          setdata({...data,version:'',update_priority:''})
          seterror({...error,version:'',update_priority:''})
          toast.success('App Update Created Successfully')
        }   
        if(response.status === 422){
          seterror({...error,department_name:response?.data?.errors?.department_name})
        } 
      }else{
        let send_data = {...data}
        send_data["id"] = state?._id
        const response = await UpdateAppUpdateService(send_data,state?._id)
        if (response.status === 200) {
          setdata({...data,version:'',update_priority:''})
          seterror({...error,version:'',update_priority:''})
          navigate(-1)
          toast.success('App Update Updated Successfully')
        }   
        if(response.status === 422){
          seterror({...error,department_name:response?.data?.errors?.department_name})
        } 
      }
  }
  }

  

  return (
    <div className='flex '>


      <SettingsMenu />
   
    <div className='sm:w-full lg:w-72 px-4 pt-5' >


        <GoBack /> 

        <h6 className='font-[700]'>{(state?._id !== null && state?._id !== undefined) ? 'Edit' : 'Add'} App Update</h6>
        <h6 className='text-[10px] bg-slate-100 p-2 font-[500] leading-snug' >Use the below form to create or update the <b> App Update</b> for your company employees.</h6>
     

        <TextInput 
            label={'Version'}  
            variant="standard"
            name="version"
            type="text"
            error={error.version}
            value={data.version}
            handlechange={handlechange}
            placeholder="Enter your Version No"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>


<h6 className='text-[12px] font-semibold mb-1 mt-1'>Update Priority </h6>

<div className="mr-2 w-[100%]">
              <Select
                    placeholder='Select Users'
                    defaultValue={data?.update_priority}
                    value={data?.update_priority}
                    onChange={(v)=>setdata({...data,update_priority:v})}
                    options={options}
                    bordered={false}
                    className="border w-[100%] border-slate-300 h-[30px]"
                />
                </div>

                <ErrorComponent error={error?.update_priority} />

        <div className='mt-5'>
        <ButtonFilledAutoWidth btnName={"SAVE"}  onClick={submitform} />  
        </div>


    </div>
    </div>
  )
}

export default AppUpdateCE