import React,{useState,useEffect} from 'react'
import GoBack from '../../components/back/GoBack'
import { useLocation, useNavigate } from 'react-router-dom'
import AtsMenu from './AtsMenu'
import { AiOutlinePlus,AiOutlineFilePdf,AiOutlineFileExcel,AiFillPlusCircle,AiOutlineCloseCircle } from 'react-icons/ai'
import {BiGridVertical} from 'react-icons/bi';
import { DeleteAtsJobBasedEmployeeService, DownloadAtsJobExcelbBasedEmployeeService, GetAtsJobBasedEmployeeService, GetAtsJobCandidateGroupStageService, GetAtsJobDetailService } from '../../services/Ats/AtsJobServices'
import { AiOutlineEdit,AiOutlineDelete } from 'react-icons/ai'
import { IoCloseOutline } from 'react-icons/io5';
import { IconButton, Tooltip } from '@mui/material'
import { Modal,Drawer } from 'antd'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button'
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { BsArrowRepeat } from 'react-icons/bs';
import toast from 'react-hot-toast'
import AtsEmployeeManagmentCE from './AtsEmployeeManagmentCE'
import { TextAreaInput1, TextInput } from '../../components/input'
import { MdSystemUpdateAlt } from 'react-icons/md'
import moment from 'moment'
import { GetAtsTrackService } from '../../services/Ats/AtsTrackServices'
import HiringTracker from './HiringTracker'
import { useSelector } from 'react-redux'
import fileDownload from "js-file-download";
import axios from 'axios';

function AtsJobDescriptionDetail() {

  const {state} = useLocation() 
  const navigator = useNavigate()
  const user = useSelector(state=>state.Auth)

  // console.log("user",user.roles)

  
  const [singleData,setsingleData] = useState({image:'',company_name:'',client_name:'',client_logo:'',client_no:'',client_email:'',address:'',are_we_serving:true})
  const [employee,setemployee] = useState({})
  const [hr_stages,sethr_stages] = useState([])
  const [pipeline,setpipeline] = useState([])
  const [page,setpage] = useState(1)
  const [modal,setmodal] = useState(false)
  const [addmodal,setaddmodal] = useState(false)
  const [updatetrackermodal,setupdatetrackermodal] = useState(false)
  const [editmodal,seteditmodal] = useState(false)
  const [detail_modal,setdetail_modal] = useState(false)
  const [selected_data,setselected_data] = useState({})
  const [tracker,settracker] = useState([])
  const [showxlmodal,setshowxlmodal] = useState(false)
  const [excelpattern,setexcelpattern] = useState([])
  const [excelpatternkeys,setexcelpatternkeys] = useState([
    {key:'employee_name'},
    {key:'mobile_no'},
    {key:'email_id'},
    {key:'current_location'},
    {key:'skype_id'},
    {key:'referred_by'},
    {key:'notice_period'},
    {key:'experience'},
    {key:'preferred_location'},
    {key:'current_ctc'},
    {key:'expected_ctc'},
    {key:'current_company'},
    {key:'agency_comment'},
    {key:'skills'},
    {key:'designation'},
    {key:'client_name'},
    {key:'company_name'},
    {key:'job_title'},
    {key:'job_description'},
    {key:'sourced_by'},
    {key:'sourced_date'},
  ])
  // const [deletedData,setdeletedData] = useState({summary:'',error:''})


  useEffect(()=>{
      getData()
      getemployeelist(page)
      getStageGroup()
      getStages()
  },[])

  useEffect(()=>{
    getemployeelist(page)
  },[page])

   console.log("state",state)

  async function getData(){
    // console.log("triggered")
     const response = await GetAtsJobDetailService(state.data)

    //  console.log("response",response?.data)
     const response1 = await GetAtsTrackService({page:1})
     settracker(response1?.data?.datas)
     setsingleData({...response?.data?.datas[0]})

  }

  async function getemployeelist(page){
    const response = await GetAtsJobBasedEmployeeService(state.data,page)
    // console.log("response?.data",response?.data)
    setemployee(response?.data)
  }

  async function getStages(){
    const response = await GetAtsTrackService({page:1})
    sethr_stages(response?.data?.datas)
  }

  async function getStageGroup(){
    const response = await GetAtsJobCandidateGroupStageService(state.data)
    // console.log("response kp",response)
    setpipeline(response?.data?.datas)

  }

  async function deleteCandidateData(){
    const response = await DeleteAtsJobBasedEmployeeService(selected_data?._id,{deleted:selected_data?.is_deleted ? "0" : "1"})
    if(response.status === 200){
      toast.success(selected_data?.is_deleted ? "Reverted Successfully" : "Deleted Successfully")
      setmodal(false)
      getemployeelist(page)
    }
  }

  async function resetfunc(){
     setpage(1)
  } 

  async function downloadExcel(){
    let key = ''
    excelpattern.forEach((k,i)=>{
      if(i !== excelpattern.length - 1){
        key += k.key + ':'
      }else{
        key += k.key
      }
    })
    const response = await DownloadAtsJobExcelbBasedEmployeeService(state?.data,key)
    if(response?.status === 200){
      setexcelpatternkeys([...excelpatternkeys,...excelpattern])
      let path = response.data.path.replace('public/','')
       setshowxlmodal(false)
       handleDownload(`${process.env.REACT_APP_AWS_IMAGE_URL}${path}`,path.split('/')[path.split('/')?.length - 1])
    }
  }

  async function showmodalfunc(){
    if(user?.roles?.includes('admin') || user?.roles?.includes('ats_head') || user?.roles?.includes('ats_manager')){
      setmodal(true)
    }
  }

  const handleDownload = (url, filename) => {
    axios.get(url, {
        responseType: 'blob',
    })
        .then((res) => {
            fileDownload(res.data, filename)
            setshowxlmodal(false)
            setexcelpattern([])
        })
  }

  async function removearrangement(e,i){
     if(i === '2'){
        let old_pattern = [...excelpattern]
        let old_pattern1 = [...excelpatternkeys]
        let findIndex = excelpattern.findIndex((e1)=>e1.key === e)
        old_pattern.splice(findIndex,1)
        old_pattern1.push({key:e})
        setexcelpattern(old_pattern)
        setexcelpatternkeys(old_pattern1)
     }else{
        let old_pattern = [...excelpattern]
        let old_pattern1 = [...excelpatternkeys]
        let findIndex1 = excelpatternkeys.findIndex((e1)=>e1.key === e)
        old_pattern.push({key:e})
        old_pattern1.splice(findIndex1,1)
        setexcelpattern(old_pattern)
        setexcelpatternkeys(old_pattern1)
     }
  }

  
  console.log(" singleData.jd_handeled_by ", singleData )

  return (
    <div className='flex min-h-screen max-h-screen h-screen overflow-hidden'>
      <Drawer style={{padding:'0px 0px'}} maskClosable={true} open={addmodal} width={'50%'} className='overflow-hidden' height={'100vh'} closable={false} footer={false} >
        <div className='h-[100vh] overflow-hidden'>
          <AtsEmployeeManagmentCE selected_data={singleData} employee_data={selected_data?.ats_hr_employee} closemodal={()=>{setaddmodal(false);getemployeelist(page);getStageGroup();setselected_data({})}} step={0} />
        </div>
      </Drawer>

      <Drawer style={{padding:'0px 0px'}} maskClosable={true} open={updatetrackermodal} width={'50%'} className='overflow-hidden'  closable={false} footer={false} >
        <div className='h-[96vh] overflow-hidden'>
        <HiringTracker tracker={tracker} selected_data={selected_data} onclose={()=>{setupdatetrackermodal(!updatetrackermodal);getemployeelist(page);getStageGroup()}} /> 
        </div>
      </Drawer>

      <Modal className='absolute top-0 left-[30%]' open={showxlmodal} height={400} closable={false} footer={false} width={600}>
        <div className='h-[400px] overflow-hiiden'>
            <h1 className='text-[14.5px] font-[700]'>Excel Download</h1>
            <h1 className='text-[13px] leading-tight'>Arrange the data in the assending order of the file data format you would like to download.</h1>

            <div className='flex max-h-[70%] overflow-y-hidden justify-between'>
            <div className='border w-[45%] overflow-y-scroll no-scrollbar p-2 mt-2'>
            <h6 className='text-center font-[700] cursor-pointer'>Available Option</h6>
          
                {excelpatternkeys?.map((k,i)=>(
                <h6 key={i}  className='capitalize relative text-[11px] text-center p-1 bg-gray-100 m-1'><BiGridVertical className='absolute left-[5px] text-gray-400 top-[8px]'/> {k?.key?.replace('_','  ')} <AiFillPlusCircle onClick={()=>removearrangement(k?.key,'1')} className='absolute right-[5px] text-gray-400 top-[8px]' /></h6>))}
      
            </div>

            <div cursor-pointer className='border w-[45%] overflow-y-scroll no-scrollbar p-2 mt-2'>
            <h6 className='text-center font-[700]'>In use</h6>
            
             
            {excelpattern?.map((k,i)=>(
            <h6 key={i}  className='capitalize relative text-[11px] text-center p-1 bg-gray-100 m-1'><BiGridVertical className='absolute left-[5px] text-gray-400 top-[8px]'/> {k?.key?.replace('_','  ')} <AiOutlineCloseCircle onClick={()=>removearrangement(k?.key,'2')} className='absolute right-[5px] text-gray-400 top-[8px]' /></h6>))}
            </div>
            </div> 

            {excelpattern?.length > 0 &&
            <div className='flex items-center justify-end border-t pt-2 mt-4'>
            <ButtonOutlinedAutoWidth btnName={'Cancel'}  onClick={()=>setshowxlmodal(false)}/>
            <h6 className='w-[4px]'></h6>
            <ButtonFilledAutoWidth btnName={'Save & Download'} onClick={downloadExcel} />
          </div>}
        </div>
      </Modal>

      <Modal open={modal} closable={false} footer={false} width={300}>
        <div>
          <h6 className='text-[14px] font-[800]'>{selected_data?.is_deleted ?  'Revert Deleted Candidated' : 'Delete Candidate'} </h6>
          <h6 className='text-[11px] bg-slate-100 p-2 leading-normal p-2'>Be sure before deleting once the data is deleted cannot be recovered !.</h6>
          
          <h6 className='text-[12px] my-2' >Selected Candidate Name : <span className='font-[700]'>{selected_data?.ats_hr_employee?.employee_name}</span> and current company : <span className='font-[700]'>{selected_data?.ats_hr_employee?.current_company}</span> who is having a experience of : <span className='font-[700]'>{selected_data?.ats_hr_employee?.experience}</span> Years  </h6>
          <div className='flex items-center justify-end border-t pt-1 mt-4'>
            <ButtonOutlinedAutoWidth btnName={'Cancel'}  onClick={()=>setmodal(false)}/>
            <h6 className='w-[4px]'></h6>
            <ButtonFilledAutoWidth btnName={'Sure'} onClick={deleteCandidateData} />
          </div>
        </div>
      </Modal>

      <Modal open={editmodal} closable={false} footer={false} width={300}>
        <div>
          <h6 className='text-[14px] font-[800]'>Edit Candidate Status </h6>
          <h6 className='text-[11px] bg-slate-100 p-2 leading-normal p-2'>Be sure before updating once the data is updated it gets track into user status!.</h6>
          

          <div className='flex items-center my-4'>
            <h6 className='text-[12px] w-[100%] mr-2 mb-1 text-center font-[700] border-b-[2px] border-b-[#000]'>Next Stage</h6>
            <h6 className='text-[12px] w-[100%] ml-2 mb-1 text-center font-[700] border-b-[2px] border-b-[#000]'>Update Stage</h6>
          </div>
          <h6 className='text-[11px] mt-1' >Selected Candidate Name : <span className='font-[700]'>{selected_data?.ats_hr_employee?.employee_name}</span> who is having employee stage : <span className='font-[700]'>{selected_data?.ats_hr_employee?.current_company}</span>  </h6>

          <TextInput label="Stage" />
          <TextAreaInput1 label="Summary" />
          <div className='flex items-center justify-end border-t pt-1 mt-4'>
            <ButtonOutlinedAutoWidth btnName={'Cancel'}  onClick={()=>seteditmodal(false)}/>
            <h6 className='w-[4px]'></h6>
            <ButtonFilledAutoWidth btnName={'Sure'} onClick={deleteCandidateData} />
          </div>
        </div>
      </Modal>

      <Modal open={detail_modal}  width={400} height={240} closable={false} footer={false}>
        <div className='relative h-[400px] overflow-y-scroll overflow-x-hidden'>

         <IoCloseOutline className='absolute right-0 top-0 cursor-pointer' onClick={()=>setdetail_modal(false)} />

          <div className='flex '>
          {(selected_data?.user_photo !== undefined && selected_data?.user_photo !== null) ? <img className='w-[90px] border h-[90px] border-slate-100 overflow-hidden object-contain' src={`${process.env.REACT_APP_AWS_IMAGE_URL}${selected_data?.user_photo}`} /> : <span className='bg-gray-100 w-5 h-5 ml-[6px] flex items-center justify-center  text-[10px] rounded overflow-hidden uppercase font-[700]'>{selected_data?.employee_name?.slice(0,2)}</span>}
            <div className='ml-2'>
            <h6 className='font-[700] flex items-center capitalize'>{selected_data?.employee_name} <AiOutlineEdit  onClick={()=>navigator('/ats/employee_managment/edit',{state:{data:selected_data}})}  size={14} className='text-slate-600 ml-2' /></h6>
            <h6 className='text-[10px] font-[700]'> {selected_data?.designation !== '' && selected_data?.designation} / {selected_data?.experience} years Expierence</h6>
            <a href={`${process.env.REACT_APP_AWS_IMAGE_URL}${selected_data?.resume}`} target='_blank'>   <span className='flex items-center text-[12px] font-[400]  cursor-pointer hover:underline'> <AiOutlineFilePdf className='mr-1 text-slate-600'/> Download Resume</span> </a>
            <div className='flex items-center mt-[-1px]'>
            </div>
            </div>
          </div>   

          <div className='pt-[10px]'>

            <div>
               <h6 className='text-[12px] font-[600] mb-2 pl-2 bg-slate-100 p-0.5'>Contact Info :</h6>
               <div className='w-[100%] px-2 mb-1'>
                 <h6 className='uppercase text-[10px] text-gray-600 mb-0.5'>Mobile number</h6>
                 <h6 className='text-[11px] font-[600]'>+91 {selected_data?.mobile_no}</h6>
               </div>
               <div className='w-[100%] px-2 mb-1'>
                 <h6 className='uppercase text-[10px] text-gray-600 mb-0.5'>Email</h6>
                 <h6 className='text-[11px] font-[600]'>{selected_data?.email_id}</h6>
               </div>
               <div className='w-[100%] px-2 mb-1'>
                 <h6 className='uppercase text-[10px] text-gray-600 mb-0.5'>Skype ID</h6>
                 <h6 className='text-[11px] font-[600]'>{selected_data?.skype_id === '' ? 'Not Added' :selected_data?.skype_id}</h6>
               </div>
            </div>

            <div className='pt-4 border-slate-100'>
                <h6 className='text-[12px] font-[600] mb-2 pl-2 bg-slate-100 p-0.5'>More Info :</h6>

              
                {selected_data?.skills !== 'undefined' &&
                <div className='w-[100%] px-2 mb-1'>
                 <h6 className='uppercase text-[11px] text-gray-600'>Skills</h6>
                 <h6 className='text-[11.5px] font-[600]'>{selected_data?.skills}</h6>
                </div>
                }
               {selected_data?.designation !== 'undefined' &&
                 <div className='w-[100%] px-2 mb-1'>
                 <h6 className='uppercase text-[11px] mt-2 text-gray-600'>Designation</h6>
                 <h6 className='text-[11.5px] font-[600]'>{selected_data?.designation}</h6>
                 </div>
                }
                
                <div className='w-[100%] px-2 mb-1'>
                 <h6 className='uppercase text-[11px] mt-2 text-gray-600'>Preferred Location</h6>
                 <h6 className='text-[11.5px] font-[600]'>{selected_data?.preferred_location}</h6>
                </div>

                 {selected_data?.agency_comment !== 'undefined' &&
                 <div className='w-[100%] px-2 mb-1'>
                 <h6 className='uppercase text-[11px] mt-2 text-gray-600'>Agency Comment</h6>
                 <h6 className='text-[11.5px] font-[600]'>{selected_data?.agency_comment}</h6>
                 </div>
                }
              
            </div>

            <div>
               <div className='w-[100%] mb-1'>
                 <h6 className='uppercase px-2  text-[10px]'>current ctc </h6>
                 <h6 className='text-[11.5px] px-2 font-[600]'>{selected_data?.current_ctc} LPA</h6>
               </div>
               <div className='w-[100%] mb-1'>
                 <h6 className='uppercase  px-2  text-[10px]'>expected ctc</h6>
                 <h6 className='text-[11.5px] px-2 font-[600]'>{selected_data?.expected_ctc} LPA</h6>
               </div>
               <div className='w-[100%] mb-1'>
                 <h6 className='uppercase  px-2  text-[10px]'>cURRENT coMPANY</h6>
                 <h6 className='text-[11.5px] px-2 font-[600]'>{selected_data?.company_name}</h6>
               </div>
               <div className='w-[100%] mb-1'>
                 <h6 className='uppercase  px-2  text-[10px]'>Notice Period</h6>
                 <h6 className='text-[11.5px]  px-2 font-[600]'>{selected_data?.notice_period} DAYS</h6>
               </div>
               <div className='w-[100%] mb-1'>
                 <h6 className='uppercase  px-2  text-[10px]'>TOTAL Expierence</h6>
                 <h6 className='text-[11.5px]  px-2 font-[600]'>{selected_data?.experience} YEARS</h6>
               </div>
            </div>
          </div>

        </div>

      </Modal>
  
      <AtsMenu /> 

    <div className='min-w-[25%] max-w-[25%] border-r border-gray-200 pl-5 min-h-screen max-h-screen h-screen overflow-y-screen overflow-x-hidden' >
            <GoBack />
           
            
            <div className='mt-2 pr-4 w-[100%]'>

              <h6 className='mt-5 mb-1 font-[800] text-[13px]'>Client Info</h6>
              <h6 className='bg-slate-100 leading-tight text-[11.5px] p-2 mb-2'>You can see the client details who is offering this job offer.</h6>
              <div className='relative flex mb-2 border-b border-slate-100 pb-2'>
              <img className='w-[100px]' onError={({currentTarget})=> {currentTarget.src = 'https://fidecrmfiles.s3.amazonaws.com/noprofile.png'}}  src={`${process.env.REACT_APP_AWS_IMAGE_URL}${singleData?.ats_hr_client?.client_logo}`}  />
              <div className='ml-4'>
              <h6 className='text-[13px] font-[700]'>{singleData?.ats_hr_client?.company_name}</h6>
              <h6 className='text-[11px]'>Client Name : <span className='font-[700]'>{singleData?.ats_hr_client?.client_name}</span></h6>
              <h6 className='text-[11px]'>Client No : <span className='font-[700]'>{singleData?.ats_hr_client?.client_no}</span></h6>
              <h6 className='text-[11px]'>Client Email : <span className='font-[700]'>{singleData?.ats_hr_client?.client_email}</span></h6>
              <span className='font-[600] text-[10px] bg-slate-300 p-1 px-2'>{singleData?.ats_hr_client?.are_we_serving ? 'Active' : 'In Active'}</span>
              </div>
              </div> 

              <h6 className='mt-5 mb-1 font-[800] text-[13px]'>Job Info</h6>
              <h6 className='bg-slate-100 leading-tight text-[11.5px] p-2 mb-2'>You can see the job details added for your reference for your work.</h6>
              <div className='relative flex mb-2 border-b border-slate-100 pb-2'>
              <div className='ml-0 w-[100%]'>
              <h6 className='text-[13px] font-[700]'>Job Title :  {singleData?.requirments !== undefined && singleData?.requirments?.length > 0 && singleData?.requirments[0]?.job_title}</h6>
              <div className='border flex mt-2 w-full min-w-[100%] border-gray-200'>
                           <div className='border-r w-[100%]  border-gray-200 '>
                              <h6 className='text-[11px] bg-slate-50 text-center font-[400]'>Opening</h6>
                              <h6 className='text-[13px] text-center font-[800]'>{singleData?.requirments !== undefined && singleData?.requirments?.length > 0 && singleData?.requirments[0]?.no_of_opening}</h6>
                           </div> 

                           <div className='border-r w-[100%]  border-gray-200 '>
                              <h6 className='text-[11px] bg-slate-50 text-center font-[400]'>Experience</h6>
                              <h6 className='text-[13px] text-center font-[800]'>{singleData?.requirments !== undefined && singleData?.requirments?.length > 0 && singleData?.requirments[0]?.experience}</h6>
                           </div> 

                           <div className='w-[100%]'>
                              <h6 className='text-[11px] bg-slate-50 text-center font-[400]'>Notice</h6>
                              <h6 className='text-[13px] text-center font-[800]'>{singleData?.requirments !== undefined && singleData?.requirments?.length > 0 && singleData?.requirments[0]?.notice_period}</h6>
                           </div> 
               </div> 

               <h6 className='text-[12px] font-[600] mt-2'>Location : </h6>
                        <div className='flex flex-wrap'>  
                          {singleData?.requirments !== undefined && singleData?.requirments?.length > 0 && singleData?.requirments[0]?.location?.split(':')?.map((e,i) => (
                          <span key={i} className='text-[10px] font-[400] mt-1 uppercase bg-slate-100 p-1 mr-1'>{e}</span>
                          ))}
                        </div>
             

                 <h6 className='text-[12px] font-[600] mt-2'>Job Type :  {singleData?.requirments !== undefined && singleData?.requirments?.length > 0 && singleData?.requirments[0]?.job_type}</h6>
                 <h6 className='text-[12px] font-[600] mt-2'>Employment Type :  {singleData?.requirments !== undefined && singleData?.requirments?.length > 0 && singleData?.requirments[0]?.employment_type}</h6>
                 {singleData?.requirments !== undefined && singleData?.requirments[0]?.job_attachment !== undefined && singleData?.requirments?.length > 0 &&
                 <h6 className='text-[12px] mt-2'>See the JD of this job by clicking on the link for more clarification  <a href={`${process.env.REACT_APP_AWS_IMAGE_URL}${singleData?.requirments !== undefined && singleData?.requirments[0]?.job_attachment}`} target='_blank'><span className='font-[700] underline text-slate-600 cursor-pointer'>View JD</span></a> </h6>}

                 {singleData?.requirments !== undefined && singleData?.requirments[0]?.job_description !== undefined && singleData?.requirments?.length > 0 && singleData?.requirments[0]?.job_description !== '' && 
                 <>
                 <h6 className='text-[13px] font-[700] mt-4'>Job Description</h6>
                 <h6 className='text-[12px] mt-2 '>{singleData?.requirments !== undefined && singleData?.requirments?.length > 0 && singleData?.requirments[0]?.job_description}</h6>    
                 </>}
                 {singleData?.requirments !== undefined && singleData?.requirments?.length > 0 && singleData?.requirments[0]?.skills !== undefined && singleData?.requirments[0]?.skills !== '' && 
                 <>
                 <h6 className='text-[13px] font-[700]  mt-4'>Roles / Skill Set</h6>
                 <h6 className='text-[12px] mt-2'>{singleData?.requirments !== undefined && singleData?.requirments?.length > 0 && singleData?.requirments[0]?.skills}</h6>   
                 </>}  
          
              </div>
              </div> 

            
           </div>  
    </div>
    <div className='min-w-[62%] max-w-[62%] bg-slate-100 p-1'>


   
    
      <div className='bg-white h-[100%]  border  p-2'>
        <div className='flex items-center justify-between'>
          <h1 className='text-[13px] font-[700]'>Candidated Shared ({employee?.pagination?.total})</h1>


          

          <div className='flex items-center'>

          <div className='flex items-center text-[12px] text-center'>

          <h6 className='mr-2 font-[600]'>{page == 1 ? employee?.datas?.length > 0 ? 1 : 0 : (page - 1) * employee?.pagination?.limit } - {employee?.pagination?.limit} of {employee?.pagination?.total} </h6>
          <IconButton onClick={resetfunc}><BsArrowRepeat size={16} /></IconButton>

          <div>
          <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
          <IconButton onClick={()=>{ page < employee?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(employee?.pagination?.totalPages === page || employee?.datas?.length === 0)  ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>

          </div>
          </div>


          {singleData?.stage !== 'Closed' &&
          <span className='flex'>
          {((singleData?.created_by?._id === user.id) || (singleData?.jd_handeled_by?.filter((f)=>f?._id === user.id)?.length > 0)) && 
          <AiOutlinePlus onClick={()=>{setselected_data({});setaddmodal(true);}} size={18} className='bg-slate-700 mr-2 rounded cursor-pointer text-white p-[2px]'/>}
          {(['admin','ats_head','ats_manager'].filter(f => user?.roles?.includes(f))?.length > 0 || singleData?.created_by?._id === user.id) &&
         <AiOutlineFileExcel onClick={()=>setshowxlmodal(true)} size={18} className='bg-gray-200 rounded cursor-pointer text-black p-[2px]'/>}
          </span>}

          </div>
        </div>


        {['admin','ats_head','ats_manager'].filter(f => user?.roles?.includes(f))?.length > 0 &&
        <div className='border border-slate-200 h-[20vh] p-2'>
          <h1 className={`z-50 text-[11px] w-[40px]  px-2  font-[600] flex items-center justify-center`}>Pipeline</h1>
        
          <div  className='max-h-[27vh]  overflow-y-scroll no-scrollbar '>

              <div className='w-[100%] mt-5 flex items-center justify-evenly'>
                {hr_stages?.map((s)=>(
                <h6 key={s._id} className='text-[12px] border-t-2 w-[100%] font-[600]  py-[5px] text-gray-700 text-center py-1 mx-[2px]' style={{borderTopColor:`${s?.color}`}}>{s?.heading}</h6>
                ))}
                </div>

              <div className={`flex items-center group justify-between py-2 `}>
                <div className='w-[100%] flex items-center justify-evenly'>
                {hr_stages?.map((s,i)=>(
                  <div key={i} className='w-[100%] flex items-center justify-center'>
                  {(pipeline?.find((f)=>f?._id?.heading_Stage === s?.heading)) !== undefined &&
                  <div className='w-[100%] flex items-center justify-center'>  
                      <div className='relative w-[90px] h-[31.5px] ml-5 opacity-70 hover:opacity-100'>  
                      <h6 className='text-[12px] w-[90px] h-[31.5px] -ml-5 font-[800] overflow-hidden relative  text-center  mx-[2px] py-2 pl-4 ' style={{background:`${s?.color}`,}}> 
                      {(pipeline?.find((f)=>f?._id?.heading_Stage === s?.heading)) !== undefined &&  pipeline?.find((f)=>f?._id?.heading_Stage === s?.heading)?.count} 
                      <p className='w-[32px] rotate-[43deg] -left-3 absolute top-0 h-[100%] bg-white text-white'>1</p> 
                      </h6>
                      <h6 className='w-[22.4px] rotate-[45deg] right-[9px] absolute top-[4.5px] h-[22.2px] z-40 bg-red-100 text-white' style={{background:`${s?.color}`,}}></h6> 
                      </div>
                  </div>}
                  </div>
                ))}
                </div>
              </div>
          </div>
        </div>}

        {employee?.datas?.length === 0 &&
            <div className='grid place-items-center mt-20  items-center justify-center'>
            <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'}  className='w-40 h-40 object-contain' /> 
            <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
            <h6 className='font-[500] w-[70%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any data added for the current Job description you have created.</h6>
          </div>
          }
        
        {employee?.datas?.length > 0 &&
        <div className={`overflow-y-scroll pb-[40px] ${['admin','ats_head','ats_manager'].filter(f => user?.roles?.includes(f))?.length > 0 ? 'h-[75vh]' : 'h-[95vh]'}`}>
        <div className='sticky  mt-2 top-0 z-40 flex bg-slate-50  py-[-5px] border-t border-r border-l border-b border-slate-200 relative'>
          <h6  className='top-0 z-50 text-[11px] w-[40px]  px-2 py-1 font-[600]  border-r flex items-center justify-center'>SL</h6>
          <h6 className='sticky top-0 z-50  text-[11px] w-[300px]  px-2 py-1 font-[600]  border-r'>Candidate Name</h6>
          <h6 className='sticky top-0 z-50  text-[11px] w-[230px]  px-2 py-1 font-[600]  border-r'>Stage</h6>
         
          <h6 className='sticky top-0 z-50  text-[11px] w-[160px] px-2 py-1 font-[600]  border-r'>Modifier Log</h6>
          <h6 className='sticky top-0 z-50  text-[11px] w-[70px]  px-2 py-1 font-[600]  sticky right-0'>Actions</h6>
        </div>
        {employee?.datas?.map((d,i)=>(
          <div key={i} className={`${d.is_deleted ? 'opacity-50 bg-red-50 border-slate-200' : 'opacity-100 bg-white-100 border-slate-200'} relative z-20 flex  border-r border-l border-b  relative`}>
            <h6  className={`z-50 text-[11px] w-[40px]  px-2 py-1 font-[600]  border-r flex items-center justify-center  ${d.is_deleted && 'border-l-4 border-l-red-500'}`}>{page === 1 ? i+1 : (i+1) + ((page-1)* employee?.pagination?.limit)}</h6>
            <h6 onClick={()=>{setselected_data(d?.ats_hr_employee);setdetail_modal(true)}} className='group z-50 truncate  relative cursor-pointer text-[11px] w-[300px]  px-2 py-1 font-[800]   border-r'>
              <h6 className='capitalize group-hover:underline'>{d?.ats_hr_employee?.employee_name}  <span> +91 {d?.ats_hr_employee?.mobile_no} </span></h6>
              <h6 className='font-[400]'><span>Experience : {d?.ats_hr_employee?.experience} Yrs / Notice peiod : {d?.ats_hr_employee?.notice_period}</span></h6>
              <h6 className='font-[400]'><span>Candidate Assigned On : {moment(d?.createdAt)?.format('DD-MM-YYYY')}</span></h6>
             {d?.duplicated && <span className='bg-slate-100 text-[9px] absolute font-[400] text-black top-[0px] right-0  h-[20px] p-1 '>Duplicated</span>}
            </h6>
            <h6 className=' z-50 relative truncate flex flex-col items-center text-[11px] w-[230px]   px-2 py-1   border-r'>
               

              <h6 className='font-[700] mb-1'>{d?.heading_Stage} <span className='font-[400] text-[10px]'>({d?.sub_heading_Stage})</span></h6>
              <div className='flex '>
                {tracker?.map((e,i) => (

                  <Tooltip key={i} title={e?.heading}>
                  <div className='flex items-center'>
                  <h6 className={`text-[8px] cursor-pointer rounded-[10px]  font-[700] w-[16px] h-[16px]  flex items-center justify-center text-center uppercase ${ d?.heading_Stage !== undefined &&  e?.heading  ===  d?.heading_Stage ? 'bg-green-300' : ' bg-slate-100'} `} style={{background:tracker?.findIndex((f)=>f?.heading === d?.heading_Stage) <= i ? '' : tracker?.find((f)=>f?.heading == e?.heading)?.color }}>{i+1} </h6>
                    {i !==  (tracker?.length - 1) && <h6 className='w-[10px] h-[1px] bg-slate-100 ' style={{background:tracker?.findIndex((f)=>f?.heading === d?.heading_Stage) <= i ? '' : tracker?.find((f)=>f?.heading == e?.heading)?.color }}></h6>}
                  </div>
                  </Tooltip>
                  ))}
              </div>
            
            </h6>
            <h6 className='top-0 z-50  text-[11px] w-[160px] px-2 py-1 font-[500]  border-r'> 
            <span className='text-[10px]'>Created By :  <span className=' text-[10px] font-[700]  '>{d?.created_by?.name}</span></span>
            {d?.is_deleted && <span className='text-[10px] ml-2'>Deleted By :  <span className=' text-[10px] font-[700]  '>{d?.deleted_by?.name}</span></span>}
             </h6>
            <h6 className={` ${d.is_deleted && 'cursor-not-allowed'} flex items-center z-50  text-[11px] w-[70px]  px-2 py-1  `}>
              <Tooltip title="Modify Status" >
              {d.is_deleted  ?  <span className='mr-2'   onClick={()=>{ setselected_data(d);setupdatetrackermodal(true)}}><MdSystemUpdateAlt size={13}  /></span> : <span className='mr-2'  onClick={()=>{ setselected_data(d);setupdatetrackermodal(true)}}><MdSystemUpdateAlt size={13}  /></span>}
              </Tooltip>
              <Tooltip title="Edit" >
              {d.is_deleted  ?  <span className='mr-2'  ><AiOutlineEdit size={13}  /></span> : <span className='mr-2'  onClick={()=>{ setselected_data(d);setaddmodal(true)}}><AiOutlineEdit size={13}  /></span>}
              </Tooltip>
              <Tooltip title="Delete" >
              {d.is_deleted  ?  <span onClick={()=>{setselected_data(d);showmodalfunc()}} ><AiOutlineDelete size={13} /></span> :  <span onClick={()=>{setselected_data(d);setmodal(true)}}><AiOutlineDelete size={13} /></span>} 
              </Tooltip>
            </h6>
            
          </div>))}
        </div>}
      </div>

    </div>

    </div>
  )
}

export default AtsJobDescriptionDetail