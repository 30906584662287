import React, { useEffect, useState } from 'react'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button'
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { IconButton } from '@material-ui/core'
import { DatePicker, Select,Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import { DeleteReimbusmentService, DownloadExcelReimbusmentService, GetFinanceLineItemReimbusmentService, GetFinanceReimbusmentService, GetReimbusmentService, UploadExcelReimbusmentService } from '../../services/ReimbusmentServices';
import moment from 'moment';
import Priceconstants from '../../constants/imageConstants';
import { AiOutlineEdit,AiOutlineDelete,AiOutlineFileExcel } from 'react-icons/ai';
import toast from 'react-hot-toast';
import FinanceMenu from './FinanceMenu'
import { GetDepartmentService, GetUsersByDepartment } from '../../services/DepartmentServices';
import { useSelector } from 'react-redux';
import fileDownload from "js-file-download";
import axios from 'axios';
import { LuHardDriveUpload } from "react-icons/lu";
import { TextInput } from '../../components/input';
import Uploader from '../../components/Uploader';
import * as XLSX from 'xlsx';

function FinanceReimbusmentList() {

  const particulars = [
    {label:'Petrol',value:'Petrol'},
    {label:'Trip',value:'Trip'},
    {label:'Lunch With Client',value:'Lunch With Client'},
    {label:'Team Lunch',value:'Team Lunch'},
    {label:'OLA / Travel Bill',value:'OLA / Travel Bill'},
    {label:'Other',value:'Other'},
  ]

  const roles = useSelector(state=>state.Auth.roles)
  const [uploadData,setuploadData] = useState(false)
  const [uploadDataItem,setuploadDataItem] = useState({user:'',department:'',expense_department:'',summary:'',amount:'',items:[],file:''})

  // console.log("roles",roles) 
  const [data,setdata] = useState({})
  const [selectedData,setselectedData] = useState({})
  const [department,setdepartment] = useState([])
  const [user,setuser] = useState([])
  const [modal,setmodal] = useState(false)
  const [search,setsearch] = useState({from_date:'',from_date1:'',to_date:'',to_date1:'',department:'',user:'',particulars:''})
  const [page,setpage] = useState(1)
  const [step,setstep] = useState(1)
  const navigate = useNavigate()

  useEffect(()=>{
    getdepartment()
  },[])

  useEffect(()=>{
      getData()
  },[step,page])

  async function getData(){
    if(step !== 4){
      const response = await GetFinanceReimbusmentService(page,step,search?.from_date1,search?.to_date1,search?.department?.label === undefined ? '' : search?.department?.value,search?.user?.label === undefined ? '' : search?.user?.value,search?.particulars?.value)
      setdata(response?.data?.datas)
    }else{
      const response = await GetFinanceLineItemReimbusmentService(page,step,search?.from_date1,search?.to_date1,search?.department?.label === undefined ? '' : search?.department?.value,search?.user?.label === undefined ? '' : search?.user?.value,search?.particulars?.value)
      setdata(response?.data?.datas)
    }
  }

  async function getdepartment(){
    const response = await GetDepartmentService();
    let arr = []
    response.data.datas.forEach((d)=>{
        arr.push({label:d?.department_name,value:d?.id})
    })
    setdepartment(arr)
  }

  async function resetfunc(){
  setpage(1)
  setsearch({from_date:'',from_date1:'',to_date:'',to_date1:'',department:'',user:'',particulars:''})
  // const response = await GetFinanceReimbusmentService(1,step,'','','','')
  // setdata(response?.data?.datas)

  if(step !== 4){
    const response = await GetFinanceReimbusmentService(page,step,search?.from_date1,search?.to_date1,search?.department?.label === undefined ? '' : search?.department?.value,search?.user?.label === undefined ? '' : search?.user?.value,search?.particulars?.value)
    setdata(response?.data?.datas)
  }else{
    const response = await GetFinanceLineItemReimbusmentService(page,step,search?.from_date1,search?.to_date1,search?.department?.label === undefined ? '' : search?.department?.value,search?.user?.label === undefined ? '' : search?.user?.value,search?.particulars?.value)
    setdata(response?.data?.datas)
  }

  }

  async function applyfilterfunction(){
    setpage(1)
    // const response = await GetFinanceReimbusmentService(1,step,search?.from_date1,search?.to_date1,search?.department?.label === undefined ? '' : search?.department?.value,search?.user?.label === undefined ? '' : search?.user?.value,search?.particulars?.value)
    // setdata(response?.data?.datas)

    if(step !== 4){
      const response = await GetFinanceReimbusmentService(page,step,search?.from_date1,search?.to_date1,search?.department?.label === undefined ? '' : search?.department?.value,search?.user?.label === undefined ? '' : search?.user?.value,search?.particulars?.value)
      setdata(response?.data?.datas)
    }else{
      const response = await GetFinanceLineItemReimbusmentService(page,step,search?.from_date1,search?.to_date1,search?.department?.label === undefined ? '' : search?.department?.value,search?.user?.label === undefined ? '' : search?.user?.value,search?.particulars?.value)
      setdata(response?.data?.datas)
    }
  }

  async function deleteDatafunc(){
  const response =  await DeleteReimbusmentService(selectedData?._id)
  if(response.status === 200){
      toast.success("Deleted Successfully")
      setmodal(false)
      getData()
  }
  }

  async function getuserbaseddepartment(id){
    const response = await GetUsersByDepartment(id) 
    let arr = []
    response.data.datas.forEach((d)=>{
        arr.push({label:d?.name,value:d?._id})
    })
    setuser(arr)
  }

  async function handlechange(val,type){
    if(type === 'department'){
        let departmentData = department.find(d=>d.value == val)
        if(search?.department?.value !== departmentData.value){
            setpage(1)
            setsearch({...search,department:departmentData})
            getuserbaseddepartment(val)
        }
    }else if(type === 'users'){
        setpage(1)
        setsearch({...search,user:user.find(d=>d.value == val)})
    }else if(type === 'select_users'){
      setuploadDataItem({...uploadDataItem,user:user.find(d=>d.value == val)})
    }else if(type === 'select_department'){
      getuserbaseddepartment(val)
      setuploadDataItem({...uploadDataItem,department:department.find(d=>d.value == val)})
    }else if(type === 'expense_department'){
      setuploadDataItem({...uploadDataItem,expense_department:department.find(d=>d.value == val)})
    }else if(type === 'particulars'){
      setsearch({...search,particulars:particulars.find(d=>d.value == val)})
    }
  } 

  async function downloaddata(){
    const response = await DownloadExcelReimbusmentService(step)
    let path = response.data.path.replace('public/','')
    handleDownload(`${process.env.REACT_APP_AWS_IMAGE_URL}${path}`,path.split('/')[path.split('/')?.length - 1])
  }

  const handleDownload = (url, filename) => {
    axios.get(url, {
        responseType: 'blob',
    })
        .then((res) => {
            fileDownload(res.data, filename)
        })
  }  

  async function uploadexcelfile(d){
    const file = d;
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });

      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      const excelData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      if(excelData.length === 0){
        toast.error("Invalid File Format")
      }else{
        let arr  = []
        let amount = 0
        excelData?.slice(1).forEach((d)=>{
          amount += d[2]
          arr.push({data:d[0],name:d[5],amount:d[2],remark:d[9]})
        })

         let dataFormat =  arr.reduce((grouped, transaction) => {
            const date = transaction.data;
            
            if (!grouped[date]) {
              grouped[date] = [];
            }
            grouped[date].push(transaction);
        
            return grouped;
          }, {});
        setuploadDataItem({...uploadDataItem,file:d,items:dataFormat,amount:amount})

        toast.success(`Expenses Excel Uploaded Successfully ${parseInt(excelData.length-1)} Items Inserted`)
      }
    }
    reader.readAsArrayBuffer(file);
  }

  async function uploadDatafunc(){
    let passData = {
        user:uploadDataItem?.user?.value === undefined ? '' : uploadDataItem?.user?.value,
        department:uploadDataItem?.department?.value === undefined ? '' : uploadDataItem?.department?.value,
        amount:uploadDataItem?.amount,
        summary:uploadDataItem?.summary,
        approvals:[]
    }

    if(uploadDataItem?.expense_department?.value !== undefined && uploadDataItem?.expense_department?.value !== ''){
        passData['expense_department'] = uploadDataItem?.expense_department?.value
    }


      passData['items'] = uploadDataItem?.items


    const response = await UploadExcelReimbusmentService(passData)
    if(response?.status === 200){
      toast.success("Excel Uploaded Successfully!")
      getData()
      setuploadDataItem({user:'',department:'',expense_department:'',summary:'',amount:'',items:[],file:''})
    }

  }

  console.log("data?.datas",data?.datas)
        
  return (
    <div>
        <div className='flex'>

        <Modal open={uploadData} className='absolute top-0 left-[41%]' width={320} closable={false} footer={false}>
          <div> 
            <h6 className='text-[15px] font-[700]'>Upload Data</h6>
            <h6 className='text-[11px] leading-tight bg-gray-100 p-2' >Before uploading please check whether the excel item is valid for the selected user.</h6>

            <div className='flex items-center'>
              <div className='w-[49%] mr-[1%]'>
              <h6 className='text-[11px] font-[600] mb-1 mt-2' >Select Department</h6>

              <div className='h-[35px] border'>
                <Select
                    placeholder=''
                    value={uploadDataItem?.department === '' ? null : uploadDataItem?.department}
                    onChange={(v)=>handlechange(v,'select_department')}
                    options={department}
                    bordered={false}
                    className='w-[100%]'
                />
                </div>
              </div>

              <div className='w-[49%] ml-[1%]'>
                <h6 className='text-[11px] font-[600] mb-1 mt-2' >Select User</h6>

                <div className='h-[35px] border'>
                <Select
                    placeholder=''
                    value={uploadDataItem?.user === '' ? null : uploadDataItem?.user}
                    onChange={(v)=>handlechange(v,'select_users')}
                    options={user}
                    bordered={false}
                    className='w-[100%]'
                />
                </div>
              </div>
            </div>

            <div className='flex items-center'>
              <div className='w-[49%] mr-[1%]'>
                <h6 className='text-[11px] font-[600] mb-1 mt-2' >Expense Department</h6>
                <div className='h-[35px] border'>
                  <Select
                      placeholder=''
                      value={uploadDataItem?.expense_department === '' ? null : uploadDataItem?.expense_department}
                      onChange={(v)=>handlechange(v,'expense_department')}
                      options={department}
                      bordered={false}
                      className='w-[100%]'
                  />
                </div>
              </div>
              <div className='w-[49%] ml-[1%]'>
                  <TextInput
                         label="Total Amount"
                         name="amount"
                         type="number"
                         readOnly={true}
                         value={uploadDataItem?.amount}
                         handlechange={(e)=>setuploadDataItem({...uploadDataItem,amount:e.target.value})}
                  />
              </div>
            </div>

            <h6 className='text-[11px] font-[600] mb-1 mt-2' >Upload Excel</h6>
            <div className='mt-2'>
              <Uploader image={uploadDataItem.file}  setimagefunc={(e)=>{uploadexcelfile(e)}}  removeimageuploadfunc = {()=>{setuploadDataItem({...uploadDataItem,file:''})}}/>    
            </div> 




          
            <div className='flex items-center border-t mt-2 pt-2'>
                <ButtonOutlinedAutoWidth btnName="Cancel" onClick={()=>setuploadData(false)} />
                <h6 className='w-[10px]'></h6>
                <ButtonFilledAutoWidth btnName="Sure" onClick={uploadDatafunc} />
             </div>

          </div>
        </Modal>

        <Modal open={modal} className='absolute top-0 left-[41%]' width={320} closable={false} footer={false}>
          <div> 
            <h6 className='text-[15px] font-[700]'>Delete Data</h6>
            <h6 className='text-[11px] leading-tight bg-gray-100 p-2' >Before Deleting the data be sure that it is required or not for further purpose.</h6>

            <h6 className='text-[11px] mt-2'>Amount : <span className='font-[700]'>{Priceconstants(selectedData?.amount)}</span></h6>
            <h6 className='text-[11px]'>Requested Date : <span className='font-[700]'>{moment(selectedData?.createdAt)?.format('LLL')}</span></h6>
            
            <div className='flex items-center border-t pt-2'>
                <ButtonOutlinedAutoWidth btnName="Cancel" onClick={()=>setmodal(false)} />
                <h6 className='w-[10px]'></h6>
                <ButtonFilledAutoWidth btnName="Sure" onClick={deleteDatafunc} />
             </div>

          </div>
        </Modal>

        <FinanceMenu />
        <div className='mx-4 w-[85%] mt-2'>
        <div className='w-[100%]   pt-2'>
            <div className='flex  items-center justify-between border-b pb-2'>
            <h6 className='font-[700] text-[14px] '>Total Reimbusment ({data?.pagination?.total})</h6>
            <div className='flex items-center'>
              <div className='flex items-center text-[12px] mr-2'>
             

                <LuHardDriveUpload onClick={()=>setuploadData(true)} size={24}  className='mr-1 bg-gray-200 p-1.5' />
                <AiOutlineFileExcel onClick={()=>downloaddata()} size={24}  className='mr-1 bg-gray-200 p-1.5' />

                <h6 className='mr-2 font-[600]'>{page == 1 ? data?.datas?.length > 0 ? 1 : 0 : (page - 1) * data?.pagination?.limit } - {data?.pagination?.limit} of {data?.pagination?.total} </h6>
                <IconButton onClick={resetfunc}><BsArrowRepeat size={16} /></IconButton>

                <div>
                <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                <IconButton onClick={()=>{ page < data?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(data?.pagination?.totalPages === page || data?.datas?.length === 0)  ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>

                </div>
              </div>

              <div className='h-[35px] mr-2'>
              <Select
                  placeholder='Select Stage'
                  value={search?.particulars === '' ? null : search?.particulars}
                  onChange={(v)=>handlechange(v,'particulars')}
                  options={particulars}
                  className=' max-w-[100px]'
              />
              </div>
              
              <div className='h-[35px] mr-2'>
              <Select
                  placeholder='Select Department'
                  value={search?.department === '' ? null : search?.department}
                  onChange={(v)=>handlechange(v,'department')}
                  options={department}
                  className=' max-w-[100px]'
              />
              </div>

              <div className='h-[35px] mr-2'>
              <Select
                  placeholder='Select User'
                  value={search?.user === '' ? null : search?.user}
                  onChange={(v)=>handlechange(v,'users')}
                  options={user}
                  className=' max-w-[100px]'
              />
              </div>


              <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.from_date} onChange={(v,v1) => {setsearch({...search,from_date:v,from_date1:v1})}} /> 

              <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.to_date} onChange={(v,v1) => {setsearch({...search,to_date:v,to_date1:v1})}} /> 

            
                

          <ButtonOutlinedAutoWidth btnName="Add Filter" onClick={()=>applyfilterfunction(1)} /> 
         
         
            </div>
            </div>

            

            <div className='flex  items-center border-b'>
            <h6 onClick={()=>{setstep(1);setpage(1)}} className={`font-[600] ${step === 1 && 'bg-slate-600 text-white'} text-center cursor-pointer py-1 w-[90px] text-[11px]`}>Pending</h6>
            <h6 onClick={()=>{setstep(2);setpage(1)}} className={`font-[600] ${step === 2 && 'bg-slate-600 text-white'} text-center cursor-pointer py-1 w-[80px] text-[11px]`}>Approved</h6>
            <h6 onClick={()=>{setstep(3);setpage(1)}} className={`font-[600] ${step === 3 && 'bg-slate-600 text-white'} text-center cursor-pointer py-1 w-[80px] text-[11px]`}>Rejected</h6>
            <h6 onClick={()=>{setstep(4);setpage(1)}} className={`font-[600] ${step === 4 && 'bg-slate-600 text-white'} text-center cursor-pointer py-1 w-[80px] text-[11px]`}>List Item</h6>
          </div>
            {step !== 4 &&
            <div className='border-t border-l border-r mt-4'>
                <div className='flex border-b'>
                  <h6 className='text-[12px] py-1 px-2 border-r w-[70px] font-[600]'>Sl No</h6>
                  <h6 className='text-[12px] py-1 px-2 border-r w-[13%] font-[600]'>Request Date</h6>
                  <h6 className='text-[12px] py-1 px-2 border-r w-[10%] font-[600]'>Amount</h6>
                  <h6 className='text-[12px] py-1 px-2 border-r w-[10%] font-[600]'>User</h6>
                  <h6 className='text-[12px] py-1 px-2 border-r w-[10%] font-[600]'>Department</h6>
                  <h6 className='text-[12px] py-1 px-2 border-r w-[17%] font-[600]'>Approvals</h6>
                  <h6 className='text-[12px] py-1 px-2 border-r w-[14%] font-[600]'>Item Info</h6>
                  <h6 className='text-[12px] py-1 px-2 border-r w-[14%] font-[600]'>Payment Mode</h6>
                  <h6 className='text-[12px] py-1 px-2 border-r w-[15%] font-[600]'>Payment Date</h6>
                  <h6 className='text-[12px] py-1 px-2 w-[10%] font-[600]'>Action</h6>
                </div>

               {data?.datas?.map((d,i)=>(
                <div className='flex border-b'>
                  <h6 className='text-[11px] py-1 px-2 border-r w-[70px] font-[500]'>{(page > 1 ? i+1+ (25 * (page - 1)) : i+1 )}</h6>
                  <h6 className='text-[10px] py-1 px-2 border-r w-[13%] font-[700]'>{moment(d?.createdAt)?.format('LL')}</h6>
                  <h6 className='text-[11px] py-1 px-2 border-r w-[10%] font-[700]'>{Priceconstants(d?.amount)}</h6>
                  <h6 className='text-[11px] py-1 px-2 border-r w-[10%] font-[700]'>{d?.user?.name}</h6>
                  <h6 className='text-[11px] py-1 px-2 border-r w-[10%] font-[700]'>{d?.expense_department?.department_name !== undefined ? d?.expense_department?.department_name : d?.department?.department_name}</h6>
                  <h6 className='text-[11px] py-1 px-2 border-r w-[17%] font-[500]'>{d?.reimbursmentapprovals?.map((d1)=>(
                    <span className='px-[3px] py-[2px] mb-[2px] bg-gray-100 text-[8px] mr-2 font-[800]'>{d1?.user?.name}</span>
                  ))}</h6>
                  <h6 className='text-[11px] py-1 px-2 border-r w-[14%] font-[400]'>{d?.reimbursmentitems?.map((r)=>(
                    <p>{r?.particular === 'Other' ? r?.other_particular : r?.particular} - Amount : <span className='font-[700]'>{Priceconstants(parseInt(r?.amount))}</span></p>
                  ))}</h6>
                  <h6 className='text-[11px] py-1 px-2 border-r w-[14%] font-[500]'>{d?.completed && <span className='bg-blue-400 font-[700] text-white px-[10px] py-[2px]'>{d?.payment}</span>}</h6>
                  <h6 className='text-[11px] py-1 px-2 border-r w-[15%] font-[500]'>{d?.completed && moment(d?.updatedAt)?.format('LLL')}</h6>
                  <h6 className='text-[11px] py-1 px-2 w-[10%] font-[500] flex'>
                    <AiOutlineEdit size={13} className='mr-2' onClick={()=>navigate('edit',{state:d})} /> 
                    {/* && step !== 2 */}
                    {(roles?.includes('admin') || roles?.includes('delete_data')) && step !== 2 &&
                    <AiOutlineDelete size={13} onClick={()=>{setselectedData(d);setmodal(true)}} />}
                  </h6>
                </div>
                ))}

            </div>}
            {step === 4 &&
            <div className='border-t border-l border-r mt-4'>
                <div className='flex border-b'>
                  <h6 className='text-[12px] py-1 px-2 border-r w-[70px] font-[600]'>Sl No</h6>
                  <h6 className='text-[12px] py-1 px-2 border-r w-[13%] font-[600]'>Request Date</h6>
                  <h6 className='text-[12px] py-1 px-2 border-r w-[10%] font-[600]'>Amount</h6>
                  <h6 className='text-[12px] py-1 px-2 border-r w-[10%] font-[600]'>User</h6>
                  <h6 className='text-[12px] py-1 px-2 border-r w-[10%] font-[600]'>Department</h6>
                  <h6 className='text-[12px] py-1 px-2 border-r w-[17%] font-[600]'>Approvals</h6>
                  <h6 className='text-[12px] py-1 px-2 border-r w-[14%] font-[600]'>Item Info</h6>
                  <h6 className='text-[12px] py-1 px-2 border-r w-[14%] font-[600]'>Payment Mode</h6>
                  <h6 className='text-[12px] py-1 px-2 border-r w-[15%] font-[600]'>Payment Date</h6>
                  <h6 className='text-[12px] py-1 px-2 w-[10%] font-[600]'>Action</h6>
                </div>

               {data?.datas?.map((d,i)=>(
                <div className='flex border-b'>
                  <h6 className='text-[11px] py-1 px-2 border-r w-[70px] font-[500]'>{(page > 1 ? i+1+ (25 * (page - 1)) : i+1 )}</h6>
                  <h6 className='text-[10px] py-1 px-2 border-r w-[13%] font-[700]'>{moment(d?.createdAt)?.format('LL')}</h6>
                  <h6 className='text-[11px] py-1 px-2 border-r w-[10%] font-[700]'>{Priceconstants(parseInt(d?.amount))}</h6>
                  <h6 className='text-[11px] py-1 px-2 border-r w-[10%] font-[700]'>{d?.reimbursments?.user?.name}</h6>
                  <h6 className='text-[11px] py-1 px-2 border-r w-[10%] font-[700]'>{d?.reimbursments?.expense_department?.department_name !== undefined ? d?.reimbursments?.expense_department?.department_name : d?.reimbursments?.department?.department_name}</h6>
                  <h6 className='text-[11px] py-1 px-2 border-r w-[17%] font-[500]'>{d?.reimbursments?.finance_approved_status}</h6>
                  <h6 className='text-[11px] py-1 px-2 border-r w-[14%] font-[400]'>
                    {d?.particular === 'Other' ? d?.other_particular : d?.particular}
                  </h6>
                  <h6 className='text-[11px] py-1 px-2 border-r w-[14%] font-[500]'>{d?.reimbursments?.completed && <span className='bg-blue-400 font-[700] text-white px-[10px] py-[2px]'>{d?.reimbursments?.payment}</span>}</h6>
                  <h6 className='text-[11px] py-1 px-2 border-r w-[15%] font-[500]'>{d?.reimbursments?.completed && moment(d?.reimbursments?.updatedAt)?.format('LLL')}</h6>
                  <h6 className='text-[11px] py-1 px-2 w-[10%] font-[500] flex'>
                    <AiOutlineEdit size={13} className='mr-2' onClick={()=>navigate('edit',{state:d})} /> 
                    {/* && step !== 2 */}
                    {(roles?.includes('admin') || roles?.includes('delete_data')) && step !== 2 &&
                    <AiOutlineDelete size={13} onClick={()=>{setselectedData(d);setmodal(true)}} />}
                  </h6>
                </div>
                ))}

            </div>}
        </div>    
        </div>
        </div>
    </div>
  )
}

export default FinanceReimbusmentList