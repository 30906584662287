import React,{useState,useEffect} from 'react'
import Grid from '@mui/material/Grid';
import { TextAreaInput1, TextInput } from '../../../components/input';
import { ButtonFilled, ButtonOutlined } from '../../../components/button';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import {Select } from 'antd';
import GoBack from '../../../components/back/GoBack';
// import DailyTaskCE from './dailyTask/DailyTaskCE';
import FcaresMenu from '../FcaresMenu';
import { BiCheckbox,BiCheckboxSquare } from "react-icons/bi";
import { CreateFcaresTicketService, GetFcaresTicketByIdService, UpdateFcaresTicketService } from '../../../services/Fcares/FCaresTicketService';
import { Tooltip } from '@mui/material'
import { IoClose } from 'react-icons/io5'
import { GetFcaresPropertyService, UploadDocsFcaresPropertyService } from '../../../services/Fcares/FCaresPropertyServices';
import { GetFcaresUserService } from '../../../services/Fcares/FCaresUserServices';
import { CreateFcaresTenantService, UpdateFcaresTenantService } from '../../../services/Fcares/FCaresTenantServices';

function FCaresTenantCE() {



  const [data,setdata] = useState({
    tenant:'',
    property:'',
    lock_in_period:'',
    lock_in_end:'',
    amount:'',
    register_date:'',
    agreement:'',
    is_expired:false,
    property_description:''
  })
  const [owners,setowners] = useState([])

  

  
 
  const [loader, setloader] = useState(false);

  const status = [{label:'Pending',value:'Pending'},{label:'Discussion',value:'Discussion'},{label:'In Progress',value:'In Progress'},{label:'Resolved',value:'Resolved'}]
  const [property,setproperty] = useState([])

  const [error,seterror] = useState({
    tenant:'',
    property:'',
    lock_in_period:'',
    lock_in_end:'',
    amount:'',
    register_date:'',
    agreement:'',
    is_expired:'',
  })

  const navigate = useNavigate()
  const location = useLocation()




  async function uploadfilefunc(v,name){

    const fd = new FormData()
    fd.append('file',v); 
    const response = await UploadDocsFcaresPropertyService(fd)
    if(response?.status === 200){
    setdata({...data,[name]:response?.data?.data})
    }
  }

  useEffect(()=>{
      // getData()
      // getOwners()
  },[])

  async function getData(){
    const response = await GetFcaresPropertyService('','','','1',1)
    let arr = []
    // console.log("response?.data?.datas",response?.data?.data)
    response?.data?.data?.datas?.forEach((d)=>{
      // console.log("D Here",d)
      let obj = { 
        label:d?.building_name,
        value:d?._id,
        client:d?.client?._id !== undefined ? {label:d?.client?.name,value:d?.client?._id} : '',
        tenant:d?.tenant?._id !== undefined ? {label:d?.tenant?.name,value:d?.tenant?._id} : '' 
      }
      arr?.push(obj)
    })
    setproperty(arr)
  }

  async function getOwners(){
    const response = await GetFcaresUserService('','','',1,1)
    let d = response?.data?.data
    let arr = []
    d?.datas?.forEach((d1)=>{
      arr.push({label:d1?.name,value:d1?._id})
    })

    setowners(arr)
    // console.log(Response)
  }

   

  
  useEffect(()=>{
    if(location?.pathname?.split('/')[location?.pathname?.split('/').length -1] === 'edit'){
      geteditdata()
    }
  },[location?.pathname])

  async function geteditdata(){
    // const response =  await GetFcaresTicketByIdService(location?.state);
    let d = location.state

    let sendData = {
      tenant:{label:d?.tenant?.name,value:d?.tenant?._id},
      property:{label:d?.property?.building_name,value:d?.property?._id},
      lock_in_period:d?.lock_in_period,
      lock_in_end:d?.lock_in_end === '' ? '' : d?.lock_in_end?.slice(0,10),
      amount:d?.amount,
      register_date:d?.register_date === '' ? '' :d?.register_date?.slice(0,10),
      agreement:d?.agreement,
      is_expired:d?.is_expired,
      _id:d?._id
    }
    let obj = {
      label:d?.property?.building_name,
      value:d?.property?._id
    }

    obj['client'] = d?.client?._id !== undefined ? {label:d?.client?.name,value:d?.client?._id} : ''
    obj['tenant'] = d?.tenant?._id !== undefined ? {label:d?.tenant?.name,value:d?.tenant?._id} : ''
    sendData['property'] = obj

    console.log("obj",obj)
   
    setdata(sendData)
  }

  // console.log("data?.operators",data?.operators)
  function handlechange(e){
    setdata({...data,[e.target.name] : e.target.value})
    seterror({...error,[e.target.name] : ''})
  }


  async function submitform(){
   
        const sendData = {...data}
        if(data?.property?.client?.value !== undefined){
          sendData['client'] = data?.property?.client?.value       
        } 
        if(data?.tenant?.value !== undefined){
          sendData['tenant'] = data?.tenant?.value       
        }    
        sendData['property'] = data?.property?.value




        if(location?.pathname?.split('/')[location?.pathname?.split('/').length - 1] === 'edit'){
        

          setloader(true)
          const response = await UpdateFcaresTenantService(sendData?._id,sendData)
          if(response.status === 200){
            setloader(false)
            // console.log("Anna data?.stage?.label",data?.stage?.label,"stage?.selected_stage?.label",stage?.selected_stage?.label)
            setloader(false)
            toast.success('Tenant Updated Successfully')
            resetform()
            navigate(-1)
          }
        }else{  
          setloader(true)
          const response = await CreateFcaresTenantService(sendData)
          if(response.status === 201){
              setloader(false)
              toast.success('Tenant Created Successfully')
              resetform()
          }
        }
  }

  function resetform(){
     setdata({
      tenant:'',
      property:'',
      lock_in_period:'',
      lock_in_end:'',
      amount:'',
      register_date:'',
      agreement:'',
      is_expired:false,
      })
      seterror({
        tenant:'',
        property:'',
        lock_in_period:'',
        lock_in_end:'',
        amount:'',
        register_date:'',
        agreement:'',
        is_expired:'',
      })
  }


  async function setpropertyfunc(v){
     let propertyFind = property?.find((f)=>f?.value === v)
     if(propertyFind !== null){
       setdata({...data,property:propertyFind})
     }
  }

  async function settenantfunc(v){
    let tenantFind = owners?.find((f)=>f?.value === v)
    if(tenantFind !== null){
      setdata({...data,tenant:tenantFind})
    }
 }


 async function searchfunc(v,type){
  //  console.log("v here",v)
  //  console.log("type here",type)
   if(type === 'property'){
    const response = await GetFcaresPropertyService(v,"","",1,1)
    let d = response?.data?.data?.datas
    let arr = []
    d?.forEach((d1)=>{
      arr.push({label:`${d1?.building_name} - client : ${d1?.client?.name} - mobile : ${d1?.client?.mobile} `,value:d1?._id})
    })
    setproperty(arr)
   }else if(type === 'tenant'){
    const response = await GetFcaresUserService(v,'','',1,1)
    let d = response?.data?.data?.datas
    let arr = []
    d?.forEach((d1)=>{
      arr.push({label:`${d1?.name} - Mobile : ${d1?.mobile}`,value:d1?._id})
    })
    setowners(arr)

   }
 }
  

  // console.log("data.kp",data?.property)
  // console.log("property",property)
  return (
    <div className='h-screen max-h-screen box-border overflow-hidden'>
    <div className='block sm:flex'>
        <FcaresMenu />
    <div className='pr-5 ml-5 min-h-screen max-h-screen h-screen overflow-y-scroll' >
    

  

    <div className='w-64 '>
      <GoBack />
     <div className='border-b  pb-2'>
      <h6 className='font-[800] mb-1'>{location?.pathname?.split('/')[location?.pathname?.split('/').length -1] === 'edit' ? 'Edit' : 'Create'} Tenant </h6> 
      <h6 className='text-[11px] leading-tight font-[500] p-2 bg-slate-100 '>Use the below form to create or edit the tenant for the property fcares .</h6> 
     </div> 
      <Grid container spacing={2}  >
        <Grid item xs={12}  md={12} >

        <h6 className='text-[12px] font-semibold mb-1 mt-1'>Property*</h6>
        <Select 
          showSearch
          value={data?.property}
          filterOption={false}
          bordered={false}
          onSearch={(v)=>searchfunc(v,"property")}
          onChange={(e)=>{setpropertyfunc(e)}} 
          options={property}
          allowClear={true}
          className='border-l-4 border-l-slate-600 w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'
        />

        {data?.property?.label !== undefined &&
        <h6 className='bg-sky-50 mt-2 p-1 text-[11px]'>
          <span className='font-[700]'> Property Info : </span>{data?.property?.label}
        </h6>}

        <h6 className='text-[12px] font-semibold mb-1 mt-1'>Tenant*</h6>
        <Select 
        showSearch
        value={data?.tenant}
        filterOption={false}
        bordered={false}
        onSearch={(v)=>searchfunc(v,"tenant")}
        onChange={(e)=>{settenantfunc(e)}} 
        options={owners}
        className='border-l-4 border-l-slate-600 w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'
        />

      {data?.tenant?.label !== undefined &&
        <h6 className='bg-sky-50 mt-2 p-1 text-[11px]'>
          <span className='font-[700]'> Tenant Info : </span>{data?.tenant?.label}
        </h6>}


       <div className='flex items-center mt-2'>
        <div className='flex cusror-pointer mr-2' onClick={()=>setdata({...data,is_expired:!data.is_expired})}>
            {!data?.is_expired ? <BiCheckbox className='text-slate-300' /> : <BiCheckboxSquare className='text-slate-600' />}
            <h6 className='text-[10px] ml-[3px] font-[700]'>Is Expired</h6>
        </div>

       
        </div>
       


      


        <div className='relative mb-1 mt-2'>
          <h6 className='text-[11px] font-[600]'>Aggrement</h6>
          {(data.agreement !== '' && data.agreement !== null) && <h6 onClick={()=>window.open(`${process.env.REACT_APP_AWS_IMAGE_URL}${data.agreement}`, "_blank")} className='text-[10px] hover:underline cursor-pointer font-[600] absolute top-0 right-0' >View File</h6>} 

      
        {(data.agreement === '' || data.agreement == null) ?
            <form onClick={()=>document.querySelector(`.input-field6`).click()} className={`p-4 flex flex-col items-center justify-center cursor-pointer border border-slate-300 `}>
                <input type='file' onChange={({target:{files}})=>{
                  files[0] && uploadfilefunc(files[0],'agreement')
                }} accept="*" className='input-field6' hidden />
            </form> 
          :
              <div className='p-2 border border-slate-300 relative flex flex-col  cursor-pointer'>
                  <Tooltip title='Delete'><IoClose className='absolute top-3 right-2' onClick={()=>setdata({...data,agreement:''})}/></Tooltip>
                  <h6 className='text-[12px] truncate w-48 ml-0'>{data?.agreement}</h6>
              </div>
          }
          </div>

          <TextInput 
              mandatory={true}
              label={'Lock In Period'}  
              variant="standard"
              name="lock_in_period"
              type="text"
              value={data.lock_in_period}
              error={error.lock_in_period}
              handlechange={handlechange}
              placeholder=""
              InputLabelProps={{
                  style: { color: '#fff', }, 
              }}/>


<TextInput 
              mandatory={true}
              label={'Lock In End'}  
              variant="standard"
              name="lock_in_end"
              type="date"
              value={data.lock_in_end}
              error={error.lock_in_end}
              handlechange={handlechange}
              placeholder=""
              InputLabelProps={{
                  style: { color: '#fff', }, 
              }}/>



<TextInput 
              mandatory={true}
              label={'Amount'}  
              variant="standard"
              name="amount"
              type="text"
              value={data.amount}
              error={error.amount}
              handlechange={handlechange}
              placeholder=""
              InputLabelProps={{
                  style: { color: '#fff', }, 
              }}/>

<TextInput 
              mandatory={true}
              label={'Register Date'}  
              variant="standard"
              name="register_date"
              type="date"
              value={data.register_date}
              error={error.register_date}
              handlechange={handlechange}
              placeholder=""
              InputLabelProps={{
                  style: { color: '#fff', }, 
              }}/>


<TextAreaInput1 
              mandatory={false}
              label={' Property Description'}  
              variant="standard"
              name="property_description"
              type="text"
              value={data.property_description}
              error={error.property_description}
              handlechange={handlechange}
              placeholder=""
              InputLabelProps={{
                  style: { color: '#fff', }, 
              }}/>



       
            
        </Grid>
       
      </Grid>

   
   <div >
    <div className='flex items-center mt-5 mb-10  border-t pt-5'>
        <div className='mr-2'>
        <ButtonOutlined btnName={'Back'} onClick={()=>navigate(-1)} />
        </div>
        <div>
        {loader ?   
        <ButtonFilled btnName={loader ? 'Loading' : 'Save'} /> :
        <ButtonFilled btnName={loader ? 'Loading' : 'Save'} onClick={()=>submitform(null)} /> }
        </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
   
  )
}

export default FCaresTenantCE