import React,{useState,useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
// import { DeleteTeamService } from '../../../services/AdminServicesfile/TeamServices';
import { Tag,Modal, Select } from 'antd';
import {AiOutlineEdit,AiOutlineDelete} from 'react-icons/ai';
import moment from 'moment';
import {IoIosClose} from 'react-icons/io';
import { ButtonFilled, ButtonFilledAutoWidth } from '../../../components/button';
import { ButtonOutlined } from '../../../components/button';
import { toast } from 'react-hot-toast';
import { DeleteTargetService, GetTargetService } from '../../../services/AdminServicesfile/TargetServices';
import SettingsMenu from '../../staticscreens/SettingsMenu';
import { GetAllInsentiveList } from '../../../services/AdminServicesfile/InsentiveServices';
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { IconButton } from '@mui/material'
import Priceconstants from '../../../constants/imageConstants';

function Target() {
  const navigator = useNavigate()
  const [data,setdata] = useState([])
  const [modal, setModal] = useState(false);
  const [page,setpage] = useState(1)
  
  const [selectedData,setselectedData] = useState({})
  const [yearData,setyearData] = useState([])
  const [selectedYear,setselectedYear] = useState('')

  useEffect(()=>{
    getdata()
    getAllInsentiveList()
  },[page,selectedYear])

  async function getdata(){
    const response = await GetTargetService(page,selectedYear)
    setdata(response.data)
  }

  async function getAllInsentiveList(){
    const response = await GetAllInsentiveList()
    let arr = []
    response?.data?.datas?.forEach((d)=>{
      arr.push({label:d?.year,value:d?.id})
    })
    setyearData(arr)
  }

  async function deleteTargetData(){
    const response = await DeleteTargetService(selectedData?._id)
    if(response?.status === 200){
      toast.success('Target Data Deleted Successfully')
      setModal(false)
      setselectedData({})
      getdata()
    }
  }

  async function resetfunc(){
    setselectedYear('')
  }

  function returnarrayfunc(v){
    if(Array.isArray(v)){
      let total = 0;
      v.forEach((v1)=>{
        total += v1
      })
      return total
    }else{
      return 0
    }
    

  }



  return (
    <div>
    
    <div className='flex'>
      <SettingsMenu />
    <div className='px-4 w-full'>
    <div className='flex items-center mt-5 border-b pb-2 justify-between  border-b'>
    <h6 className='font-[900] text-[14px] '>Target ({data?.pagination?.total})</h6>

    <div className='flex items-center'>
    <div className='flex items-center text-[12px] mr-2'>
    <h6 className='mr-2 font-[600]'>{page == 1 ? data?.datas?.length > 0 ? 1 : 0 : (page - 1) * data?.pagination?.limit } - {data?.pagination?.limit} of {data?.pagination?.total} </h6>
    <IconButton onClick={resetfunc} ><BsArrowRepeat size={16} /></IconButton>

    <div>
    <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}} ><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
    <IconButton onClick={()=>{ page < data?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${data?.pagination?.totalPages === page ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>

    </div>
    </div> 
    <Select options={yearData} placeholder="Year" bordered={false} value={selectedYear !== '' ? selectedYear : null} onChange={(e)=>setselectedYear(e)} size='small' className='border rounded-md border-slate-300 w-[80px] py-[2px] mr-2'/>

    <ButtonFilledAutoWidth onClick={()=>navigator('create')} btnName="Add New" />
    </div>
    </div>

    <Modal
    open={modal}  
    closable={false}
    maskClosable={false}
    onClose={()=>setModal(false)}
    bodyStyle={{borderRadius:'0px !important'}}
    style={{maxWidth:'300px',borderRadius:'0px !important'}}
    footer={null}
  >
  <div style={{maxWidth:'300px',borderRadius:'0px !important'}} className="relative z-0 ">
       <IoIosClose size={20} className="absolute -right-4 -top-4 z-10" onClick={()=>{setModal(false)}} />
       <h6 className='text-[15px] font-[700]'>Are you sure ?</h6>
       <h6 className='text-[11px] leading-tight'>Please be sure before deleting the team because it might affect the team target and other process.</h6>
      

         
       <div className='flex mt-2'>
        <div className='mr-1'>
        <ButtonOutlined btnName ="Cancel" onClick={()=>{setModal(false);setselectedData({});setpage(1)}}  />
        </div>
        <div className='ml-1'  >

        <ButtonFilled btnName ="Confirm"  onClick={deleteTargetData}/>
        </div>
       </div>
  </div>
  </Modal>

      {data?.datas?.length === 0 &&
        <div className='grid place-items-center mt-20  items-center justify-center'>
          <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' /> 
          <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
          <h6 className='font-[500] w-[70%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any data added based on your current page option please click on add the data button to see list here.</h6>
        </div>
       }

    {data?.datas?.length > 0 && 
    <div className="max-h-[85vh] overflow-y-scroll grid grid-cols-2 md:grid-cols-4 mt-1 -mx-0.5">
            {data?.datas?.map((d)=>(
            <div key={d?._id} className='border px-3 py-1 mt-1 -pt-2 mx-1 relative cursor-pointer' >
                <div className='mt-1 relative'>
                    <span className='absolute right-1' onClick={()=>{navigator('edit',{state:d})}}><AiOutlineEdit size={14}/></span>
                    <span className='absolute right-7'  onClick={()=>{setselectedData(d);setModal(true)}} ><AiOutlineDelete size={14}/></span>
                    <h6 className='text-[12px] font-[800]' >{d?.user?.name}</h6>
                    <h6 className='text-[11px] font-[500]' >Year : {d?.target_year}</h6>
                    {/* {console.log(d?.target?.reduce((a,b)=>{return a+b},0))} */}
                    {/* <h6 className='text-[11px] font-[500]' >Year : {d?.target_year} /Salary : {d?.salary}</h6> */}
                    {/* <h6 className='text-[11px] font-[500]' >Achieve Times : {d?.achieve_times}</h6> */}
                    <h6 className='text-[11px] font-semibold mt-0 mb-0' >Target : {Priceconstants(returnarrayfunc(d?.target))} / Target Achieved: {d?.target_achieved}</h6>
                    <h6 className='text-[11px] font-semibold mt-0 mb-0 -ml-0.5' > {d?.users?.map(e => <Tag className='text-[9px] m-0.5'>{e?.name}</Tag>  )}</h6>
                    <h6 className='text-[9px] font-semibold mb-1' >Created At : {moment(d?.createdAt).format('LLL')}</h6>
                </div>
            </div>))}
    </div>}

    {/* <div className="tex-center flex items-center justify-center mt-10 ">
      {(data?.pagination?.total > data?.pagination?.limit &&
        <Pagination size={'small'} page={page} className="flex justify-center my-10 pb-20" count={Math.ceil(data?.pagination?.total / 25) } onChange={handlePagination} />
      )}
</div> */}
</div>  
</div>


</div>
  )
}

export default Target