import { deleteRequest, get, post, postfd, put } from "../../helpers/apihelpers";



export const UploadAtsEmployeeService=async(data)=>{
    try {
        const result = await postfd(`api/hr/employee/upload_file`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const CreateAtsEmployeeService=async(data)=>{
    try {
        const result = await postfd(`api/hr/employee/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const CreateAtsEmployeeToClientJobService=async(data)=>{
    try {
        const result = await post(`api/hr/employee/assign_employee_client`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateAtsEmployeeService=async(data,id)=>{
    try {
        const result = await postfd(`api/hr/employee/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetAtsEmployeeService=async({page})=>{
    try {
        const result = await get(`api/hr/employee/get?page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}


export const GetAtsDetailEmployeeService=async(id)=>{
    try {
        const result = await get(`api/hr/employee/get/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const FilterAtsEmployeeService=async({text,from_date,to_date,page})=>{
    try {
        const result = await get(`api/hr/employee/filter?search_text=${text}&from_date=${from_date}&to_date=${to_date}&page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteAtsEmployeeService=async(id)=>{
    try {
        const result = await deleteRequest(`api/hr/employee/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}


export const GetAllAtsEmployeeSharedBasedOnJd=async({page})=>{
    try {
        const result = await get(`api/hr/employee/get_all_employee_shared_based_on_jd?page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetAllAtsEmployeeSharedBasedOnHiringStage=async(page,stage,sub_stage,from_date,to_date)=>{
    try {
        const result = await get(`api/hr/employee/get_all_employee_shared_based_on_hiring_stage?page=${page}&stage=${stage}&sub_stage=${sub_stage}&from_date=${from_date}&to_date=${to_date}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const FilterAllAtsEmployeeSharedBasedOnJd=async({page,text,from_date1,to_date1})=>{
    try {
        const result = await get(`api/hr/employee/filter_all_employee_shared_based_on_jd?search_text=${text === undefined ? ''  : text}&from_date=${from_date1}&to_date=${to_date1}&page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}