import React,{useState,useEffect} from 'react'
import ItMenu from '../ItMenu'
import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../../components/button';
import { Modal,DatePicker} from 'antd';
import { IconButton } from '@mui/material'
import moment from 'moment';
import { AiOutlineEdit,AiOutlineDelete } from 'react-icons/ai';
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { DeleteUpcomingPurchaseOptionOptionService, GetUpcomingPurchaseOptionOptionService } from '../../../services/ITServices/PuchaseOption/UpcomingPurchaseOptionServices';
import { useSelector } from 'react-redux';
import { GetInventoryOptionService } from '../../../services/ITServices/InventoryOption/InventoryOptionServices';
import { DeleteInventoryPurchaseOptionService, GetInventoryPurchaseOptionService } from '../../../services/ITServices/PuchaseOption/InventoryPurchaseOptionServices';

function InventoryPurchaseOptionList() {

  const roles = useSelector(state=>state.Auth.roles)

  const navigator = useNavigate()

  const [step,setstep] = useState(1)
  const [data,setdata] = useState([])
  const [selecteddata,setselecteddata] = useState({})
  const [modal, setModal] = useState(false);
  const [search,setsearch] = useState({text:'',from_date:'',to_date:'',from_date1:'',to_date1:''})

  const [page,setpage] = useState(1)

  const navigate = useNavigate()

 


  useEffect(()=>{
    getdata()
  },[page])







    async function getdata(){
        const response = await GetInventoryPurchaseOptionService(page,search?.text,search?.from_date1,search?.to_date1)
        setdata(response.data)
    }  

    async function deletedata(){
        const response = await DeleteInventoryPurchaseOptionService(selecteddata._id)
        if(response.status === 200){
            setModal(false)
            toast.success("Deleted Successfully")
            getdata()
        }
    }

    async function resetfunc() {
      setpage(1)
      setsearch({text:'',from_date:'',to_date:'',from_date1:'',to_date1:''})
      const response = await GetInventoryPurchaseOptionService(1,'','','')
      setdata(response.data)
    }

    async function applyfilterfunction() {
      setpage(1)
      const response = await GetInventoryPurchaseOptionService(1,search?.text,search?.from_date1,search?.to_date1)
      setdata(response.data)
    }

  return (
    <div className='flex mx-0 box-border  max-h-screen overflow-y-scroll overflow-x-hidden'>
        <Modal
        keepMounted
        open={modal}
        onClose={()=>setModal(false)}
        width={300}
        footer={false}
        closable={false}
       
      >
        <div >
          <h6 className="font-bold text-[15px] text-center mb-2 w-full">Are you sure?</h6>
          <h6 className='bg-slate-100 text-center text-[12px] p-1.5 font-[400]'>After deleting you cannot retrieve it back before deleting check once whether you have used it in some data</h6>
          <div className='flex justify-end mt-3 '>
            {/* <div className='mr-1 w-full'> */}
            <ButtonOutlinedAutoWidth btnName={'Cancel'} onClick={()=>setModal(false)} />
            {/* </div> */}
            <div  className='ml-2'>
            <ButtonFilledAutoWidth btnName={'Confirm'} onClick={()=>deletedata()}  /> 
            </div>
          </div>
        </div>
        </Modal>
        

        <div>
            <ItMenu />
        </div>
        <div className='px-4 w-[100%]'>
        <div >
        <div className='pt-5'>
        <div className="flex justify-between align-center items-center border-b pb-2 ">
        <span className="font-black text-[14px]">Total Inventory Puchase ({data?.pagination?.total})</span>


        <div className='flex items-center'>
              <div className='flex items-center text-[12px] mr-2'>
                   

                        <h6 className='mr-2 font-[600]'>{page == 1 ? data?.datas?.length > 0 ? 1 : 0 : (page - 1) * data?.pagination?.limit } - {data?.pagination?.limit} of {data?.pagination?.total} </h6>
                        <IconButton onClick={resetfunc}><BsArrowRepeat size={16} /></IconButton>

                        <div>
                        <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                        <IconButton onClick={()=>{ page < data?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(data?.pagination?.totalPages === page || data?.datas?.length === 0)  ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>

                        </div>
                    </div>


                    <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.from_date} onChange={(v,v1) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,from_date:v,from_date1:v1})}} /> 

                    <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.to_date} onChange={(v,v1) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,to_date:v,to_date1:v1})}} /> 

                    
                    <div className='mr-2 flex'>
                        

                    <input  id="search_text" placeholder='Search text' type='text' value={search.text} onChange={(e)=>setsearch({...search,text:e.target.value})} className='border py-[3px] focus:ring-0 focus:outline-0 text-[14px]  w-28 px-1 rounded-md border-slate-300' />
                     
                 

                   
                    </div>    

                  
                <ButtonOutlinedAutoWidth btnName="Add Filter" onClick={()=>applyfilterfunction(1)} /> 
               
                <div className='ml-2'>
                <ButtonFilledAutoWidth btnName="Add Data" onClick={()=>navigate('create')}/> 
                </div>
                    </div>

        
        </div>
        </div>

       
        {data?.datas?.length === 0 &&
          <div className='grid place-items-center mt-20  items-center justify-center'>
            <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' /> 
            <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
            <h6 className='font-[500] w-[70%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any data added based on your current page option please click on add the data button to see list here.</h6>
          </div>
        }


        {data?.datas?.length > 0 &&
         <div className='h-[88vh] w-[100%] overflow-x-hidden overflow-y-scroll'>
         {data?.datas?.length > 0 &&
         <div className='max-h-[85vh] w-[100%] mt-4 border-t  overflow-x-hidden  border-l border-r overflow-y-scroll'>
               <div className='sticky top-0 z-50 flex  bg-white py-[-5px] border-b border-slate-200 relative'>
                   <h6  className='top-0 bg-white z-50 text-[12px] min-w-[5%]  max-w-[5%]  px-2 py-1 font-[600] text-slate-600 border-r flex items-center justify-center'>Sl No</h6>
                   <h6 className='sticky top-0 z-50  text-[12px]  min-w-[15%] max-w-[15%]  px-2 py-1 font-[600] text-slate-600 border-r'>Purchased Department</h6>
                   <h6 className='sticky top-0 z-50  text-[12px] min-w-[40%] max-w-[40%]  px-2 py-1 font-[600] text-slate-600 border-r'>Purchase Summary</h6>
                   <h6 className='sticky top-0 z-50  text-[12px] min-w-[13%] max-w-[13%]  px-2 py-1 font-[600] text-slate-600 border-r'>Purchased Date</h6>
                   <h6 className='sticky top-0 z-50  text-[12px] min-w-[18%] max-w-[18%]  px-2 py-1 font-[600] text-slate-600 border-r'>Remarks</h6>
                   <h6 className='sticky top-0 z-50  text-[12px] min-w-[10%] max-w-[10%]  px-2 py-1 font-[600] text-slate-600 '>Actions</h6>
               </div>
               {data?.datas?.map((d,i)=>(
               <div className='flex  bg-white py-[-5px] border-b border-slate-200 relative'>
                   <h6  className='bg-white text-[12px] min-w-[5%]  max-w-[5%]   px-2 py-1 font-[500] border-r flex  justify-center'>{(page > 1 ? i+1+ (data?.pagination?.limit * (page - 1)) : i+1 )}</h6>
                   <h6 className=' text-[12px] min-w-[15%] max-w-[15%] px-2 py-1 font-[500] border-r'>{d?.department?.department_name}</h6>
                   <h6 className=' text-[12px]  min-w-[40%] max-w-[40%]  px-2 py-1 font-[500] border-r'>{d?.purchase_summary?.map((p)=>(
                    <span key={p?._id}>
                    <span>Item Info : <b>{p?.item_info}</b></span> / <span>Quantity :  <b>{p?.quantity}</b> /</span> <span>Cost :  <b>{p?.cost}</b> /</span>  <span>Purchase Type :  <b>{p?.purchase_type}</b> </span> <br></br>
                    </span>
                   ))}</h6>
                   <h6 className=' text-[12px]  min-w-[13%] max-w-[13%] px-2 py-1 font-[500] border-r'>{moment(d?.createdAt)?.format('lll')}</h6>
                   <h6 className=' text-[12px] min-w-[18%] max-w-[18%]  px-2 py-1 font-[500] border-r'>{d?.remarks}</h6>
                   <h6 className='flex items-center text-[12px]  min-w-[10%] max-w-[10%]  px-2 py-1 font-[500] '>
                     <AiOutlineEdit onClick={()=>navigator(`edit`,{state:d})} className='mr-2' />
                     {(roles?.includes('admin') || roles?.includes('delete_data')) &&
                     <AiOutlineDelete onClick={()=>{setselecteddata(d);setModal(true)}} />}
                   </h6>
               </div>))}
         </div>}
     </div>}
          </div>
        </div>
    </div>
  )
}

export default InventoryPurchaseOptionList