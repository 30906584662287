import { post,put,get,deleteRequest, postfd } from "../../../helpers/apihelpers";

export const UploadManagedOfficeAttachmentService=async(data)=>{
    try {
        const result = await postfd(`api/database/managed_office/upload_file`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const CreateManagedOfficeService=async(data)=>{
    try {
        const result = await post(`api/database/managed_office/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateManagedOfficeService=async(data,id)=>{
    try {
        const result = await put(`api/database/managed_office/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetManagedOfficeService=async(page,from_date,to_date,text,step)=>{
    try {
        const result = await get(`api/database/managed_office/get?page=${page}&from_date=${from_date}&to_date=${to_date}&text=${text}&step=${step}`,);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteManagedOfficeService=async(id)=>{
    try {
        const result = await deleteRequest(`api/database/managed_office/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteAdminManagedOfficeService=async(id)=>{
    try {
        const result = await deleteRequest(`api/database/managed_office/delete_admin/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const FilterManagedOfficeService=async(id)=>{
    try {
        const result = await get(`api/database/managed_office/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}
