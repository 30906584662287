import React from 'react'
import MDCallsMenu from './MDCallsMenu'
import MDCallsLeadlist from './MDCallsLeadlist'

function MDCallsLeadBase() {

  return (
    <div className='h-screen max-h-screen box-border overflow-hidden'>

        <div className='block sm:flex'>
        <MDCallsMenu />
        <div className='w-[86%]  ml-4  mt-4'>
         <MDCallsLeadlist />
        </div>    

    </div>    
    </div>
  )
}

export default MDCallsLeadBase